import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(rows: object[], fileName: string, columns?: string[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    let keys: string[] = [];

    rows.forEach((r) => {
      const _keys = Object.keys(r).filter((k) => {
        if (columns?.length) {
          return columns.includes(k);
        } else {
          return true;
        }
      });
      keys = [..._keys, ...keys];
    });

    keys = keys.filter((n, i) => keys.indexOf(n) === i);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map((row: any, i) => {
          const line = keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');

              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }

              return cell;
            })
            .join(separator);
          return line;
        })
        .join('\n');

    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }
  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }
}
