import { IOnboardingMessages } from '../types/interfaces/configuration/messages';

// TODO: move to config
// export const LOGIN_FAILED = 'Login is mislukt, probeer het opnieuw.';
// export const FORM_VALIDATION_FAILED_SNACKBAR = 'Vul het formulier in.';
export const DEFAULT_MESSAGES: IOnboardingMessages = {
  generalFailure: 'A server communication error occured.. please try again.',
  sessionExpired: 'Session expired. Log in to continue.',
  login: {
    failed: 'Login failed.',
    success: 'Logged in.',
    invalid: 'Validation failed.',
    twoFaFailed: '2-Factor authentication failed.',
    ui: {
      button_text: 'LOG IN',
      forgot_password_link_text: 'Forgot password?',
      label_password: 'Password',
      label_username: 'Username',
      subtitle: 'Enter your username and password to log in.',
      title: 'Welcome back!',
    },
  },
  logout: {
    confirmButtonText: 'LOG OUT',
    confirmQuestion: 'Are you sure you want to log out?',
  },
  twoFa: {
    ui: {
      title: 'Set up 2-Step Verification',
      input_code_label: 'Enter code from your Authenticator app.',
      qr_code_label:
        'Scan this QR code in the Authenticator App to link your device.',
      code_input_label: 'Authenticator code',
      whoops: 'Whoops...',
      fetch_code_error:
        'Something went wrong trying to fetch your code. Try again later',
      back_button_text: 'Back',
      proceed_button_text: 'Proceed',
      failed_snackbar: 'Login failed, try again.',
      two_step_failed: '2-Step verification failed, try again.',
      invalid_token: 'Token was invalid, this should not happen',
    },
  },
  editProfile: {
    cancel_button_text: 'Cancel',
    form: {
      email: 'E-Mail',
      firstname: 'Firstname',
      job_title: 'Job Title',
      lastname: 'Lastname',
      mobile: 'Mobile phone',
      submit_button_text: 'Save',
      telephone: 'Telephone',
    },
    remove_button_text: 'Remove',
    reset_button_text: 'RESET PASSWORD',
    reset_button_tooltip: 'Sends a reset password link to your e-mail address.',

    reset_2fa_button_text: 'RESET 2ND FACTOR DEVICE',
    reset_2fa_button_tooltip:
      "Reset 2-factor authentication device. You'll be able to link a new device on your next login.",

    slider: 'ENABLE 2-FACTOR AUTHENTICATION (RECOMMENDED)',
    upload_button_text: 'Upload',
    uploader_text:
      'Drop file here for upload or click to select a file from your device',
    update_failed: 'Your profile could not be updated!',
    updated: 'Your profile has been updated!',
    profile_undefined_error: 'User profile is not defined',
    activated_2fa_failure:
      'Could not activate two factor authentication. Try again later.',
    deactivated_2fa_failure:
      'Could not deactivate two factor authentication. Try again later.',
    activated_2fa_success:
      'Two factor authentication was successfully activated.',
    deactivated_2fa_success:
      'Two factor authentication was successfully deactivated.',
    reset_link_sent:
      'A link to reset your password is send to your e-mail address.',
    reset_link_failure: 'Could not send e-mail link... Try again later.',
  },
  register: {
    form: {
      email: 'E-Mail',
      firstname: 'Firstname',
      lastname: 'Lastname',
      register_button_text: 'Register',
      invalid: 'Please fill out the form before proceeding.', // NL: 'Gelieve het formulier volledig in te vullen.',
    },
  },
  resetPassword: {
    form: {
      back_button_text: 'Go Back',
      email: 'E-Mail',
      submit_button_text: 'Submit',
      invalid: 'Fill out the form correctly before proceeding.',
    },
    form_instructions: `Fill out you e-mail and receive a link where you can reset your
password`,
    title: 'Reset password',
    link_sent: 'Password link sent!', // NL: 'Wachtwoord link verzonden.'
    no_account_with_email: 'No account found with given e-mail address.', // NL: 'Er bestaat geen account met dit e-mailadres.'
  },
  setPassword: {
    form: {
      password: 'Password',
      password_confirmation: 'Password confirmation',
      submit_button_text: 'Submit',
      invalid: 'Fill out the form before proceeding.', // 'Vul het formulier in.'
    },
    form_instructions: 'Fill out your new password',
    title: 'Choose a new password',
    update_failed:
      'Something went wrong in the process of updating password. Try again.', // NL: 'Er ging iets fout bij het wijzigen van jouw wachtwoord. Probeer het nog eens.'
    updated: 'Password reset successfully.', // NL: 'Wachtwoord is opnieuw ingesteld.'
    passwords_dont_match: "Passwords don't match!", // NL: 'Wachtwoorden komen niet overeen'
    minLengthError: (min: number) =>
      `Password should be at least ${min} characters.`, // 'Wachtwoorden moet minstens ' + this.config.minPasswordLength + ' karakters tellen.'
  },
};
