<div class="dialog-container">
  <h1 mat-dialog-title>Map CSV</h1>
  <div mat-dialog-content>
    <p>Choose a name</p>
    <ng-container *ngIf="selectedPreset === 'new' && viewmodel">
      <mat-accordion class="property-map-accordion">
        <mat-expansion-panel *ngFor="let vm of viewmodel; let i = index">
          <mat-expansion-panel-header [class.dismissed]="vm.dismissed">
            <mat-panel-title>
              {{ vm.fg.get('propName')?.value }}
              <mat-icon *ngIf="vm.dismissed">not_interested</mat-icon>
            </mat-panel-title>
            <mat-panel-description>
              {{
                vm.fg.get('propDescription')?.value ??
                  'mapped from ' + vm.importMap.name
              }}
              <span>
                <button
                  mat-icon-button
                  [color]="vm.dismissed ? 'warn' : 'primary'"
                  (click)="togglePropertyDismissed(vm, $event)"
                >
                  <mat-icon *ngIf="vm.dismissed === false"
                    >not_interested</mat-icon
                  >
                  <mat-icon *ngIf="vm.dismissed === true">restart_alt</mat-icon>
                </button>
                <mat-icon>
                  {{
                    vm.fg.get('propType')?.value === 'number' ? 'tag' : 'notes'
                  }}
                </mat-icon>
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="expansion-panel-body">
            <div class="viewmodel-group col" [formGroup]="vm.fg">
              <div>
                <mat-form-field appearance="outline" class="viewmodel-field">
                  <mat-label>Property Name</mat-label>
                  <input matInput type="text" formControlName="propName" />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="outline" class="viewmodel-field">
                  <mat-label>Value Type</mat-label>
                  <mat-select formControlName="propType">
                    <mat-option [value]="'string'">string</mat-option>
                    <mat-option [value]="'number'">number</mat-option>
                    <mat-option [value]="'enum'">enum</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <ng-container
                *ngIf="
                  vm.fg.get('propType')?.value === 'enum' &&
                  vm.fg.get('enumValues')
                "
              >
                <div formArrayName="enumValues" *ngIf="enumControls(vm.fg)">
                  <div
                    class="viewmodel-array-row"
                    *ngFor="
                      let option of enumControls(vm.fg);
                      let optionId = index
                    "
                  >
                    <mat-form-field>
                      <mat-label>Enum Options</mat-label>
                      <input
                        matInput
                        type="text"
                        [formControlName]="optionId"
                      />
                      <button
                        matSuffix
                        mat-icon-button
                        aria-label="Remove"
                        class="viewmodel-array-infix-remove-button"
                        (click)="removeEnumValueControl(vm.fg, optionId)"
                        color="warn"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="addEnumValueControl(vm.fg)"
                  >
                    <mat-icon>add</mat-icon> OPTION
                  </button>
                </div>
                <mat-divider class="enum-options-divider"></mat-divider>
              </ng-container>
              <div>
                <mat-form-field appearance="outline" class="viewmodel-field">
                  <mat-label>Description</mat-label>
                  <textarea
                    matInput
                    formControlName="propDescription"
                    cols="30"
                    rows="10"
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="actions">
                <button
                  mat-raised-button
                  [color]="vm.dismissed ? 'warn' : 'primary'"
                  (click)="togglePropertyDismissed(vm)"
                >
                  <mat-icon *ngIf="vm.dismissed === false"
                    >not_interested</mat-icon
                  >
                  <mat-icon *ngIf="vm.dismissed === true">restart_alt</mat-icon>
                  {{ vm.dismissed ? 'RE-ENABLE' : 'DISMISS' }}
                </button>
              </div>
            </div>
            <div class="col">
              <mat-list>
                <div mat-subheader>Samples</div>
                <mat-list-item *ngFor="let sample of vm.sampleValues">{{
                  sample
                }}</mat-list-item>
              </mat-list>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>
  <div mat-dialog-actions align="end">
    <a class="cancel-link cancel" (click)="ref.close()"> Cancel </a>
    <button mat-raised-button (click)="importData()">IMPORT</button>
  </div>
</div>
