<h1 mat-dialog-title i18n="@@remove-user-title">Remove User</h1>
<div mat-dialog-content>
  <p i18n="@@are-you-sure-delete-team-member">
    Are you sure you want to remove
    <span class="bold">{{ data.firstName }}</span> from your team?
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close i18n="@@no">NO</button>
  <button mat-button (click)="removeUser(data.id)" i18n="@@yes">YES</button>
</div>
