export interface NavLink {
  isActive: boolean;
  label: string;
  icon?: string;
  link?: string;
  info?: string;
  children?: NavLink[];
}

export class NavLink implements NavLink {
  constructor(
    label: string,
    link: string,
    info?: string,
    icon?: string,
    isActive?: boolean
  ) {
    this.label = label;
    this.link = link;
    this.info = info;
    this.isActive = isActive ?? false;
    this.icon = icon;
  }
}

export class NavLinkGroup implements NavLink {
  isActive: boolean;
  label: string;
  link = undefined;
  icon?: string;
  info?: string | undefined;
  children?: NavLink[] | undefined;
  constructor(
    label: string,
    children: NavLink[],
    icon?: string,
    info?: string
  ) {
    this.label = label;
    this.info = info;
    this.children = children;
    this.isActive = false;
    this.icon = icon;
  }
}
