import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  GdprToolState,
  selectAddSubjectRegisterSettingsData,
} from '../../../store';
import { Store } from '@ngrx/store';
import {
  ExternalController,
  ProcessingPurpose,
  DataLocation,
  DisclosedTo,
  LegalGround,
  TransferOutsideEEA,
} from '@ca/gdpr';
import { CaSubscriber, IndexedName } from '@ca/ca-utils';

@Component({
  selector: 'ca-add-subject-register-overview',
  template: `
    <div class="add-subject-register-overview">
      <h1 i18n="@@overview-title">Overview</h1>
      <p i18n="@@overview-subtitle">
        You'll be adding these purposes to the [SUBJECT TYPE] data register,
        with the following global parameters:
      </p>
      <mat-list>
        <ng-container *ngFor="let u of upsertData">
          <div mat-subheader>{{ u['purpose'].name }}</div>

          <mat-list-item
            ><span i18n="@@locations-list-label">locations: </span
            >{{
              displayNamedObject(u['dataLocationIds'], dataLocations)
            }}</mat-list-item
          >
          <mat-list-item
            ><span i18n="@@legal-grounds-list-label">legal-grounds: </span>
            {{ displayNamedObject(u['legalGroundIds'], legalGrounds) }}
            {{
              u['legalGroundClarification']
                ? '- ' + u['legalGroundClarification']
                : ''
            }}
          </mat-list-item>
          <mat-list-item
            ><span i18n="@@lifetime-list-label"> lifetime:</span>
            {{ u['lifetimeMonths'] }} months
            {{
              u['lifetimeMonthsClarification']
                ? '- ' + u['lifetimeMonthsClarification']
                : ''
            }}</mat-list-item
          >
          <mat-list-item
            ><span i18n="@@disclosed-to-list-label"
              >This information is disclosedTo</span
            >
            {{ displayNamedObject(u['disclosedToIds'], disclosedTos) }}
          </mat-list-item>
          <mat-list-item
            ><span i18n="@@transfers-outside-eea-label"
              >transfers outsideEEA:</span
            >{{
              displayNamedObject(
                u['transfersOutsideEEAIds'],
                transfersOutsideEEA
              )
            }}
            {{
              u['transfersOutsideEEAClarification']
                ? '- ' + u['transfersOutsideEEAClarification']
                : ''
            }}
          </mat-list-item>
        </ng-container>
      </mat-list>
    </div>
  `,
})
export class AddSubjectRegisterOverviewComponent implements OnInit, OnDestroy {
  @Input() upsertData!: { [key: string]: any }[];
  private sub = new CaSubscriber();
  purposes: ProcessingPurpose[] = [];
  dataLocations?: DataLocation[];
  disclosedTos?: DisclosedTo[];
  externalControllers?: ExternalController[];
  legalGrounds?: LegalGround[];
  transfersOutsideEEA?: TransferOutsideEEA[];

  constructor(private store: Store<GdprToolState>) {
    this.sub.subscribe(
      this.store.select(selectAddSubjectRegisterSettingsData),
      {
        next: (data) => {
          if (data.processingPurposes) this.purposes = data.processingPurposes;
          if (data.dataLocations) this.dataLocations = data.dataLocations;
          if (data.disclosedTo) this.disclosedTos = data.disclosedTo;
          if (data.externalControllers)
            this.externalControllers = data.externalControllers;
          if (data.legalGrounds) this.legalGrounds = data.legalGrounds;
          if (data.transfersOutsideEEA)
            this.transfersOutsideEEA = data.transfersOutsideEEA;
        },
      }
    );
  }

  displayNamedObject<T extends IndexedName>(ids: number[], list?: T[]) {
    return list && ids
      ? list
          .filter((e) => ids.includes(e.id))
          .map((e) => e.name)
          .join(', ')
      : [];
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  ngOnInit(): void {
    if (!this.upsertData) throw new Error('Upsert data must be specified');
  }
}
