import { Component } from '@angular/core';
import { GdprToolState } from '../../../store';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '../../shared/dumb/info-modal/info-modal.component';
import { DSR_CATEGORIES } from '../../../config/dsr';
import { ProcedureCategory } from '@ca/gdpr';
import { UpsertDataSubjectRequestDialogComponent } from '../dialogs/upsert-data-subject-request-dialog/upsert-data-subject-request-dialog.component';
import { UpsertDataSubjectRequest } from '@ca/gdpr';
import { newDSR } from '../../../config/defaults';

@Component({
  selector: 'ca-procedure-categories',
  template: `<div class="container container-sbtw dsr-categories">
    <h1 i18n="@@dsr-categories-title">DSR Categories</h1>
    <mat-grid-list cols="4" rowHeight="2:1" gutterSize="1.33em">
      <mat-grid-tile
        *ngFor="let dsr of dsrCategories"
        class="dsr-category-container"
      >
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{ dsr.title }}</mat-card-title>
          </mat-card-header>
          <mat-card-content></mat-card-content>
          <mat-card-actions align="end">
            <button
              mat-button
              (click)="registerRequest(dsr)"
              i18n="@@register-dsr-btn"
            >
              Register Request
            </button>
            <button
              mat-button
              (click)="openInfoDialog(dsr)"
              i18n="@@more-info-btn"
            >
              More Info
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div> `,
  styles: [
    `
      $container-padding: 1.33vw;
      $toolbarHeight: 64px;
      .container {
        padding: $container-padding;
        width: calc(100vw - $container-padding * 2);
        min-height: calc(100vh - $toolbarHeight - $container-padding * 2);
      }

      .dsr-categories-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 1.33em;
        column-gap: 1.33em;
      }
      .mat-grid-list {
        width: 100%;
        height: 100%;
      }
      .mat-mdc-card {
        width: 100%;
        min-height: 9.33em;
        // height: 100%;
      }
    `,
  ],
})
export class ProcedureCategoriesComponent {
  dsrCategories: ProcedureCategory[] = DSR_CATEGORIES;

  constructor(private store: Store<GdprToolState>, public dialog: MatDialog) {}
  registerRequest(dsr: ProcedureCategory) {
    this.dialog.open(UpsertDataSubjectRequestDialogComponent, {
      data: newDSR(dsr.right),
      disableClose: true,
    });
  }
  openInfoDialog(dsr: ProcedureCategory) {
    this.dialog.open(InfoModalComponent, {
      data: { title: dsr.title, htmlContent: dsr.description },
    });
  }
}
