//#region Varia
import { CaDataModuleConfiguration } from '@ca/ca-data';
import { environment } from '../environments/environment.development';
import { ROOT_REDUCER, metaReducers } from './store';
//#endregion

//#region Modules
import {
  AreYouSureOverlayConfiguration,
  CaAreYouSureModule,
  DEFAULT_ARE_YOU_SURE_OVERLAY_CONFIGURATION,
} from '@ca/ca-are-you-sure';
import {
  OnboardingModule,
  OnboardingModuleConfiguration,
} from '@ca/onboarding';
import {
  SnackbarModule,
  SnackbarModuleConfiguration,
  SnackbarServiceConfiguration,
} from '@ca/ca-snackbar';
import { MailingModule } from '@ca/mailing';
import { CsvImportMapperModule } from '@ca/csv-import-mapper';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CA_ENVIRONMENT, CaNgCoreModule } from '@ca/ca-ng-core';
import { OverlayModule } from '@angular/cdk/overlay';
import { UnlayerEditorModule } from '@ca/unlayer-editor';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

//#endregion

//#region Services
import { ExternalControllersService } from './services/settings/external-controllers.service';
import { DataLocationsService } from './services/settings/data-locations.service';
// import { DataRegisterService } from './services/data-register/data-register.service';
import { ExternalProcessorsService } from './services/settings/external-processors.service';
import { SecurityMeasureService } from './services/settings/security-measure.service';
import { SubjectTypeService } from './services/settings/subject-type.service';
import { DSRService } from './services/dsr/dsr.service';
import { FileUploadService } from './services/core/file-upload.service';
import { InfoService } from './services/core/info.service';
import { MessagesService } from './services/core/messages.service';
import { RemarksService } from './services/core/remarks.service';
import { QuestionAndAnswerService } from './services/dsr/question-and-answer.service';
import { DsrDataSubjectQnaService } from './services/public/dsr-data-subject-qna.service';
//#endregion

//#region Effects
import { GeneralEffects } from './store/effects/general.effects';
import { MailingEffects } from './store/effects/mailing.effects';
import { DataRegisterEffects } from './store/effects/data-register.effects';
import { DSREffects } from './store/effects/dsr.effects';
import { SettingsEffects } from './store/effects/settings.effects';
import { FileUploadEffects } from './store/effects/file-upload.effects';
import { PublicEffects } from './store/effects/public.effects';
import { GenericEffects } from './store/effects/generic.effects';
//#endregion

//#region Pipes
import { NamedObjectsPipe } from './pipes/named-objects.pipe';
//#endregion

//#region Components
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { DataregisterComponent } from './components/data-register/data-register.component';
import { UpsertDataLocationDialogComponent } from './components/app-settings/dialogs/upsert-data-location-dialog/upsert-data-location-dialog.component';
import { DataLocationsComponent } from './components/app-settings/data-locations/data-locations.component';
import { ExternalProcessorsComponent } from './components/app-settings/external-processors/external-processors.component';
import { UpsertExternalProcessorDialogComponent } from './components/app-settings/dialogs/upsert-external-processor-dialog/upsert-external-processor-dialog.component';
import { DataSubjectTypeComponent } from './components/app-settings/data-subject-type/data-subject-type.component';
import { UpsertSubjectTypeComponent } from './components/app-settings/dialogs/upsert-subject-type/upsert-subject-type.component';
import { SecurityMeasuresComponent } from './components/app-settings/security-measures/security-measures.component';
import { UpsertSecurityMeasureDialogComponent } from './components/app-settings/dialogs/upsert-security-measure-dialog/upsert-security-measure-dialog.component';
import { TeamComponent } from './components/team/team.component';
import { LegalGroundsComponent } from './components/app-settings/legal-grounds/legal-grounds.component';
import { UpsertLegalBasisDialogComponent } from './components/app-settings/dialogs/upsert-legal-basis-dialog/upsert-legal-basis-dialog.component';
import { TransfersOutsideEeaComponent } from './components/app-settings/transfers-outside-eea/transfers-outside-eea.component';
import { UpsertTransferOutsideEEADialogComponent } from './components/app-settings/dialogs/upsert-transfer-outside-eeadialog/upsert-transfer-outside-eeadialog.component';
import { DisclosedToComponent } from './components/app-settings/disclosed-to/disclosed-to.component';
import { UpsertDisclosedToDialogComponent } from './components/app-settings/dialogs/upsert-disclosed-to-dialog/upsert-disclosed-to-dialog.component';
import { InfoHoverComponent } from './components/shared/dumb/info-hover/info-hover.component';
import { InfoModalComponent } from './components/shared/dumb/info-modal/info-modal.component';
import { DataSubjectRequestDetailComponent } from './components/dsr/data-subject-request-detail/data-subject-request-detail.component';
import { RemoveDataSubjectRequestDialogComponent } from './components/dsr/dialogs/remove-data-subject-request-dialog/remove-data-subject-request-dialog.component';
import { UpsertDataSubjectRequestDialogComponent } from './components/dsr/dialogs/upsert-data-subject-request-dialog/upsert-data-subject-request-dialog.component';
import { DSRComponent } from './components/dsr/dsr.component';
import { ProcedureCategoriesComponent } from './components/dsr/procedure-categories/procedure-categories.component';

import { RemarksComponent } from './components/shared/remarks/remarks.component';
import { UpsertRemarkComponent } from './components/shared/remarks/upsert-remark/upsert-remark.component';
import { ExternalProcessorsTableComponent } from './components/shared/dsr/external-processors-table/external-processors-table.component';
import { UpsertRemarkDialogComponent } from './components/shared/remarks/upsert-remark-dialog/upsert-remark-dialog.component';
import { RemarksListComponent } from './components/shared/remarks/remarks-list/remarks-list.component';
import { ConfirmIdentityComponent } from './components/public/confirm-identity/confirm-identity.component';
import { DsrSummaryComponent } from './components/dsr/dsr-summary/dsr-summary.component';
import { PrivacyStatementGeneratorDialogComponent } from './components/shared/privacy-statement-generator-dialog/privacy-statement-generator-dialog.component';
import { CompanySettingsComponent } from './components/company-settings/company-settings.component';
import { ExternalControllersComponent } from './components/app-settings/external-controllers/external-controllers.component';
import { ProcessingPurposesComponent } from './components/app-settings/processing-purposes/processing-purposes.component';
import { UpsertProcessingPurposeComponent } from './components/app-settings/dialogs/upsert-processing-purpose/upsert-processing-purpose.component';
import { DataCategoriesComponent } from './components/app-settings/data-categories/data-categories.component';
import { UpsertDataCategoriesComponent } from './components/app-settings/dialogs/upsert-data-categories/upsert-data-categories.component';
import { ControllerRegisterComponent } from './pages/controller-register/controller-register.component';
import { ProcessorRegisterComponent } from './pages/processor-register/processor-register.component';
import { UpsertDataCategoryInRegisterComponent } from './components/data-register/upsert-data-category-in-register/upsert-data-category-in-register.component';
import { AddSubjectRegisterComponent } from './components/data-register/add-subject-register/add-subject-register.component';
import { UpsertExternalControllerComponent } from './components/app-settings/dialogs/upsert-external-controller/upsert-external-controller.component';
import { DataRegisterTableComponent } from './components/data-register/data-register-table/data-register-table.component';
import { UpsertPurposeInRegisterComponent } from './components/data-register/upsert-purpose-in-register/upsert-purpose-in-register.component';
import { AddSubjectRegisterDialogComponent } from './components/data-register/add-subject-register-dialog/add-subject-register-dialog.component';
import { AddSubjectRegisterOverviewComponent } from './components/data-register/add-subject-register-overview/add-subject-register-overview.component';
import { DataLocationsSelectorComponent } from './components/app-settings/controls/data-locations-selector/data-locations-selector.component';
import { DisclosedToSelectorComponent } from './components/app-settings/controls/disclosed-to-selector/disclosed-to-selector.component';
import { DataCategorySelectorComponent } from './components/app-settings/controls/data-category-selector/data-category-selector.component';
import { SecurityMeasuresSelectorComponent } from './components/app-settings/controls/security-measures-selector/security-measures-selector.component';
import { SelectorComponent } from './components/app-settings/controls/selector-component';
import { ProcessingPurposeSelectorComponent } from './components/app-settings/controls/processing-purpose-selector/processing-purpose-selector.component';
import { SelectDataSubjectTypesComponent } from './components/dsr/select-data-subject-types/select-data-subject-types.component';
import { MarkDataComponent } from './components/dsr/mark-data/mark-data.component';
import { DsrStatusBadgeComponent } from './components/dsr/dsr-status-badge/dsr-status-badge.component';
import { ProcessDsrComponent } from './components/dsr/process-dsr/process-dsr.component';
import { SystemMessagesComponent } from './components/shared/system-messages/system-messages.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { DsrCommunicationAreaComponent } from './components/dsr/dsr-communication-area/dsr-communication-area.component';
import { HUMIDOR_ONBOARDING_MESSAGES } from './config/onboarding';
import { ReplyToAdressesService } from './services/settings/reply-to-adresses.service';
import { ReplyToAddressesComponent } from './components/app-settings/reply-to-addresses/reply-to-addresses.component';
import { UpsertReplyToAddressesComponent } from './components/app-settings/dialogs/upsert-reply-to-addresses/upsert-reply-to-addresses.component';
import { NewRopaDialogComponent } from './components/new-ropa-dialog/new-ropa-dialog.component';
import { QuestionnaireComponent } from './components/data-register/questionnaire/questionnaire.component';
//#endregion

@NgModule({
  declarations: [
    // pipes
    NamedObjectsPipe,

    //#region Components
    AppComponent,
    HomeComponent,
    DataregisterComponent,
    DataLocationsComponent,
    ExternalProcessorsComponent,
    DataSubjectTypeComponent,
    SecurityMeasuresComponent,
    TeamComponent,
    DSRComponent,
    DataSubjectRequestDetailComponent,
    ProcedureCategoriesComponent,
    RemoveDataSubjectRequestDialogComponent,
    LegalGroundsComponent,
    TransfersOutsideEeaComponent,
    DisclosedToComponent,
    ExternalProcessorsTableComponent,
    ConfirmIdentityComponent,
    DsrSummaryComponent,
    PrivacyStatementGeneratorDialogComponent,
    CompanySettingsComponent,
    ExternalControllersComponent,
    ProcessingPurposesComponent,
    DataCategoriesComponent,
    ControllerRegisterComponent,
    ProcessorRegisterComponent,
    AddSubjectRegisterComponent,
    DataRegisterTableComponent,
    AddSubjectRegisterDialogComponent,
    AddSubjectRegisterOverviewComponent,
    DataLocationsSelectorComponent,
    DisclosedToSelectorComponent,
    DataCategorySelectorComponent,
    SecurityMeasuresSelectorComponent,
    ProcessingPurposeSelectorComponent,
    SelectDataSubjectTypesComponent,
    MarkDataComponent,
    DsrStatusBadgeComponent,
    ProcessDsrComponent,
    SystemMessagesComponent,
    NavigationComponent,
    //#endregion

    //#region Upsert
    UpsertDataLocationDialogComponent,
    UpsertExternalProcessorDialogComponent,
    UpsertSubjectTypeComponent,
    UpsertSecurityMeasureDialogComponent,
    UpsertDataSubjectRequestDialogComponent,
    UpsertLegalBasisDialogComponent,
    UpsertTransferOutsideEEADialogComponent,
    UpsertDisclosedToDialogComponent,
    UpsertRemarkDialogComponent,
    UpsertDataCategoriesComponent,
    UpsertExternalControllerComponent,
    UpsertProcessingPurposeComponent,
    UpsertRemarkComponent,
    UpsertDataCategoryInRegisterComponent,
    UpsertPurposeInRegisterComponent,
    //#endregion

    //#region Shared
    SelectorComponent,
    InfoHoverComponent,
    InfoModalComponent,
    RemarksComponent,
    RemarksListComponent,
    //#endregion

    DsrCommunicationAreaComponent,
    ReplyToAddressesComponent,
    UpsertReplyToAddressesComponent,
    NewRopaDialogComponent,
    QuestionnaireComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OverlayModule,
    HttpClientModule,
    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      SettingsEffects,
      DataRegisterEffects,
      DSREffects,
      FileUploadEffects,
      PublicEffects,
      MailingEffects,
      GenericEffects,
      GeneralEffects,
    ]),
    OnboardingModule.forRoot(
      new OnboardingModuleConfiguration({
        messages: HUMIDOR_ONBOARDING_MESSAGES,
      })
    ),
    MailingModule,
    SnackbarModule,
    CaNgCoreModule,
    CaAreYouSureModule,
    UnlayerEditorModule,
    CsvImportMapperModule,
    FormsModule,
    ReactiveFormsModule,
    GuidedTourModule,

    //#region Angular Material Modules
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatBadgeModule,
    MatChipsModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatTableModule,
    MatDialogModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCardModule,
    MatStepperModule,
    MatButtonModule,
    MatTabsModule,
    MatSelectModule,
    MatProgressBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSidenavModule,
    MatExpansionModule,
    MatGridListModule,
    MatDividerModule,
    MatSlideToggleModule,
    //#endregion
  ],
  providers: [
    {
      provide: SnackbarModuleConfiguration,
      useValue: new SnackbarModuleConfiguration(),
    },
    {
      provide: SnackbarServiceConfiguration,
      useValue: new SnackbarModuleConfiguration().service,
    },
    {
      provide: CA_ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: OnboardingModuleConfiguration,
      useValue: new OnboardingModuleConfiguration(),
    },
    {
      provide: CaDataModuleConfiguration,
      useValue: new CaDataModuleConfiguration(),
    },
    {
      provide: AreYouSureOverlayConfiguration,
      useValue: DEFAULT_ARE_YOU_SURE_OVERLAY_CONFIGURATION,
    },
    DataLocationsService,
    ExternalProcessorsService,
    ExternalControllersService,
    SecurityMeasureService,
    SubjectTypeService,
    DSRService,
    DsrDataSubjectQnaService,
    FileUploadService,
    InfoService,
    MessagesService,
    RemarksService,
    QuestionAndAnswerService,
    ReplyToAdressesService,
    GuidedTourService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
