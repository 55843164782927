import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { newSecurityMeasure } from '../../../config/defaults';
import { SecurityMeasure } from '@ca/gdpr';
import { GdprToolState, selectSecurityMeasures } from '../../../store';
import { deleteSecurityMeasure } from '../../../store/actions/settings.actions';
import { UpsertSecurityMeasureDialogComponent } from '../dialogs/upsert-security-measure-dialog/upsert-security-measure-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SecurityMeasuresInfoHover } from '../../../config/info-hovers';

@Component({
  selector: 'ca-security-measures',
  template: `
    <h1 class="info-title" i18n="@@security-measures-title">
      Security Measures
      <!--<ca-info-hover [info]="info"></ca-info-hover>-->
    </h1>
    <ng-container *ngIf="dataSource">
      <mat-form-field>
        <mat-label i18n="Form field search label|@@search">Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. Biometric data"
          #input
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            i18n="@@description"
          >
            Description
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            caCutCellText
            [maxCellTextLength]="60"
            [cellText]="element.description"
          >
            {{ element.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="remove(element)"
                i18n="@@delete-btn"
              >
                DELETE<mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page of security measures"
    ></mat-paginator>
    <button mat-raised-button (click)="add()" i18n="@@add-btn">
      <mat-icon>add</mat-icon> ADD
    </button>
  `,
})
export class SecurityMeasuresComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource?: MatTableDataSource<SecurityMeasure>;
  displayedColumns: string[] = ['actions', 'name', 'description'];
  info = SecurityMeasuresInfoHover;
  constructor(
    private store: Store<GdprToolState & OnboardingState>,
    public dialog: MatDialog
  ) {
    this.store.select(selectSecurityMeasures).subscribe((securityMeasures) => {
      if (!this.dataSource)
        this.dataSource = new MatTableDataSource(securityMeasures ?? []);
      else this.dataSource.data = securityMeasures ?? [];
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  add() {
    this.dialog.open(UpsertSecurityMeasureDialogComponent, {
      data: { upsert: newSecurityMeasure },
      disableClose: true,
    });
  }
  edit(securityMeasure: SecurityMeasure) {
    this.dialog.open(UpsertSecurityMeasureDialogComponent, {
      data: { upsert: securityMeasure },
      disableClose: true,
    });
  }
  remove(securityMeasure: SecurityMeasure) {
    this.store.dispatch(deleteSecurityMeasure({ id: securityMeasure.id }));
  }

  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
