import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ReplyToAddress } from '@ca/ca-mailing';
import { GdprToolState, selectReplyToAdresses } from '../../../store';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { OnboardingState } from '@ca/onboarding';
import { UpsertReplyToAddressesComponent } from '../dialogs/upsert-reply-to-addresses/upsert-reply-to-addresses.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { deleteReplyToAddress } from '../../../store/actions/settings.actions';
import { newReplyToAddress } from '../../../config/defaults';

@Component({
  selector: 'ca-reply-to-addresses',
  templateUrl: './reply-to-addresses.component.html',
  styleUrls: ['./reply-to-addresses.component.scss'],
})
export class ReplyToAddressesComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource?: MatTableDataSource<ReplyToAddress>;
  displayedColumns: string[] = ['actions', 'name', 'email'];
  // protected info = ProcessingPurposesInfoHover;
  constructor(
    private store: Store<GdprToolState & { onboarding: OnboardingState }>,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.store
      .select(selectReplyToAdresses)
      .subscribe((replyToAddresses?: ReplyToAddress[]) => {
        if (!this.dataSource)
          this.dataSource = new MatTableDataSource(replyToAddresses ?? []);
        else this.dataSource.data = replyToAddresses ?? [];
      });
  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  add() {
    this.dialog.open(UpsertReplyToAddressesComponent, {
      data: { upsert: newReplyToAddress },
      disableClose: true,
    });
  }

  edit(purpose: ReplyToAddress) {
    this.dialog.open(UpsertReplyToAddressesComponent, {
      data: { upsert: purpose },
      disableClose: true,
    });
  }

  remove(purpose: ReplyToAddress) {
    this.store.dispatch(deleteReplyToAddress({ id: purpose.id }));
  }
  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
