import { createSelector } from '@ngrx/store';
import { onlyUnique } from '@ca/ca-utils';
import { GdprToolState } from '..';
import {
  ControllerRoPARow,
  ProcessingActivityRole,
  ProcessorRoPARow,
  ProcessingPurpose,
} from '@ca/gdpr';
import { InlineInsertedType } from '../reducers/settings.reducer';
import { PublicState } from '../reducers/public.reducer';

//#region Settings
export const selectSettings = (state: GdprToolState) => state.settings;
export const selectDataLocations = createSelector(
  selectSettings,
  (state) => state.dataLocations ?? []
);
export const selectCompanySettings = createSelector(
  selectSettings,
  (state) => state.companySettings
);
export const selectReplyToAdresses = createSelector(
  selectSettings,
  (state) => state.replyToAddresses
);
export const selectEmailTemplates = createSelector(
  selectSettings,
  (state) => state.emailTemplates
);
export const selectDisclosedTo = createSelector(
  selectSettings,
  (state) => state.disclosedTo ?? []
);
export const selectLegalGrounds = createSelector(
  selectSettings,
  (state) => state.legalGrounds
);
export const selectExternalProcessors = createSelector(
  selectSettings,
  (state) => state.externalProcessors
);
export const selectExternalControllers = createSelector(
  selectSettings,
  (state) => state.externalControllers
);
export const selectProcessingPurposesAndInlineUpserts = createSelector(
  selectSettings,
  (state) => ({
    purposes: state.processingPurposes,
    inlineInserted: state.inlineInserted?.filter(
      (e) => e.resource === 'processing-purpose'
    ),
  })
);
export const selectProcessingPurposes = createSelector(
  selectSettings,
  (state) => state.processingPurposes
);
export const selectAddSubjectRegisterSettingsData = createSelector(
  selectSettings,
  (state) => ({
    dataSubjectTypes: state.dataSubjectTypes,
    dataCategories: state.dataCategories,
    dataLocations: state.dataLocations,
    disclosedTo: state.disclosedTo,
    legalGrounds: state.legalGrounds,
    processingPurposes: state.processingPurposes,
    transfersOutsideEEA: state.transfersOutsideEEA,
    externalControllers: state.externalControllers,
  })
);
export const selectProcessingPurposeById = (id: number) =>
  createSelector(selectSettings, (state) =>
    state.processingPurposes?.find((s) => s.id === id)
  );
export const selectDataCategories = createSelector(
  selectSettings,
  (state) => state.dataCategories ?? []
);
export const selectSecurityMeasures = createSelector(
  selectSettings,
  (state) => state.securityMeasures ?? []
);
export const selectDataSubjectTypes = createSelector(
  selectSettings,
  (state) => state.dataSubjectTypes
);
export const selectTransfersOutsideEEA = createSelector(
  selectSettings,
  (state) => state.transfersOutsideEEA
);

//#endregion

//#region Data Register
export const selectDataRegister = (state: GdprToolState) => state.dataRegister;
export const selectDataRegisterForExport = (
  role: ProcessingActivityRole | 'all'
) =>
  createSelector(selectDataRegister, (dataRegisterState) => {
    const rows: ((ControllerRoPARow | ProcessorRoPARow) & {
      purpose: ProcessingPurpose;
    })[] = [];
    if (
      dataRegisterState.controllerGroups !== undefined &&
      (role === 'all' || role === 'controller')
    ) {
      dataRegisterState.controllerGroups.forEach((g) =>
        g.rows.forEach((r) => rows.push({ ...r, purpose: g.purpose }))
      );
    }

    if (
      dataRegisterState.processorGroups !== undefined &&
      (role === 'all' || role === 'processor')
    ) {
      dataRegisterState.processorGroups.forEach((g) =>
        g.rows.forEach((r) => rows.push({ ...r, purpose: g.purpose }))
      );
    }
    // console.log(
    //   'dataLocations',
    //   rows.map((r) => r.dataLocations)
    // );
    // console.log(
    //   'transferEEA',
    //   rows.map((r) => r.transferEEA)
    // );
    const exportRows = rows.map((r) => ({
      'Processing Purpose': r.purpose.name,
      'Data Category': `${r.dataCategory.name}${
        r.dataCategory.sensitive ? ' *' : ''
      }`,
      'Data Locations': r.dataLocations?.map((e) => e.name).join(', '),
      'Security Measures': r.dataLocations
        ?.map((e) =>
          e.securityMeasures && e.securityMeasures.length > 0
            ? e.securityMeasures.map((e) => e.name).join(', ')
            : undefined
        )
        .filter((e) => e !== undefined)
        .join(', '),
      'Disclosed To': r.disclosedTo?.map((e) => e.name).join(', '),
      'Transfers Outside EEA':
        r.transferEEA && r.transferEEA.length > 0
          ? `${r.transferEEA?.map((tr) => `${tr.name}`).join(', ')}${
              r.transferEEAClarification
                ? ' - ' + r.transferEEAClarification
                : ''
            }`
          : $localize`:@@nvt:NOT APPLICABLE`, // niet van toepassing
      'Created At': r.createdAt,
      'Updated At': r.updatedAt,
    }));

    console.log('EXPORTING DATA REGISTER', role, rows, '=>', exportRows);
    return exportRows;
  });
// export const selectProcessingActivities = createSelector(
//   selectDataRegister,
//   (state) => state.processingActivities
// );
// export const selectExpandedProcessingActivity = createSelector(
//   selectDataRegister,
//   (state) => state.expandedProcessingActivity
// );

export const selectProcessingGroups = createSelector(
  selectDataRegister,
  (state) => ({
    controller: state.controllerGroups,
    processor: state.processorGroups,
  })
);
export const selectControllerGroups = createSelector(
  selectDataRegister,
  (state) => state.controllerGroups
);
export const selectProcessorGroups = createSelector(
  selectDataRegister,
  (state) => state.processorGroups
);
//#endregion

//#region Public
export const selectPublicState = (s: { public: PublicState }) => s.public;
//#endregion

//#region Shared
export const selectUnlinkedMedia = (state: GdprToolState) =>
  state.wip.unlinkedMedia;
//#region Inline Inserts
export const selectInlineInserted = createSelector(
  selectSettings,
  (state) => state.inlineInserted
);
export const selectInlineInsertedByType = (
  inlineInsertedType: InlineInsertedType
) =>
  createSelector(selectSettings, (state) =>
    state.inlineInserted?.filter((e) => e.resource === inlineInsertedType)
  );
export const selectInlineInsertedByTypeAndOrigin = (
  inlineInsertedType: InlineInsertedType,
  originId: string
) =>
  createSelector(selectSettings, (state) =>
    state.inlineInserted?.filter(
      (e) => e.resource === inlineInsertedType && originId === e.originId
    )
  );
//#endregion
//#endregion

//#region DSR
// export const selectDSRState = (state: GdprToolState) => state.dsrs;
// export const selectDSRs = createSelector(
//   selectDSRState,
//   (state: DSRState) => state.requests
// );
// export const selectDSR = createSelector(selectDSRState, (state) => state.dsr);

// export const selectDSRRemarks = createSelector(
//   selectDSR,
//   (state) => state?.remarks
// );
// export const selectDSRMarkedRoPA = (state: GdprToolState, dsrId: number) => {
//   const dsr = state.dsrs.requests?.find((e) => e.id === dsrId);
//   if (!dsr) throw new Error(`DSR with id ${dsrId} not found!`);
//   let markedRoPA: ProcessingActivityGroups = [];

//   if (dsr.markedRoPA !== undefined && dsr.markedRoPA.length > 0) {
//     // USER SELECTION
//     if (state.dataRegister.controllerGroups)
//       markedRoPA = state.dataRegister.controllerGroups.filter((e) =>
//         dsr.markedRoPA.includes(e.id)
//       );

//     if (state.dataRegister.processorGroups) {
//       const processorRoPA = state.dataRegister.processorGroups.filter((e) =>
//         dsr.markedRoPA.includes(e.id)
//       );
//       markedRoPA = markedRoPA
//         ? [...markedRoPA, ...processorRoPA]
//         : processorRoPA;
//     }
//   } else {
//     // SELECT ALL RoPA FOR SELECTED DATASUBJECTTYPES
//     if (state.dataRegister.controllerGroups)
//       markedRoPA = state.dataRegister.controllerGroups.filter((e) =>
//         dsr.dataSubjectTypes.includes(e.dataSubjectType.id)
//       );

//     if (state.dataRegister.processorGroups) {
//       const processorRoPA = state.dataRegister.processorGroups.filter((e) =>
//         dsr.dataSubjectTypes.includes(e.dataSubjectType.id)
//       );
//       markedRoPA = markedRoPA
//         ? [...markedRoPA, ...processorRoPA]
//         : processorRoPA;
//     }
//   }

//   return {
//     markedRoPA,
//   };
// };
export const selectUsedDataSubjectTypes = (state: GdprToolState) => {
  const inControllerRegister = state.dataRegister.controllerGroups
    ?.map((e) => e.dataSubjectType.id)
    .filter(onlyUnique);

  const inProcessorRegister = state.dataRegister.controllerGroups
    ?.map((e) => e.dataSubjectType.id)
    .filter(onlyUnique);

  let usedIds: number[] = [];
  if (inControllerRegister != undefined && inControllerRegister.length > 0)
    usedIds.push(...inControllerRegister);
  if (inProcessorRegister != undefined && inProcessorRegister.length > 0)
    usedIds.push(...inProcessorRegister);
  usedIds = usedIds.filter(onlyUnique);

  return state.settings.dataSubjectTypes?.filter((e) => usedIds.includes(e.id));
};

// export const selectUpcomingDSRDeadlines = createSelector(selectDSRs, (state) =>
//   state ? [...state].sort(SortByNearestDeadline).slice(0, 5) : undefined
// );
//#endregion

//#region System Messages
export const selectSystemMessages = (state: GdprToolState) =>
  state.systemMessages;
//#endregion
