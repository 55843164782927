import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CaDataResponse, CaDataService, PutResult } from '@ca/ca-data';
import { HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { CaEnvironment, IQueryParameter } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import {
  OnboardingState,
  selectActiveDivisionId,
  FEATURE_KEY,
} from '@ca/onboarding';
import { CA_ENVIRONMENT, EMPTY_QUERY_PARAM } from '@ca/ca-ng-core';
@Injectable()
export abstract class CrudService<T, PutResultType = string>
  implements OnDestroy
{
  protected url!: string;
  protected companyId?: number;
  protected companyIdSubscription?: Subscription;

  get companyQueryParameter(): IQueryParameter {
    return this.companyId
      ? {
          name: 'companyId',
          value: this.companyId,
        }
      : EMPTY_QUERY_PARAM;
  }

  constructor(
    protected data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected env: CaEnvironment,
    protected urlSegment: string,
    protected store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    this.url = `${this.env.apiBaseUrl}${urlSegment}`;

    this.companyIdSubscription = this.store
      .select(selectActiveDivisionId)
      .subscribe((id) => (this.companyId = id));
  }

  ngOnDestroy(): void {
    this.companyIdSubscription?.unsubscribe();
  }

  get(
    params: IQueryParameter[] = []
  ): Observable<HttpResponse<CaDataResponse<T[]>>> {
    return this.data.get<CaDataResponse<T[]>>(
      this.url,
      this.constructParams(params)
    );
  }

  getSingle(
    id: number,
    params: IQueryParameter[] = []
  ): Observable<HttpResponse<CaDataResponse<T>>> {
    return this.data.get<CaDataResponse<T>>(
      this.url + `/${id}`,
      this.constructParams(params)
    );
  }

  delete(id: number, params: IQueryParameter[] = []): Promise<boolean> {
    return this.data.deleteSafely(
      this.url,
      { id },
      this.constructParams(params)
    );
  }

  upsert<UpsertModel>(
    upsert: UpsertModel,
    params: IQueryParameter[] = []
  ): Observable<HttpResponse<PutResult<PutResultType>>> {
    return this.data.put<UpsertModel, PutResultType>(
      this.url,
      upsert,
      this.constructParams(params)
    );
  }

  protected constructParams(params?: IQueryParameter[]): IQueryParameter[] {
    return params
      ? [...params, this.companyQueryParameter]
      : [this.companyQueryParameter];
  }
}
