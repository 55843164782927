import { createReducer, on } from '@ngrx/store';

import {
  dismissSystemMessageSuccess,
  loadSystemMessagesSuccess,
} from '../actions/general.actions';
import { SystemMessage } from '../../models/messaging.models';
import { onboardingActions } from '@ca/onboarding';

export interface SystemMessagesState {
  messages: SystemMessage[];
}
export const initialState: SystemMessagesState = {
  messages: [],
};
export const reducer = createReducer(
  initialState,
  on(loadSystemMessagesSuccess, (_, { messages }) => ({ ..._, messages })),
  // TODO: add effect to mark them as read using the API
  on(dismissSystemMessageSuccess, (_, { request }) => ({
    ..._,
    messages:
      _.messages && _.messages.length > 0
        ? _.messages.filter((e) => e.id != request.id)
        : [],
  })),
  on(onboardingActions.logout, () => initialState)
);
