import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

import * as fromPublic from './reducers/public.reducer';
import * as fromLoading from './reducers/loading.reducer';
import * as fromSettings from './reducers/settings.reducer';
import * as fromDataRegister from './reducers/data-register.reducer';
import * as fromWIP from './reducers/wip.reducer';
import * as fromMessages from './reducers/system-messages.reducer';

import { hydrationMetaReducer } from './reducers/hydratation.reducer';

export interface GdprToolState {
  loading: fromLoading.LoadingState;

  settings: fromSettings.SettingsState;

  dataRegister: fromDataRegister.DataRegisterState;

  wip: fromWIP.WIPState;

  public: fromPublic.PublicState;

  systemMessages: fromMessages.SystemMessagesState;
}

export const ROOT_REDUCER: ActionReducerMap<GdprToolState> = {
  loading: fromLoading.reducer,
  settings: fromSettings.reducer,
  dataRegister: fromDataRegister.reducer,
  wip: fromWIP.reducer,
  public: fromPublic.reducer,
  systemMessages: fromMessages.reducer,
};

const log_prefix = '< Store >\n$';
const log = (...args: any): void => console.log(`${log_prefix}`, ...args);

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    log('state', state);
    log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<GdprToolState>[] =
  !environment.production ? [hydrationMetaReducer] : [hydrationMetaReducer];

export * from './selectors';
