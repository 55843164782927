import { CancelReason, ProcedureCategory } from '@ca/gdpr';

// a text and optionaly an attachment should be provided with this reason to cancel
export const CANCEL_REASONS: { [key: string]: CancelReason } = {
  'Manifestly Unfounded': {
    name: $localize`:@@cancel-reason-manifestly-unfounded:Manifestly Unfounded`,
    description: $localize`:@@cancel-reason-manifestly-unfounded-description:The request is clearly not for a proper purpose`,
    requiresAttachment: true,
    requiresExplanation: false,
    emailTemplateId: 0,
    // input: [{ key:'reason': label: 'Explain rejection'; placeholder: 'Specify the reason for rejecting the request.' type:'string'; }]
  },
  Excessive: {
    name: $localize`:@@cancel-reason-excessive:Excessive`,
    description: $localize`:@@cancel-reason-excessive-description:There is a clear intent to harm the controller (you). The request can be lawfully rejected.`,
    requiresAttachment: true,
    requiresExplanation: true,
    emailTemplateId: 0,
  },
  // Not a cancel reason, more of a Limitation
  'Rights and Freedom of Others': {
    name: $localize`:@@cancel-reason-freedom-of-others:Rights and Freedom of Others`,
    // the law states clearly that economical interests do not count!
    description: $localize`:@@cancel-reason-freedom-of-others-description:The requested personal data can not be provided because it is protected by intellectual property rights or the privacy of others. In this case you should provide a response to the request with the conflicting data, excluding all information that should remain private.`,
    requiresAttachment: true,
    requiresExplanation: false,
    emailTemplateId: 0,
  },
  'National Law': {
    name: $localize`:@@cancel-reason-national-law:National Law`,
    description: $localize`:@@cancel-reason-national-law-description:An additional restriction applies under national law.`,
    requiresAttachment: true,
    requiresExplanation: true,
    emailTemplateId: 0,
  },
};

export const DSR_CATEGORIES: ProcedureCategory[] = [
  {
    right: 'right-to-be-forgotten',
    title: $localize`:@@dsr-type-right-to-erasure:The Right to Erasure`,
    description: $localize`:@@dsr-type-right-to-erasure-description:<p>Individuals have the right to request the removal of personal information from your records and to tell you to
      cease further data dissemination immediately. You must delete data if any of the following apply:</p>
      <ul>
        <li>It was collected unlawfully</li>
        <li>It is no longer needed</li>
        <li>It was collected during the individual's childhood</li>
        <li>It appears online</li>
      </ul>
      <p>Your organization can deny the erasure if the request violates: However, if you retain personal data, you must have the subject's
      consent for further processing of the data.</p>
      <ul>
        <li>The right to freedom of expression</li>
        <li>The public interest in public health or scientific or historical research</li>
        <li>The establishment, exercise or defense of legal claims</li>
      </ul>
      `,
  },
  {
    right: 'right-to-insight',
    title: $localize`:@@dsr-type-right-to-access:The Right of Access`,
    description: $localize`:@@dsr-type-right-to-access-description:<p>Each person is entitled to know exactly what information an organization has about them, how it was collected,
  and whether and how their personal data is being processed. Individuals are allowed to access their own data
  and learn about:</p>
  <ul>
    <li>The categories of data you collect</li>
    <li>The purpose of your data processing</li>
    <li>Who the data was disclosed to</li>
    <li>How long the data will be stored</li>
    <li>Where the data was obtained from</li>
  </ul>`,
  },
  {
    right: 'right-to-limit-processing',
    title: $localize`:@@dsr-type-right-to-restriction:The Right to Restriction of Processing`,
    description: $localize`:@@dsr-type-right-to-restriction-description:<p>If  it  is  unclear  whether  an  individual's  information  must  be  deleted,  that  person  can  request  temporary
      restrictions on processing until your organization does all of the following:</p>
      <ul>
        <li>Fixes the issue</li>
        <li>Informs the individual of the correction</li>
        <li>Obtains consent to continue with processing</li>
      </ul>`,
  },
  {
    right: 'right-to-oppose',
    title: $localize`:@@dsr-type-right-to-object:The Right to Object to Data Processing Activities`,
    description: $localize`:@@dsr-type-right-to-object-description:<p>Individuals can tell companies to stop using their data for marketing or other purposes.
      There are three legitimate reasons you can use to either deny a request or not comply with it in full:</p>
      <ul>
        <li>The individual has made excessive or unfounded DSR requests</li>
        <li>The data is used for public, historical or statistical purposes</li>
        <li>You need to use the data to exercise your legal claims</li>
      </ul>
      <p>Compliance with this requirement often means moving data to another internal IT system.</p>`,
  },
  {
    right: 'right-to-retract',
    title: $localize`:@@dsr-type-right-to-opt-out:The Right to Opt Out`,
    description: $localize`:@@dsr-type-right-to-opt-out-description:<p>Data subjects must be given the option to opt out of having their data sold to third parties.</p>`,
  },
  {
    right: 'right-to-edit',
    title: $localize`:@@dsr-type-right-to-rectification:The Right to Rectification`,
    description: $localize`:@@dsr-type-right-to-rectification-description:<p>If an individual believes the data you store about them is inaccurate, you must ensure that it is corrected across
      all your data locations.</p>`,
  },
  {
    right: 'right-to-transfer',
    title: $localize`:@@dsr-type-right-to-portability:The Right to Portability`,
    description: $localize`:@@dsr-type-right-to-portability-description:<p>Individuals have the right to require a company to transfer their personal data to another service provider. This
      right is intended to promote interoperability by facilitating the transfer of user data between data controllers. It
      is also expected to encourage competition between digital services, since users can switch between providers
      without losing their personal data.</p>`,
  },
  {
    right: 'right-to-information',
    title: $localize`:@@dsr-type-right-to-information:The Right to be Informed`,
    description: $localize`:@@dsr-type-right-to-information-description:<p>Your organization must be transparent and honest about what personal data you collect and what you do with
      it. You must have this information available to individuals at any time and especially at the point of collection,
      without request from a data subject.</p>`,
  },
];
