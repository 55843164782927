import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ViewModel,
  MapCsvImportDialogData,
  PropertyViewModel,
} from '../models';
import { ImportCSVToViewModel } from '../utils';

@Component({
  selector: 'ca-csv-import-mapper',
  templateUrl: './csv-import-mapper.component.html',
  styleUrls: ['./csv-import-mapper.component.scss'],
})
export class CsvImportMapperComponent implements OnInit {
  selectedPreset = 'new';
  results?: object[];
  viewmodel?: ViewModel;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected data: MapCsvImportDialogData,
    protected ref: MatDialogRef<MapCsvImportDialogData>
  ) {}

  ngOnInit() {
    const res = ImportCSVToViewModel({
      loadResult: this.data.loadResult,
      separator: this.data.separator,
      numberOfSamplesToShow: this.data.numberOfSamplesToShow,
    });
    this.viewmodel = res?.viewmodel;
    this.results = res?.rawData;
  }

  getEnumFormArray(formGroup: FormGroup): FormArray {
    return formGroup.get('enumValues') as FormArray;
  }

  enumControls(formGroup: FormGroup) {
    return this.getEnumFormArray(formGroup).controls;
  }

  addEnumValueControl(formGroup: FormGroup) {
    this.getEnumFormArray(formGroup).push(
      new FormControl<string | null>(null, Validators.required)
    );
  }

  removeEnumValueControl(formGroup: FormGroup, index: number) {
    this.getEnumFormArray(formGroup).removeAt(index);
  }

  togglePropertyDismissed(vm: PropertyViewModel, eventToKill?: Event) {
    if (eventToKill) {
      // TODO: make this optional when dismissed is turned of => AUTO_OPEN_ON_ACTIVATE_PROPERTY
      eventToKill.stopPropagation();
      eventToKill.preventDefault();
    }
    vm.dismissed = !vm.dismissed;
    if (vm.dismissed) vm.fg.disable();
    else vm.fg.enable();
  }
  importData() {
    // TODO: what to do here?
  }
}
