<!-- Company info -->
<div class="company-info-card">
  <mat-card>
    <mat-card-header>
      <h1 mat-card-title i18n="@@company-settings-title">Company Settings</h1>
    </mat-card-header>

    <mat-card-content *ngIf="fg">
      <form class="upsert-form" [formGroup]="fg">
        <h2 i18n="@@contact-person-fg-title">Contact Person</h2>

        <div class="info-hover-form-field">
          <mat-form-field class="stretch2">
            <mat-label i18n="@@given_name">Given name</mat-label>
            <input matInput formControlName="contactFirstName" />
          </mat-form-field>
          <mat-form-field class="stretch2">
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input matInput formControlName="contactLastName" />
          </mat-form-field>
        </div>

        <div class="info-hover-form-field">
          <mat-form-field class="stretch">
            <mat-label i18n="@@email">E-Mail</mat-label>
            <input matInput formControlName="contactEmail" />
          </mat-form-field>
        </div>

        <h2 i18n="@@manage-company-settings-title">Manage company settings</h2>
        <div>
          <div class="info-hover-form-field">
            <mat-form-field class="stretch">
              <mat-label i18n="@@company-name">Company name</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>

          <!-- Reply to address -->
          <div class="info-hover-form-field" *ngIf="replyAddresses">
            <mat-form-field class="stretch">
              <mat-label i18n="@@reply-to-address">Reply to address</mat-label>
              <mat-select formControlName="replyToAddress">
                <mat-option [value]="ra" *ngFor="let ra of replyAddresses">
                  {{ ra.name }} ({{ ra.email }})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Address -->
          <div formGroupName="address">
            <div class="info-hover-form-field">
              <mat-form-field class="stretch2">
                <mat-label i18n="Form field label for street|@@street"
                  >Street</mat-label
                >
                <input matInput formControlName="street" />
              </mat-form-field>
              <mat-form-field class="stretch4">
                <mat-label i18n="@@housenr">No.</mat-label>
                <input matInput formControlName="houseNumber" type="number" />
              </mat-form-field>
              <mat-form-field class="stretch4">
                <mat-label i18n="@@housenr-suffix">No. suffix</mat-label>
                <input matInput formControlName="houseNumberSuffix" />
              </mat-form-field>
            </div>

            <div class="info-hover-form-field">
              <mat-form-field class="stretch3">
                <mat-label i18n="@@postal">Postal code</mat-label>
                <input matInput formControlName="postalCode" />
              </mat-form-field>
              <mat-form-field class="stretch3">
                <mat-label i18n="Form field label for City|@@city"
                  >City</mat-label
                >
                <input matInput formControlName="city" />
              </mat-form-field>
              <mat-form-field class="stretch3">
                <mat-label i18n="Form field label for Country|@@country"
                  >Country</mat-label
                >
                <input matInput formControlName="country" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <h2 i18n="@@dpo-title">DPO (optional)</h2>
        <div class="info-hover-form-field">
          <mat-form-field class="stretch">
            <mat-label i18n="@@assign-dpo-label">Assign the DPO</mat-label>
            <mat-select formControlName="dpoUserId">
              <mat-option [value]="0" i18n="@@assign-dpo-ctrl-empty-option"
                >No DPO assigned</mat-option
              >
              <mat-option [value]="user.id" *ngFor="let user of users">
                {{ user.firstName }} {{ user.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>

    <mat-card-actions align="end">
      <button
        color="primary"
        mat-raised-button
        [disabled]="!this.fg?.valid"
        (click)="upsertCompanySettings()"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-card-actions>
  </mat-card>
</div>
