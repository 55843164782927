import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpsertDataSubjectTypeActionData } from '@ca/gdpr';
import { upsertSubjectType } from '../../../../../app/store/actions/settings.actions';

@Component({
  selector: 'ca-upsert-subject-type',
  template: `
    <h2 mat-dialog-title>
      {{ title }}
    </h2>
    <div mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg">
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input
              matInput
              type="text"
              formControlName="name"
              placeholder="Clients"
            />
          </mat-form-field>
        </div>
      </form>
    </div>
    <mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        color="primary"
        type="button"
        [disabled]="!fg.valid"
        (click)="save()"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-dialog-actions>
  `,
})
export class UpsertSubjectTypeComponent {
  fg: FormGroup;
  get title() {
    return $localize`:@@create-or-edit-data-subject-type:${
      this.data.id > 0 ? 'Edit' : 'Create'
    } Data Subject Type`;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: UpsertDataSubjectTypeActionData,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpsertSubjectTypeComponent>,
    private store: Store
  ) {
    this.fg = fb.group({
      id: [data.id, Validators.required],
      name: [data.name, Validators.required],
    });
  }
  save() {
    this.store.dispatch(upsertSubjectType(this.fg.value));
    this.dialogRef.close();
  }
}
