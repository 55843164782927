import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpsertInlineDialogData } from '@ca/ca-ng-core';
import { UpsertProcessingPurposeActionData } from '@ca/gdpr';
import { Store } from '@ngrx/store';
import { newProcessingPurpose } from '../../../../config/defaults';
import { CreateProcessingPurposeForm } from '../../../../utils/forms';
import { upsertProcessingPurpose } from '../../../../store/actions/settings.actions';

@Component({
  selector: 'ca-upsert-reply-to-addresses',
  template: ` <h2 mat-dialog-title>
      {{ title }}
    </h2>
    <mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg">
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input
              matInput
              type="text"
              formControlName="name"
              i18n-placeholder="@@upsert-reply-to-address-name-input-ph"
              placeholder="Default"
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for e-mail|@@e-mail"
              >E-Mail</mat-label
            >
            <input
              matInput
              type="text"
              formControlName="email"
              i18n-placeholder="@@upsert-reply-to-address-email-input-ph"
              placeholder="example@domain.co"
            />
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        color="primary"
        type="button"
        [disabled]="!fg.valid"
        (click)="save()"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-dialog-actions>`,
})
export class UpsertReplyToAddressesComponent {
  fg: FormGroup;

  get title() {
    return $localize`:@@create-or-edit-reply-to-address:${
      this.data.id > 0 ? 'Edit' : 'Create'
    } Reply-to address`;
  }
  protected data: UpsertProcessingPurposeActionData;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected _data: UpsertInlineDialogData<UpsertProcessingPurposeActionData>,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpsertProcessingPurposeActionData>,
    private store: Store
  ) {
    this.data =
      this._data.upsert === null ? newProcessingPurpose : _data.upsert;
    this.fg = CreateProcessingPurposeForm(this.fb, this.data);
  }

  save() {
    if (this._data.inline && this._data.originId !== undefined)
      console.log(this.fg.value);
    // this.store.dispatch(
    //   upsertProcessingPurposeInline({
    //     upsert: this.fg.value,
    //     originId: this._data.originId,
    //   })
    // );
    else if (this._data.inline) throw new Error('');
    else this.store.dispatch(upsertProcessingPurpose(this.fg.value));
    this.dialogRef.close();
  }
}
