export enum OnboardingAction {
  ONBOARDING_ERROR = '[Onboarding Error] Onboarding Error',

  SUBMIT_LOGIN_FORM = '[Login] Submit Form',
  SUBMIT_LOGIN_FORM_SUCCESS = '[Login] Submit Form [Success]',
  SUBMIT_LOGIN_FORM_FAILURE = '[Login] Submit Form [Failure]',

  TOGGLE_PASSWORD_VISIBILITY = '[Login] Toggle Password Field Visibility',

  LOGIN_REQUEST = '[Login] Login Request',
  LOGIN_SUCCESS = '[Login] Login Request [Success]',
  LOGIN_FAILURE = '[Login] Login Request [Failure]',

  LOGOUT = '[Login] Log out',

  TWO_FA_REQUEST = '[Login] Two Factor Authentication Request',
  TWO_FA_SUCCESS = '[Login] Two Factor Authentication Request [Success]',
  TWO_FA_FAILURE = '[Login] Two Factor Authentication Request [Failure]',

  VALIDATE_BEARER_TOKEN = '[User] Validate Bearer token',

  LOAD_PROFILE = '[User] Load User Profile',
  LOAD_PROFILE_SUCCESS = '[User] Load User Profile [Success]',
  UPDATE_PROFILE = '[User] Update User Profile',
  UPDATE_PROFILE_SUCCESS = '[User] Update User Profile [Success]',
  HEARTBEAT = '[User] Check Heartbeat',
  HEARTBEAT_SUCCESS = '[User] Check Heartbeat [Success]',
  /**
   * User Management
   */
  LOAD_USERS = '[Users] Load Users',
  LOAD_USERS_SUCCESS = '[Users] Load Users [Success]',

  OPEN_UPSERT_USER = '[Users] Open Upsert User Modal',

  UPSERT_USER = '[Users] Upsert User',
  UPSERT_USER_SUCCESS = '[Users] Upsert User [Success]',

  OPEN_REMOVE_USER = '[Users] Open Remove User Modal',
  REMOVE_USER = '[Users] Remove User',
  REMOVE_USER_SUCCESS = '[Users] Remove User [Success]',

  LOAD_DIVISIONS = '[Divsions] Load Divisions',
  LOAD_DIVISIONS_SUCCESS = '[Divsions] Load Divisions [Success]',

  LOAD_ROLES = '[Roles] Load Roles',
  LOAD_ROLES_SUCCESS = '[Roles] Load Roles [Success]',

  /**
   * Password
   */
  RESET_PASSWORD = '[Password] Reset Password',
  RESET_PASSWORD_REQUEST = '[Password] Reset Password Request',
  RESET_PASSWORD_SUCCESS = '[Password] Reset Password Request [Success]',
  RESET_PASSWORD_FAILURE = '[Password] Reset Password Request [Failure]',

  SET_PASSWORD_REQUEST = '[Password] Set password Request',
  SET_PASSWORD_SUCCESS = '[Password] Set password Request [Success]',
  SET_PASSWORD_FAILURE = '[Password] Set password Request [Failure]',

  /**
   * Application scope
   */
  SET_ACTIVE_DIVISION = '[Division] Set active division',

  /**
   * Errors
   */
  sessionExpired = '[User] session expired',

  unauthorizedPageLoad = '[User] Unauthorized Page Load',
  unauthorizedAPIResult = '[User] Unauthorized API result',
}
