import { IUserProfile } from '../types';

export const defaultUserProfile: IUserProfile = {
  email: '',
  gsm: '',
  linkedDivisions: [],
  preferredLanguage: 'en',
  twoFaEnabled: false,
  active: true,
  avatar: '',
  passwordStatus: 'create',
  id: 0,
  tel: '',
  firstName: '',
  lastName: '',
  readOnly: false,
  twoFaDeviceLinked: false,
};
