import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@ca/core';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(value?: Address, ...args: unknown[]): unknown {
    return value != undefined
      ? `${value.street} ${value.houseNumber}${
          value.houseNumberSuffix ? ' ' + value.houseNumberSuffix : ''
        }, ${value.postalCode} ${value.city}, ${value.country}`
      : '';
  }
}
