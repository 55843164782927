import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';

import { OnboardingService } from '../../services';
import { OnboardingModuleConfiguration, OnboardingState } from '../../types';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaSubscriber } from '@ca/ca-utils';
import {
  LoggingService,
  NotificationFactory,
  QueueNotification,
} from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';

export abstract class OnboardingEffects {
  protected nf = new NotificationFactory('OnboardingEffects');
  protected sub = new CaSubscriber();

  constructor(
    protected config: OnboardingModuleConfiguration,
    protected svc: OnboardingService,
    protected actions$: Actions,
    protected router: Router,
    protected snackbar: SnackbarService,
    protected logger: LoggingService,
    protected store: Store<{ onboarding: OnboardingState }>
  ) {}

  protected QueueError(message: string, duration?: number) {
    return QueueNotification(this.nf.createError(message, undefined, duration));
  }
  protected QueueSuccess(message: string, duration?: number) {
    return QueueNotification(this.nf.createSuccess(message, duration));
  }
}
