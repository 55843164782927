import { Component } from '@angular/core';
import {
  GdprToolState,
  selectCompanySettings,
  selectReplyToAdresses,
} from '../../store';
import { IUserProfile, OnboardingState, selectUsers } from '@ca/onboarding';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CompanySettings } from '@ca/gdpr';
import { UpsertCompanySettings } from '../../store/actions/company-settings.actions';
import { CreateAddressFormGroupInitializer } from '../../utils/forms';
import { ReplyToAddress } from '@ca/ca-mailing';
import { LoggingService } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
})
export class CompanySettingsComponent {
  protected fg?: FormGroup;
  protected companySettings!: CompanySettings;
  protected users?: IUserProfile[];
  protected contactPersonNameInfo = $localize`:@@contact-person-name-info:The given - and last name of the contact person for all GDPR-related communication.`;
  protected contactPersonEmailInfo = $localize`:@@contact-person-e-mail-info:The e-mail address to be used for all GDPR-related communication?`;
  protected companyNameInfo = $localize`:@@company-name-info:The name of your company.`;
  protected replyAddressInfo = $localize`:@@reply-address-info:What email will the data subjects reply to?`;
  protected dpoInfo = $localize`:@@dpo-info:Assign the data protection officer user here. It is not obligated to have a DPO, so you can safely leave this empty.`;
  protected replyAddresses?: ReplyToAddress[];

  constructor(
    private store: Store<GdprToolState & { onboarding: OnboardingState }>,
    private fb: FormBuilder,
    private logger: LoggingService,
    public dialog: MatDialog
  ) {
    this.store.select(selectUsers).subscribe((users) => {
      if (users) {
        this.users = [...users];
      }
    });

    this.store.select(selectReplyToAdresses).subscribe((replyAddresses) => {
      if (replyAddresses) this.replyAddresses = replyAddresses;
    });
    this.store.select(selectCompanySettings).subscribe((companysettings) => {
      if (companysettings) {
        this.companySettings = companysettings;
        this.fg = fb.group({
          companyId: [this.companySettings.companyId, Validators.required],
          dpoUserId: this.companySettings.dpoUser?.id ?? null,
          contactFirstName: [
            this.companySettings.contactFirstName ?? null,
            Validators.required,
          ],
          contactLastName: [
            this.companySettings.contactLastName ?? null,
            Validators.required,
          ],
          contactEmail: [
            this.companySettings.contactEmail ?? null,
            [Validators.required, Validators.email],
          ],

          name: [this.companySettings.name, Validators.required],
          address: CreateAddressFormGroupInitializer(
            this.fb,
            this.companySettings.address
          ),
          replyToAddress: [
            this.companySettings.replyToAddress
              ? this.replyAddresses?.find(
                  (e) => e.email === this.companySettings.replyToAddress.email
                )
              : null,
            [Validators.required],
          ],
        });
      }
    });
  }

  upsertCompanySettings() {
    if (this.fg?.value)
      this.store.dispatch(UpsertCompanySettings(this.fg.value));
  }
}
