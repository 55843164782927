import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Selector, Store } from '@ngrx/store';
import { GdprToolState } from '../../../store';
import {
  CaSubscriber,
  IndexedName,
  displayNamedObjectOrString,
  filterByNamedObjectOrString,
  isString,
} from '@ca/ca-utils';
import { FormGroup } from '@angular/forms';
import { startWith, map, Observable } from 'rxjs';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'ca-selector',
  template: `
    <ng-container>
      <div class="ca-selector">
        <mat-form-field>
          <mat-label>{{ label }}</mat-label>
          <mat-chip-grid
            #chipGrid
            [attr.aria-label]="label + ' selection'"
            [formControlName]="formControlName"
          >
            <mat-chip-row
              *ngFor="let selected of selectedItems"
              (removed)="remove(selected)"
            >
              {{ selected.name }}
              <button
                matChipRemove
                [attr.aria-label]="'remove ' + selected.name"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input
            [placeholder]="'Add ' + label + '...'"
            #optionInput
            [matChipInputFor]="chipGrid"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
          />

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint>
            <span i18n="@@generic-type-to-filter-or-create-message"
              >Type to filter existing types or create a new
            </span>
            {{ label }}
          </mat-hint>
        </mat-form-field>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .ca-selector {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
      }
    `,
  ],
})
export class SelectorComponent<T extends IndexedName>
  implements OnInit, OnDestroy
{
  @ViewChild('optionInput') optionInput!: ElementRef<HTMLInputElement>;
  @Input() formGroup!: FormGroup;
  @Input() selectedItems: T[] = [];
  protected formControlName!: string;
  protected ariaLabel?: string;
  protected label!: string;
  private sub = new CaSubscriber();
  filteredOptions?: Observable<T[]>;
  protected options?: T[];
  announcer = inject(LiveAnnouncer);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  protected selector!: Selector<GdprToolState, T[] | undefined>;
  constructor(protected store: Store<GdprToolState>) {}

  ngOnInit(): void {
    if (!this.formGroup) throw new Error('FormGroup must be specified');

    this.filteredOptions = this.formGroup.get('name')?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterDataSubjectTypesForSelect(value || ''))
    );
  }

  getData() {
    this.sub.subscribe(this.store.select(this.getSelector()), {
      next: (res?: T[]) => {
        this.options = res ?? [];
      },
    });
  }

  getSelector(): Selector<GdprToolState, T[] | undefined> {
    if (this.selector) return this.selector;
    else throw new Error('not implemented');
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  remove(selectedItem: T) {
    const index = this.selectedItems.findIndex((e) => e.id === selectedItem.id);
    if (this.selectedItems && index && index >= 0) {
      this.selectedItems.splice(index, 1);
      this.announcer.announce(`removed ${selectedItem.name}`);
    }
  }

  add(event: MatChipInputEvent): void {
    // store active selection to restore
    // add inserted id to selected options
    const value: T = isString(event.value)
      ? ({
          name: (event.value || '').trim(),
          id: 0,
        } as T)
      : (event.value as T);
    // TODO: upsert and add
    // Add our keyword
    if (value) this.selectedItems.push(value);

    // Clear the input value
    event.chipInput.clear();
  }
  private _filterDataSubjectTypesForSelect(value: T | string): T[] {
    return filterByNamedObjectOrString(value, this.options);
  }
  protected displayFn(option?: T | string) {
    return displayNamedObjectOrString(option);
  }
}
