<div class="dsr-content">
  <div class="header">
    <div class="container dsr-header">
      <h3 i18n="@@dsr-page-title">Data Subject Requests - DSR</h3>
      <div>
        <button
          mat-raised-button
          (click)="addDataSubjectRequest()"
          color="primary"
          i18n="@@add-dsr-btn"
        >
          <mat-icon>add</mat-icon> REGISTER NEW DSR
        </button>
      </div>
    </div>
    <div>
      <button mat-icon-button (click)="showFilters = !showFilters">
        <mat-icon *ngIf="showFilters === false">filter_list</mat-icon>
        <!--<mat-icon *ngIf="showFilters === true">filter_list_off</mat-icon>-->
      </button>
      <div
        class="container dsr-filters"
        [style.display]="showFilters ? 'flex' : 'none'"
      >
        <button mat-icon-button (click)="showFilters = !showFilters">
          <mat-icon *ngIf="showFilters === true">filter_list_off</mat-icon>
        </button>
        <!-- <mat-chip-listbox
          [attr.aria-label]="filtersLabel"
          (change)="filtersChange($event)"
          multiple
          #dsrStatusFilter
        >
          <mat-chip-option color="accent" value="In Progress"
            >In Progress</mat-chip-option
          >
          <mat-chip-option color="accent" value="Waiting for Data Subject"
            >Waiting for Data Subject</mat-chip-option
          >
          <mat-chip-option color="accent" value="Canceled"
            >Canceled</mat-chip-option
          >
          <mat-chip-option color="accent" value="Rejected"
            >Rejected</mat-chip-option
          >
        </mat-chip-listbox> -->
      </div>
    </div>
  </div>

  <div class="dsrs">
    <mat-card class="dsr-card" *ngFor="let dsr of filteredDataSubjectRequests">
      <mat-card-header>
        <div class="dsr-card-title">
          <span i18n="@@dsr-deadline-date">
            {{ dsr.firstName }} {{ dsr.lastName }} - Deadline
            {{ getDeadline(dsr) }}
          </span>
          <ca-dsr-status-badge [dsr]="dsr"></ca-dsr-status-badge>
        </div>
      </mat-card-header>

      <mat-divider></mat-divider>
      <mat-card-content>
        <div class="dsr-card-content">
          {{ dsr.context }}
        </div>
      </mat-card-content>
      <mat-card-actions align="end" class="dsr-card-actions card-actions">
        <button
          mat-raised-button
          color="warn"
          (click)="removeDsr(dsr)"
          i18n="@@remove-dsr-btn"
        >
          <mat-icon>block</mat-icon> REJECT
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="openRequest(dsr)"
          i18n="@@process-dsr-btn"
        >
          <mat-icon>start</mat-icon> PROCESS
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
