import { Injectable, OnDestroy } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { SnackbarServiceConfiguration, ISnackbarConfiguration } from './config';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService implements OnDestroy {
  openSnackbar?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private config: SnackbarServiceConfiguration,
    private snackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.openSnackbar?.dismiss();
  }
  close() {
    this.openSnackbar?.dismiss();
  }

  private _snackbar<T = any>(
    message: string,
    snackbarConfig: ISnackbarConfiguration,
    buttonText: string,
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<TextOnlySnackBar> {
    let c: MatSnackBarConfig<T> = {
      duration: snackbarConfig.duration,
      panelClass: snackbarConfig.panelClass,
    };

    if (config) c = { ...c, ...config };
    this.openSnackbar = this.snackbar.open(message, buttonText, c);
    return this.openSnackbar;
  }

  errorSnackbar<T = any>(
    message: string,
    buttonText = this.config.errorSnackbar.defaultButtonText,
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this._snackbar<T>(
      message,
      this.config.errorSnackbar,
      buttonText,
      config
    );
  }

  successSnackbar<T = any>(
    message: string,
    buttonText = this.config.successSnackbar.defaultButtonText,
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this._snackbar<T>(
      message,
      this.config.successSnackbar,
      buttonText,
      config
    );
  }

  infoSnackbar<T = any>(
    message: string,
    buttonText = this.config.infoSnackbar.defaultButtonText,
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this._snackbar<T>(
      message,
      this.config.infoSnackbar,
      buttonText,
      config
    );
  }
}
