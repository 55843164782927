import { QuestionnaireConfig } from './models';
import {
  DynamicMultiSelectQuestion,
  DynamicSingleSelectQuestion,
  StaticABQuestion,
} from './utils';

const singleSelectOrOtherForDataSubjectType = DynamicSingleSelectQuestion(
  {
    id: 0,
    settingsStateKey: 'dataSubjectTypes',
    text: $localize`:@@dynamic-single-select-data-subject-question:Whose personal data do you store?`,
    summary_label: 'About?',
  },
  true
);

const multiOrOtherSelectForPurposes = DynamicMultiSelectQuestion(
  {
    id: 1,
    settingsStateKey: 'processingPurposes',
    text: $localize`:@@dynamic-multi-select-processing-purpose-question:Why do you store this data?`,
  },
  true
);

const multiOrOtherSelectForDataCategories = DynamicMultiSelectQuestion(
  {
    id: 2,
    settingsStateKey: 'dataCategories',
    text: $localize`:@@dynamic-multi-select-data-category-question:Which information is stored?`,
  },
  true
);

const multiOrOtherDataLocations = DynamicMultiSelectQuestion({
  id: 3,
  settingsStateKey: 'dataLocations',
  text: $localize`:@@dynamic-multi-select-data-locations-question:Where is this information stored?`,
});

const dynamicMultiSelectThirdParty = DynamicMultiSelectQuestion({
  id: 4,
  settingsStateKey: 'externalProcessors',
  text: $localize`:@@dynamic-multi-select-external-processors:`,
  optional: true,
  skipByDefault: true,
});

const staticThirdParty = StaticABQuestion({
  id: 5,
  text: $localize`:@@static-external-processors-question:Do you share this data with external party? (not due to legal obligation)`,
  answers: [
    {
      id: 1,
      text: $localize`:@@yes:YES`,
      type: 'static',
      value: $localize`:@@yes:YES`,
      navigateToQuestionId: dynamicMultiSelectThirdParty.id,
    },
    {
      id: 2,
      text: $localize`:@@no:NO`,
      type: 'static',
      value: $localize`:@@no:NO`,
    },
  ],
});

export const fromSubject: QuestionnaireConfig = {
  questions: [
    singleSelectOrOtherForDataSubjectType,
    multiOrOtherSelectForPurposes,
    multiOrOtherSelectForDataCategories,
    multiOrOtherDataLocations,
    staticThirdParty,
    dynamicMultiSelectThirdParty, // TODO: skip by default
  ],
  summaryTitle: 'Answers',
  pageTitle: 'Getting started with your Data Register',
};
