import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ControllerRoPAGroup,
  ControllerRoPARow,
  ExternalController,
  ProcessingActivityRole,
  ProcessorRoPAGroup,
  ProcessorRoPARow,
  DataCategory,
  DataLocation,
  DisclosedTo,
  LegalGround,
  ProcessingPurpose,
  TransferOutsideEEA,
  UpsertDataCategoryActionData,
} from '@ca/gdpr';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  GdprToolState,
  selectAddSubjectRegisterSettingsData,
  selectProcessingPurposeById,
} from '../../../store';
import {
  CaSubscriber,
  displayNamedObjectOrString,
  filterByNamedObjectOrString,
} from '@ca/ca-utils';
import { CreateDataCategoryConfigurationFG } from '../../../utils/forms';
import { Observable, map, startWith } from 'rxjs';
import { newDataCategory } from '../../../config/defaults';
import { UpsertRoPARow } from '../../../store/actions/data-register.actions';

/**
 * Used to create a data category from within a Subject Type Category of a data register.
 */
@Component({
  selector: 'ca-upsert-data-category-from-register',
  templateUrl: './upsert-data-category-in-register.component.html',
})
export class UpsertDataCategoryInRegisterComponent
  implements OnInit, OnDestroy
{
  get title() {
    return $localize`:@@create-or-update-data-category:${
      this.data.upsertDataCategory.id > 0 ? 'Update' : 'Add'
    } Data Category ${
      this.purpose
        ? (this.data.upsertDataCategory.id > 0 ? 'in ' : 'to ') +
          this.purpose.name
        : ''
    }`;
  }

  get categoryNameCtrl() {
    return this.fg.get(['dataCategory', 'name'] as const) as FormControl;
  }

  get categoryId() {
    return this.fg.get(['dataCategory', 'name', 'id'] as const)?.value;
  }
  purpose?: ProcessingPurpose;
  fg: FormGroup;
  dataCategoryLabel = $localize`:@@data-category-question:What category personal information do you want to store?`;
  dataCategoryInfo = $localize`:@@data-category-info:Qualifies the specific categories of the personal information we process.`;

  private sub = new CaSubscriber();
  filteredDataCategories?: Observable<DataCategory[]>;
  dataCategoriesForSelect?: DataCategory[];
  dataLocations?: DataLocation[];
  disclosedTos?: DisclosedTo[];
  externalControllers?: ExternalController[];
  legalGrounds?: LegalGround[];
  transfersOutsideEEA?: TransferOutsideEEA[];

  constructor(
    protected fb: FormBuilder,
    protected ref: MatDialogRef<UpsertDataCategoryInRegisterComponent>,
    private store: Store<GdprToolState>,
    @Inject(MAT_DIALOG_DATA) protected data: IUpsertDataCategoryDialogData
  ) {
    this.sub.subscribe(
      // TODO: create more specific selector?
      this.store.select(selectAddSubjectRegisterSettingsData),
      {
        next: (data) => {
          if (data.dataLocations) this.dataLocations = data.dataLocations;
          if (data.dataCategories)
            this.dataCategoriesForSelect = data.dataCategories;
          if (data.disclosedTo) this.disclosedTos = data.disclosedTo;
          if (data.externalControllers)
            this.externalControllers = data.externalControllers;
          if (data.legalGrounds) this.legalGrounds = data.legalGrounds;
          if (data.transfersOutsideEEA)
            this.transfersOutsideEEA = data.transfersOutsideEEA;
        },
      }
    );
    // TODO: create form for existing row
    this.fg = CreateDataCategoryConfigurationFG(this.fb, {
      parentId: data.parent.id,
      id: data.row?.id ?? 0,
      dataCategory: data.row?.dataCategory ?? newDataCategory,
      parent: data.parent,
    });

    this.store
      .select(selectProcessingPurposeById(data.parent.purpose.id))
      .subscribe((res?: ProcessingPurpose) => (this.purpose = res));
  }

  ngOnInit() {
    this.filteredDataCategories = this.categoryNameCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterDataCategoriesForSelect(value || ''))
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  save() {
    const upsert = this.fg.getRawValue();
    if (upsert.dataCategory && upsert.dataCategory?.name?.id > 0) {
      upsert.dataCategoryId = upsert.dataCategory.name.id;
      delete upsert.dataCategory;
    }
    this.store.dispatch(UpsertRoPARow(upsert));
    this.ref.close();
  }

  private _filterDataCategoriesForSelect(
    value: DataCategory | string
  ): DataCategory[] {
    return filterByNamedObjectOrString(value, this.dataCategoriesForSelect);
  }

  protected displayFn(option?: DataCategory | string) {
    return displayNamedObjectOrString(option);
  }
}

export interface IUpsertDataCategoryDialogData {
  upsertDataCategory: UpsertDataCategoryActionData;
  parent: ControllerRoPAGroup | ProcessorRoPAGroup;
  mode: ProcessingActivityRole;
  dataCategoryIdsToExclude: number[];
  row?: ControllerRoPARow | ProcessorRoPARow;
}
