import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataSubjectType } from '@ca/gdpr';
import { GdprToolState, selectDataSubjectTypes } from '../../../store';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { deleteSubjectType } from '../../../store/actions/settings.actions';
import { UpsertSubjectTypeComponent } from '../dialogs/upsert-subject-type/upsert-subject-type.component';
import { newDataSubjectType } from '../../../config/defaults';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSubjectTypesInfoHover } from '../../../config/info-hovers';

@Component({
  selector: 'ca-subject-type',
  template: `
    <h1 class="info-title" i18n="@@data-subject-types-title">
      Data Subject Types
      <!--<ca-info-hover [info]="info"></ca-info-hover>-->
    </h1>
    <ng-container *ngIf="dataSource">
      <mat-form-field>
        <mat-label i18n="@@search-input-label">Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. Biometric data"
          #input
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <table
        mat-table
        class="mat-elevation-z8"
        [dataSource]="dataSource"
        matSort
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="remove(element)"
                i18n="@@delete-btn"
              >
                DELETE<mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page of data subject types"
    ></mat-paginator>
    <button mat-raised-button (click)="addSubjectType()" i18n="@@add-btn">
      <mat-icon>add</mat-icon> ADD
    </button>
  `,
})
export class DataSubjectTypeComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource?: MatTableDataSource<DataSubjectType>;
  displayedColumns: string[] = ['actions', 'name'];
  info = DataSubjectTypesInfoHover;
  constructor(
    private store: Store<GdprToolState & OnboardingState>,
    public dialog: MatDialog
  ) {
    this.store.select(selectDataSubjectTypes).subscribe((subjectTypes) => {
      if (!this.dataSource)
        this.dataSource = new MatTableDataSource(subjectTypes ?? []);
      else this.dataSource.data = subjectTypes ?? [];
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  addSubjectType() {
    this.dialog.open(UpsertSubjectTypeComponent, {
      data: newDataSubjectType,
      disableClose: true,
    });
  }

  edit(subjectType: DataSubjectType) {
    this.dialog.open(UpsertSubjectTypeComponent, {
      data: subjectType,
      disableClose: true,
    });
  }

  remove(subjectType: DataSubjectType) {
    this.store.dispatch(
      deleteSubjectType({
        id: subjectType.id,
      })
    );
  }

  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
