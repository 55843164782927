import { createAction, props } from '@ngrx/store';
import { CaNotification } from '../notifications';
// import { FilesDroppedEventData } from '../../file-uploader/file-uploader.component';

export enum CoreActions {
  QUEUE_NOTIFICATION = '[Notifications] Queue Notification',
  UNQUEUE_NOTIFICATION = '[Notifications] Unqueue Notification',
  NOTIFICATION_SYSTEM_INIT = '[Notifications] Ready to be notified',
  CLEAR_NOTIFICATIONS = '[Notifications] Clear Notifications',
  // UPLOAD_FILES = '[Files] Upload Files',
  // UPLOAD_FILES_SUCCESS = '[Files] Upload Files [Success]',
}

export const ReadyToBeNotified = createAction(
  CoreActions.NOTIFICATION_SYSTEM_INIT
);
export const QueueNotification = createAction(
  CoreActions.QUEUE_NOTIFICATION,
  props<{ notification: CaNotification }>()
);
export const UnqueueNotification = createAction(
  CoreActions.UNQUEUE_NOTIFICATION
);
export const ClearNotifications = createAction(CoreActions.CLEAR_NOTIFICATIONS);
// export const UploadFiles = createAction(
//   CoreActions.UPLOAD_FILES,
//   props<FilesDroppedEventData>()
// );
// export const UploadFilesSuccess = createAction(
//   CoreActions.UPLOAD_FILES,
//   props<{ request: FilesDroppedEventData; insertMediaIds: number[] }>()
// );
