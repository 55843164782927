import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from './snackbar.service';
import {
  SnackbarModuleConfiguration,
  SnackbarServiceConfiguration,
} from './config';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './store/effects/notification.effects';

@NgModule({
  imports: [MatSnackBarModule, EffectsModule.forFeature([NotificationEffects])],
  providers: [SnackbarService],
})
export class SnackbarModule {
  static forRoot(
    configuration: SnackbarModuleConfiguration
  ): ModuleWithProviders<SnackbarModule> {
    return {
      ngModule: SnackbarModule,
      providers: [
        {
          provide: SnackbarModuleConfiguration,
          useValue: configuration,
        },
        {
          provide: SnackbarServiceConfiguration,
          useValue: configuration.service,
        },
      ],
    };
  }
}
