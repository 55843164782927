import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnlayerEditorComponent } from './unlayer-editor/unlayer-editor.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UnlayerEditorComponent],
  exports: [UnlayerEditorComponent],
})
export class UnlayerEditorModule {}
