import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DataController,
  Organization,
  PrivacyStatementGenerator,
  PrivacyStatementType,
} from '@ca/privacy-statement-generator';
import { ProcessingActivityRow } from '@ca/gdpr';

@Component({
  selector: 'ca-privacy-statement-generator-dialog',
  template: `
    <h2 mat-dialog-title>Generate {{ StatementName }} from selected RoPA</h2>
    <mat-dialog-content>
      <!-- <ca-ropa-table
        (selectionChanged)="selection = $event"
        [multiple]="data.statementType === 'notice'"
      ></ca-ropa-table> -->
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        mat-raised-button
        [disabled]="selection === undefined || selection.length === 0"
        (click)="Generate()"
      >
        GENERATE
      </button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./privacy-statement-generator-dialog.component.scss'],
})
export class PrivacyStatementGeneratorDialogComponent {
  // TODO: add more configuration options to privacy policy + use chat GPT API prompts to make the result more customizable
  selection?: ProcessingActivityRow[];
  get StatementName() {
    return this.data.statementType === 'policy'
      ? 'Privacy Policy'
      : 'Privacy Statement';
  }
  private psg?: PrivacyStatementGenerator;
  public get PSG(): PrivacyStatementGenerator {
    if (this.psg === undefined) this.psg = new PrivacyStatementGenerator();
    return this.psg;
  }
  constructor(
    private ref: MatDialogRef<PrivacyStatementGeneratorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      statementType: PrivacyStatementType;
      dataController: DataController;
      organisation: Organization;
    }
  ) {}

  Generate() {
    if (this.selection != undefined && this.selection?.length > 0) {
      let res = '';
      switch (this.data.statementType) {
        case 'policy':
          res = this.PSG.generatePrivacyPolicy({
            dataController: this.data.dataController,
            organization: this.data.organisation,
            // ropa: this.selection[0],
          });
          break;
        case 'notice':
          res = this.PSG.generatePrivacyNotice({
            dataController: this.data.dataController,
            organization: this.data.organisation,
            // processingActivities: this.selection,
          });
          break;
      }
    }
  }
}
