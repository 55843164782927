import { createReducer, on } from '@ngrx/store';
import {
  LoadQuestionsSuccess,
  SetHistoryVisibility,
} from '../actions/public.actions';
import { PublicQnaData } from '../../services/public/dsr-data-subject-qna.service';

export interface PublicState {
  posedQuestions: PublicQnaData[] | undefined;
  answeredQuestions: PublicQnaData[] | undefined;
  showHistory: boolean;
}
const initalState: PublicState = {
  posedQuestions: undefined,
  answeredQuestions: undefined,
  showHistory: false,
};

export const reducer = createReducer(
  initalState,
  on(LoadQuestionsSuccess, (_, { questions }) => ({
    ..._,
    posedQuestions: questions?.filter((e) => e.answer === null),
    answeredQuestions: questions?.filter((e) => e.answer !== null),
  })),
  on(SetHistoryVisibility, (_, { showHistory }) => ({
    ..._,
    showHistory,
  }))
);
