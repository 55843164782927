<mat-drawer-container class="dsr-drawer-container" style="position: relative">
  <mat-drawer
    #additionalInfoDrawer
    mode="side"
    position="end"
    class="questions-and-answers-drawer"
  >
    <div class="communication-area" *ngIf="dsr !== undefined">
      <div>
        <button mat-button (click)="additionalInfoDrawer.toggle()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <span i18n="@@additional-info">Additional Info</span>
      </div>

      <mat-tab-group>
        <mat-tab
          i18n-label="@@dsr-communication-area-label"
          label="Communication"
        >
          <ca-dsr-communication-area></ca-dsr-communication-area>
        </mat-tab>

        <mat-tab i18n-label="@@remarks-list-label" label="Remarks">
          <ca-remarks-list></ca-remarks-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-drawer>

  <div class="dsr-drawer-content" *ngIf="dsr !== undefined">
    <div class="header">
      <button mat-button (click)="navigateBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h3>
        <ng-container i18n="@@dsr-issued-by-label">
          DSR issued by
        </ng-container>
        {{ dsr.firstName }}
        {{ dsr.lastName }}
      </h3>
      <button
        *ngIf="additionalInfoDrawer.opened === false"
        type="button"
        style="margin: 0.66em 1.33em"
        color="primary"
        mat-raised-button
        (click)="additionalInfoDrawer.toggle()"
        i18n="@@show-additional-info-btn"
      >
        Show Additional Info <mat-icon>question_answer</mat-icon>
      </button>
      <span *ngIf="additionalInfoDrawer.opened === true"></span>
    </div>

    <div class="container">
      <ca-dsr-summary *ngIf="dsr" [dsr]="dsr"></ca-dsr-summary>

      <div class="stepper">
        <mat-stepper
          *ngIf="showStepper"
          orientation="vertical"
          [selectedIndex]="dsr.activeStep"
          [linear]="true"
        >
          <!-- 0) Confirm Identity -->
          <mat-step *ngIf="fg" [stepControl]="fg.confirmIdentity">
            <ng-template matStepLabel>
              <div class="stepper-header">
                <h3>
                  <mat-icon
                    [color]="dsr.activeStep > 0 ? 'primary' : 'warn'"
                    inline
                    >how_to_reg</mat-icon
                  >
                  <ng-container i18n="@@confirm-requester-identity-title"
                    >Confirm Requester Identity</ng-container
                  >
                </h3>

                <ca-remarks
                  *ngIf="dsr"
                  [context]="ctx['confirmIdentity']"
                  [parentId]="dsr.id"
                  [parentTable]="dsrTable"
                  (click)="preventStepperEvents($event)"
                  (showRemarksChanged)="
                    updateRemarksList($event.remarks, 'dsr.confirmIdentity')
                  "
                ></ca-remarks>
              </div>
            </ng-template>

            <div class="step-body">
              <div class="step-instructions">
                <p i18n="@@confirm-identity-step-instructions-pt1">
                  Before undertaking any action we must confirm that the request
                  originated from the actual person the requester claims to be.
                </p>
                <p i18n="@@confirm-identity-step-instructions-pt2">
                  This can be done by matching the requester's e-mail address
                  with a record in your own data. If the e-mail address in the
                  request matches the one you have, you can confirm the
                  requester's idenity with an automated e-mail confirmation
                  request.
                </p>
              </div>

              <div class="step-actions">
                <div style="display: flex; justify-content: space-between">
                  <button
                    class="step-action"
                    mat-raised-button
                    (click)="confirmIdentityByEMail()"
                    [disabled]="dsr.identityConfirmed"
                    i18n="@@send-identity-confirmation-e-mail-btn"
                  >
                    SEND IDENTIY CONFIRMATION E-MAIL
                  </button>
                  <span
                    class="sent-at-label"
                    *ngIf="dsr.identityConfirmationSentAt"
                    i18n="@@sent-at-label"
                    >Sent at: {{ dsr.identityConfirmationSentAt }}</span
                  >
                  <span
                    class="sent-at-label"
                    *ngIf="dsr.identityConfirmationReceivedAt"
                    i18n="@@received-at-label"
                    >Received at: {{ dsr.identityConfirmationSentAt }}</span
                  >
                </div>

                <div [formGroup]="fg.confirmIdentity" class="step-action">
                  <mat-slide-toggle
                    formControlName="identityConfirmed"
                    (change)="confirmManually()"
                  >
                    <span i18n="@@mark-identity-confirmed-btn">
                      Mark Identity Confirmed
                    </span>
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

            <div class="step-actions">
              <button
                mat-raised-button
                (click)="step()"
                [disabled]="!dsr.identityConfirmed"
                i18n="@@next-btn"
              >
                NEXT
              </button>
            </div>
          </mat-step>

          <!-- 1) Qualify Data Subject -->
          <mat-step>
            <!--
              [editable]="dsr.activeStep < 3"
            [completed]="dsr.activeStep > 1 && dsr.identityConfirmed"
             -->
            <ng-template matStepLabel>
              <div class="stepper-header">
                <h3>
                  <mat-icon
                    [color]="dsr.activeStep > 1 ? 'primary' : 'warn'"
                    inline
                    >groups</mat-icon
                  >
                  <ng-container i18n="@@qualify-data-subject-step-title">
                    Qualify Data Subject</ng-container
                  >
                </h3>

                <ca-remarks
                  *ngIf="dsr"
                  [context]="ctx['qualifyDataSubject']"
                  [parentId]="dsr.id"
                  [parentTable]="dsrTable"
                  (click)="preventStepperEvents($event)"
                  (showRemarksChanged)="
                    updateRemarksList(
                      $event.remarks,
                      'dsr.qualifyDataSubjectTypes'
                    )
                  "
                ></ca-remarks>
              </div>
            </ng-template>
            <div class="step-body">
              <ca-select-data-subject-types
                [initialSelection]="dsr.dataSubjectTypes"
                (selectionChanged)="onDataSubjectTypesChanged($event)"
              ></ca-select-data-subject-types>
            </div>
            <div class="step-actions">
              <button
                mat-raised-button
                (click)="step()"
                [disabled]="
                  dsr.dataSubjectTypes === undefined ||
                  dsr.dataSubjectTypes.length === 0
                "
                i18n="@@next-btn"
              >
                NEXT
              </button>
            </div>
          </mat-step>

          <!-- 2) Mark Data -->
          <mat-step [optional]="true">
            <!--
                          [editable]="dsr.activeStep < 3"
            [completed]="dsr.activeStep > 2"
             -->
            <ng-template matStepLabel>
              <div class="stepper-header">
                <h3>
                  <mat-icon
                    [color]="dsr.activeStep > 2 ? 'primary' : 'warn'"
                    inline
                    >checklist</mat-icon
                  >
                  <ng-container i18n="@@mark-data-btn">Mark Data</ng-container>
                </h3>
                <ca-remarks
                  *ngIf="dsr"
                  [context]="ctx['processStep']"
                  [parentId]="dsr.id"
                  [parentTable]="dsrTable"
                  (click)="preventStepperEvents($event)"
                  (showRemarksChanged)="
                    updateRemarksList($event.remarks, 'dsr.processStep')
                  "
                ></ca-remarks>
              </div>
            </ng-template>

            <div class="step-body">
              <ca-mark-data
                [initialValue]="dsr.markedRoPA"
                [dataSubjectTypes]="dsr.dataSubjectTypes"
                (selectionChanged)="onMarkedData($event)"
              ></ca-mark-data>
            </div>

            <div class="step-actions">
              <button
                mat-raised-button
                (click)="step('previous')"
                i18n="@@back-btn"
              >
                BACK
              </button>
              <button mat-raised-button (click)="step()" i18n="@@next-btn">
                NEXT
              </button>
            </div>
          </mat-step>

          <!-- 3) Process Request -->
          <mat-step>
            <!--
                          [editable]="dsr.activeStep === 3"
            [completed]="dsr.activeStep > 3"
             -->
            <ng-template matStepLabel>
              <div class="stepper-header">
                <h3 i18n="@@process-step-label">
                  <mat-icon
                    [color]="dsr.activeStep > 3 === true ? 'primary' : 'warn'"
                    inline
                    >checklist</mat-icon
                  >
                  Process
                </h3>

                <ca-remarks
                  *ngIf="dsr"
                  [context]="ctx['processStep']"
                  [parentId]="dsr.id"
                  [parentTable]="dsrTable"
                  (click)="preventStepperEvents($event)"
                  (showRemarksChanged)="
                    updateRemarksList($event.remarks, 'dsr.processStep')
                  "
                ></ca-remarks>
              </div>
            </ng-template>

            <div class="step-body">
              <ca-process-dsr [dsr]="dsr"></ca-process-dsr>
            </div>

            <div class="step-actions">
              <button
                mat-raised-button
                (click)="step('previous')"
                i18n="@@back-btn"
              >
                BACK
              </button>
              <button mat-raised-button (click)="step()" i18n="@@next-btn">
                NEXT
              </button>
            </div>
          </mat-step>

          <!-- 4) Respond -->
          <mat-step>
            <ng-template matStepLabel>
              <div class="stepper-header">
                <h3>
                  <mat-icon
                    [color]="dsr.activeStep > 4 ? 'primary' : 'warn'"
                    inline
                    >tour</mat-icon
                  >
                  <ng-container i18n="@@completion-step-label">
                    Completion
                  </ng-container>
                </h3>

                <ca-remarks
                  *ngIf="dsr"
                  [context]="ctx['completionStep']"
                  [parentId]="dsr.id"
                  [parentTable]="dsrTable"
                  (click)="preventStepperEvents($event)"
                  (showRemarksChanged)="
                    updateRemarksList($event.remarks, 'dsr.completionStep')
                  "
                ></ca-remarks>
              </div>
            </ng-template>
            <div class="step-body">
              <p i18n="@@dsr-process-completed-message">You are now done.</p>
            </div>
            <div class="step-actions">
              <button mat-button (click)="step('previous')" i18n="@@back-btn">
                BACK
              </button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</mat-drawer-container>
