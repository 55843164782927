import { FilesDroppedEventData } from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';
import { GdprToolState, selectUnlinkedMedia } from '../store';
import {
  ClearUnlinkedMedia,
  UploadFiles,
} from '../store/actions/file-upload.actions';
import { filter, firstValueFrom } from 'rxjs';
/**
 * File Upload Handler
 */
export abstract class FileUploadHandler<T extends { id: number }> {
  protected data?: T;
  protected insertMediaIds: number[] = [];
  protected uploadHistory: string[] = [];

  constructor(protected store: Store<GdprToolState>, protected _data?: T) {
    if (this._data) this.data = this._data;
  }

  init(data: T) {
    this.data = data;
  }

  filterRes(
    event: FilesDroppedEventData,
    res: { trackingId: string; unlinkedIds: number[] }[]
  ) {
    const trackedResult = res.find((e) => e.trackingId === event.trackingId);
    if (trackedResult) return trackedResult.unlinkedIds;
    return [];
  }

  onUploadMedia(event: FilesDroppedEventData) {
    if (
      event.files.length > 0 &&
      this.uploadHistory.findIndex((e) => event.trackingId === e) < 0
    ) {
      this.store.dispatch(UploadFiles(event));
      // listen for uploaded ids filtered by
      if (this.data?.id === 0) {
        this.uploadHistory.push(event.trackingId);

        firstValueFrom(
          this.store
            .select(selectUnlinkedMedia)
            .pipe(
              filter(
                (v) => v.findIndex((e) => e.trackingId === event.trackingId) > 0
              )
            )
        ).then((res) => {
          const trackedResults = this.filterRes(event, res);

          this.insertMediaIds =
            this.insertMediaIds.length > 0
              ? [...this.insertMediaIds, ...trackedResults]
              : [...trackedResults];

          this.store.dispatch(
            ClearUnlinkedMedia({ keyToClear: event.trackingId })
          );
        });
      }
    }
  }
}
