import { HumidorUpsertEmailTemplateData } from '../models/e-mail-templates.models';
import {
  UpsertDataCategoryActionData,
  UpsertDataLocation,
  UpsertDataSubjectTypeActionData,
  UpsertDisclosedTo,
  UpsertExternalProcessorActionData,
  UpsertLegalGroundActionData,
  UpsertProcessingPurposeActionData,
  UpsertSecurityMeasure,
  UpsertTransferOutsideEEAActionData,
  UpsertExternalControllerActionData,
  // UpsertProcessingActivity,
} from '@ca/gdpr';
import { DataSubjectRight, UpsertDataSubjectRequest } from '@ca/gdpr';
import { UpsertAddressInline } from '@ca/core';
import { UpsertReplyToAddressesData } from '@ca/ca-mailing';
// import { UpsertReplyToAddressesComponent } from '../components/app-settings/dialogs/upsert-reply-to-addresses/upsert-reply-to-addresses.component';

/**
 * Settings
 */
//#region App Settings
export const newDataLocation: UpsertDataLocation = {
  id: 0,
  description: '',
  name: '',
  technology: '',
  securityMeasureIds: [],
};

export const newDisclosedTo: UpsertDisclosedTo = {
  id: 0,
  name: '',
  description: '',
  external: false,
};

export const newMailTemplate: HumidorUpsertEmailTemplateData = {
  id: 0,
  name: '',
  config: {},
  html: '',
  isDefault: false,
  subject: '',
  type: 'general',
};
export const newAddress: UpsertAddressInline = {
  city: '',
  country: '',
  houseNumber: 0,
  houseNumberSuffix: '',
  postalCode: '',
  street: '',
};
export const newExternalProcessor: UpsertExternalProcessorActionData = {
  id: 0,
  name: '',
  email: '',
  phone: '',
  address: newAddress,
};
export const newExternalController: UpsertExternalControllerActionData = {
  id: 0,
  name: '',
  email: '',
  phone: '',
  vatNumber: '',
  address: newAddress,
};
export const newSecurityMeasure: UpsertSecurityMeasure = {
  id: 0,
  name: '',
  description: '',
};
export const newDataSubjectType: UpsertDataSubjectTypeActionData = {
  id: 0,
  name: '',
};
export const newTransferOutsideEEA: UpsertTransferOutsideEEAActionData = {
  id: 0,
  name: '',
};
export const newReplyToAddress: UpsertReplyToAddressesData = {
  email: '',
  id: 0,
  name: '',
};
export const newProcessingPurpose: UpsertProcessingPurposeActionData = {
  description: '',
  id: 0,
  name: '',
};
export const newDataCategory: UpsertDataCategoryActionData = {
  description: '',
  id: 0,
  name: '',
  sensitive: false,
};
export const newLegalGround: UpsertLegalGroundActionData = {
  id: 0,
  name: '',
  description: '',
};
//#endregion

/**
 * Data Register
 */

//#region DSR
export const newDSR: (
  invokedRight?: DataSubjectRight
) => UpsertDataSubjectRequest = (invokedRight = 'right-to-insight') => ({
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  context: '',
  invokedRight: invokedRight,
  procedureId: 0,
  identityConfirmed: false,
  requestedAt: '',
  preferredLanguage: 'en',
});

//#endregion
