import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { DataLocation } from '@ca/gdpr';
import { GdprToolState, selectDataLocations } from '../../../store';
import { deleteDataLocation } from '../../../store/actions/settings.actions';
import { UpsertDataLocationDialogComponent } from '../dialogs/upsert-data-location-dialog/upsert-data-location-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { newDataLocation } from '../../../config/defaults';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataLocationsInfoHover } from '../../../config/info-hovers';

@Component({
  selector: 'ca-data-locations',
  template: `
    <h1 class="info-title">
      <span i18n="@@data-locations-title"> Data Locations </span>
      <!--<ca-info-hover [info]="info"></ca-info-hover>-->
    </h1>
    <ng-container *ngIf="dataSource">
      <mat-form-field>
        <mat-label i18n="@@search-input-label">Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. Biometric data"
          #input
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="technology">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            i18n="@@technology"
          >
            Technology
          </th>
          <td mat-cell *matCellDef="let element">{{ element.technology }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef i18n="@@description">
            Description
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="table-text"
            caCutCellText
            [maxCellTextLength]="60"
            [cellText]="element.description"
          >
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="securityMeasures">
          <th mat-header-cell *matHeaderCellDef i18n="@@security-measures">
            Security Measures
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            <!--
            TODO: make cut cell text work with display pipes?
            caCutCellText
            [maxCellTextLength]="60"
            [cellText]="element.securityMeasures"
            -->
            {{ element.securityMeasures | namedObjects }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="remove(element)"
                i18n="@@delete-btn"
              >
                DELETE<mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <ng-container>
          <td>
            <!-- TODO: add btn function? -->
            <mat-icon>add</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page of data locations"
    ></mat-paginator>
    <button mat-raised-button (click)="addDataLocation()" i18n="@@add-btn">
      <mat-icon>add</mat-icon> ADD
    </button>
  `,
})
export class DataLocationsComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  protected info = DataLocationsInfoHover;
  dataSource?: MatTableDataSource<DataLocation>;
  displayedColumns: string[] = [
    'actions',
    'name',
    'technology',
    'securityMeasures',
    'description',
  ];

  constructor(private store: Store<GdprToolState>, public dialog: MatDialog) {
    this.store.select(selectDataLocations).subscribe((dls) => {
      if (!this.dataSource) this.dataSource = new MatTableDataSource(dls);
      else this.dataSource.data = dls ?? [];
    });
  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  addDataLocation() {
    this.dialog.open(UpsertDataLocationDialogComponent, {
      data: { upsert: newDataLocation },
      disableClose: true,
    });
  }

  edit(dl: DataLocation) {
    this.dialog.open(UpsertDataLocationDialogComponent, {
      data: { upsert: dl },
      disableClose: true,
    });
  }

  remove(dl: DataLocation) {
    this.store.dispatch(deleteDataLocation({ id: dl.id }));
  }

  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
