import { Component, Input } from '@angular/core';
import { getDashboardRoute } from '../../app-routing.module';
import { NavLink } from '../../models/navigation.models';

@Component({
  selector: 'ca-navigation',
  template: `
    <mat-nav-list *ngIf="links">
      <ng-container *ngFor="let link of links">
        <a
          *ngIf="link.link"
          [activated]="link.isActive"
          mat-list-item
          [routerLink]="navLink(link)"
          matTooltipPosition="right"
          [matTooltip]="link.info ?? ''"
          [matTooltipDisabled]="link.info === undefined"
        >
          <mat-icon *ngIf="link.icon" matListItemIcon>{{ link.icon }}</mat-icon>
          {{ link.label }}</a
        >

        <mat-list-item
          *ngIf="link.link === undefined"
          (click)="link.isActive = !link.isActive"
          [activated]="settingsLinkIsActive"
          matTooltipPosition="right"
          [matTooltip]="link.info ?? ''"
          [matTooltipDisabled]="link.info === undefined"
        >
          <mat-icon *ngIf="link.icon" matListItemIcon>{{ link.icon }}</mat-icon>
          {{ link.label }}
        </mat-list-item>
        <ng-container *ngIf="settingsLinkIsActive || link.isActive">
          <a
            *ngFor="let childLink of link.children"
            [activated]="childLink.isActive"
            mat-list-item
            class="sub-navigation-item"
            [routerLink]="navLink(childLink)"
            matTooltipPosition="right"
            [matTooltip]="childLink.info ?? ''"
            [matTooltipDisabled]="childLink.info === undefined"
          >
            <mat-icon matListItemIcon>arrow_right</mat-icon>
            {{ childLink.label }}</a
          >
        </ng-container>
      </ng-container>
    </mat-nav-list>
  `,
  styles: [
    `
      .mdc-list-item--with-leading-icon.mdc-list-item.sub-navigation-item {
        padding-left: 0.33em;
      }
    `,
  ],
})
export class NavigationComponent {
  @Input() links!: NavLink[];
  navLink = (l: NavLink) => getDashboardRoute(l);
  get settingsLinkIsActive(): boolean {
    const settingsChildLinks = this.links.find(
      (e) => e.link === undefined
    )?.children;
    return settingsChildLinks
      ? settingsChildLinks.findIndex((e) => e.isActive) >= 0
      : false;
  }
}
