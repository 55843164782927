import { createAction, props } from '@ngrx/store';
import { GdprToolActionType } from '../action.types';
import {
  ProcessingActivityGroups,
  // ProcessingActivityAsController,
  // ProcessingActivityDetail,
  UpsertControllerRoPAGroup,
  UpsertControllerRoPARow,
  // UpsertProcessingActivity,
  UpsertProcessorRoPAGroup,
  UpsertProcessorRoPARow,
  ProcessingActivityGroup,
  ProcessingActivityRole,
} from '@ca/gdpr';
import {
  DeleteProcessingActivityResult,
  DeleteRoPAGroupResult,
  DeleteRoPARowResult,
  // UpsertProcessingActivityResult,
  UpsertRoPAGroupResult,
  UpsertRoPARowResult,
} from '../models';
import { DeleteRequest } from '@ca/ca-data';
import { IUpsertDataCategoryDialogData } from '../../components/data-register/upsert-data-category-in-register/upsert-data-category-in-register.component';
import { UpsertRoPAPurposeFromRegister } from '../../components/data-register/upsert-purpose-in-register/upsert-purpose-in-register.component';

export const loadProcessingActivities = createAction(
  GdprToolActionType.LoadProcessingActivities
);
// export const loadProcessingActivitiesSuccess = createAction(
//   GdprToolActionType.LoadProcessingActivitiesSuccess,
//   props<{ processingActivities: ProcessingActivityAsController[] }>()
// );
export const loadSingleProcessingActivity = createAction(
  GdprToolActionType.LoadSingleProcessingActivity
);
// export const loadSingleProcessingActivitySuccess = createAction(
//   GdprToolActionType.LoadSingleProcessingActivitySuccess,
//   props<{ processingActivity: ProcessingActivityAsController }>()
// );
// export const expandProcessingActivityRow = createAction(
//   GdprToolActionType.expandProcessingActivityRow,
//   props<{ processingActivity: ProcessingActivityAsController | null }>()
// );
// export const expandProcessingActivityRowSuccess = createAction(
//   GdprToolActionType.expandProcessingActivityRowSuccess,
//   props<{ expandedProcessingActivity: ProcessingActivityDetail | null }>()
// );
// export const upsertProcessingActivity = createAction(
//   GdprToolActionType.UpsertProcessingActivity,
//   props<UpsertProcessingActivity>()
// );
// export const upsertProcessingActivitySuccess = createAction(
//   GdprToolActionType.UpsertProcessingActivitySuccess,
//   props<UpsertProcessingActivityResult>()
// );
export const deleteProcessingActivity = createAction(
  GdprToolActionType.DeleteProcessingActivity,
  props<DeleteRequest>()
);
export const deleteProcessingActivitySuccess = createAction(
  GdprToolActionType.DeleteProcessingActivitySuccess,
  props<DeleteProcessingActivityResult>()
);
export const InsertNewRoPA = createAction(GdprToolActionType.InsertNewRoPA);

//#region V2
export const LoadDataRegister = createAction(
  GdprToolActionType.LoadDataRegister
);
export const LoadDataRegisterSuccess = createAction(
  GdprToolActionType.LoadDataRegisterSuccess,
  props<{ ropa: ProcessingActivityGroups }>()
);
export const LoadRoPADetail = createAction(
  GdprToolActionType.LoadRoPADetail,
  props<{ id: number }>()
);
export const LoadRoPADetailSuccess = createAction(
  GdprToolActionType.LoadRoPADetailSuccess,
  props<{ detail: ProcessingActivityGroup }>()
);
export const UpsertRoPAGroup = createAction(
  GdprToolActionType.UpsertRoPAGroup,
  props<UpsertProcessorRoPAGroup | UpsertControllerRoPAGroup>()
);
export const UpsertRoPAGroupSuccess = createAction(
  GdprToolActionType.UpsertRoPAGroupSuccess,
  props<UpsertRoPAGroupResult>()
);
export const UpsertRoPARow = createAction(
  GdprToolActionType.UpsertRoPARow,
  props<UpsertProcessorRoPARow | UpsertControllerRoPARow>()
);
export const UpsertRoPARowSuccess = createAction(
  GdprToolActionType.UpsertRoPARowSuccess,
  props<UpsertRoPARowResult>()
);
export const DeleteRoPAGroup = createAction(
  GdprToolActionType.DeleteRoPAGroup,
  props<DeleteRequest>()
);
export const DeleteRoPAGroupSuccess = createAction(
  GdprToolActionType.DeleteRoPAGroupSuccess,
  props<DeleteRoPAGroupResult>()
);
export const DeleteRoPARow = createAction(
  GdprToolActionType.DeleteRoPARow,
  props<DeleteRequest>()
);
export const DeleteRoPARowSuccess = createAction(
  GdprToolActionType.DeleteRoPARowSuccess,
  props<DeleteRoPARowResult>()
);

export const UpsertDataSubjectPurposeFromRegister = createAction(
  GdprToolActionType.UpsertDataSubjectPurposeFromRegister,
  props<UpsertRoPAPurposeFromRegister>()
);
export const AddCategoryFromRegister = createAction(
  GdprToolActionType.AddCategoryFromRegister,
  props<IUpsertDataCategoryDialogData>()
);
export const UpsertCategoryFromRegister = createAction(
  GdprToolActionType.UpsertCategoryFromRegister,
  props<IUpsertDataCategoryDialogData>()
);
export const ExportDataRegister = createAction(
  GdprToolActionType.ExportDataRegister,
  props<{
    role: ProcessingActivityRole | 'all';
  }>()
);
//#endregion
