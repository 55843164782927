import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  DSR,
  DSRStepProgress,
  DataSubjectRight,
  UpsertDataSubjectRequestActionData,
} from '@ca/gdpr';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { APP_ROUTES } from '../../../config/navigation';
import { GdprToolState } from '../../../store';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import {
  // ProcessingActivityAsController,
  ProcessingActivityGroups,
} from '@ca/gdpr';
import { DSRProcedureForm } from './DSRProcedureForm';
import {
  ConfirmDataSubjectIdentity,
  ConfirmDataSubjectIdentityByMail,
  LoadDSR,
  upsertDataSubjectRequest,
  upsertDataSubjectRequestSuccess,
} from '../../../store/actions/dsr.actions';
import { MailRecipient } from '@ca/ca-mailing';
import { AreYouSureService } from '@ca/ca-are-you-sure';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import {
  DSRContext,
  DSRContextKey,
  TableNamesForLinking,
} from '../../../config/table-names-for-linking';
import { RemarksListComponent } from '../../shared/remarks/remarks-list/remarks-list.component';
import {
  GetInvokedRightLabel,
  GetRemarksContextHeaderAndOrder,
} from '../../../utils/dsr';
import * as moment from 'moment';

import { CaEnvironment, CaSubscriber, GroupBy } from '@ca/ca-utils';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';
import { DataSubjectType } from '@ca/gdpr';
import { Actions, ofType } from '@ngrx/effects';
import { UpsertDataSubjectRequestResult } from '../../../store/models';
import { Remark } from '@ca/core';
export const MAX_STEPPER_INDEX = 4;

@Component({
  selector: 'ca-data-subject-request-detail',
  templateUrl: './data-subject-request-detail.component.html',
  styles: [
    `
      .step-actions {
        button.mdc-button {
          margin-left: 1.33em;
        }
      }
    `,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true },
    },
  ],
})
export class DataSubjectRequestDetailComponent implements OnDestroy {
  private remarksList?: RemarksListComponent;
  @ViewChild(RemarksListComponent) protected set content(
    remarksList: RemarksListComponent
  ) {
    if (remarksList) {
      this.remarksList = remarksList;
      if (this.dsr?.remarks && this.dsr.remarks.length > 0) {
        // this.dsr.remarks
        const groups = GroupBy<DSRContextKey, Remark>(
          this.dsr.remarks,
          (r) => r.context as DSRContextKey
        );
        groups.forEach((v, k) => {
          this.remarksList?.update({
            ...GetRemarksContextHeaderAndOrder(k),
            key: k,
            remarks: v,
          });
        });
      }
    }
  }

  protected showStepper = false;
  protected stepper?: MatStepper;
  @ViewChild(MatStepper) protected set Stepper(stepper: MatStepper) {
    if (stepper) {
      this.stepper = stepper;
      if (this.dsr && this.dsr.activeStep > 0)
        for (let i = 0; i < this.dsr.activeStep; i++) {
          const step = this.stepper.steps.get(i);
          if (step) {
            // complete and mark interacted, all steps that preceed the current active stepper index
            step._markAsInteracted();
            step.completed = true;
          }
        }
    }
  }

  private sub = new CaSubscriber();
  protected dsr?: DSR;
  protected invokedRight?: DataSubjectRight;
  protected fg?: DSRProcedureForm;
  protected dsrTable = TableNamesForLinking.DSRs;
  protected ctx = DSRContext;

  protected get InvokedRightLabel() {
    return this.dsr
      ? GetInvokedRightLabel(this.dsr?.invokedRight)
      : $localize`:@@unknown-invoked-right-label:UNKNOWN`;
  }

  protected get DSRDeadline() {
    return this.dsr
      ? moment(this.dsr.requestedAt, 'DD/MM/YYYY').add(1, 'months').fromNow()
      : $localize`:@@unknown-requested-date-label:Request date unknown`;
  }

  protected get DSRConfirmIdentityRequestSentToday() {
    return this.dsr
      ? moment(this.dsr.identityConfirmationSentAt, 'DD/MM/YYYY HH:mm').isSame(
          moment(),
          'date'
        )
      : false;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<GdprToolState & OnboardingState>,
    private ays: AreYouSureService,
    private actions$: Actions,
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment
  ) {
    this.sub.subscribe(
      this.actions$.pipe(ofType(upsertDataSubjectRequestSuccess)),
      {
        next: (data: UpsertDataSubjectRequestResult) => {
          this.store.dispatch(LoadDSR({ id: data.request.id }));
        },
      }
    );
    this.route.paramMap.subscribe((params: ParamMap) => {
      const paramId = params.get('id');
      if (paramId != null && +paramId !== this.dsr?.id) {
        this.store.dispatch(LoadDSR({ id: +paramId }));
      }
    });
  }

  // ngOnInit(): void {
  // this.sub.subscribe(this.store.select(selectDSR), {
  //   next: (dsr?: DSR) => {
  //     this.dsr = dsr;
  //     if (dsr !== undefined) {
  //       this.fg = new DSRProcedureForm(dsr);
  //       this.showStepper = true;
  //     }
  //   },
  // });
  // }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  updateRemarksList(remarks: Remark[], key: DSRContextKey) {
    this.remarksList?.update({
      remarks,
      key,
      ...GetRemarksContextHeaderAndOrder(key),
    });
  }

  step(direction: 'next' | 'previous' = 'next') {
    if (this.dsr)
      switch (this.dsr.activeStep) {
        case 0:
          this.updateState({
            id: this.dsr.id,
            activeStep: direction === 'next' ? 1 : 0,
          });
          break;

        case 1:
          this.updateState({
            id: this.dsr.id,
            activeStep: direction === 'next' ? 2 : 1,
          });
          break;

        case 2:
          this.updateState({
            id: this.dsr.id,
            activeStep: direction === 'next' ? 3 : 1,
          });
          break;

        case 3:
          this.updateState({
            id: this.dsr.id,
            activeStep: direction === 'next' ? 4 : 2,
          });
          break;

        case 4:
          this.updateState({
            id: this.dsr.id,
            activeStep: direction === 'next' ? 4 : 2,
          });
          break;
      }
  }

  onDataSubjectTypesChanged(e: DataSubjectType[]) {
    if (this.dsr)
      this.updateState({
        id: this.dsr.id,
        dataSubjectTypes: e.map((e) => e.id),
      });
  }

  onMarkedData(e: ProcessingActivityGroups) {
    if (this.dsr)
      this.updateState({
        id: this.dsr.id,
        markedRoPA: e.map((e) => e.id),
      });
  }

  updateState(updateData?: UpsertDataSubjectRequestActionData) {
    if (this.dsr && updateData)
      this.store.dispatch(
        upsertDataSubjectRequest(
          updateData ?? {
            id: this.dsr.id,
          }
        )
      );
  }

  confirmManually() {
    const fc = this.fg?.confirmIdentity.get('identityConfirmed');
    if (fc?.value === true) {
      fc.disable();
      firstValueFrom(
        this.ays
          .OpenAreYouSure({
            icon: 'report',
            title: $localize`:@@manually-confirm-identity-dialog-title:Watch out!`,
            cancelButtonText: $localize`:@@cancel-confirm-identity-dialog-btn:No thanks, I'll make sure of that first!`,
            confirmButtonText: $localize`:@@confirm-identity-dialog-btn:Yes, I know who I'm dealing with!`,
            confirmQuestion: $localize`:@@confirm-identity-dialog-question:Are you sure you want to manually confirm the Data Subjects Identity?`,
          })
          .afterClosed()
      ).then((res?: boolean) => {
        fc.setValue(res);
        if (res === false) fc.enable();
        else if (this.dsr) {
          this.store.dispatch(
            ConfirmDataSubjectIdentity({
              dsrId: this.dsr.id,
            })
          );
        }
      });
    }
  }

  confirmIdentityByEMail() {
    if (this.dsr) {
      if (!this.dsr.email) throw new Error('Data Subject E-Mail is not known');
      if (!this.dsr.firstName)
        throw new Error('Data Subject First Name is not known');
      if (!this.dsr.lastName)
        throw new Error('Data Subject Last Name is not known');

      const recipient: MailRecipient = {
        email: this.dsr.email,
        name: `${this.dsr.firstName} ${this.dsr.lastName}`,
      };

      this.store.dispatch(
        ConfirmDataSubjectIdentityByMail({
          parentTable: this.dsrTable,
          context: this.ctx['confirmIdentity'],
          parentId: this.dsr.id,
          emailTemplateId: 11,
          recipients: [recipient],
          replaces: {
            invokedRightName: this.InvokedRightLabel,
            accessLink: `${this.env.baseUrl}/validate/dsr?code=${this.dsr.identityConfirmationToken}`,
          },
          // TODO: COMPANY SETTINGS!!!
          // write default to store and subscribe to it in
          replyTo: {
            id: 0,
            email: 'gdpr@humidor.be',
            name: 'Humidor',
          },
        })
      );
    }
  }

  navigateBack() {
    this.router.navigate([`dashboard/${APP_ROUTES['dsrs']}`]);
  }

  // Prevents selection of step when clicking remarks button in step header
  preventStepperEvents(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
export interface DSRResults {
  markRoPA: MarkRoPAState;
  // process: ProcedureStepState;
}

export interface MarkRoPAState {
  // selection: ProcessingActivityAsController[];
  completed: boolean;
}
// export interface ProcedureStepState {
//   progress?: ProcessProgress;
//   completed: boolean;
// }
// export type ProcessProgress = ProcessProgressPerLocation[];
// export type ProcessProgressPerLocation = {
//   categories: NamedProgress[];
//   location: NamedProgress;
// };

export const DEFAULT_PROGRESS: DSRStepProgress = {
  completed: false,
};
export const DEFAULT_QUALIFY_SUBJECT_STATE: DSRStepProgress = {
  ...DEFAULT_PROGRESS,
  dataSubjectTypes: [],
};

export const STEP_1_CONFIRM_IDENTITY = 0;
export const STEP_2_QUALIFY_SUBJECT = 1;
export const STEP_3_MARK_DATA = 2;
export const STEP_4_PROCESS_REQUEST = 3;
export const STEP_5_RESPOND = 4;

export const setStepCompleted = (step: DSRStepProgress, completed = true) => ({
  ...step,
  completed,
});
