import { Injectable } from '@angular/core';
import { FileUploadService } from '../../services/core/file-upload.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  UploadFiles,
  UploadFilesSuccess,
} from '../actions/file-upload.actions';
import { firstValueFrom, switchMap } from 'rxjs';
import { UploadFilesActionData } from '../models';
import { QueueNotification } from '@ca/ca-ng-core';

@Injectable()
export class FileUploadEffects {
  constructor(
    private fileUploader: FileUploadService,
    private actions$: Actions
  ) {}
  onFileUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadFiles),
      switchMap((value: UploadFilesActionData) =>
        firstValueFrom(this.fileUploader.upload(value)).then((res) =>
          res.ok && res.body
            ? UploadFilesSuccess({
                request: value,
                result: { success: res.body.success, results: res.body.data },
              })
            : QueueNotification({
                notification: {
                  message: `Failed to upload ${value.files.length} files.`,
                  source: 'FileUploadEffects',
                  type: 'error',
                },
              })
        )
      )
    )
  );
}
