import { Component, Input } from '@angular/core';
import { DSR } from '@ca/gdpr';
import * as moment from 'moment';
import { GdprToolState } from '../../../store';
import { Store } from '@ngrx/store';
import { OpenRejectOrCancelDSR } from '../../../store/actions/dsr.actions';

@Component({
  selector: 'ca-dsr-summary',
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title i18n="@@dsr-info-title">DSR Info </mat-card-title>
        <mat-card-subtitle>
          <ca-dsr-status-badge [dsr]="dsr"></ca-dsr-status-badge>
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content style="padding: 1.33em">
        <p>
          <span i18n="@@requester-name">Requester: </span>{{ dsr.firstName }}
          {{ dsr.lastName }}
        </p>
        <p><span i18n="@@dsr-status">Status: </span>{{ dsr.status }}</p>
        <p>
          <span i18n="@@dsr-date">Request Date: </span>{{ dsr.requestedAt
          }}<span i18n="@@dsr-deadline-ext"> - Deadline </span>{{ DSRDeadline }}
        </p>
        <p>
          {{ dsr.context }}
        </p>
      </mat-card-content>

      <mat-card-actions class="card-actions" align="end">
        <button color="warn" mat-raised-button (click)="cancel()">
          <mat-icon>cancel</mat-icon> <span i18n="@@cancel-btn">CANCEL</span>
        </button>
        <button
          color="warn"
          mat-raised-button
          (click)="reject()"
          i18n="@@reject-btn"
        >
          <mat-icon>block</mat-icon> REJECT
        </button>
      </mat-card-actions>
    </mat-card>
  `,
})
export class DsrSummaryComponent {
  @Input() dsr!: DSR;

  constructor(private store: Store<GdprToolState>) {}

  protected get DSRDeadline() {
    return this.dsr
      ? moment(this.dsr.requestedAt, 'DD/MM/YYYY').add(1, 'months').fromNow()
      : $localize`:@@specify-requested-at:Specify RequestedAt`;
  }

  cancel() {
    this.store.dispatch(
      OpenRejectOrCancelDSR({
        dsr: this.dsr,
        mode: 'cancel',
      })
    );
  }

  reject() {
    this.store.dispatch(
      OpenRejectOrCancelDSR({
        dsr: this.dsr,
        mode: 'reject',
      })
    );
  }
}
