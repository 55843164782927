import { Inject, Injectable } from '@angular/core';
import { CrudService } from '../CrudService';
import { CompanySettings } from '@ca/gdpr';
import { CaDataResponse, CaDataService } from '@ca/ca-data';
import { CaEnvironment, IQueryParameter } from '@ca/ca-utils';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService extends CrudService<
  CompanySettings,
  'put-company-info'
> {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, '/api/settings/company-info', store);
  }
  getCompanySettings(
    params?: IQueryParameter[]
  ): Observable<HttpResponse<CaDataResponse<CompanySettings, string>>> {
    return this.data.get<CaDataResponse<CompanySettings>>(
      this.url,
      this.constructParams(params)
    );
  }
}
