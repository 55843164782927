import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ca-new-ropa-dialog',
  template: `
    <h1 mat-dialog-title>New record of processing activity</h1>
    <mat-dialog-content>
      <ng-container *ngIf="question$ | async">
        <h2>{{ (question$ | async)?.question }}</h2>
        <button
          mat-button
          *ngFor="let ans of (question$ | async)?.options"
          (click)="answer(ans)"
        >
          {{ ans.label }}
        </button>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button (click)="previous()" mat-button>back</button>

      <button mat-button mat-dialog-close [disabled]="!completed">Okay</button>
    </mat-dialog-actions>
  `,
  styles: [],
})
export class NewRopaDialogComponent {
  questions: Question[] = [
    {
      id: 'role',
      question: 'Who decided to process this personal information?',
      options: [
        {
          label: 'My company',
          value: 'controller',
          pointer: 'purpose',
        },
        {
          label: 'A client',
          value: 'processor',
          pointer: 'purpose',
        },
      ],
    },
    {
      id: 'purpose',
      question: 'Why do you want to process this data?',
      options: [
        {
          label: 'other',
          pointer: '',
          value: '',
        },
      ],
    },
  ];

  answers: Answers = [];

  completed = false;
  private _questionBS = new BehaviorSubject(this.questions[0]);
  protected question$ = this._questionBS.asObservable();
  previous() {
    // prevent if current question is the first!
    // const currentIndex = this.questions.findIndex(this._questionBS.value);
  }
  answer(answer: Answer) {
    this.answers.push({
      question_id: this._questionBS.value.id,
      answer_value: answer.value,
    });
    if (answer.pointer) {
      const next = this.questions.find((q) => q.id === answer.pointer);
      if (next) {
        this._questionBS.next(next);
        return;
      }
    }
    this.completed = true;
  }
}
export type Answers = {
  question_id: string;
  answer_value: string;
}[];
export interface Question {
  id: string;
  question: string;
  options: Answer[];
}
export interface Answer {
  label: string;
  value: string;
  pointer?: string;
}
