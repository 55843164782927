import { Inject, Injectable } from '@angular/core';
import { CaDataService } from '@ca/ca-data';
import { CaEnvironment } from '@ca/ca-utils';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { ControllerRoPAGroup, ProcessorRoPAGroup } from '@ca/gdpr';
import { CrudService } from '../CrudService';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class ProcessingActivityGroupsService extends CrudService<
  ControllerRoPAGroup | ProcessorRoPAGroup,
  'put-processing-activity-groups'
> {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, '/api/dataregister/processing-activity-groups', store);
  }
}
