import { ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { CaSubscriber } from '@ca/ca-utils';

export class ProtectedPublicComponent {
  protected sub = new CaSubscriber();
  protected uuid: string | null = null;

  init(
    queryParamMap: Observable<ParamMap>,
    callback?: (params: ParamMap) => void
  ) {
    this.sub.subscribe(queryParamMap, {
      next: (params: ParamMap) => {
        this.uuid = params.get('code');
        if (callback) callback(params);
      },
    });
  }
  destroy() {
    this.sub.closeSubscriptions();
  }
}
