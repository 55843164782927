import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { UserService } from '../../services/user.service';
import { OnboardingService } from '../../services/onboarding.service';
import {
  IEditProfileMessages,
  OnboardingModuleConfiguration,
  IToggle2FAResponse,
  IResetPasswordApiResponse,
  OnboardingState,
  UserState,
} from '../../types';
import {
  IUserProfile,
  PutUpdateProfileRequest,
} from '../../types/interfaces/V2';
import { firstValueFrom } from 'rxjs';
import { AreYouSureDefaultComponent } from '@ca/ca-are-you-sure';
import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaSubscriber, IEnvironmentBase } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import { loadProfile, updateProfile } from '../../store/actions';
import { selectUser } from '../../store';

@Component({
  selector: 'ca-edit-profile',
  templateUrl: './edit-profile.component.html',
  styles: [
    `
      .edit-user-tab {
        height: 100%;
        display: flex;
        justify-content: center;
      }
      .tabs-container {
        width: 100%;
        height: 100%;
      }

      .personal-info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .personal-info-side {
          min-width: 44%;
          max-width: 50%;
          overflow: hidden;
          margin: 1.33em;
        }
      }
      .user-profile-personal-info-form {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        div.full-width {
          mat-form-field {
            width: 90%;
          }
        }
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        row-gap: 0.66em;
      }
    `,
  ],
})
export class EditProfileComponent implements OnDestroy, OnInit {
  // TODO: add a way to update password and username in separate form
  // profile?: GetUserProfileResponse;
  profile?: IUserProfile;
  profileFormGroup?: FormGroup;
  credentialsFormGroup?: FormGroup;

  avatarFormGroup?: FormGroup;
  // uploader!: FileUploader;
  sub: CaSubscriber = new CaSubscriber();
  msgs: IEditProfileMessages;
  hasBaseDropZoneOver?: boolean;
  dispatchedLoad = false;

  //SECURITY
  private areYouSureDialogRef?: MatDialogRef<AreYouSureDefaultComponent>;
  private profileObserver = {
    next: (user?: UserState) => {
      const p = user?.profile;
      if (p) {
        this.profile = { ...p };
        this.profileFormGroup = this.fb.group({
          firstName: p.firstName,
          lastName: p.lastName,
          email: p.email,
          mobile: p.gsm,
          tel: p.tel,
        });
      } else if (!this.dispatchedLoad) {
        this.store.dispatch(loadProfile());
        this.dispatchedLoad = true;
      }
    },
  };

  constructor(
    private config: OnboardingModuleConfiguration,
    private svc: UserService,
    private onboardingSvc: OnboardingService,
    @Inject(CA_ENVIRONMENT) public env: IEnvironmentBase,
    private fb: FormBuilder,
    private logger: LoggingService,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    private store: Store<{ onboarding: OnboardingState }>
  ) {
    this.msgs = this.config.messages.editProfile;
  }
  ngOnInit(): void {
    this.sub.subscribe(this.store.select(selectUser), this.profileObserver);
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  updatePersonalInfo() {
    if (this.profile) {
      const update: PutUpdateProfileRequest = {
        id: this.profile?.id,
        ...this.profileFormGroup?.value,
      };
      this.store.dispatch(
        updateProfile({ request: update, current: this.profile })
      );
    }
  }

  reset2FADevice() {
    alert('TODO: reset 2-FA device call');
  }

  toggle2FA() {
    this.areYouSureDialogRef = this.dialog.open(AreYouSureDefaultComponent, {
      disableClose: true,
      data: {},
    });
    // TODO: turn into actions and effects!

    firstValueFrom(this.areYouSureDialogRef.afterClosed()).then(
      (confirmed: boolean) => {
        if (confirmed) {
          if (!this.profile) throw new Error(this.msgs.profile_undefined_error);
          firstValueFrom(
            this.onboardingSvc.toggle2FA(this.profile.twoFaEnabled)
          ).then((res: IToggle2FAResponse) => {
            if (res.success && this.profile) {
              this.snackbar.successSnackbar(
                res.activated
                  ? this.msgs.activated_2fa_success
                  : this.msgs.deactivated_2fa_success
              );
              this.profile.twoFaEnabled = res.activated;
            } else {
              if (!this.profile)
                throw new Error(this.msgs.profile_undefined_error);
              this.profile.twoFaEnabled = !this.profile.twoFaEnabled;
              this.snackbar.errorSnackbar(
                res.activated
                  ? this.msgs.activated_2fa_failure
                  : this.msgs.deactivated_2fa_failure
              );
            }
          });
        } else {
          if (!this.profile) throw new Error(this.msgs.profile_undefined_error);
          this.profile.twoFaEnabled = !this.profile.twoFaEnabled;
        }
      }
    );
  }

  resetPassword() {
    this.areYouSureDialogRef = this.dialog.open(AreYouSureDefaultComponent, {
      disableClose: true,
    });
    firstValueFrom(this.areYouSureDialogRef.afterClosed()).then(
      (confirmed: boolean) => {
        if (confirmed) {
          if (!this.profile) throw new Error(this.msgs.profile_undefined_error);
          const formData = new FormData();
          formData.append('email', this.profile.email);
          firstValueFrom(this.onboardingSvc.resetPassword(formData)).then(
            (res: IResetPasswordApiResponse) => {
              if (res.success)
                this.snackbar.successSnackbar(this.msgs.reset_link_sent);
              else this.snackbar.errorSnackbar(this.msgs.reset_link_failure);
            }
          );
        }
      }
    );
  }
}
