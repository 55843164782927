import { Inject, Injectable } from '@angular/core';
import { CrudService } from '../CrudService';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';
import { CaDataService } from '@ca/ca-data';
import { CaEnvironment } from '@ca/ca-utils';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { QuestionAndAnswer } from '@ca/core';

@Injectable({
  providedIn: 'root',
})
export class QuestionAndAnswerService extends CrudService<
  QuestionAndAnswer,
  'put-questions-and-answers'
> {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, '/api/data-subject-rights/questions-and-answers', store);
  }
}
