import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ExternalController,
  ProcessingActivityGroup,
  ProcessingActivityRole,
  DataLocation,
  DisclosedTo,
  LegalGround,
  ProcessingPurpose,
  TransferOutsideEEA,
} from '@ca/gdpr';
import { CreatePurposeConfigurationFG } from '../../../utils/forms';

import { Observable, map, startWith } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  GdprToolState,
  selectAddSubjectRegisterSettingsData,
  selectProcessingPurposes,
} from '../../../store';
import {
  CaSubscriber,
  IndexedName,
  displayNamedObjectOrString,
  filterByNamedObjectOrString,
} from '@ca/ca-utils';

@Component({
  selector: 'ca-upsert-purpose-in-register',
  templateUrl: './upsert-purpose-in-register.component.html',
})
export class UpsertPurposeInRegisterComponent implements OnInit, OnDestroy {
  protected fg: FormGroup;
  private sub = new CaSubscriber();
  purposeLabel = $localize`:@@processing-purpose-question:Why do you process this information?`;
  purposeInfo = $localize`:@@processing-purpose-info:Purposes describe our intent and reason for processing personal data`;

  purposeOptions?: ProcessingPurpose[];
  filteredProcessingPurposes?: Observable<ProcessingPurpose[]>;

  dataLocations?: DataLocation[];
  disclosedTos?: DisclosedTo[];
  externalControllers?: ExternalController[];
  legalGrounds?: LegalGround[];
  transfersOutsideEEA?: TransferOutsideEEA[];

  get title() {
    return $localize`:@@update-or-add-processing-purpose:${
      this.data.recordToUpdate !== undefined
        ? 'Update ' + this.data.recordToUpdate.purpose.name
        : 'Add Processing Purpose'
    } for ${this.data.dataSubjectType.name}`;
  }

  constructor(
    protected ref: MatDialogRef<UpsertPurposeInRegisterComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected data: UpsertRoPAPurposeFromRegister,
    private fb: FormBuilder,
    private store: Store<GdprToolState>
  ) {
    this.sub.subscribe(this.store.select(selectProcessingPurposes), {
      next: (purposes) =>
        (this.purposeOptions = purposes?.filter(
          (e) => !data.excludePurposeOptions.includes(e.id)
        )),
    });
    this.sub.subscribe(
      // TODO: create more specific selector?
      this.store.select(selectAddSubjectRegisterSettingsData),
      {
        next: (data) => {
          if (data.dataLocations) this.dataLocations = data.dataLocations;
          if (data.disclosedTo) this.disclosedTos = data.disclosedTo;
          if (data.externalControllers)
            this.externalControllers = data.externalControllers;
          if (data.legalGrounds) this.legalGrounds = data.legalGrounds;
          if (data.transfersOutsideEEA)
            this.transfersOutsideEEA = data.transfersOutsideEEA;
        },
      }
    );
    this.fg = CreatePurposeConfigurationFG(this.fb, {
      dataSubjectType: data.dataSubjectType,
      role: data.role,
      upsert: data.recordToUpdate,
    });
  }
  ngOnInit(): void {
    this.filteredProcessingPurposes = this.fg.get('purpose')?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterProcessingPurposesForSelect(value.name || ''))
    );
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  save() {
    const v = this.fg.getRawValue();
    if (
      v.purpose !== undefined &&
      (v.purposeId === undefined || v.purposeId === 0)
    )
      v.purposeId = v.purpose.id;

    if (
      v.dataSubjectType !== undefined &&
      (v.dataSubjectTypeId === undefined || v.dataSubjectTypeId === 0)
    )
      v.dataSubjectTypeId = v.dataSubjectType.id;
  }
  protected displayFn(option?: ProcessingPurpose | string) {
    return displayNamedObjectOrString(option);
  }
  private _filterProcessingPurposesForSelect(
    value: string | ProcessingPurpose
  ): ProcessingPurpose[] {
    return filterByNamedObjectOrString(value, this.purposeOptions);
  }
}
export interface UpsertRoPAPurposeFromRegister {
  role: ProcessingActivityRole;
  dataSubjectType: IndexedName;
  recordToUpdate?: ProcessingActivityGroup;
  excludePurposeOptions: number[];
}
