import { createAction, props } from '@ngrx/store';
import { GdprToolActionType } from '../action.types';
import {
  ScheduleMailData,
  SendMailResponse,
  PostSendMail,
  LoadMailLogsActionData,
  MailLog,
} from '@ca/ca-mailing';
import { DeleteRequest, DeleteResult } from '@ca/ca-data';

export const ScheduleMail = createAction(
  GdprToolActionType.ScheduleMail,
  props<ScheduleMailData>()
);
export const ScheduleMailSuccess = createAction(
  GdprToolActionType.ScheduleMailSuccess,
  props<{ request: ScheduleMailData; response: SendMailResponse }>()
);

export const SendMail = createAction(
  GdprToolActionType.SendMail,
  props<PostSendMail>()
);
export const SendMailSuccess = createAction(
  GdprToolActionType.SendMailSuccess,
  props<{ request: PostSendMail; response: SendMailResponse }>()
);

export const LoadMailLogs = createAction(
  GdprToolActionType.LoadMailLogs,
  props<LoadMailLogsActionData>()
);
export const LoadMailLogsSuccess = createAction(
  GdprToolActionType.LoadMailLogsSuccess,
  props<{ request: LoadMailLogsActionData; response: MailLog[] }>()
);

export const CancelScheduledMail = createAction(
  GdprToolActionType.CancelScheduledMail,
  props<DeleteRequest>()
);
export const CancelScheduledMailSuccess = createAction(
  GdprToolActionType.CancelScheduledMailSuccess,
  props<{ request: DeleteRequest; response: DeleteResult }>()
);
