import { Pipe, PipeTransform } from '@angular/core';

interface NamedObject {
  name: string;
}
@Pipe({
  name: 'namedObjects',
})
export class NamedObjectsPipe implements PipeTransform {
  transform<T extends NamedObject>(value: T[] | undefined): string {
    return value != undefined ? value.map((el) => el.name).join(', ') : '';
  }
}
