import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { GdprToolState } from '../../../store';
import { Store } from '@ngrx/store';
import { TableNamesForLinking } from '../../../config/table-names-for-linking';
import { Observable, firstValueFrom, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  UpsertRemarkDialogComponent,
  UpsertRemarkDialogData,
} from './upsert-remark-dialog/upsert-remark-dialog.component';
import { CaSubscriber } from '@ca/ca-utils';
import { Remark } from '@ca/core';

@Component({
  selector: 'ca-remarks',
  template: `<button color="primary" mat-icon-button (click)="upsertRemark()">
    <mat-icon>feedback</mat-icon>
  </button>`,
})
export class RemarksComponent implements OnInit, OnDestroy {
  @Input() title = 'Remarks';
  @Input() context!: string;
  @Input() parentTable!: string;
  @Input() parentId!: number;
  @Output() showRemarksChanged = new EventEmitter<ShowRemarksChangedEvent>();
  private sub = new CaSubscriber();
  showRemarks = false;

  remarks: Remark[] = [];
  remarks$?: Observable<Remark[]>;

  constructor(private store: Store<GdprToolState>, private dialog: MatDialog) {}

  ngOnInit(): void {
    if (
      this.context === undefined ||
      this.parentTable === undefined ||
      this.parentId === undefined
    ) {
      throw new Error(
        'Inputs must be provided: context, parentTable and parentId'
      );
    }
    // switch (this.parentTable) {
    //   // case TableNamesForLinking.DSRs:
    //   //   this.remarks$ = this.store
    //   //     .select(selectDSRRemarks)
    //   //     .pipe(
    //   //       map((remarks?: Remark[]) =>
    //   //         remarks !== undefined
    //   //           ? remarks.filter((e) => e.context === this.context)
    //   //           : []
    //   //       )
    //   //     );
    //   //   break;

    //   default:
    //     throw new Error(
    //       'No solution provided for parentTable ' + this.parentId
    //     );
    //     break;
    // }
    // this.sub.subscribe(this.remarks$, {
    //   next: (remarks: Remark[]) => {
    //     this.remarks = remarks;
    //   },
    // });
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  upsertRemark(remark?: Remark) {
    this.dialog.open<UpsertRemarkDialogComponent, UpsertRemarkDialogData>(
      UpsertRemarkDialogComponent,
      {
        data: {
          context: this.context,
          id: remark?.id ?? 0,
          parentId: this.parentId,
          parentTable: this.parentTable,
          remark: remark?.remark ?? undefined,
        },
      }
    );
  }
  toggleShowRemarks() {
    this.showRemarks = !this.showRemarks;
    if (this.remarks$)
      firstValueFrom(this.remarks$).then((res) => {
        const eventData = {
          visible: this.showRemarks,
          remarks: res ?? [],
          context: this.context,
          parentId: this.parentId,
          parentTable: this.parentTable,
        };
        this.showRemarksChanged.emit(eventData);
      });
  }
}
export interface ShowRemarksChangedEvent {
  visible: boolean;
  remarks: Remark[];
  context: string;
  parentTable: string;
  parentId: number;
}
