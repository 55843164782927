import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DataSubjectType } from '@ca/gdpr';
import { GdprToolState, selectUsedDataSubjectTypes } from '../../../store';
import { CaSubscriber } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'ca-select-data-subject-types',
  template: `
    <h1 i18n="@@qualify-data-subject-title">
      How would you qualify the subject of the request?
    </h1>
    <h4 i18n="@@select-data-subject-types-label">
      Select one or more Data Subject Types
    </h4>
    <mat-error
      *ngIf="dataSubjectTypes === undefined || dataSubjectTypes.length === 0"
      i18n="@@couldnt-find-data-subject-types-in-register"
    >
      Could not find any data subject types in the data register. Fill the data
      register before proceeding.
    </mat-error>
    <mat-selection-list
      (selectionChange)="onSelectionChanged()"
      multiple
      [compareWith]="compareFn"
    >
      <mat-list-option *ngFor="let dst of dataSubjectTypes" [value]="dst">
        {{ dst.name }}
      </mat-list-option>
    </mat-selection-list>
  `,
})
export class SelectDataSubjectTypesComponent implements OnDestroy {
  @Input() multiple = true;
  @Input() initialSelection?: number[];

  @Output() selectionChanged = new EventEmitter<DataSubjectType[]>();

  private list?: MatSelectionList;
  @ViewChild(MatSelectionList) protected set content(list: MatSelectionList) {
    if (list) {
      this.list = list;
      this.list.compareWith = this.compareFn;
      this.list.options.forEach((e) => {
        if (this.initialSelection?.includes(e.value.id)) e.selected = true;
        this.cdr.detectChanges();
      });
    }
  }

  protected dataSubjectTypes?: DataSubjectType[];
  private sub = new CaSubscriber();

  constructor(
    private store: Store<GdprToolState>,
    private cdr: ChangeDetectorRef
  ) {
    this.sub.subscribe(this.store.select(selectUsedDataSubjectTypes), {
      next: (dts?: DataSubjectType[]) => {
        this.dataSubjectTypes = dts;
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  onSelectionChanged() {
    this.selectionChanged.emit(
      this.list?.selectedOptions.selected.map((e) => e.value)
    );
  }

  compareFn(
    option: DataSubjectType,
    selectedValue?: DataSubjectType | DataSubjectType[]
  ) {
    return selectedValue && Array.isArray(selectedValue)
      ? selectedValue.map((e) => e.id).includes(option.id)
      : selectedValue?.id === option.id ?? false;
  }
}
