<div class="upsert-dsr">
  <h2 mat-dialog-title>{{ title }}</h2>
  <div mat-dialog-content *ngIf="fg && requestFG && requesterFG">
    <div [formGroup]="fg">
      <input formControlName="id" hidden />
    </div>

    <mat-vertical-stepper [linear]="true" #stepper animationDuration="1000">
      <mat-step *ngIf="requesterFG" [stepControl]="requesterFG">
        <form [formGroup]="fg">
          <div [formGroup]="requesterFG">
            <ng-template matStepLabel i18n="@@requester-info-title"
              >Requester Info</ng-template
            >
            <div>
              <mat-form-field class="full-width">
                <mat-label i18n="@@first-name">First name</mat-label>
                <input matInput type="text" formControlName="firstName" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="full-width">
                <mat-label i18n="@@last-name">Last name</mat-label>
                <input matInput type="text" formControlName="lastName" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="full-width">
                <mat-label i18n="@@requester-e-mail"
                  >Email of the requester</mat-label
                >
                <input matInput type="text" formControlName="email" />
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="full-width">
                <mat-label i18n="@@requester-preferred-language"
                  >Data Subject's Preferred Language</mat-label
                >
                <mat-select formControlName="preferredLanguage">
                  <mat-option value="en" i18n="@@english">English</mat-option>
                  <mat-option value="nl" i18n="@@dutch">Dutch</mat-option>
                  <mat-option value="fr" i18n="@@french">French</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <button mat-raised-button matStepperNext i18n="@@next-btn">
                NEXT
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step *ngIf="requestFG" [stepControl]="requestFG">
        <form [formGroup]="fg">
          <div [formGroup]="requestFG">
            <ng-template matStepLabel i18n="@@request-info"
              >Request Info</ng-template
            >
            <div class="full-screen">
              <mat-form-field class="full-width">
                <mat-label i18n="@@invoked-right">Invoked Right</mat-label>
                <mat-select formControlName="invokedRight">
                  <mat-option
                    *ngFor="let category of dsrCategories"
                    [value]="category.right"
                    >InvokedRightLabel({{ category.right }})</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="full-width">
                <mat-label i18n="@@requested-at">Requested At</mat-label>
                <input
                  matInput
                  [max]="maxDate"
                  formControlName="requestedAt"
                  [matDatepicker]="picker"
                  (click)="picker.open()"
                />
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <mat-checkbox
              formControlName="identityConfirmed"
              i18n="@@identity-confirmed"
            >
              Identity confirmed
            </mat-checkbox>

            <div>
              <mat-form-field class="full-width">
                <mat-label i18n="@@request-context"
                  >Context of the request</mat-label
                >
                <textarea
                  matInput
                  cdkTextareaAutosize
                  type="textarea"
                  formControlName="context"
                ></textarea>
              </mat-form-field>
            </div>
            <div>
              <button mat-button matStepperPrevious i18n="@@back-btn">
                BACK
              </button>
              <button mat-raised-button matStepperNext i18n="@@finish-btn">
                FINISH
              </button>
            </div>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel i18n="@@done-text">Done</ng-template>
        <ng-container i18n="@@done-text2"> You are now done. </ng-container>

        <div>
          <button mat-button matStepperPrevious i18n="@@back-btn">BACK</button>
          <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="save()"
            i18n="@@save-btn"
          >
            SAVE
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
  <mat-dialog-actions align="end">
    <a
      class="cancel-link cancel"
      style="margin-right: 0.66em"
      mat-dialog-close
      i18n="@@cancel-btn"
    >
      Cancel
    </a>
  </mat-dialog-actions>
</div>
