import { Inject, Injectable } from '@angular/core';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { CrudService } from '../CrudService';
import { LegalGround } from '@ca/gdpr';
import { CaDataService } from '@ca/ca-data';
import { CaEnvironment } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class LegalGroundsService extends CrudService<
  LegalGround,
  'put-legal-basis'
> {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, '/api/settings/legal-basis', store);
  }
}
