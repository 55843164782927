import { IOnboardingMessages } from '@ca/onboarding';

export const HUMIDOR_ONBOARDING_MESSAGES: IOnboardingMessages = {
  generalFailure: $localize`:@@onboarding-general-failure-msg:A server communication error occured.. please try again.`,
  sessionExpired: $localize`:@@onboarding-session-expired:Session expired. Log in to continue.`,
  login: {
    failed: $localize`:@@onboarding-login-failed:Login failed.`,
    success: $localize`:@@onboarding-logged-in:Logged in.`,
    invalid: $localize`:@@onboarding-validation-failed:Validation failed.`,
    twoFaFailed: $localize`:@@onboarding-2FA-failed:2-Factor authentication failed.`,
    ui: {
      button_text: $localize`:@@onboarding-log-in-btn:LOG IN`,
      forgot_password_link_text: $localize`:@@onboarding-forgot-pass-btn:Forgot password?`,
      label_password: $localize`:@@onboarding-password-label:Password`,
      label_username: $localize`:@@onboarding-username-label:Username`,
      subtitle: $localize`:@@onboarding-login-subtitle:Enter your username and password to log in.`,
      title: $localize`:@@onboarding-login-title:Login`,
    },
  },
  logout: {
    confirmButtonText: $localize`:@@onboarding-log-out-btn:LOG OUT`,
    confirmQuestion: $localize`:@@onboarding-ays-log-out:Are you sure you want to log out?`,
  },
  twoFa: {
    ui: {
      title: $localize`:@@onboarding-2FA-title:Set up 2-Step Verification`,
      input_code_label: $localize`:@@onboarding-2FA-code-input-hint:Enter code from your Authenticator app.`,
      qr_code_label: $localize`:@@onboarding-2FA-scan-qr-code-label:Scan this QR code in the Authenticator App to link your device.`,
      code_input_label: $localize`:@@onboarding-2FA-code-input-label:Authenticator code`,
      whoops: $localize`:@@onboarding-whoops:Whoops...`,
      fetch_code_error: $localize`:@@onboarding-something-went-wrong:Something went wrong trying to fetch your code. Try again later`,
      back_button_text: $localize`:@@onboarding-back-btn:Back`,
      proceed_button_text: $localize`:@@onboarding-proceed-btn:Proceed`,
      failed_snackbar: $localize`:@@onboarding-login-failed-snackbar:Login failed, try again.`,
      two_step_failed: $localize`:@@onboarding-2FA-failed-snackbar:2-Step verification failed, try again.`,
      invalid_token: $localize`:@@onboarding-invalid-token:Token was invalid, this should not happen`,
    },
  },
  editProfile: {
    cancel_button_text: $localize`:@@onboarding-cancel-btn:Cancel`,
    form: {
      email: $localize`:@@onboarding-e-mail:E-Mail`,
      firstname: $localize`:@@onboarding-first-name:First name`,
      job_title: $localize`:@@onboarding-job-title:Job Title`,
      lastname: $localize`:@@onboarding-last-name:Lastname`,
      mobile: $localize`:@@onboarding-mobile:Mobile phone`,
      submit_button_text: $localize`:@@onboarding-save-btn:Save`,
      telephone: $localize`:@@onboarding-telephone:Telephone`,
    },
    remove_button_text: $localize`:@@onboarding-remove-btn:Remove`,
    reset_button_text: $localize`:@@onboarding-reset-pass-btn:RESET PASSWORD`,
    reset_button_tooltip: $localize`:@@onboarding-reset-pass-btn-info:Sends a reset password link to your e-mail address.`,

    reset_2fa_button_text: $localize`:@@onboarding-reset-2FA-btn:RESET 2ND FACTOR DEVICE`,
    reset_2fa_button_tooltip: $localize`:@@onboarding-reset-2FA-btn-info:Reset 2-factor authentication device. You'll be able to link a new device on your next login.`,
    slider: $localize`:@@onboarding-enable-2FA-btn:ENABLE 2-FACTOR AUTHENTICATION (RECOMMENDED)`,
    upload_button_text: $localize`:@@onboarding-upload-btn:Upload`,
    uploader_text: $localize`:@@onboarding-file-dropzone-text:Drop file here for upload or click to select a file from your device`,
    update_failed: $localize`:@@onboarding-profile-update-error-msg:Your profile could not be updated!`,
    updated: $localize`:@@onboarding-profile-update-success-message:Your profile has been updated!`,
    profile_undefined_error: $localize`:@@onboarding-profile-undefined-error-msg:User profile is not defined`,
    activated_2fa_failure: $localize`:@@onboarding-enable-2FA-error-msg:Could not activate two factor authentication. Try again later.`,
    deactivated_2fa_failure: $localize`:@@onboarding-disable-2FA-error-msg:Could not deactivate two factor authentication. Try again later.`,
    activated_2fa_success: $localize`:@@onboarding-enable-2FA-success-msg:Two factor authentication was successfully activated.`,
    deactivated_2fa_success: $localize`:@@onboarding-disable-2FA-success-msg:Two factor authentication was successfully deactivated.`,
    reset_link_sent: $localize`:@@onboarding-reset-pass-link-sent-msg:A link to reset your password is send to your e-mail address.`,
    reset_link_failure: $localize`:@@onboarding-reset-pass-link-error:Could not send e-mail link... Try again later.`,
  },
  register: {
    form: {
      email: $localize`:@@email:E-Mail`,
      firstname: $localize`:@@first-name:First name`,
      lastname: $localize`:@@last-name:Last name`,
      register_button_text: $localize`:@@register-btn:Register`,
      invalid: $localize`:@@onboarding-invalid-register-form:Please fill out the form before proceeding.`, // NL: $localize`Gelieve het formulier volledig in te vullen.`,
    },
  },
  resetPassword: {
    form: {
      back_button_text: $localize`:@@back-btn:BACK`,
      email: $localize`:@@email:E-Mail`,
      submit_button_text: $localize`:@@submit:SUBMIT`,
      invalid: $localize`:@@onboarding-invalid-reset-form:Fill out the form correctly before proceeding.`,
    },
    form_instructions: $localize`:@@onboarding-reset-pass-instructions:Fill out you e-mail and receive a link where you can reset your
password`,
    title: $localize`:@@onboarding-reset-pass-title:Reset password`,
    link_sent: $localize`:@@onboarding-reset-pass-link-sent:Password link sent!`, // NL: $localize`Wachtwoord link verzonden.'
    no_account_with_email: $localize`:@@onboarding-reset-pass-no-account-found:No account found with given e-mail address.`, // NL: $localize`Er bestaat geen account met dit e-mailadres.'
  },
  setPassword: {
    form: {
      password: $localize`:@@onboarding-password:Password`,
      password_confirmation: $localize`:@@onboarding-password-confirmation:Password confirmation`,
      submit_button_text: $localize`:@@submit-btn:SUBMIT`,
      invalid: $localize`:@@onboarding-invalid-setpass-form:Fill out the form before proceeding.`, // 'Vul het formulier in.'
    },
    form_instructions: $localize`:@@onboarding-set-pass-instructions:Fill out your new password`,
    title: $localize`:@@onboarding-set-pass-title:Choose a new password`,
    update_failed: $localize`:@@onboarding-set-pass-error-msg:Something went wrong in the process of updating password. Try again.`, // NL: $localize`Er ging iets fout bij het wijzigen van jouw wachtwoord. Probeer het nog eens.'
    updated: $localize`:@@onboarding-set-pass-success-msg:Password reset successfully.`, // NL: $localize`Wachtwoord is opnieuw ingesteld.'
    passwords_dont_match:
      ":@@onboarding-set-pass-do-not-match-msg:Passwords don't match!", // NL: $localize`Wachtwoorden komen niet overeen'
    minLengthError: (min: number) =>
      $localize`:@@onboarding-set-pass-min-length-msg:Password should be at least ${min} characters.`, // 'Wachtwoorden moet minstens ' + this.config.minPasswordLength + ' karakters tellen.'
  },
};
