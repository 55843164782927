<div class="app">
  <mat-toolbar class="toolbar" *ngIf="isLoggedIn">
    <button
      mat-icon-button
      class="menu-button"
      aria-label="Menu icon-button with menu icon"
      (click)="snav?.toggle()"
    >
      <mat-icon><span class="material-symbols-outlined"> menu </span></mat-icon>
    </button>

    <a [routerLink]="['dashboard']">
      <img
        alt="Humidor Logo"
        class="logo"
        src="assets/humidor_logo_white.svg"
      />
    </a>

    <span class="toolbar-spacer"></span>
    <ca-system-messages></ca-system-messages>
    <ca-nav-bar-user-widget></ca-nav-bar-user-widget>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" autosize>
    <!-- [style.marginTop.px]="mobileQuery.matches ? 64 : 0" -->
    <!-- To prevent page dimensions from changing when sidenav is toggled use: mode="over"-->
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="64"
      class="sidenav"
      [(opened)]="sidenavOpened"
    >
      <ca-navigation [links]="links"></ca-navigation>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="sidenav-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
