import { createAction, props } from '@ngrx/store';
import { GdprToolActionType } from '../action.types';
import {
  DSR,
  DataSubjectRight,
  UpsertDataSubjectRequestActionData,
} from '@ca/gdpr';
import {
  CancelDSRActionData,
  CancelDSRActionResult,
  DeleteDataSubjectRequestResult,
  GetSingle,
  OpenRejectOrCancelDSRDialogActionData,
  PoseQuestionToDataSubjectActionData,
  PoseQuestionToDataSubjectActionResult,
  RejectDSRActionData,
  RejectDSRActionResult,
  UpsertDataSubjectRequestResult,
} from '../models';
import { DeleteRequest, PostResult } from '@ca/ca-data';
import { PostSendMailLinked } from '@ca/ca-mailing';

export const loadDataSubjectRequests = createAction(
  GdprToolActionType.LoadDataSubjectRequests
);
export const loadDataSubjectRequestsSuccess = createAction(
  GdprToolActionType.LoadDataSubjectRequestsSuccess,
  props<{ dataSubjectRequests: DSR[] }>()
);
export const upsertDataSubjectRequest = createAction(
  GdprToolActionType.UpsertDataSubjectRequest,
  props<UpsertDataSubjectRequestActionData>()
);
export const registerNewDSR = createAction(
  GdprToolActionType.RegisterNewDSR,
  props<{ invokedRight?: DataSubjectRight }>
);
export const upsertDataSubjectRequestSuccess = createAction(
  GdprToolActionType.UpsertDataSubjectRequestSuccess,
  props<UpsertDataSubjectRequestResult>()
);
export const LoadDSR = createAction(
  GdprToolActionType.LoadDSR,
  props<GetSingle>()
);
export const LoadDSRSuccess = createAction(
  GdprToolActionType.LoadDSRSuccess,
  props<{ request: GetSingle; result: DSR }>()
);
export const ClearDSR = createAction(GdprToolActionType.ClearDSR);

//#region Confirm Identity
export const ConfirmDataSubjectIdentity = createAction(
  GdprToolActionType.ConfirmDataSubjectIdentity,
  props<{ dsrId: number }>()
);
export const ConfirmDataSubjectIdentitySuccess = createAction(
  GdprToolActionType.ConfirmDataSubjectIdentitySuccess,
  props<{ dsrId: number }>()
);
export const ConfirmDataSubjectIdentityByMail = createAction(
  GdprToolActionType.ConfirmDataSubjectIdentityByMail,
  props<PostSendMailLinked>()
);
export const ConfirmDataSubjectIdentityByMailSuccess = createAction(
  GdprToolActionType.ConfirmDataSubjectIdentityByMailSuccess,
  props<{ request: PostSendMailLinked; response: PostResult }>()
);
//#endregion

//#region Cancel or Reject
export const OpenRejectOrCancelDSR = createAction(
  GdprToolActionType.OpenRejectOrCancelDSR,
  props<OpenRejectOrCancelDSRDialogActionData>()
);
export const rejectDSR = createAction(
  GdprToolActionType.RejectDSR,
  props<RejectDSRActionData>()
);
export const rejectDSRSuccess = createAction(
  GdprToolActionType.RejectDSRSuccess,
  props<RejectDSRActionResult>()
);
export const cancelDSR = createAction(
  GdprToolActionType.CancelDSR,
  props<CancelDSRActionData>()
);
export const cancelDSRSuccess = createAction(
  GdprToolActionType.CancelDSRSuccess,
  props<CancelDSRActionResult>()
);
//#endregion

//#region Questions
export const poseQuestionToDataSubject = createAction(
  GdprToolActionType.PoseQuestionToDataSubject,
  props<PoseQuestionToDataSubjectActionData>()
);
export const poseQuestionToDataSubjectSuccess = createAction(
  GdprToolActionType.PoseQuestionToDataSubjectSuccess,
  props<PoseQuestionToDataSubjectActionResult>()
);
//#endregion

// TODO: remove
export const deleteDataSubjectRequest = createAction(
  GdprToolActionType.DeleteDataSubjectRequest,
  props<DeleteRequest>()
);
export const deleteDataSubjectRequestSuccess = createAction(
  GdprToolActionType.DeleteProcessingActivitySuccess,
  props<DeleteDataSubjectRequestResult>()
);
