import { Inject, Injectable } from '@angular/core';
import { BaseMailService } from './base-mail.service';
import { CaDataService, PostResult } from '@ca/ca-data';
import { CaEnvironment } from '@ca/ca-utils';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { PostSendMail, PostSendMailLinked } from '@ca/ca-mailing';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class MailService extends BaseMailService {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, store);
  }

  sendMail(config: PostSendMail): Observable<PostResult> {
    return this.data
      .post<PostSendMail, PostResult>(
        `${this.baseUrl}/send`,
        { ...config, sandBoxMode: this.sandboxMode },
        this.constructParams()
      )
      .pipe(
        map((res) =>
          res.ok && res.body !== null
            ? res.body
            : {
                type: 'post-send-mail',
                success: false,
                debug: this.sandboxMode,
              }
        )
      );
  }
  sendLinkedMail(config: PostSendMailLinked): Observable<PostResult> {
    return this.data
      .post<PostSendMailLinked, PostResult>(
        `${this.baseUrl}/send`,
        { ...config, sandBoxMode: this.sandboxMode },
        this.constructParams()
      )
      .pipe(
        map((res) =>
          res.ok && res.body !== null
            ? res.body
            : {
                type: 'post-send-mail',
                success: false,
                debug: this.sandboxMode,
              }
        )
      );
  }
}
