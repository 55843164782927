import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CsvImportMapperComponent } from '../csv-import-mapper/csv-import-mapper.component';
import { MapCsvImportDialogData } from '../models';

@Component({
  selector: 'ca-csv-import-button',
  template: `
    <input
      type="file"
      #fileInput
      style="display: none"
      accept=".csv"
      (change)="openMapper($event)"
    />

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>upload_file</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="selectMenuItem(fileInput, ',')">
        COMMA SEPARATED
      </button>
      <button mat-menu-item (click)="selectMenuItem(fileInput, ';')">
        SEMICOLON SEPARATED
      </button>
    </mat-menu>
  `,
})
export class CsvImportButtonComponent {
  protected separator: ',' | ';' = ',';
  constructor(private dialog: MatDialog) {}

  selectMenuItem(fileInput: HTMLInputElement, separator: ',' | ';') {
    this.separator = separator;
    fileInput.click();
  }

  openMapper(e: any) {
    const file = e.target.files[0];
    const reader = new FileReader();

    const openDialog = (loadResult: string[]) =>
      this.dialog.open<CsvImportMapperComponent, MapCsvImportDialogData>(
        CsvImportMapperComponent,
        {
          data: {
            numberOfSamplesToShow: 15,
            separator: this.separator,
            loadResult,
          },
        }
      );

    reader.onload = function (e) {
      const contents = e.target?.result?.toString();
      if (contents) openDialog(contents.split('\n'));
    };

    reader.readAsText(file);
  }
}
