import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpsertLegalGroundActionData } from '@ca/gdpr';
import { upsertLegalGround } from '../../../../store/actions/settings.actions';

@Component({
  selector: 'ca-upsert-legal-basis-dialog',
  template: `
    <h2 mat-dialog-title>{{ title }}</h2>
    <div mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg">
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for description|@@description"
              >Description</mat-label
            >
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="description"
            ></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
    <mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!fg.valid"
        type="button"
        (click)="save()"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-dialog-actions>
  `,
})
export class UpsertLegalBasisDialogComponent {
  get title() {
    return $localize`:@@create-or-edit-legal-ground:${
      this.data.id > 0 ? 'Edit' : 'Create'
    } Legal Ground`;
  }
  fg: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: UpsertLegalGroundActionData,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpsertLegalBasisDialogComponent>,
    private store: Store
  ) {
    this.fg = fb.group({
      id: [data.id, Validators.required],
      name: [data.name, Validators.required],
      description: [data.description, Validators.required],
    });
  }
  save() {
    this.store.dispatch(upsertLegalGround(this.fg.value));
    this.dialogRef.close();
  }
}
