import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { newProcessingPurpose } from '../../../config/defaults';
import { ProcessingPurpose } from '@ca/gdpr';
import { GdprToolState, selectProcessingPurposes } from '../../../store';
import { deleteProcessingPurpose } from '../../../store/actions/settings.actions';
import { UpsertProcessingPurposeComponent } from '../dialogs/upsert-processing-purpose/upsert-processing-purpose.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProcessingPurposesInfoHover } from '../../../config/info-hovers';

@Component({
  selector: 'ca-processing-purposes',
  template: `
    <h1 class="info-title" i18n="@@processing-purposes-title">
      Processing Purposes
      <!--<ca-info-hover [info]="info"></ca-info-hover>-->
    </h1>
    <ng-container *ngIf="dataSource">
      <mat-form-field>
        <mat-label i18n="Form field search label|@@search">Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          i18n-placeholder="@@search-processing-purpose-input-ph"
          placeholder="Ex. Biometric data"
          #input
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef i18n="@@description">
            Description
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="remove(element)"
                i18n="@@delete-btn"
              >
                DELETE<mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page of processing purposes"
    ></mat-paginator>
    <button mat-raised-button (click)="add()" i18n="@@add-btn">
      <mat-icon>add</mat-icon> ADD
    </button>
  `,
})
export class ProcessingPurposesComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource?: MatTableDataSource<ProcessingPurpose>;
  displayedColumns: string[] = ['actions', 'name', 'description'];
  protected info = ProcessingPurposesInfoHover;
  constructor(
    private store: Store<GdprToolState & { onboarding: OnboardingState }>,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.store
      .select(selectProcessingPurposes)
      .subscribe((purposes?: ProcessingPurpose[]) => {
        if (!this.dataSource)
          this.dataSource = new MatTableDataSource(purposes ?? []);
        else this.dataSource.data = purposes ?? [];
      });
  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  add() {
    this.dialog.open(UpsertProcessingPurposeComponent, {
      data: { upsert: newProcessingPurpose },
      disableClose: true,
    });
  }

  edit(purpose: ProcessingPurpose) {
    this.dialog.open(UpsertProcessingPurposeComponent, {
      data: { upsert: purpose },
      disableClose: true,
    });
  }

  remove(purpose: ProcessingPurpose) {
    this.store.dispatch(deleteProcessingPurpose({ id: purpose.id }));
  }
  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
