import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SecurityMeasure, UpsertDataLocation } from '@ca/gdpr';
import { Store } from '@ngrx/store';
import {
  upsertDataLocation,
  upsertDataLocationInline,
} from '../../../../store/actions/settings.actions';
import { GdprToolState, selectSecurityMeasures } from '../../../../store';
import { map, Observable } from 'rxjs';
import { UpsertInlineDialogData } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-upsert-data-location-dialog',
  template: `<h2 mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg" #form>
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Technology|@@technology"
              >Technology</mat-label
            >
            <input matInput type="text" formControlName="technology" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for description|@@description"
              >Description</mat-label
            >
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="description"
            ></textarea>
          </mat-form-field>
        </div>
        <ca-security-measures-selector
          [formGroup]="fg"
        ></ca-security-measures-selector>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="save()"
        [disabled]="!fg.valid"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-dialog-actions> `,
})
export class UpsertDataLocationDialogComponent {
  get title() {
    return $localize`:@@create-or-edit-data-location:${
      this.data.upsert.id > 0 ? 'Edit' : 'Create'
    } Data Location`;
  }
  fg: FormGroup;
  securityMeasuresInfo = $localize`:@@what-security-measures:What security measures have you taken to protect this data?`;
  securityMeasures$: Observable<SecurityMeasure[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: UpsertInlineDialogData<UpsertDataLocation>,
    private fb: FormBuilder,
    protected dialogRef: MatDialogRef<UpsertDataLocationDialogComponent>,
    private store: Store<GdprToolState>
  ) {
    this.securityMeasures$ = this.store
      .select(selectSecurityMeasures)
      .pipe(map((v) => (v ? v : [])));

    this.fg = fb.group({
      id: [data.upsert.id, Validators.required],
      name: [data.upsert.name, Validators.required],
      description: data.upsert.description,
      technology: [data.upsert.technology, Validators.required],
      securityMeasureIds: [data.upsert.securityMeasureIds, Validators.required],
    });
  }
  save() {
    if (
      this.data.inline &&
      this.data.inline === true &&
      this.data.originId != undefined
    )
      this.store.dispatch(
        upsertDataLocationInline({
          upsert: this.fg.value,
          originId: this.data.originId,
        })
      );
    else if (this.data.inline === true)
      throw new Error('Specify origin ID when using Inline Upsert!');
    else this.store.dispatch(upsertDataLocation(this.fg.value));
    this.dialogRef.close();
  }
}
