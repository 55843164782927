import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsvImportMapperComponent } from './csv-import-mapper/csv-import-mapper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { CsvImportButtonComponent } from './csv-import-button/csv-import-button.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatMenuModule,
    MatListModule,
  ],
  declarations: [CsvImportMapperComponent, CsvImportButtonComponent],
  exports: [CsvImportMapperComponent, CsvImportButtonComponent],
})
export class CsvImportMapperModule {}
