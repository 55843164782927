import { Address } from '@ca/core';
import { PrivacyPolicyInput, PrivacyNoticeInput } from './models';
export * from './models';
export class PrivacyStatementGenerator {
  generatePrivacyPolicy(input: PrivacyPolicyInput): string {
    const { organization, dataController } = input;

    const privacyPolicy = `
    Privacy Policy
    --------------

    Organization: ${organization.name}
    Contact Address: ${formatAddress(organization.contact.address)}
    Email: ${organization.contact.email}
    Phone: ${organization.contact.phone}

    Data Controller: ${dataController.name}
    Data Controller Contact: ${dataController.contact}
  `;

    return privacyPolicy;
  }

  generatePrivacyNotice(input: PrivacyNoticeInput): string {
    const { organization, dataController } = input;

    const privacyNotice = `
    Privacy Notice
    --------------

    Organization: ${organization.name}
    Contact Address: ${formatAddress(organization.contact.address)}
    Email: ${organization.contact.email}
    Phone: ${organization.contact.phone}

    Data Controller: ${dataController.name}
    Data Controller Contact: ${dataController.contact}

    Processing Activities:
  `;

    // for (const processingActivity of processingActivities) {
    //   privacyNotice += `
    //   Processing Activity: ${processingActivity.name}
    //   Purpose: ${processingActivity.purpose}
    //   Data Categories: ${processingActivity.dataCategories.join(', ')}

    //   Legal Basis Clarification: ${processingActivity.legalBasisClarification}
    //   Lifetime (Months): ${processingActivity.lifetimeMonths}
    //   Transfer Outside EEA Clarification: ${
    //     processingActivity.transferEEAClarification || 'N/A'
    //   }
    //   Lifetime: ${processingActivity.lifetime}
    //   Role: ${processingActivity.role}

    //   ${
    //     processingActivity.role === 'controller'
    //       ? 'Data Processors'
    //       : 'Data Controllers'
    //   }:
    //   ${
    //     processingActivity.role === 'controller'
    //       ? 'N/A'
    //       : formatDisclosedTo(processingActivity.disclosedTo)
    //   }

    //   Locations:
    //   ${formatLocations(processingActivity.locations)}

    //   Security Measures:
    //   ${formatSecurityMeasures(processingActivity.securityMeasures)}

    //   Data Subjects:
    //   ${formatDataSubjects(processingActivity.dataSubjects)}

    //   Justifications:
    //   ${formatJustifications(processingActivity.justifications)}

    //   Legal Grounds:
    //   ${formatLegalGrounds(processingActivity.legalGrounds)}
    // `;
    // }

    return privacyNotice;
  }
}

function formatAddress(address: Address): string {
  return `${address.street} ${address.houseNumber}${
    address.houseNumberSuffix ? address.houseNumberSuffix : ''
  }, ${address.postalCode} ${address.city}, ${address.country}`;
}

function formatDisclosedTo(
  disclosedTo?: { name: string; description?: string }[]
): string {
  if (!disclosedTo || disclosedTo.length === 0) {
    return 'N/A';
  }

  return disclosedTo
    .map((item) => `${item.name}: ${item.description || 'N/A'}`)
    .join('\n');
}

function formatLocations(
  locations?: { name: string; description: string; technology: string }[]
): string {
  if (!locations || locations.length === 0) {
    return 'N/A';
  }

  return locations
    .map((location) => `${location.name}: ${location.description}`)
    .join('\n');
}

function formatSecurityMeasures(
  securityMeasures?: { name: string; description: string }[]
): string {
  if (!securityMeasures || securityMeasures.length === 0) {
    return 'N/A';
  }

  return securityMeasures
    .map((measure) => `${measure.name}: ${measure.description}`)
    .join('\n');
}

function formatDataSubjects(dataSubjects?: { name: string }[]): string {
  if (!dataSubjects || dataSubjects.length === 0) {
    return 'N/A';
  }

  return dataSubjects.map((subject) => subject.name).join(', ');
}

function formatJustifications(
  justifications?: { name: string; description: string }[]
): string {
  if (!justifications || justifications.length === 0) {
    return 'N/A';
  }

  return justifications
    .map(
      (justification) => `${justification.name}: ${justification.description}`
    )
    .join('\n');
}

function formatLegalGrounds(
  legalGrounds?: { name: string; description: string }[]
): string {
  if (!legalGrounds || legalGrounds.length === 0) {
    return 'N/A';
  }

  return legalGrounds
    .map((ground) => `${ground.name}: ${ground.description}`)
    .join('\n');
}
