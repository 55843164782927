import { Component, Input } from '@angular/core';
// TODO: i18n id voorzien?
@Component({
  selector: 'ca-info-hover',
  template: `<mat-icon
    class="ca-info-hover"
    i18n-matTooltip
    [matTooltip]="info"
    matTooltipPosition="below"
    >info</mat-icon
  >`,
})
export class InfoHoverComponent {
  @Input() info!: string;
}
