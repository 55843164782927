import { Component } from '@angular/core';
import { QuestionVM, QuestionnaireSummary } from './models';
import { Questionnaire } from './Questionnaire';
import { GdprToolState, selectSettings } from '../../../store';
import { Store } from '@ngrx/store';
import { CaSubscriber } from '@ca/ca-utils';
import { fromSubject } from './questionnaires.config';

@Component({
  selector: 'ca-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent {
  private sub = new CaSubscriber();
  questionnaire?: Questionnaire;
  activeQuestion?: QuestionVM;
  summary?: QuestionnaireSummary;
  completed = false;

  get showBack() {
    return (
      (this.summary?.answeredQuestions.length ?? 0) > 0 ||
      this.questionnaire?.showOther
    );
  }

  private questionObserver = {
    next: (q: QuestionVM | undefined) => {
      this.activeQuestion = q;
    },
    complete: () => {
      console.log('completed');
      this.completed = true;
      // convert questionnaire answers to porcessing activities
    },
  };

  constructor(private readonly store: Store<GdprToolState>) {
    this.sub.subscribe(this.store.select(selectSettings), {
      next: (settings) => {
        this.questionnaire = new Questionnaire(fromSubject, settings);

        this.sub.subscribe(
          this.questionnaire.activeQuestion$,
          this.questionObserver
        );

        this.questionnaire.summary.subscribe((s) => {
          this.summary = s;
        });
      },
    });
  }
}
