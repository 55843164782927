import { Directive, OnDestroy } from '@angular/core';

import { OnboardingService } from '../services/onboarding.service';
import { CaSubscriber } from '@ca/ca-utils';
import { CaDataService } from '@ca/ca-data';
import { LoggingService } from '@ca/ca-ng-core';

@Directive({
  selector: '[caLogoutOnUnauthorized]',
})
export class LogoutOnUnauthorizedDirective implements OnDestroy {
  sub: CaSubscriber = new CaSubscriber();
  constructor(
    private dataService: CaDataService,
    private onboarding: OnboardingService,
    private logger: LoggingService
  ) {
    this.sub.subscribe(this.dataService.unauthorizedEvent, {
      next: () => {
        this.logger.log('LOGOUT: unauthorized event from API received.');
        this.onboarding.logout();
      },
    });
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
