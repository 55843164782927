import { Injectable } from '@angular/core';
import { MailService } from '../../services/mail/mail.service';
import { MailLogsService } from '../../services/mail/mail-logs.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SendMail, SendMailSuccess } from '../actions/mailing.actions';
import { catchError, firstValueFrom, of, switchMap } from 'rxjs';
import { PostSendMail } from '@ca/ca-mailing';
import { PostResult } from '@ca/ca-data';
import { NotificationFactory, QueueNotification } from '@ca/ca-ng-core';

@Injectable()
export class MailingEffects {
  nf = new NotificationFactory('MailingService');
  constructor(
    private mail$: MailService,
    private logs$: MailLogsService,
    private actions$: Actions
  ) {}
  onSendMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendMail),
      switchMap((request: PostSendMail) =>
        firstValueFrom(this.mail$.sendMail(request)).then(
          (response: PostResult) =>
            response.success
              ? SendMailSuccess({ request, response })
              : QueueNotification(this.nf.createError('Could not send mail'))
        )
      ),
      catchError(() =>
        of(QueueNotification(this.nf.createError('Could not send mail')))
      )
    )
  );
}
