import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnboardingModuleConfiguration, OnboardingState } from '../types';
import { getBearerHeaderFromLocalStorage } from './utils';
import {
  GetUserProfileResponse,
  PutUpdateProfileRequest,
  PutUpdateProfileResult,
} from '../types/interfaces/V2';
import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';
import { CaEnvironment, CaSubscriber } from '@ca/ca-utils';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private sub: CaSubscriber;

  constructor(
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment,
    private configuration: OnboardingModuleConfiguration,
    private http: HttpClient,
    private logger: LoggingService,
    private store: Store<{ onboarding: OnboardingState }>
  ) {
    this.sub = new CaSubscriber();
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  getUserProfile(): Promise<GetUserProfileResponse> {
    return firstValueFrom(
      this.http.get<GetUserProfileResponse>(
        this.env.apiBaseUrl + this.configuration.routes.profile,
        {
          headers: getBearerHeaderFromLocalStorage(
            this.env.sessionStorageBearerKey
          ),
        }
      )
    );
  }

  async putUserProfile(putData: PutUpdateProfileRequest): Promise<boolean> {
    const res = await firstValueFrom(
      this.http.put<PutUpdateProfileResult>(
        this.env.apiBaseUrl + this.configuration.routes.updateProfile,
        putData,
        {
          headers: getBearerHeaderFromLocalStorage(
            this.env.sessionStorageBearerKey
          ),
        }
      )
    );
    return res.success;
  }
}
