import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ExternalProcessor } from '@ca/gdpr';
import { GdprToolState, selectExternalProcessors } from '../../../store';
import { OnboardingState } from '@ca/onboarding';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { deleteExternalProcessor } from '../../../store/actions/settings.actions';
import { UpsertExternalProcessorDialogComponent } from '../dialogs/upsert-external-processor-dialog/upsert-external-processor-dialog.component';
import { newExternalProcessor } from '../../../config/defaults';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ExternalProcessorInfoHover } from '../../../config/info-hovers';

@Component({
  selector: 'ca-processors',
  template: `
    <h1 class="info-title" i18n="@@external-processors-title">
      External processors
      <!--<ca-info-hover [info]="info"></ca-info-hover>-->
    </h1>

    <ng-container *ngIf="dataSource">
      <mat-form-field>
        <mat-label i18n="Form field search label|@@search">Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. Biometric data"
          #input
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email">
            E-Mail
          </th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@phone">
            Phone
          </th>
          <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            i18n="@@address"
          >
            Address
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            {{ element.address | address }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="remove(element)"
                i18n="@@delete-btn"
              >
                DELETE<mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page of data categories"
      aria-label-i18n="@@paginator-size-selector"
    ></mat-paginator>

    <button mat-raised-button (click)="addExternalProcessor()" i18n="@@add-btn">
      <mat-icon>add</mat-icon> ADD
    </button>
  `,
})
export class ExternalProcessorsComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  protected info = ExternalProcessorInfoHover;
  dataSource?: MatTableDataSource<ExternalProcessor>;
  //TODO: Add address
  displayedColumns: string[] = ['actions', 'name', 'email', 'phone', 'address'];

  constructor(
    private store: Store<GdprToolState & OnboardingState>,
    public dialog: MatDialog
  ) {
    this.store.select(selectExternalProcessors).subscribe((processors) => {
      if (!this.dataSource)
        this.dataSource = new MatTableDataSource(processors ?? []);
      else this.dataSource.data = processors ?? [];
    });
  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  addExternalProcessor() {
    this.dialog.open(UpsertExternalProcessorDialogComponent, {
      data: newExternalProcessor,
      disableClose: true,
    });
  }
  edit(externalProcessor: ExternalProcessor) {
    this.dialog.open(UpsertExternalProcessorDialogComponent, {
      data: externalProcessor,
      disableClose: true,
    });
  }
  remove(externalProcessor: ExternalProcessor) {
    this.store.dispatch(deleteExternalProcessor({ id: externalProcessor.id }));
  }

  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
