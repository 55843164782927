<h3 i18n="@@select-external-processors">
  Select the external processors of the data in question
</h3>
<table *ngIf="dataSource" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef i18n="@@name">Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef i18n="@@email">E-Mail</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="selection.toggle(row)"
  ></tr>
</table>
<mat-list *ngIf="selected">
  <mat-list-item *ngFor="let exP of selection.selected">
    {{ exP.email }}</mat-list-item
  >
  <p i18n="@@dsr-inform-external-processors-hint">
    Inform the external processors that the data subject request wants his data
    removed.
  </p>
  <button mat-raised-button i18n="@@dsr-send-external-processors-mail">
    SEND E-MAIL
  </button>
</mat-list>
