import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DSRService } from '../../services/dsr/dsr.service';
import {
  ConfirmDataSubjectIdentity,
  ConfirmDataSubjectIdentityByMail,
  ConfirmDataSubjectIdentityByMailSuccess,
  LoadDSR,
  LoadDSRSuccess,
  OpenRejectOrCancelDSR,
  cancelDSR,
  cancelDSRSuccess,
  deleteDataSubjectRequest,
  deleteDataSubjectRequestSuccess,
  loadDataSubjectRequests,
  loadDataSubjectRequestsSuccess,
  poseQuestionToDataSubject,
  poseQuestionToDataSubjectSuccess,
  registerNewDSR,
  rejectDSR,
  rejectDSRSuccess,
  upsertDataSubjectRequest,
  upsertDataSubjectRequestSuccess,
} from '../actions/dsr.actions';
import { firstValueFrom, map, switchMap } from 'rxjs';
import { DSR, UpsertDataSubjectRequestActionData } from '@ca/gdpr';
import {
  CaDataResponse,
  DeleteRequest,
  PostResult,
  PutResult,
} from '@ca/ca-data';
import { HttpResponse } from '@angular/common/http';
import { ERROR_SOURCES } from '../../config/errors';
import { GdprToolActionType } from '../action.types';

import { NotificationFactory, QueueNotification } from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';
import { abortDelete } from '../actions/general.actions';
import {
  CancelDSRActionData,
  CancelDSRActionResult,
  GetSingle,
  OpenRejectOrCancelDSRDialogActionData,
  PoseQuestionToDataSubjectActionData,
  PoseQuestionToDataSubjectActionResult,
  RejectDSRActionData,
  RejectDSRActionResult,
} from '../models';
import { ConfirmDataSubjectIdentitySuccess } from '../actions/dsr.actions';
import { MatDialog } from '@angular/material/dialog';
import { PostSendMailLinked } from '@ca/ca-mailing';
import { MailService } from '../../services/mail/mail.service';
import { QuestionAndAnswerService } from '../../services/dsr/question-and-answer.service';
import { RemoveDataSubjectRequestDialogComponent } from '../../components/dsr/dialogs/remove-data-subject-request-dialog/remove-data-subject-request-dialog.component';
import { UpsertDataSubjectRequestDialogComponent } from '../../components/dsr/dialogs/upsert-data-subject-request-dialog/upsert-data-subject-request-dialog.component';
import { newDSR } from '../../config/defaults';
import { TableNamesForLinking } from '../../config/table-names-for-linking';
import { UpsertQuestion } from '@ca/core';

@Injectable()
export class DSREffects {
  private nf = new NotificationFactory(ERROR_SOURCES.dsr);

  constructor(
    private actions$: Actions,
    private dsrs: DSRService,
    private qna: QuestionAndAnswerService,
    private store: Store,
    protected dialog: MatDialog,
    protected mailing: MailService
  ) {
    this.actions$
      .pipe(ofType(upsertDataSubjectRequestSuccess))
      .subscribe(({ response }) => {
        this.store.dispatch(loadDataSubjectRequests());
        this.store.dispatch(LoadDSR({ id: response.id }));
      });

    this.actions$
      .pipe(
        ofType(
          poseQuestionToDataSubjectSuccess,
          rejectDSRSuccess,
          cancelDSRSuccess
        )
      )
      .subscribe(
        (
          v:
            | PoseQuestionToDataSubjectActionResult
            | RejectDSRActionResult
            | CancelDSRActionResult
        ) => this.store.dispatch(LoadDSR({ id: v.request.id }))
      );

    this.actions$.pipe(ofType(registerNewDSR)).subscribe(() => {
      this.dialog.open(UpsertDataSubjectRequestDialogComponent, {
        data: newDSR(),
        disableClose: true,
      });
    });

    this.actions$
      .pipe(ofType(OpenRejectOrCancelDSR))
      .subscribe((value: OpenRejectOrCancelDSRDialogActionData) =>
        this.openRejectOrCancelDialog(value)
      );
  }

  onLoadDataSubjectRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GdprToolActionType.LoadDataSubjectRequests),
      switchMap(() => {
        return firstValueFrom(this.dsrs.get())
          .then((res: HttpResponse<CaDataResponse<DSR[]>>) => {
            if (res.ok && res.body != null) {
              return loadDataSubjectRequestsSuccess({
                dataSubjectRequests: res.body.data,
              });
            }
            return QueueNotification(
              this.nf.createError('data subject requests', 'get')
            );
          })
          .catch(() => {
            return QueueNotification(
              this.nf.createError('data subject requests', 'get')
            );
          });
      })
    )
  );

  onUpsertDataSubjectRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertDataSubjectRequest),
      switchMap((value: UpsertDataSubjectRequestActionData) =>
        firstValueFrom(
          this.dsrs.upsert<UpsertDataSubjectRequestActionData>(value)
        )
          .then((res: HttpResponse<PutResult<'put-invoked-rights'>>) =>
            res.ok && res.body && res.body.success
              ? upsertDataSubjectRequestSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('data subject request', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('data subject request', 'upsert')
            )
          )
      )
    )
  );

  onDeleteDataSubjectRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDataSubjectRequest),
      switchMap((value: DeleteRequest) =>
        this.dsrs
          .delete(value.id)
          .then((res) =>
            res
              ? deleteDataSubjectRequestSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('data subject request', 'remove')
            )
          )
      )
    )
  );

  onLoadDSR$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDSR),
      switchMap((value: GetSingle) =>
        firstValueFrom(this.dsrs.getSingle(value.id)).then(
          (res: HttpResponse<CaDataResponse<DSR>>) =>
            res.ok && res.body && res.body.data
              ? LoadDSRSuccess({ request: value, result: res.body.data })
              : QueueNotification(this.nf.createError('DSR', 'get'))
        )
      )
    )
  );

  onPoseQuestionToDataSubject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(poseQuestionToDataSubject),
      switchMap((v: PoseQuestionToDataSubjectActionData) =>
        firstValueFrom(
          this.qna.upsert<UpsertQuestion>({
            id: 0,
            parentId: v.id,
            parentTable: TableNamesForLinking.DSRs,
            question: v.question,
          })
        ).then((res) =>
          res.ok && res.body && res.body.success
            ? poseQuestionToDataSubjectSuccess({
                request: v,
              })
            : QueueNotification(
                this.nf.createError('question to data subject', 'upsert')
              )
        )
      )
    )
  );

  onConfirmIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfirmDataSubjectIdentity),
      switchMap((request: { dsrId: number }) =>
        this.dsrs
          .upsert({
            id: request.dsrId,
            identityConfirmed: true,
          })
          .pipe(
            map((response: HttpResponse<PutResult<'put-invoked-rights'>>) =>
              response.ok && response.body != null && response.body.success
                ? ConfirmDataSubjectIdentitySuccess({ dsrId: request.dsrId })
                : QueueNotification(this.nf.createError('DSR status', 'upsert'))
            )
          )
      )
    )
  );
  onConfirmIdentityByEMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfirmDataSubjectIdentityByMail),
      switchMap((request: PostSendMailLinked) =>
        this.mailing
          .sendLinkedMail(request)
          .pipe(
            map((response: PostResult) =>
              response.success
                ? ConfirmDataSubjectIdentityByMailSuccess({ request, response })
                : QueueNotification(
                    this.nf.createError(
                      'Could not send mail to confirm identity.'
                    )
                  )
            )
          )
      )
    )
  );

  onArchiveDSR$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelDSR),
      switchMap((request: CancelDSRActionData) =>
        this.dsrs.upsert({ ...request, status: 'Archived' }).pipe(
          map((res: HttpResponse<PutResult<'put-invoked-rights'>>) =>
            res.ok && res.body !== null
              ? cancelDSRSuccess({
                  request: { ...request },
                  response: res.body,
                })
              : QueueNotification(this.nf.createError('Could not archive DSR.'))
          )
        )
      )
    )
  );
  onRejectDSR$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectDSR),
      switchMap((request: RejectDSRActionData) =>
        this.dsrs.upsert({ ...request, status: 'Rejected' }).pipe(
          map((res: HttpResponse<PutResult<'put-invoked-rights'>>) =>
            res.ok && res.body !== null
              ? rejectDSRSuccess({
                  request,
                  response: res.body,
                })
              : QueueNotification(this.nf.createError('Could not reject DSR.'))
          )
        )
      )
    )
  );

  private openRejectOrCancelDialog(
    data: OpenRejectOrCancelDSRDialogActionData
  ): void {
    this.dialog.open<
      RemoveDataSubjectRequestDialogComponent,
      OpenRejectOrCancelDSRDialogActionData
    >(RemoveDataSubjectRequestDialogComponent, {
      data,
      disableClose: true,
    });
  }
}
