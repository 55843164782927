import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { PublicState } from '../../../store/reducers/public.reducer';
import { ProtectedPublicComponent } from '../ProtectedPublicComponent';
import { FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { DsrDataSubjectQnaService } from '../../../services/public/dsr-data-subject-qna.service';
import { selectPublicState } from '../../../store';
import {
  SetHistoryVisibility,
  LoadQuestions,
} from '../../../store/actions/public.actions';
import { QnAViewModel } from '../dsr-data-subject-qna/QnAViewModel';

@Component({
  selector: 'ca-confirm-identity',
  template: `
    <div class="public-page">
      <img preload class="logo" src="assets/humidor_logo.svg" />
      <ng-container *ngIf="success === true">
        <h1 i18n="@@qna-public-confirm-identity-thanks">
          Thanks for confirming your identity
        </h1>
      </ng-container>
      <h1 *ngIf="success === false" i18n="@@qna-public-something-went-wrong">
        Something went wrong. If you think this is a mistake, please contact ...
      </h1>
      <div class="public-qna" *ngIf="posedQuestions !== undefined">
        <div
          class="public-question posed"
          *ngFor="let qna of posedQuestions"
          [formGroup]="qna.fg"
        >
          <h3>{{ qna.question }}</h3>
          <input formControlName="id" hidden />
          <mat-form-field style="width: 100%">
            <mat-label i18n="@@qna-public-your-anwer">Your answer</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="answer"
            ></textarea>
          </mat-form-field>
          <input formControlName="uuid" hidden />
          <input formControlName="readAt" hidden />

          <button
            [disabled]="qna.fg.valid !== true"
            mat-button
            (click)="answerQuestion(qna.fg)"
            i18n="@@send-mail"
          >
            <mat-icon>send_mail</mat-icon> SEND
          </button>
        </div>

        <mat-divider></mat-divider>

        <div class="qna-history">
          <ng-container
            *ngIf="qnAHistory !== undefined && qnAHistory.length > 0"
          >
            <button mat-raised-button (click)="toggleHistory()">
              {{ historyLabel }} ({{ qnAHistory.length }})
            </button>

            <ng-container *ngIf="showHistory === true">
              <div
                *ngFor="let qna of qnAHistory"
                class="public-question answered"
              >
                <h3>{{ qna.question }}</h3>
                <p>{{ qna.answer }}</p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        *ngIf="posedQuestions === undefined"
        i18n="@@qna-public-no-questions"
      >
        Nothing to declare
      </div>
    </div>
  `,
})
export class ConfirmIdentityComponent
  extends ProtectedPublicComponent
  implements OnDestroy
{
  success = false;
  showHistory = false;
  posedQuestions?: QnAViewModel[];
  qnAHistory?: QnAViewModel[];
  get historyLabel(): string {
    return this.showHistory
      ? $localize`:@@history-hide:HIDE HISTORY`
      : $localize`:@@history-show:SHOW HISTORY`;
  }
  constructor(
    private route: ActivatedRoute,
    private svc: DsrDataSubjectQnaService,
    private store: Store<{ public: PublicState }>
  ) {
    super();
    this.init(this.route.queryParamMap, (params: ParamMap) => {
      this.success = params.get('valid') === 'true';
      if (this.uuid !== null) this.loadQuestions();
    });

    this.sub.subscribe(this.store.select(selectPublicState), {
      next: ({ posedQuestions, answeredQuestions, showHistory }) => {
        this.showHistory = showHistory;
        if (this.uuid !== null && answeredQuestions != undefined)
          this.qnAHistory = this.svc.mapQnaDataToViewModel(
            this.uuid,
            answeredQuestions
          );
        if (this.uuid !== null && posedQuestions != undefined)
          this.posedQuestions = this.svc.mapQnaDataToViewModel(
            this.uuid,
            posedQuestions
          );
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  toggleHistory() {
    this.store.dispatch(
      SetHistoryVisibility({ showHistory: !this.showHistory })
    );
  }

  answerQuestion(fg: FormGroup) {
    firstValueFrom(this.svc.answerQuestion(fg.value)).then((res) => {
      if (res.success) this.loadQuestions();
    });
    // .catch((err) => console.log(err));
  }

  private loadQuestions() {
    if (this.uuid !== null)
      this.store.dispatch(LoadQuestions({ uuid: this.uuid }));
  }
}
