<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <form class="upsert-form" style="padding: 0.66em 1.66em" [formGroup]="fg">
    <input formControlName="id" hidden />
    <input hidden formControlName="purposeId" />
    <ng-container
      *ngIf="data.recordToUpdate !== undefined && data.recordToUpdate.id > 0"
    >
      <input
        matInput
        hidden
        [disabled]="true"
        type="text"
        aria-label="Processing Purpose Type Name Input"
        formControlName="purpose"
      />
    </ng-container>
    <ng-container
      *ngIf="data.recordToUpdate === undefined || data.recordToUpdate.id <= 0"
    >
      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label>{{ purposeLabel }}</mat-label>
          <input
            matInput
            type="text"
            aria-label="Processing Purpose Type Name Input"
            formControlName="purpose"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let option of filteredProcessingPurposes | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint i18n="@@type-to-filter-or-create-processing-purpose">
            Type to filter existing purposes or create a new Type
          </mat-hint>
        </mat-form-field>
        <!-- <ca-info-hover [info]="purposeInfo"></ca-info-hover> -->
      </div>
    </ng-container>

    <input hidden formControlName="dataSubjectType" />
    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@lifetime-months">Lifetime in months</mat-label>
        <input matInput type="number" formControlName="lifetimeMonths" />
      </mat-form-field>
      <!-- <ca-info-hover
        info="How long will you generally process/store personal information for this purpose?"
      ></ca-info-hover> -->
    </div>

    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@clarify-lifetime">Clarify lifetime</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="lifetimeClarification"
        ></textarea>
      </mat-form-field>
      <!-- <ca-info-hover
        info="Explain why you process/store personal information for this purpose."
      ></ca-info-hover> -->
    </div>

    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@data-locations">Data locations</mat-label>
        <mat-select formControlName="dataLocationIds" multiple>
          <mat-option *ngFor="let dl of dataLocations" [value]="dl.id">{{
            dl.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <ca-info-hover
        info="Where will you process/store this personal information?"
      ></ca-info-hover> -->
    </div>

    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@disclosed-to">Disclosed to</mat-label>
        <mat-select formControlName="disclosedToIds" multiple>
          <mat-option *ngFor="let dl of disclosedTos" [value]="dl.id">{{
            dl.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <ca-info-hover
        info="Who will have access to this personal information?"
      ></ca-info-hover> -->
    </div>

    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@legal-grounds">Legal grounds</mat-label>
        <mat-select formControlName="legalGroundIds" multiple>
          <mat-option *ngFor="let dl of legalGrounds" [value]="dl.id">{{
            dl.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <ca-info-hover
        info="Who will have access to this personal information?"
      ></ca-info-hover> -->
    </div>

    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@clarify-legal-grounds"
          >Clarify legal grounds</mat-label
        >
        <textarea
          matInput
          formControlName="legalGroundClarification"
        ></textarea>
      </mat-form-field>
      <!-- <ca-info-hover
        info="Argue why you base your right to process this personal information on this/these legal ground(s)?"
      ></ca-info-hover> -->
    </div>

    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@transfers-outside-eea"
          >Transfers outside the EEA</mat-label
        >
        <mat-select formControlName="transferEEAIds" multiple>
          <mat-option *ngFor="let dl of transfersOutsideEEA" [value]="dl.id">{{
            dl.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <ca-info-hover
        info="Do you transfer this data outside the EEA?"
      ></ca-info-hover> -->
    </div>

    <div class="info-hover-form-field">
      <mat-form-field class="stretch">
        <mat-label i18n="@@clarify-transfers-outside-eea"
          >Clarify transfer outside the EEA</mat-label
        >
        <textarea
          matInput
          formControlName="transferEEAClarification"
        ></textarea>
      </mat-form-field>
      <!-- <ca-info-hover
        info="Argue your right to transfer this personal information to this/these countries outside the EEA?"
      ></ca-info-hover> -->
    </div>

    <!-- TODO: ADD MEDIA UPLOAD -->
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a
    class="cancel-link cancel"
    style="margin-right: 0.66em"
    (click)="ref.close()"
    i18n="@@cancel-btn"
  >
    Cancel
  </a>
  <button mat-raised-button color="primary" (click)="save()" i18n="@@save-btn">
    SAVE
  </button>
</mat-dialog-actions>
