import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DataSubjectType } from '@ca/gdpr';
import { GdprToolState, selectDataRegister } from '../../../store';
import { Store } from '@ngrx/store';
import { CaSubscriber, GroupBy } from '@ca/ca-utils';
import {
  ProcessingActivityGroup,
  ProcessingActivityGroups,
  ProcessingActivityRows,
} from '@ca/gdpr';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'ca-mark-data',
  template: `
    <mat-selection-list
      *ngIf="dataSubjectTypes"
      (selectionChange)="selectionChange()"
      [compareWith]="compareFn"
    >
      <ng-container *ngFor="let gr of groupNames; let i = index">
        <div mat-subheader>{{ gr }}</div>
        <mat-list-option *ngFor="let r of getGroupValues(i)" [value]="r">
          <div matListItemTitle>
            {{ r.purpose.name }}
          </div>
          <div matListItemLine>
            {{ displayRows(r.rows) }}
          </div>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  `,
})
export class MarkDataComponent {
  private list?: MatSelectionList;
  @ViewChild(MatSelectionList) protected set content(list: MatSelectionList) {
    if (list) {
      this.list = list;
      const hasInitializer =
        this.initialValue !== undefined && this.initialValue.length > 0;
      setTimeout(() => {
        if (this.list) {
          this.list.options.forEach((e) => {
            if (
              (hasInitializer === true &&
                this.initialValue?.includes(e.value.id)) ||
              hasInitializer === false
            )
              e.selected = true;
          });
        }
      }, 200);
    }
  }

  @Input() dataSubjectTypes?: number[];
  @Input() initialValue?: number[];

  @Output() selectionChanged = new EventEmitter<ProcessingActivityGroups>();

  protected ropa?: ProcessingActivityGroups;
  protected groupNames: string[] = [];
  protected groupValues: ProcessingActivityGroups[] = [];

  getGroupValues(i: number) {
    return this.groupValues[i];
  }

  private sub = new CaSubscriber();
  constructor(protected store: Store<GdprToolState>) {
    this.sub.subscribe(this.store.select(selectDataRegister), {
      next: (state) => {
        this.groupNames = [];
        this.groupValues = [];
        const processingActivities =
          state.controllerGroups && state.processorGroups
            ? [...state.controllerGroups, ...state.processorGroups]
            : state.controllerGroups
            ? state.controllerGroups
            : state.processorGroups
            ? state.processorGroups
            : [];

        const ropa =
          this.dataSubjectTypes && this.dataSubjectTypes.length > 0
            ? FilterRoPAGroupsByDataSubjectTypeIds(
                processingActivities,
                this.dataSubjectTypes
              )
            : processingActivities;

        const map: Map<string, ProcessingActivityGroups> = GroupBy(
          ropa,
          (e) => e.dataSubjectType.name
        );
        for (const k of map.keys()) {
          let v = map.get(k);

          if (v) {
            v = v.filter((e) => e.rows.length > 0);
            if (v.length > 0) {
              this.groupNames.push(k);
              this.groupValues.push(v.filter((e) => e.rows.length > 0));
            }
          } else throw new Error('No Value for key: ' + k);
        }
      },
    });
  }

  selectionChange() {
    if (this.list && this.list.selectedOptions.hasValue()) {
      this.selectionChanged.emit(
        this.list.selectedOptions.selected.map((e) => e.value)
      );
    }
  }

  displayRows(rows: ProcessingActivityRows) {
    return rows.map((e) => e.dataCategory.name).join(', ');
  }

  compareFn(
    option: ProcessingActivityGroup,
    selectedValue: ProcessingActivityGroups
  ) {
    return selectedValue.map((e) => e.id).includes(option.id);
  }
}

export function FilterRoPAGroupsByDataSubjectTypeIds(
  ropaGroups: ProcessingActivityGroups,
  dataSubjectTypeIds: number[]
) {
  return ropaGroups.filter((e) =>
    dataSubjectTypeIds.includes(e.dataSubjectType.id)
  );
}

export function FilterRoPAGroupsByDataSubjectType(
  ropaGroups: ProcessingActivityGroups,
  dataSubjectTypes: DataSubjectType[]
) {
  return FilterRoPAGroupsByDataSubjectTypeIds(
    ropaGroups,
    dataSubjectTypes.map((e) => e.id)
  );
}
