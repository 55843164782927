import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UpsertRemark, UpsertRemarkSuccess } from '../actions/general.actions';
import { map, switchMap } from 'rxjs';
import { UpsertRemarkData } from '../models';
import { RemarksService } from '../../services/core/remarks.service';
import { PutResult } from '@ca/ca-data';
import { NotificationFactory, QueueNotification } from '@ca/ca-ng-core';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class GenericEffects {
  private nf = new NotificationFactory('GenericEffects');
  constructor(private actions$: Actions, private remarks: RemarksService) {}

  onUpsertRemark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRemark),
      switchMap((request: UpsertRemarkData) =>
        this.remarks
          .upsert(request)
          .pipe(
            map((response: HttpResponse<PutResult<'put-remarks'>>) =>
              response.ok && response.body != null && response.body.success
                ? UpsertRemarkSuccess({ request, response: response.body })
                : QueueNotification(this.nf.createError('remark', 'upsert'))
            )
          )
      )
    )
  );
}
