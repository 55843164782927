import { createSelector } from '@ngrx/store';
import { ILinkedDivision, State } from '../types';

// Root selectors
export const selectOnboarding = (state: State) => state.onboarding;
export const selectToken = (state: State) => state.onboarding.user.token;
export const selectUser = (state: State) =>
  state?.onboarding?.user ?? undefined;
export const selectUserName = (state: State) =>
  state && state.onboarding
    ? state.onboarding.user
      ? state.onboarding.user.profile?.firstName
      : undefined
    : undefined;

// Memoized Selectors
export const selectProfile = createSelector(
  selectUser,
  (state) => state.profile
);
export const selectActiveDivision = createSelector(
  selectUser,
  (state) => state?.activeDivision
);
export const selectActiveDivisionId = createSelector(
  selectUser,
  (state) => state?.activeDivision?.division?.id
);
export const selectUserDivisions = createSelector(
  selectUser,
  (state) => state?.divisions
);
export const selectUsersDivisions = createSelector(
  selectUser,
  (state) => state?.profile?.linkedDivisions
);

export const selectUserRoles = createSelector(
  selectUser,
  (state) => state?.roles
);
export const selectUserId = createSelector(
  selectUser,
  (state) => state?.profile?.id
);
export const selectUsers = createSelector(selectUser, (state) => state?.users);
export const selectUserHasMultipleDivisions = createSelector(
  selectUser,
  (state) => state?.hasMultipleDivisions
);
export const selectUserLinkedDivisions = createSelector(
  selectUser,
  (state) => state?.profile?.linkedDivisions
);
export const selectUserIsLoggedIn = createSelector(
  selectUser,
  (state) => state?.profile !== undefined && state.token != undefined
);
export const selectIsUserManager = createSelector(selectUser, (state) =>
  state !== undefined && state.activeDivision !== undefined
    ? state.activeDivision.role.accessLevel < 3
    : false
);

export const selectAccessLevel = createSelector(selectUser, (state) =>
  state !== undefined && state.activeDivision != undefined
    ? state.activeDivision.role.accessLevel
    : 1000
);

export const selectDashboardUserData = createSelector(selectUser, (state) => ({
  activeDivision: state?.activeDivision,
  userDivisions: state?.profile?.linkedDivisions,
  isLoggedIn: state?.profile !== undefined && state.token != undefined,
  isUserManager:
    state !== undefined && state.activeDivision !== undefined
      ? state.activeDivision.role.accessLevel < 3
      : false,
  hasToken: state.token !== undefined,
}));

export interface DashboardUserData {
  activeDivision: ILinkedDivision | undefined;
  userDivisions: ILinkedDivision[] | undefined;
  isLoggedIn: boolean;
  isUserManager: boolean;
  hasToken: boolean;
}
