import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AllowedSeparator,
  ImportMap,
  PropertyMapFormGroup,
  PropertyViewModel,
  SupportedType,
  ViewModel,
} from '../models';
import {
  stringIsNumber,
  trimLeadingAndTrailingSpaces,
  camelize,
  groupBy,
} from '@ca/ca-utils';

export const COMMA_SEPARATOR = ',';
export const SEMICOLON_SEPARATOR = ';';

/**
 * IMPORT MAPS
 */
export function toImportDataMap(
  i: number,
  value: string,
  columnName: string
): ImportMap {
  const isNum = stringIsNumber(value);
  return {
    index: i,
    value: isNum ? +value : value,
    guessedType: isNum ? 'number' : 'string',
    name: columnName,
  };
}
export function groupImportMaps(
  importMaps: ImportMap[]
): Record<number, ImportMap[]> {
  return groupBy(
    importMaps.sort((a, b) => a.index - b.index),
    (i: { index: any }) => i.index
  );
}
export function importMapsToObjects(importMaps: ImportMap[]) {
  const groupedByIndex: Record<number, ImportMap[]> = groupBy(
    importMaps.sort((a, b) => a.index - b.index),
    (i: { index: any }) => i.index
  );
  const objects = [];
  for (const i in groupedByIndex) {
    const obj: any = {};
    groupedByIndex[i].forEach((map) => (obj[map.name] = map.value));
    objects.push(obj);
  }
  return objects;
}
export function getCSVColumnNames(row: string, separator: string): string[] {
  return row
    .split(separator)
    .map((columnName) => trimLeadingAndTrailingSpaces(columnName));
}
export function getCSVRowData(rows: string[], separator: string): string[][] {
  return rows.slice(1).map((d) => d.split(separator));
}
export function convertCSVDataToImportMaps(
  data: string[][],
  columnNames: string[]
): ImportMap[] {
  const importMaps: ImportMap[] = [];
  data.forEach((d, index) =>
    d.forEach((x, i) =>
      importMaps.push(toImportDataMap(index, x, columnNames[i]))
    )
  );
  const _importMaps: Record<number, ImportMap[]> = groupBy(
    importMaps,
    (m: { index: any }) => m.index
  );

  for (const _key in _importMaps) {
    const g = _importMaps[_key];
    const firstType: SupportedType = g[0].guessedType;
    const needsSanitization =
      _importMaps[_key].findIndex((el) => el.guessedType !== firstType) > 0;
    if (needsSanitization) {
      _importMaps[_key].forEach((el) => (el.guessedType = 'string'));
    }
  }
  return importMaps;
}
export function getSamples(
  importMaps: ImportMap[],
  importMap: ImportMap,
  numberOfSamplesToShow: number
): (string | number)[] {
  return importMaps
    .filter(
      (e) => e.name === importMap.name && e.value !== 0 && e.value !== '0'
    )
    .slice(0, numberOfSamplesToShow)
    .map((e) => e.value);
}
export function createViewModelFromImportMapGroup(
  importMaps: ImportMap[],
  numberOfSamplesToShow = 5
): (importMap: ImportMap) => PropertyViewModel {
  return (importMap: ImportMap) => {
    const fg = CreatePropertyMapFormGroup(importMap);
    // TODO: create a better way to get samples, this should be done only once for each property
    const sampleValues = getSamples(
      importMaps,
      importMap,
      numberOfSamplesToShow
    );
    const dismissed = false;
    attachEnumListener(fg);
    return { importMap, fg, sampleValues, dismissed };
  };
}
export const ImportCSVToViewModel: (args: {
  loadResult: string[] | undefined;
  separator: AllowedSeparator;
  numberOfSamplesToShow: number;
}) => { rawData: any[]; viewmodel: ViewModel } | undefined = (args) => {
  const { loadResult, separator, numberOfSamplesToShow } = args;

  if (loadResult && loadResult.length > 0) {
    const columnNames = getCSVColumnNames(loadResult[0], separator);
    const data = getCSVRowData(loadResult, separator);
    const importMaps = convertCSVDataToImportMaps(data, columnNames);

    return {
      rawData: importMapsToObjects(importMaps), // TODO: map results to objects
      viewmodel: groupImportMaps(importMaps)[0].map(
        createViewModelFromImportMapGroup(importMaps, numberOfSamplesToShow)
      ),
    };
  }

  return undefined;
};

/**
 * FORM
 */
export function CreatePropertyMapFormGroup(
  importMap: ImportMap
): PropertyMapFormGroup {
  return new FormGroup({
    propName: new FormControl<null | string>(
      importMap.name ? camelize(importMap.name) : null, // TODO: make property name convertor function selectable 'camel case' | ''
      Validators.required
    ),
    propType: new FormControl<null | SupportedType>(
      importMap.guessedType,
      Validators.required
    ),
    propDescription: new FormControl<null | string>(null),
    // enumValues: new FormArray<FormControl<null | string>>([], ) => added when propType is set to 'enum'
  });
}
export function attachEnumListener(fg: PropertyMapFormGroup): void {
  fg.controls.propType.valueChanges.subscribe(
    (proptype: SupportedType | null) => {
      if (proptype === null) {
        console.log('VALUE WAS NULL');
        return;
      }
      if (proptype === 'enum') addEnumValuesControl(fg);
      else removeEnumValuesControl(fg);
    }
  );
}
export function addEnumValuesControl(formGroup: FormGroup): void {
  formGroup.addControl(
    'enumValues',
    new FormArray<FormControl<null | string>>(
      [
        new FormControl<string | null>(null, Validators.required),
        new FormControl<string | null>(null, Validators.required),
      ],
      [Validators.required, Validators.minLength(2)]
    )
  );
}
export function removeEnumValuesControl(formGroup: FormGroup): void {
  formGroup.removeControl('enumValues');
}
