import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CaDataResponse, PostResult } from '@ca/ca-data';
import { CaEnvironment } from '@ca/ca-utils';
import { QnAViewModel } from '../../components/public/dsr-data-subject-qna/QnAViewModel';
import { PostAnswer } from '../../components/public/dsr-data-subject-qna/PostAnswer';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { map } from 'rxjs';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class DsrDataSubjectQnaService {
  private url: string;
  constructor(
    @Inject(CA_ENVIRONMENT) protected env: CaEnvironment,
    private http: HttpClient
  ) {
    this.url = `${this.env.apiBaseUrl}/api/data-subject-rights/questions-and-answers`;
  }

  getQnAViewModel(accessCode: string) {
    return this.http
      .get<CaDataResponse<PublicQnaData[]>>(`${this.url}?uuid=${accessCode}`)
      .pipe(map((e) => (e.data ? e.data : [])));
  }
  mapQnaDataToViewModel(accessCode: string, qnas: PublicQnaData[]) {
    return qnas.map<QnAViewModel>((q) => ({
      ...q,
      status: q.answer === null ? 'posed' : 'answered',
      fg: new FormGroup({
        id: new FormControl(q.id),
        uuid: new FormControl(accessCode),
        answer: new FormControl(null, Validators.required),
        readAt: new FormControl(moment().format('DD/MM/YYYY HH:mm')),
      }),
    }));
  }
  answerQuestion(answer: PostAnswer) {
    return this.http.post<PostResult>(this.url, {
      ...answer,
      respondedAt: moment().format('DD/MM/YYYY HH:mm'),
    });
  }
}

export interface PublicQnaData {
  id: number;
  question: string;
  answer: null | string;
  createdAt: string;
}
