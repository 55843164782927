import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { GdprToolState, selectSystemMessages } from '../../../store';
import { CaSubscriber } from '@ca/ca-utils';
import {
  SystemMessage,
  SystemMessageAction,
} from '../../../models/messaging.models';
import { map } from 'rxjs';
import { dismissSystemMessage } from '../../../store/actions/general.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'ca-system-messages',
  template: `
    <button
      [disabled]="(hasMessages$ | async) === false"
      (click)="isOpen = !isOpen"
      type="button"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      mat-icon-button
    >
      <mat-icon
        [matBadgeHidden]="(hasMessages$ | async) === false"
        [matBadge]="(messages$ | async)?.length"
        matBadgeColor="accent"
      >
        message
      </mat-icon>
      <!-- Include text description of the icon's meaning for screen-readers -->
      <span class="cdk-visually-hidden">
        A messages icon with a badge showing how many unread messages the user
        has.
      </span>
    </button>
    <!-- This template displays the overlay content and is connected to the button -->
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      (detatch)="isOpen = false"
      (backdropClick)="isOpen = false"
    >
      <!-- TODO: create system-messages component -->
      <ul class="container notification-container">
        <li *ngFor="let msg of messages$ | async">
          <mat-card>
            <mat-card-header>
              <mat-card-title>{{ msg.title }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ msg.message }}</p>
            </mat-card-content>
            <mat-card-actions align="end">
              <a
                class="cancel-link cancel"
                (click)="dismiss(msg)"
                i18n="@@dismiss-msg-btn"
              >
                Dismiss
              </a>
              <button
                mat-button
                *ngIf="msg.action"
                (click)="dismiss(msg, true)"
              >
                {{ getActionLabel(msg.action) }}
              </button>
            </mat-card-actions>
          </mat-card>
        </li>
      </ul>
    </ng-template>
  `,
  styles: [
    `
      .container {
        border: solid 1px #ccc;
        border-radius: 5px;
        background: #fff;
        text-align: center;
        padding: 10px;
        margin: 0;
      }

      .container > li {
        list-style-type: none;
        padding: 8px 0;
      }

      .container > li:last-child {
        border-bottom: none;
      }
    `,
  ],
})
export class SystemMessagesComponent implements OnDestroy {
  private sub = new CaSubscriber();
  protected isOpen = false;
  protected messages$ = this.store
    .select(selectSystemMessages)
    .pipe(map((s) => s.messages));

  protected hasMessages$ = this.messages$.pipe(
    map((v) => v !== null && v.length > 0)
  );
  protected messageCount$ = this.messages$.pipe(map((v) => v.length));

  constructor(private store: Store<GdprToolState>, private router: Router) {
    this.sub.subscribe(this.messages$, {
      next: (msgs: SystemMessage[]) => {
        if (msgs.length === 0) {
          this.isOpen = false;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  protected getActionLabel(action: SystemMessageAction) {
    return action.type === 'ext'
      ? $localize`:@@open-in-new-window:OPEN IN NEW WINDOW`
      : $localize`:@@folow-link-btn:TAKE ME THERE!`;
  }

  protected dismiss(msg: SystemMessage, invokeAction = false) {
    if (invokeAction && msg.action) {
      switch (msg.action.type) {
        case 'ext':
          window.open(msg.action.url, '_blank');
          break;
        case 'nav':
          this.router.navigate([msg.action.url]);
          this.isOpen = false;
          break;
        default:
          throw new Error('Action type could not be resolved: ' + msg.action);
      }
    }
    this.store.dispatch(dismissSystemMessage({ id: msg.id }));
  }
}
