<div class="upsert-data-subject-type">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-dialog-content>
    <mat-stepper #stepper [linear]="true">
      <!-- Choose Subject and Purposes -->
      <mat-step
        i18n-label="
          Stepper step label for Choose Subject and
          Purpose|@@register-purpose-step-label"
        label="Choose Subject and Purposes"
        [stepControl]="subjectAndPurposeFG"
      >
        <form class="upsert-form" [formGroup]="subjectAndPurposeFG">
          <div class="info-hover-form-field">
            <mat-form-field class="stretch">
              <mat-label>{{ nameLabel }}</mat-label>
              <input
                matInput
                type="text"
                aria-label="Data Subject Type Name Input"
                formControlName="name"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let option of filteredDataSubjectTypes | async"
                  [value]="option"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint i18n="@@filter-or-create-subject-hint">
                Type to filter existing types or create a new Subject
              </mat-hint>
            </mat-form-field>
            <!-- <ca-info-hover [info]="nameInfo"></ca-info-hover> -->
          </div>

          <ca-processing-purpose-selector
            [formGroup]="subjectAndPurposeFG"
          ></ca-processing-purpose-selector>
        </form>
        <div>
          <a
            class="cancel-link cancel"
            style="margin-right: 0.66em"
            mat-dialog-close
            i18n="@@cancel-btn"
          >
            Cancel
          </a>
          <button
            mat-button
            (click)="confirmSubjectAndPurposes(stepper)"
            [disabled]="!subjectAndPurposeFG.valid"
            i18n="@@next-btn"
          >
            NEXT
          </button>
        </div>
      </mat-step>

      <!-- Foreach purpose, specify configuration -->
      <mat-step
        *ngIf="purposesConfigurationFG"
        [stepControl]="purposesConfigurationFG"
        i18n-label="
          Stepper step label for Configure Purpose
          Parameters|@@purpose-config-step-label"
        label="Configure Purpose Parameters"
      >
        <ng-container [formGroup]="purposesConfigurationFG">
          <ng-container formArrayName="purposeConfigurations">
            <div
              class="purpose-configuration"
              *ngFor="let pc of RoPAGroupFormArray.controls; let i = index"
            >
              <h1 *ngIf="selectedPurposeNames">
                <span i18n="@@configure-purpose-x-label"
                  >Configure Purpose
                </span>
                {{ selectedPurposeNames[i] }}
              </h1>
              <form [formGroup]="pc">
                <input type="number" hidden formControlName="id" />
                <input type="number" hidden formControlName="purposeId" />
                <input hidden formControlName="dataSubjectType" />
                <div class="info-hover-form-field">
                  <mat-form-field class="stretch">
                    <mat-label
                      i18n="
                        Form field label for Lifetime in months|@@lifetimeMonths"
                      >Lifetime in months</mat-label
                    >
                    <input
                      matInput
                      type="number"
                      formControlName="lifetimeMonths"
                    />
                  </mat-form-field>
                </div>

                <div class="info-hover-form-field">
                  <mat-form-field class="stretch">
                    <mat-label i18n="@@clarify-lifetime"
                      >Clarify lifetime</mat-label
                    >
                    <textarea
                      matInput
                      type="text"
                      formControlName="lifetimeClarification"
                    ></textarea>
                  </mat-form-field>
                </div>

                <ca-data-locations-selector
                  [formGroup]="RoPAGroupFormArray.controls[i]"
                ></ca-data-locations-selector>

                <ca-disclosed-to-selector
                  [formGroup]="pc"
                ></ca-disclosed-to-selector>

                <div class="info-hover-form-field">
                  <mat-form-field class="stretch">
                    <mat-label i18n="@@legal-grounds">Legal grounds</mat-label>
                    <mat-select formControlName="legalGroundIds" multiple>
                      <mat-option
                        *ngFor="let dl of legalGrounds"
                        [value]="dl.id"
                        >{{ dl.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="info-hover-form-field">
                  <mat-form-field class="stretch">
                    <mat-label i18n="@@clarify-legal-grounds"
                      >Clarify legal grounds</mat-label
                    >
                    <textarea
                      matInput
                      formControlName="legalGroundClarification"
                    ></textarea>
                  </mat-form-field>
                  <!-- <ca-info-hover
                    info="Argue why you base your right to process this personal information on this/these legal ground(s)?"
                  ></ca-info-hover> -->
                </div>

                <div class="info-hover-form-field">
                  <mat-form-field class="stretch">
                    <mat-label i18n="@@transfers-outside-eea"
                      >Transfers outside the EEA</mat-label
                    >
                    <mat-select formControlName="transferEEAIds" multiple>
                      <mat-option
                        *ngFor="let dl of transfersOutsideEEA"
                        [value]="dl.id"
                        >{{ dl.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <!-- <ca-info-hover
                    info="Do you transfer this data outside the EEA?"
                  ></ca-info-hover> -->
                </div>

                <div class="info-hover-form-field">
                  <mat-form-field class="stretch">
                    <mat-label i18n="@@clarify-transfers-outside-eea"
                      >Clarify transfer outside the EEA</mat-label
                    >
                    <textarea
                      matInput
                      formControlName="transferEEAClarification"
                    ></textarea>
                  </mat-form-field>
                  <!-- <ca-info-hover
                    info="Argue your right to transfer this personal information to this/these countries outside the EEA?"
                  ></ca-info-hover> -->
                </div>

                <!-- TODO: ADD MEDIA UPLOAD -->
              </form>
            </div>
          </ng-container>
        </ng-container>

        <div>
          <a
            class="cancel-link cancel"
            style="margin-right: 0.66em"
            mat-dialog-close
            i18n="@@cancel-btn"
          >
            Cancel
          </a>
          <button mat-button matStepperPrevious i18n="@@back-btn">BACK</button>
          <button
            mat-button
            matStepperNext
            [disabled]="!RoPAGroupFormArray.valid"
            i18n="@@next-btn"
          >
            NEXT
          </button>
        </div>
      </mat-step>

      <mat-step i18n-label="@@review-step-label" label="Review">
        <ca-add-subject-register-overview
          *ngIf="stepper.selectedIndex === 2"
          [upsertData]="UpsertData"
        ></ca-add-subject-register-overview>

        <div class="actions">
          <a
            class="cancel-link cancel"
            style="margin-right: 0.66em"
            mat-dialog-close
            i18n="@@cancel-btn"
          >
            Cancel
          </a>
          <button mat-button matStepperPrevious i18n="@@back-btn">BACK</button>
          <button
            mat-raised-button
            i18n="@@add-subject-btn"
            (click)="addDataSubjectTypeRegister()"
          >
            ADD SUBJECT
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </mat-dialog-content>
</div>
