import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AddCategoryFromRegister,
  DeleteRoPAGroup,
  DeleteRoPAGroupSuccess,
  DeleteRoPARow,
  DeleteRoPARowSuccess,
  ExportDataRegister,
  LoadDataRegister,
  LoadDataRegisterSuccess,
  UpsertCategoryFromRegister,
  UpsertDataSubjectPurposeFromRegister,
  UpsertRoPAGroup,
  UpsertRoPAGroupSuccess,
  UpsertRoPARow,
  UpsertRoPARowSuccess,
} from '../actions/data-register.actions';
import { firstValueFrom, map, switchMap } from 'rxjs';
import { CaDataResponse, DeleteRequest, PutResult } from '@ca/ca-data';
import { HttpResponse } from '@angular/common/http';
import {
  ProcessingActivityGroups,
  UpsertControllerRoPAGroup,
  UpsertControllerRoPARow,
  UpsertProcessorRoPAGroup,
  UpsertProcessorRoPARow,
} from '@ca/gdpr';
import { Store } from '@ngrx/store';
import { GdprToolState, selectDataRegisterForExport } from '..';
import { ERROR_SOURCES } from '../../config/errors';
import { abortDelete } from '../actions/general.actions';
import { MatDialog } from '@angular/material/dialog';
import { CaSubscriber } from '@ca/ca-utils';
import { ProcessingActivityGroupsService } from '../../services/data-register/processing-activity-groups.service';
import {
  IUpsertDataCategoryDialogData,
  UpsertDataCategoryInRegisterComponent,
} from '../../components/data-register/upsert-data-category-in-register/upsert-data-category-in-register.component';
import { UpsertPurposeInRegisterComponent } from '../../components/data-register/upsert-purpose-in-register/upsert-purpose-in-register.component';
import { ProcessingActivitiesService } from '../../services/data-register/processing-activities.service';
import { ExportService } from '../../services/export/export.service';
import * as moment from 'moment';
import { NotificationFactory, QueueNotification } from '@ca/ca-ng-core';

@Injectable()
export class DataRegisterEffects implements OnDestroy {
  private nf = new NotificationFactory(ERROR_SOURCES.dataRegister);
  private sub = new CaSubscriber();
  constructor(
    private actions$: Actions,
    private processingActivityGroups: ProcessingActivityGroupsService,
    private processingActivityRows: ProcessingActivitiesService,
    private store: Store<GdprToolState>,
    protected dialog: MatDialog,
    protected exporter: ExportService
  ) {
    this.sub.subscribe(
      this.actions$.pipe(ofType(UpsertDataSubjectPurposeFromRegister)),
      {
        next: (data) => {
          this.dialog.open(UpsertPurposeInRegisterComponent, { data });
        },
      }
    );

    this.sub.subscribe(
      this.actions$.pipe(
        ofType(AddCategoryFromRegister, UpsertCategoryFromRegister)
      ),
      {
        next: (data: IUpsertDataCategoryDialogData) => {
          this.dialog.open(UpsertDataCategoryInRegisterComponent, { data });
        },
      }
    );

    this.sub.subscribe(
      this.actions$.pipe(ofType(UpsertRoPAGroupSuccess, UpsertRoPARowSuccess)),
      {
        next: () => this.store.dispatch(LoadDataRegister()),
      }
    );
    this.sub.subscribe(this.actions$.pipe(ofType(ExportDataRegister)), {
      next: ({ role }) => {
        firstValueFrom(
          this.store.select(selectDataRegisterForExport(role))
        ).then((res) => {
          this.exporter.exportToCsv(
            res,
            `[ORGANIZATION NAME] Data Register ${moment().format(
              'DD-MM-YYYY HHmm'
            )}`
          );
        });
      },
    });
  }

  onDeleteRoPAGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRoPAGroup),
      switchMap((value: DeleteRequest) =>
        this.processingActivityGroups
          .delete(value.id)
          .then((res) =>
            res
              ? DeleteRoPAGroupSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError(
                'processing activity group (purpoes)',
                'remove'
              )
            )
          )
      )
    )
  );
  onDeleteRoPARow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteRoPARow),
      switchMap((value: DeleteRequest) =>
        this.processingActivityRows
          .delete(value.id)
          .then((res) =>
            res
              ? DeleteRoPARowSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError(
                'processing activity row (data category)',
                'remove'
              )
            )
          )
      )
    )
  );
  //#region V2
  onLoadDataRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDataRegister),
      switchMap(() =>
        this.processingActivityGroups.get().pipe(
          map(
            (
              res: HttpResponse<
                CaDataResponse<ProcessingActivityGroups | undefined>
              >
            ) => {
              return res && res.ok && res.body != null && res.body.data
                ? LoadDataRegisterSuccess({ ropa: res.body.data })
                : QueueNotification(
                    this.nf.createError('Data Register', 'get')
                  );
            }
          )
        )
      )
    )
  );

  onUpsertRoPAGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRoPAGroup),
      switchMap((value: UpsertProcessorRoPAGroup | UpsertControllerRoPAGroup) =>
        firstValueFrom(this.processingActivityGroups.upsert(value))
          .then(
            (res: HttpResponse<PutResult<'put-processing-activity-groups'>>) =>
              res.ok && res.body && res.body.success
                ? UpsertRoPAGroupSuccess({
                    request: value,
                    response: res.body,
                  })
                : QueueNotification(
                    this.nf.createError('processing activity group', 'upsert')
                  )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('processing activity group', 'upsert')
            )
          )
      )
    )
  );

  onUpsertRoPARow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertRoPARow),
      switchMap((value: UpsertProcessorRoPARow | UpsertControllerRoPARow) =>
        firstValueFrom(this.processingActivityRows.upsert(value))
          .then((res: HttpResponse<PutResult<'put-processing-activities'>>) =>
            res.ok && res.body && res.body.success
              ? UpsertRoPARowSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError(
                    'processing activity row (data category)',
                    'upsert'
                  )
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError(
                'processing activity row (data category)',
                'upsert'
              )
            )
          )
      )
    )
  );
  //#endregion
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
