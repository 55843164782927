import { createReducer, on } from '@ngrx/store';
import {
  CompanySettings,
  DataLocation,
  DisclosedTo,
  ExternalProcessor,
  LegalGround,
  SecurityMeasure,
  DataSubjectType,
  TransferOutsideEEA,
  ProcessingPurpose,
  DataCategory,
} from '@ca/gdpr';

// import {
//   DeleteEmailTemplateSuccess,
//   LoadEmailTemplatesSuccess,
// } from '../actions/e-mail-templates.actions';
import { onboardingActions } from '@ca/onboarding';
import {
  loadDataLocationsSuccess,
  loadExternalProcessorsSucces,
  loadDataSubjectTypesSucces,
  LoadExternalControllersSuccess,
  loadSecurityMeasuresSucces,
  loadDisclosedToSuccess,
  loadLegalGroundsSuccess,
  deleteDataLocationSuccess,
  deleteExternalProcessorSuccess,
  deleteSubjectTypeSuccess,
  DeleteExternalControllerSuccess,
  deleteSecurityMeasureSuccess,
  loadTransfersOutsideEEASuccess,
  deleteDisclosedToSuccess,
  deleteTransferOutsideEEASuccess,
  deleteLegalGroundSuccess,
  loadProcessingPurposesSuccess,
  deleteProcessingPurposeSuccess,
  loadDataCategoriesSuccess,
  deleteDataCategorySuccess,
  upsertSecurityMeasureInlineSuccess,
  upsertDisclosedToInlineSuccess,
  upsertProcessingPurposeInlineSuccess,
  removeFormUpsertInlineQueue,
  upsertDataLocationInlineSuccess,
} from '../actions/settings.actions';
import { LoadCompanySettingsSuccess } from '../actions/company-settings.actions';
import { HumidorEmailTemplate } from '../../models/e-mail-templates.models';
import { ExternalController } from '@ca/gdpr';
import { IndexedName } from '@ca/ca-utils';
import { ReplyToAddress } from '@ca/ca-mailing';

export type InlineInsertedType =
  | 'security-measure'
  | 'data-location'
  | 'disclosed-to'
  | 'data-category'
  | 'processing-purpose';

export type InlineInsertedResource = {
  resource: InlineInsertedType;
  id: number;
  originId: string;
};

export type UpsertInline<T extends IndexedName> = {
  upsert: T;
  originId: string;
};

export interface SettingsState {
  inlineInserted?: InlineInsertedResource[];
  companySettings?: CompanySettings;
  emailTemplates?: HumidorEmailTemplate[];

  dataCategories?: DataCategory[];
  dataLocations?: DataLocation[];
  disclosedTo?: DisclosedTo[];
  replyToAddresses?: ReplyToAddress[];
  externalProcessors?: ExternalProcessor[];
  externalControllers?: ExternalController[];
  legalGrounds?: LegalGround[];
  processingPurposes?: ProcessingPurpose[];
  securityMeasures?: SecurityMeasure[];
  dataSubjectTypes?: DataSubjectType[];
  transfersOutsideEEA?: TransferOutsideEEA[];
}
// TODO: move to actions/settings.ts

export const initialState: SettingsState = {};

export const reducer = createReducer(
  initialState,
  //#region LOAD
  on(loadDataLocationsSuccess, (_, { dataLocations }) => ({
    ..._,
    dataLocations: dataLocations,
  })),
  on(loadExternalProcessorsSucces, (_, { externalProcessors }) => ({
    ..._,
    externalProcessors: externalProcessors,
  })),
  on(loadDataSubjectTypesSucces, (_, { subjectTypes }) => ({
    ..._,
    dataSubjectTypes: subjectTypes,
  })),
  on(LoadExternalControllersSuccess, (_, { externalControllers }) => ({
    ..._,
    externalControllers,
  })),
  on(loadSecurityMeasuresSucces, (_, { securityMeasures }) => ({
    ..._,
    securityMeasures,
  })),
  // on(LoadEmailTemplatesSuccess, (_, { templates }) => ({
  //   ..._,
  //   emailTemplates: templates,
  // })),
  on(loadDisclosedToSuccess, (_, { disclosedTo }) => ({
    ..._,
    disclosedTo,
  })),
  on(loadLegalGroundsSuccess, (_, { legalGrounds }) => ({
    ..._,
    legalGrounds,
  })),
  on(loadTransfersOutsideEEASuccess, (_, { transfersOutsideEEA }) => ({
    ..._,
    transfersOutsideEEA: transfersOutsideEEA,
  })),
  on(LoadCompanySettingsSuccess, (_, { companySettings }) => ({
    ..._,
    companySettings: companySettings,
  })),
  on(loadProcessingPurposesSuccess, (_, { processingPurposes }) => ({
    ..._,
    processingPurposes,
  })),

  on(loadDataCategoriesSuccess, (_, { dataCategories }) => ({
    ..._,
    dataCategories,
  })),
  //#endregion

  //#region DELETE
  on(deleteDataLocationSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          dataLocations: _.dataLocations?.filter((e) => e.id !== request.id),
        }
      : _
  ),
  on(deleteExternalProcessorSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          externalProcessors: _.externalProcessors?.filter(
            (e) => e.id !== request.id
          ),
        }
      : _
  ),
  on(deleteSubjectTypeSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          dataSubjectTypes: _.dataSubjectTypes?.filter(
            (e) => e.id !== request.id
          ),
        }
      : _
  ),
  on(DeleteExternalControllerSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          externalControllers: _.externalControllers?.filter(
            (e) => e.id !== request.id
          ),
        }
      : _
  ),
  on(deleteSecurityMeasureSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          securityMeasures: _.securityMeasures?.filter(
            (e) => e.id !== request.id
          ),
        }
      : _
  ),
  // on(DeleteEmailTemplateSuccess, (_, { request, success }) =>
  //   success
  //     ? {
  //         ..._,
  //         emailTemplates: _.emailTemplates?.filter((e) => e.id !== request.id),
  //       }
  //     : _
  // ),
  on(deleteDisclosedToSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          disclosedTo: _.disclosedTo?.filter((e) => e.id !== request.id),
        }
      : _
  ),

  on(deleteTransferOutsideEEASuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          transfersOutsideEEA: _.transfersOutsideEEA?.filter(
            (e) => e.id !== request.id
          ),
        }
      : _
  ),
  on(deleteLegalGroundSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          legalGrounds: _.legalGrounds?.filter((e) => e.id !== request.id),
        }
      : _
  ),
  on(deleteProcessingPurposeSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          processingPurposes: _.processingPurposes?.filter(
            (e) => e.id !== request.id
          ),
        }
      : _
  ),
  on(deleteDataCategorySuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          dataCategories: _.dataCategories?.filter((e) => e.id !== request.id),
        }
      : _
  ),
  //#endregion

  //#region UPSERT INLINE
  // TODO: make more generic and use type to determine which resource list to load
  on(upsertProcessingPurposeInlineSuccess, (_, { request, response }) => ({
    ..._,
    inlineInserted: (_.inlineInserted
      ? [
          ..._.inlineInserted,
          {
            id: response.id,
            resource: 'processing-purpose',
            originId: request.originId,
          },
        ]
      : [
          {
            id: response.id,
            resource: 'processing-purpose',
            originId: request.originId,
          },
        ]) as InlineInsertedResource[],
  })),
  on(upsertSecurityMeasureInlineSuccess, (_, { request, response }) => ({
    ..._,
    inlineInserted: (_.inlineInserted
      ? [
          ..._.inlineInserted,
          {
            id: response.id,
            resource: 'security-measure',
            originId: request.originId,
          },
        ]
      : [
          {
            id: response.id,
            resource: 'security-measure',
            originId: request.originId,
          },
        ]) as InlineInsertedResource[],
  })),
  on(upsertDisclosedToInlineSuccess, (_, { request, response }) => ({
    ..._,
    inlineInserted: (_.inlineInserted
      ? [
          ..._.inlineInserted,
          {
            id: response.id,
            resource: 'disclosed-to',
            originId: request.originId,
          },
        ]
      : [
          {
            id: response.id,
            resource: 'disclosed-to',
            originId: request.originId,
          },
        ]) as InlineInsertedResource[],
  })),
  on(upsertDataLocationInlineSuccess, (_, { request, response }) => ({
    ..._,
    inlineInserted: (_.inlineInserted
      ? [
          ..._.inlineInserted,
          {
            id: response.id,
            resource: 'data-location',
            originId: request.originId,
          },
        ]
      : [
          {
            id: response.id,
            resource: 'data-location',
            originId: request.originId,
          },
        ]) as InlineInsertedResource[],
  })),
  on(removeFormUpsertInlineQueue, (_, { originId }) => ({
    ..._,
    inlineInserted: _.inlineInserted
      ? _.inlineInserted.filter((e) => e.originId !== originId)
      : _.inlineInserted,
  })),
  //#endregion

  // TODO: use division name by default
  // on(onboardingActions.loadDivisionsSuccess, (_, { divisions }) =>
  //   _.companySettings?.name != undefined
  //     ? _
  //     : divisions.length >0 ? (): _
  // ),
  // LOGOUT
  on(onboardingActions.logout, () => initialState)
);
