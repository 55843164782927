import { Inject, Injectable } from '@angular/core';
import { CaDataResponse, CaDataService } from '@ca/ca-data';
import { MailLog } from '@ca/ca-mailing';
import { CaEnvironment } from '@ca/ca-utils';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { BaseMailService } from './base-mail.service';
import { Observable, map } from 'rxjs';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class MailLogsService extends BaseMailService {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, store);
  }
  getMailLogs() {
    return this.data
      .get<CaDataResponse<MailLog>>(
        `${this.baseUrl}/logs`,
        this.constructParams()
      )
      .pipe(map((data) => (data.ok && data.body ? data.body.data : [])));
  }
  getMailSchedule(): Observable<MailLog[]> {
    return this.data
      .get<CaDataResponse<MailLog[]>>(
        `${this.baseUrl}/scheduled`,
        this.constructParams()
      )
      .pipe(map((data) => (data.ok && data.body ? data.body.data : [])));
  }
  cancelScheduledMail(id: number) {
    return this.data.deleteSafely(
      `${this.baseUrl}/scheduled`,
      { id },
      this.constructParams()
    );
  }
}
