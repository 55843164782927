import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { OnboardingState } from '@ca/onboarding';
import {
  DSRStatus,
  DataSubjectRight,
  SupportedLanguageCode,
  UpsertDataSubjectRequest,
} from '@ca/gdpr';
import { upsertDataSubjectRequest } from '../../../../store/actions/dsr.actions';
import { GdprToolState } from '../../../../store';
import { DSR_CATEGORIES } from '../../../../config/dsr';
import { ProcedureCategory } from '@ca/gdpr';

import { GetInvokedRightLabel } from '../../../../utils/dsr';

@Component({
  selector: 'ca-upsert-data-subject-request-dialog',
  templateUrl: './upsert-data-subject-request-dialog.component.html',
  styles: [
    `
      .upsert-dsr {
        min-width: 60vw;
        .full-width {
          width: 100%;
        }
      }
    `,
  ],
})
export class UpsertDataSubjectRequestDialogComponent implements OnInit {
  fg?: FormGroup;
  maxDate: Date;
  dsrCategories: ProcedureCategory[] = DSR_CATEGORIES;
  title?: string;
  get requesterFG() {
    return this.fg?.get('requester') as FormGroup;
  }
  get requestFG() {
    return this.fg?.get('request') as FormGroup;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: UpsertDataSubjectRequest,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpsertDataSubjectRequestDialogComponent>,
    private store: Store<GdprToolState & OnboardingState>
  ) {
    this.maxDate = new Date();
    this.fg = this.fb.group({
      id: [this.data.id, Validators.required],
      // status: [this.data.status ?? 'Registered', Validators.required],
      requester: this.fb.group({
        firstName: [this.data.firstName, Validators.required],
        lastName: [this.data.lastName, Validators.required],
        email: [this.data.email, [Validators.required, Validators.email]],
        preferredLanguage: [this.data.preferredLanguage, Validators.required],
      }),
      request: this.fb.group({
        invokedRight: [this.data.invokedRight, Validators.required],
        requestedAt: [this.data.requestedAt, Validators.required],
        identityConfirmed: [this.data.identityConfirmed, Validators.required],
        context: [this.data.context, Validators.required],
        procedureId: [this.data.procedureId],
      }),
    });
  }
  ngOnInit(): void {
    this.title =
      this.data.id > 0
        ? $localize`:@@update-dsr:Update DSR`
        : $localize`:@@register-dsr:Register DSR`;
  }

  save() {
    if (this.fg) {
      const date = this.fg?.value['request']['requestedAt'] as Date;
      this.fg.value['request']['requestedAt'] =
        moment(date).format('DD/MM/YYYY');
      this.store.dispatch(
        upsertDataSubjectRequest(MapRawUpsertDSR(this.fg.value))
      );
      this.dialogRef.close();
    }
  }
  InvokedRightLabel = GetInvokedRightLabel;
}
export interface RawUpsertDataSubjectRequest {
  id: number;
  status: DSRStatus;
  requester: {
    email: string;
    firstName: string;
    lastName: string;
    preferredLanguage: SupportedLanguageCode;
  };
  request: {
    context: string;
    procedureId?: number;
    identityConfirmed: boolean;
    requestedAt: string;
    invokedRight: DataSubjectRight;
    cancelReason?: string;
  };
}
export const MapRawUpsertDSR: (
  raw: RawUpsertDataSubjectRequest
) => UpsertDataSubjectRequest = (raw) => ({
  id: raw.id,
  ...raw.request,
  ...raw.requester,
  status: raw.id > 0 ? raw.status : 'Registered',
});
