import * as moment from 'moment';
import { DSRContextKey } from '../config/table-names-for-linking';
import { DSR, DataSubjectRight } from '@ca/gdpr';

export function GetInvokedRightLabel(invokedRight: DataSubjectRight) {
  switch (invokedRight) {
    case 'right-to-be-forgotten':
      return 'right to be forgotten';
    case 'right-to-edit':
      return 'right to rectify';
    case 'right-to-information':
      return 'right to information';
    case 'right-to-insight':
      return 'right to insight';
    case 'right-to-limit-processing':
      return 'right to limit processing';
    case 'right-to-oppose':
      return 'right to oppose';
    case 'right-to-retract':
      return 'right to retract';
    case 'right-to-transfer':
      return 'right to transfer';
    default:
      return 'not specified';
  }
}
export function GetRemarksContextHeaderAndOrder(context: DSRContextKey) {
  switch (context) {
    case 'dsr.global':
      return { header: 'General', order: 0 };
    case 'dsr.confirmIdentity':
      return { header: 'Confirm Identity', order: 1 };
    case 'dsr.markRoPA':
      return { header: 'Mark RoPA Step', order: 2 };
    case 'dsr.processStep':
      return { header: 'Process Request Step', order: 3 };
    case 'dsr.completionStep':
      return { header: 'Completion Step', order: 4 };
    default:
      return {
        header: 'Not categorized',
        order: 100,
      };
  }
}
export function SortByNearestDeadline(a: DSR, b: DSR) {
  return moment(a.requestedAt, 'DD/MM/YYYY').diff(
    moment(b.requestedAt, 'DD/MM/YYYY')
  );
}

export function GetDSRDeadline(dsr: DSR, responseTimeInMonths = 1) {
  return dsr
    ? moment(dsr.requestedAt, 'DD/MM/YYYY')
        .add(responseTimeInMonths, 'months')
        .fromNow()
    : 'not specified...';
}
