import { Component, Input } from '@angular/core';
import { DataCategory } from '@ca/gdpr';
import { SelectorComponent } from '../selector-component';
import { GdprToolState, selectDataCategories } from '../../../../store';
import { Selector, Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ca-data-category-selector',
  template: `<ng-container *ngIf="label && formControlName && options">
    <div class="ca-selector">
      <mat-form-field>
        <mat-label>{{ label }}</mat-label>
        <mat-chip-grid
          #chipGrid
          [attr.aria-label]="label + ' selection'"
          [formControlName]="formControlName"
        >
          <mat-chip-row
            *ngFor="let selected of selectedItems"
            (removed)="remove(selected)"
          >
            {{ selected.name }}
            <button matChipRemove [attr.aria-label]="'remove ' + selected.name">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input
          [placeholder]="'Add ' + label + '...'"
          #fruitInput
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
        />

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint i18n="@@generic-type-or-create-message">
          Type to filter existing types or create a new {{ label }}
        </mat-hint>
      </mat-form-field>
    </div>
  </ng-container>`,
})
export class DataCategorySelectorComponent extends SelectorComponent<DataCategory> {
  @Input() override formGroup!: FormGroup;
  protected override label = $localize`:@@data-categories:Data Categories`;
  protected override ariaLabel = $localize`:@@data-category-input:Data Category Input`;
  protected override formControlName = 'dataCategoryIds';
  protected override selector: Selector<
    GdprToolState,
    DataCategory[] | undefined
  > = selectDataCategories;

  constructor(store: Store<GdprToolState>) {
    super(store);
    this.getData();
  }
}
