import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GdprToolState } from '../../../../store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CANCEL_REASONS } from '../../../../config/dsr';
import { OpenRejectOrCancelDSRDialogActionData } from '../../../../store/models';
import { rejectDSR, cancelDSR } from '../../../../store/actions/dsr.actions';
import { CancelReason } from '@ca/gdpr';

@Component({
  selector: 'ca-remove-data-subject-request-dialog',
  template: `
    <form [formGroup]="fg" class="ca-form cancel-dsr-form">
      <h1 mat-dialog-title i18n="@@cancel-dsr-title">Cancel DSR</h1>
      <div mat-dialog-content>
        <input type="text" formControlName="id" hidden />
        <div>
          <p i18n="@@cancel-dsr-warning">
            Beware: rejecting a DSR is only lawful under strict conditions. Be
            sure to have a good reason before rejecting a request.
          </p>
        </div>
        <div *ngIf="data.mode === 'reject'">
          <mat-form-field class="full-width">
            <mat-label i18n="@@select-lawful-reason"
              >Select a lawful reason</mat-label
            >
            <mat-select formControlName="lawfulReason">
              <mat-option
                *ngFor="let option of options"
                [value]="option"
                [matTooltip]="option.description"
                matTooltipPosition="right"
                >{{ option.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="full-width">
            <mat-label i18n="@@cancel-reason">Cancel reason</mat-label>
            <input matInput type="text" formControlName="cancelReason" />
          </mat-form-field>
        </div>
        <!-- TODO: add file upload for attachments to support cancellation -->
      </div>

      <div mat-dialog-actions align="end">
        <a
          class="cancel-link cancel"
          style="margin-right: 0.66em"
          mat-dialog-close
          i18n="@@abort-btn"
        >
          Abort
        </a>
        <button
          color="warn"
          [disabled]="fg.value['cancelReason'] === ''"
          mat-raised-button
          (click)="rejectOrCancelRequest()"
          i18n="@@reject-or-cancel-btn"
        >
          <mat-icon>block</mat-icon>
          {{ data.mode === 'reject' ? 'REJECT' : 'CANCEL' }} DSR
        </button>
      </div>
    </form>
  `,
})
export class RemoveDataSubjectRequestDialogComponent {
  public fg: FormGroup;
  protected options = Object.keys(CANCEL_REASONS).map((k) => CANCEL_REASONS[k]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected data: OpenRejectOrCancelDSRDialogActionData,
    private store: Store<GdprToolState>,
    private dialogRef: MatDialogRef<RemoveDataSubjectRequestDialogComponent>,
    private fb: FormBuilder
  ) {
    // Waarom deze fg?
    this.fg = this.fb.group({
      id: [data.dsr.id, Validators.required],
      lawfulReason: [
        null,
        this.data.mode === 'reject'
          ? Validators.required
          : Validators.nullValidator,
      ],
      cancelReason: [data.dsr.cancelReason, Validators.required],
    });
  }

  rejectOrCancelRequest() {
    const cancelReason = this.fg.get('cancelReason')?.value;
    const reason: CancelReason = this.fg.get('lawfulReason')?.value;
    const rejectionReason: string = reason.name + ' - ' + reason?.description;
    if (cancelReason)
      this.store.dispatch(
        this.data.mode === 'reject'
          ? rejectDSR({
              id: this.data.dsr.id,
              cancelReason: cancelReason,
              rejectionReason,
            })
          : cancelDSR({
              id: this.data.dsr.id,
              cancelReason: cancelReason,
            })
      );
    this.dialogRef.close(true);
  }
}
