<div class="upsert-remark-dialog">
  <h3 mat-dialog-title i18n="@@remarks-title">Remarks</h3>
  <form mat-dialog-content>
    <div *ngIf="remark">
      <mat-form-field style="width: 100%">
        <mat-label i18n="@@remark">Remark</mat-label>
        <textarea matInput rows="5" [formControl]="remark"></textarea>
      </mat-form-field>
    </div>
  </form>

  <div mat-dialog-actions align="end">
    <a
      class="cancel-link cancel"
      style="margin-right: 0.66em"
      mat-dialog-close
      i18n="@@cancel-btn"
    >
      Cancel
    </a>
    <button
      *ngIf="remark"
      [disabled]="remark.valid === false"
      mat-raised-button
      color="primary"
      (click)="saveRemark()"
      i18n="@@save-btn"
    >
      SAVE
    </button>
  </div>
</div>
