import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { NavLink } from './models/navigation.models';
import { APP_ROUTES } from './config/navigation';

import {
  RegisterComponent,
  ONBOARDING_APP_ROUTES,
  EditProfileComponent,
  LoginComponent,
  ResetPasswordComponent,
  SetPasswordComponent,
  AuthGuardService,
} from '@ca/onboarding';
import { HomeComponent } from './pages/home/home.component';
import { DataregisterComponent } from './components/data-register/data-register.component';
import { DSRComponent } from './components/dsr/dsr.component';
import { DataSubjectRequestDetailComponent } from './components/dsr/data-subject-request-detail/data-subject-request-detail.component';
// import { MailTemplatesComponent } from './components/app-settings/mail-templates/mail-templates.component';
import { DataSubjectTypeComponent } from './components/app-settings/data-subject-type/data-subject-type.component';
import { DataLocationsComponent } from './components/app-settings/data-locations/data-locations.component';
import { ExternalProcessorsComponent } from './components/app-settings/external-processors/external-processors.component';
import { SecurityMeasuresComponent } from './components/app-settings/security-measures/security-measures.component';
import { LegalGroundsComponent } from './components/app-settings/legal-grounds/legal-grounds.component';
import { TransfersOutsideEeaComponent } from './components/app-settings/transfers-outside-eea/transfers-outside-eea.component';
import { ProcedureCategoriesComponent } from './components/dsr/procedure-categories/procedure-categories.component';
import { ConfirmIdentityComponent } from './components/public/confirm-identity/confirm-identity.component';
import { DisclosedToComponent } from './components/app-settings/disclosed-to/disclosed-to.component';
import { CompanySettingsComponent } from './components/company-settings/company-settings.component';
import { ExternalControllersComponent } from './components/app-settings/external-controllers/external-controllers.component';
import { ProcessingPurposesComponent } from './components/app-settings/processing-purposes/processing-purposes.component';
import { DataCategoriesComponent } from './components/app-settings/data-categories/data-categories.component';
import { ControllerRegisterComponent } from './pages/controller-register/controller-register.component';
import { ProcessorRegisterComponent } from './pages/processor-register/processor-register.component';
import { ReplyToAddressesComponent } from './components/app-settings/reply-to-addresses/reply-to-addresses.component';
import { QuestionnaireComponent } from './components/data-register/questionnaire/questionnaire.component';

const ONBOARDING_ROUTES: Routes = [
  {
    path: ONBOARDING_APP_ROUTES.REGISTER,
    component: RegisterComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.ACCESS,
    component: SetPasswordComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.LOGIN,
    component: LoginComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.RESETPASS,
    component: ResetPasswordComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.PROFILE,
    component: EditProfileComponent,
  },
  {
    path: 'access',
    redirectTo: ONBOARDING_APP_ROUTES.ACCESS,
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: ONBOARDING_APP_ROUTES.LOGIN,
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    redirectTo: ONBOARDING_APP_ROUTES.RESETPASS,
    pathMatch: 'full',
  },
];

const PROTECTED_ROUTES: Routes = [
  {
    path: APP_ROUTES['dashboard'],
    title: 'dashboard',
    component: HomeComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dataCategories']}`,
    title: 'Data Categories',
    component: DataCategoriesComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dataLocations']}`,
    title: 'Data Locations',
    component: DataLocationsComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dataSubjectTypes']}`,
    title: 'Data Subject Types',
    component: DataSubjectTypeComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['legalGrounds']}`,
    title: 'Legal Grounds',
    component: LegalGroundsComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['replyToAddresses']}`,
    title: 'Reply-to Addresses',
    component: ReplyToAddressesComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['companySettings']}`,
    title: 'Company Settings',
    component: CompanySettingsComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['processors']}`,
    title: 'External Processors',
    component: ExternalProcessorsComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['controllers']}`,
    title: 'External Controllers',
    component: ExternalControllersComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['securityMeasures']}`,
    title: 'Security Measures',
    component: SecurityMeasuresComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['processingPurposes']}`,
    title: 'Processing Purposes',
    component: ProcessingPurposesComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['transfersOutsideEEA']}`,
    title: 'Transfers Outside EEA',
    component: TransfersOutsideEeaComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['departments']}`,
    title: 'Entities',
    component: DisclosedToComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dataregister']}`,
    title: 'Dataregister',
    component: DataregisterComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dataregisterController']}`,
    title: 'Controller Dataregister',
    component: ControllerRegisterComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dataregisterProcessor']}`,
    title: 'Processor Dataregister',
    component: ProcessorRegisterComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dsrs']}`,
    title: 'Requests',
    component: DSRComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['procedureCategories']}`,
    title: 'Procedures',
    component: ProcedureCategoriesComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['procedures']}/:id`,
    title: 'Edit Procedure',
    component: DataSubjectRequestDetailComponent,
  },
  {
    path: `dashboard/${APP_ROUTES['dataRegisterQuestionnaire']}`,
    title: 'Getting started',
    component: QuestionnaireComponent,
  },
];

const PUBLIC_ROUTES: Routes = [
  {
    path: 'public/dsr',
    title: 'Confirm Identity',
    component: ConfirmIdentityComponent,
  },
  {
    path: 'public/dsr-qna',
    title: 'DSR Questions',
    component: ConfirmIdentityComponent,
  },
];

export const getDashboardRouteByUrlSegment = (segment: string) =>
  `${APP_ROUTES['dashboard']}/${segment}`;

export const getDashboardRoute = (l: NavLink) =>
  l.link === APP_ROUTES['dashboard']
    ? l.link
    : `${APP_ROUTES['dashboard']}/${l.link}`;

const routes: Routes = [
  ...ONBOARDING_ROUTES,
  ...PUBLIC_ROUTES,
  ...PROTECTED_ROUTES.map((e) => ({
    ...e,
    canActivate: [AuthGuardService],
  })),
  {
    path: '',
    redirectTo: ONBOARDING_APP_ROUTES.LOGIN,
    pathMatch: 'full',
  },
  {
    path: `**`,
    component: LoginComponent, // dashboard/${DASHBOARD_ROUTES.home}`
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
