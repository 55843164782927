import { Component, OnDestroy, OnInit } from '@angular/core';
import { ILinkedDivision, OnboardingState } from '../../../types';
import { OnboardingService } from '../../../services';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FEATURE_KEY, ONBOARDING_APP_ROUTES } from '../../../config';
import { CaSubscriber } from '@ca/ca-utils';
import { selectActiveDivision, selectUsersDivisions } from '../../../store';
import { setActiveDivision } from '../../../store/actions';

@Component({
  selector: 'ca-nav-bar-user-widget',
  template: ` <button
      mat-icon-button
      [matMenuTriggerFor]="userMenu"
      class="profile-button"
      i18n-aria-label="@@app-component-user-actions-aria-label"
      aria-label="User actions icon-button with account circle icon"
    >
      <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button
        *ngFor="let company of divisions"
        mat-menu-item
        [class.active]="company.id === activeDivision?.division?.id"
        (click)="selectCompany(company)"
      >
        {{ company.division.name }}
      </button>
    </mat-menu>

    <mat-menu #userMenu="matMenu">
      <ng-container *ngIf="hasMultipleDivisions === true">
        <button
          mat-menu-item
          class="ca-active-division-button"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>business</mat-icon>
          {{ activeDivision?.division?.name }}
        </button>
      </ng-container>

      <button
        mat-menu-item
        class="profile-button"
        i18n-aria-label="@@app-component-edit-profile-aria-label"
        aria-label="Edit user profile icon-button with account circle icon"
        (click)="editProfile()"
      >
        EDIT PROFILE
        <mat-icon>account_circle</mat-icon>
      </button>

      <button
        mat-menu-item
        class="logout-button"
        i18n-aria-label="Logout icon button|@@logout-btn"
        aria-label="Logout icon button"
        (click)="logout()"
      >
        LOG OUT
        <mat-icon>logout</mat-icon>
      </button>
    </mat-menu>`,
})
export class NavBarUserWidgetComponent implements OnDestroy, OnInit {
  protected divisions?: ILinkedDivision[];
  protected activeDivision?: ILinkedDivision;
  protected isLoggedIn = false;
  protected get hasMultipleDivisions() {
    return this.divisions != undefined ? this.divisions.length > 0 : false;
  }

  private sub = new CaSubscriber();

  constructor(
    private onboarding: OnboardingService,
    private router: Router,
    private store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {}

  ngOnInit(): void {
    this.onboarding.checkBearerToken().then((heartbeat) => {
      this.isLoggedIn = heartbeat;
    });
    this.sub.subscribe(this.store.select(selectActiveDivision), {
      next: (d) => (this.activeDivision = d),
    });

    this.sub.subscribe(this.store.select(selectUsersDivisions), {
      next: (d) => (this.divisions = d),
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  selectCompany(c: ILinkedDivision) {
    this.store.dispatch(setActiveDivision({ division: c }));
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  logout() {
    this.onboarding.logout(true);
  }

  editProfile() {
    this.router.navigate([ONBOARDING_APP_ROUTES.PROFILE]);
  }
}
