import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpsertDisclosedTo } from '@ca/gdpr';
import {
  upsertDisclosedTo,
  upsertDisclosedToInline,
} from '../../../../store/actions/settings.actions';
import { UpsertInlineDialogData } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-upsert-disclosed-to-dialog',
  template: `<h2 mat-dialog-title>
      {{ title }}
    </h2>
    <div mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg">
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for description|@@description"
              >Description</mat-label
            >
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="description"
            ></textarea>
          </mat-form-field>
        </div>
        <div>
          <mat-slide-toggle formControlName="external"
            >Is external?</mat-slide-toggle
          >
        </div>
      </form>
    </div>
    <mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="save()"
        [disabled]="!fg.valid"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-dialog-actions> `,
})
export class UpsertDisclosedToDialogComponent {
  get title() {
    return $localize`:@@create-or-edit-disclosed-to:${
      this.data.upsert.id > 0 ? 'Edit' : 'Create'
    } Disclosed To`;
  }
  fg: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected data: UpsertInlineDialogData<UpsertDisclosedTo>,
    private fb: FormBuilder,
    protected dialogRef: MatDialogRef<UpsertDisclosedToDialogComponent>,
    private store: Store
  ) {
    this.fg = fb.group({
      id: [data.upsert.id, Validators.required],
      name: [data.upsert.name, Validators.required],
      description: [data.upsert.description, Validators.required],
      external: [data.upsert.external, Validators.required],
    });
  }

  save() {
    if (
      this.data.inline &&
      this.data.inline === true &&
      this.data.originId != undefined
    )
      this.store.dispatch(
        upsertDisclosedToInline({
          upsert: this.fg.value,
          originId: this.data.originId,
        })
      );
    else if (this.data.inline && this.data.inline === true)
      throw new Error('Specify origin ID when using Inline Upsert!');
    else this.store.dispatch(upsertDisclosedTo(this.fg.value));
    this.dialogRef.close();
  }
}
