import {
  API_ROUTES,
  CHECK_TOKEN_INTERVAL,
  MIN_PASSWORD_LENGTH,
  DEFAULT_MESSAGES,
  ONBOARDING_APP_ROUTES,
} from '../../config';
import {
  IOnboardingRoutes,
  IOnboardingMessages,
  IOnboardingModuleConfiguration,
} from '../interfaces/configuration';
import { OnboardingAppRoutes } from '../types';

export class OnboardingModuleConfiguration
  implements IOnboardingModuleConfiguration
{
  /**
   * Onboarding module configuration
   * @param options - pass options to override
   */
  constructor(options: Partial<IOnboardingModuleConfiguration> = {}) {
    this.routes = {
      ...API_ROUTES,
      ...options.routes,
    };
    this.checkTokenIntervalMs =
      options.checkTokenIntervalMs ?? CHECK_TOKEN_INTERVAL;
    this.minPasswordLength = options.minPasswordLength ?? MIN_PASSWORD_LENGTH;
    this.messages = {
      ...DEFAULT_MESSAGES,
      ...options.messages,
    };
    this.appRoutes = {
      register: ONBOARDING_APP_ROUTES.REGISTER,
      login: options.appRoutes?.login ?? ONBOARDING_APP_ROUTES.LOGIN,
      resetPassword:
        options.appRoutes?.resetPassword ?? ONBOARDING_APP_ROUTES.RESETPASS,
      access: options.appRoutes?.access ?? ONBOARDING_APP_ROUTES.ACCESS,
      editProfile:
        options.appRoutes?.editProfile ?? ONBOARDING_APP_ROUTES.PROFILE,
      redirectOnSuccess:
        options.appRoutes?.redirectOnSuccess ?? ONBOARDING_APP_ROUTES.REDIRECT,
    };
  }
  appRoutes: OnboardingAppRoutes;
  messages: IOnboardingMessages;
  minPasswordLength: number;
  checkTokenIntervalMs: number;
  routes: IOnboardingRoutes;
}
