<h1 class="info-title" i18n="@@reply-to-addresses-title">Reply-to Addresses</h1>
<ng-container *ngIf="dataSource">
  <mat-form-field>
    <mat-label i18n="Form field search label|@@search">Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
        Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email">
        E-Mail
      </th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
            EDIT <mat-icon color="primary">edit</mat-icon>
          </button>
          <button mat-menu-item (click)="remove(element)" i18n="@@delete-btn">
            DELETE<mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>
<mat-paginator
  [pageSizeOptions]="[5, 10, 25, 50]"
  aria-label="Select page of reply-to addresses"
></mat-paginator>
<button mat-raised-button (click)="add()" i18n="@@add-btn">
  <mat-icon>add</mat-icon> ADD
</button>
