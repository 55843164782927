import { Component, Inject, OnDestroy } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaSubscriber } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import { loadDivisions, loadRoles, upsertUser } from '../../../store/actions';
import {
  ILinkedDivision,
  IUserDivision,
  IUserProfile,
  IUserRole,
  Locale,
  OnboardingState,
  PutUpsertUserRequest,
} from '../../../types';
import {
  selectAccessLevel,
  selectUserDivisions,
  selectUserLinkedDivisions,
  selectUserRoles,
} from '../../../store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ca-upsert-user-dialog',
  templateUrl: './upsert-user-dialog.component.html',
  styleUrls: ['./upsert-user-dialog.component.scss'],
})
export class UpsertUserDialogComponent implements OnDestroy {
  private sub = new CaSubscriber();
  fg: FormGroup;
  private loadedDivisions = false;
  private loadedRoles = false;
  userDivisions$: Observable<ILinkedDivision[] | undefined>;
  accessLevel = 1000;

  divisionsForSelect: IUserDivision[] = [];
  rolesForSelect: IUserRole[] = [];
  languagesForSelect: Locale[] = ['en', 'nl', 'fr'];

  get divisions(): FormArray {
    return this.fg.get('linkedDivisions') as FormArray;
  }

  get toggle2FALabel() {
    return this.data.twoFaEnabled
      ? $localize`:@@onboarding-disable-2FA-label:Disable Multifactor Authentication`
      : $localize`:@@onboarding-enable-2FA-label:Enable Multifactor Authentication`;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUserProfile,
    private fb: FormBuilder,
    private store: Store<{ onboarding: OnboardingState }>
  ) {
    this.userDivisions$ = this.store.select(selectUserLinkedDivisions);

    this.sub.subscribe(this.store.select(selectAccessLevel), {
      next: (al?: number) => (this.accessLevel = al ?? 1000),
    });

    this.sub.subscribe(this.store.select(selectUserDivisions), {
      next: (res: IUserDivision[] | undefined) => {
        if (res === undefined && !this.loadedDivisions)
          this.store.dispatch(loadDivisions());
        else if (res) this.divisionsForSelect = res;
      },
    });

    this.sub.subscribe(this.store.select(selectUserRoles), {
      next: (res: IUserRole[] | undefined) => {
        if (res === undefined && !this.loadedRoles)
          this.store.dispatch(loadRoles());
        else if (res) this.rolesForSelect = res;
      },
    });

    this.fg = new FormGroup({
      id: new FormControl(data.id), // hidden
      firstName: new FormControl(data.firstName, [
        Validators.required,
        Validators.minLength(2),
      ]),
      lastName: new FormControl(data.lastName, [
        Validators.required,
        Validators.minLength(2),
      ]),
      email: new FormControl(data.email, [
        Validators.required,
        Validators.email,
        Validators.minLength(6),
      ]),
      gsm: new FormControl(data.gsm, [Validators.minLength(9)]),
      tel: new FormControl(data.tel, [Validators.minLength(9)]),
      linkedDivisions: new FormArray(
        data.linkedDivisions.map((e, i) =>
          this.fb.group({
            divisionsId: [e.division.id, Validators.required],
            rolesId: [e.role.id, Validators.required],
            jobTitle: [
              e.jobTitle,
              [Validators.required, Validators.minLength(3)],
            ],
          })
        ),
        [Validators.required]
      ),
      active: new FormControl(data.active),
      twoFaEnabled: new FormControl(data.twoFaEnabled),
      preferredLanguage: new FormControl(data.preferredLanguage, [
        Validators.required,
        Validators.maxLength(2),
      ]),
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  newDivision() {
    return this.fb.group({
      divisionsId: [null, Validators.required],
      rolesId: [null, Validators.required],
      jobTitle: [null, [Validators.required, Validators.minLength(3)]],
    });
  }

  addDivision(event: { preventDefault: () => void }) {
    event.preventDefault();
    this.divisions.push(this.newDivision());
  }

  removeDivision(i: number) {
    this.divisions.removeAt(i);
  }

  save() {
    this.fg.updateValueAndValidity({ emitEvent: true });
    if (this.fg.valid)
      this.store.dispatch(upsertUser(this.fg.value as PutUpsertUserRequest));
  }
}
