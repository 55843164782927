export const DISPLAYED_COLUMNS_CONTROLLER_ROPA_TABLE = [
  'actions',
  'category',
  'lifetimeMonths',
  'legalGrounds',
  'dataLocations',
  'disclosedTo',
  'transferOutsideEEA',
  'createdAt',
  'updatedAt',
];

export const DISPLAYED_COLUMNS_PROCESSOR_ROPA_TABLE = [
  'actions',
  'category',
  'controllers',
  'lifetimeMonths',
  'legalGrounds',
  'dataLocations',
  'disclosedTo',
  'transferOutsideEEA',
  'createdAt',
  'updatedAt',
];
