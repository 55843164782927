import { createReducer, on } from '@ngrx/store';
import {
  ClearNotifications,
  QueueNotification,
  UnqueueNotification,
} from './actions';

const BaseErrorMsg = {
  get: 'Could not get ',
  upsert: 'Could not store ',
  remove: 'Could not remove ',
};

export type ErrorBaseType = 'get' | 'upsert' | 'remove';

const createErrorMessage = (resourceName: string, type: ErrorBaseType) =>
  BaseErrorMsg[type] + resourceName;

export const CreateError: (
  message: string,
  source: string,
  type?: ErrorBaseType,
  lifetime?: number
) => { notification: CaNotification } = (
  message: string,
  source: string,
  type?: ErrorBaseType,
  lifetime?: number
) => {
  return {
    notification: {
      message: type ? createErrorMessage(message, type) : message,
      source,
      type: 'error',
      lifetime,
    },
  };
};

export const CreateInfo: (
  message: string,
  source: string,
  lifetime?: number
) => { notification: CaNotification } = (
  message: string,
  source: string,
  lifetime?: number
) => {
  return {
    notification: {
      message,
      source,
      type: 'info',
      lifetime,
    },
  };
};

export const CreateSuccess: (
  message: string,
  source: string,
  lifetime?: number
) => { notification: CaNotification } = (
  message: string,
  source: string,
  lifetime?: number
) => {
  return {
    notification: {
      message,
      source,
      type: 'success',
      lifetime,
    },
  };
};

export const CreateWarning: (
  message: string,
  source: string,
  lifetime?: number
) => { notification: CaNotification } = (
  message: string,
  source: string,
  lifetime?: number
) => {
  return {
    notification: {
      message,
      source,
      type: 'warning',
      lifetime,
    },
  };
};

export class Factory {
  source: string;
  constructor(source = 'default') {
    this.source = source;
  }
  createError = (message: string, type?: ErrorBaseType, lifetime?: number) =>
    CreateError(message, this.source, type, lifetime);

  createInfo = (message: string, lifetime?: number) =>
    CreateInfo(message, this.source, lifetime);

  createSuccess = (message: string, lifetime?: number) =>
    CreateSuccess(message, this.source, lifetime);

  createWarning = (message: string, lifetime?: number) =>
    CreateWarning(message, this.source, lifetime);
}

export interface CaNotification {
  type: 'error' | 'warning' | 'success' | 'info';
  message: string;
  source: string;
  lifetime?: number;
  action?: string;
}

export interface NotificationsState {
  queue: CaNotification[];
}

export const initialState: NotificationsState = {
  queue: [],
};

export const reducer = createReducer(
  initialState,

  on(QueueNotification, (_, { notification }) => ({
    ..._,
    queue: [..._.queue, notification],
  })),
  on(UnqueueNotification, (_) => ({
    ..._,
    queue: _.queue.slice(1),
  })),
  on(ClearNotifications, () => initialState)
);
