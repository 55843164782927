export const ERROR_SOURCES = {
  dataLocations: 'Data Locations - GDPR Tool',
  externalProcessors: 'External Processors - GDPR Tool',
  dataRegister: 'Data Register - GDPR Tool',
  dsr: 'DSR - GDPR Tool',
  subjectTypes: 'Subject Types - GDPR Tool',
  legalBasis: 'Legal Basis - GDPR Tool',
  securityMeasures: 'Security Measures - GDPR Tool',
  disclosedTo: 'Disclosed To - GDPR Tool',
};
export const MISSING_INPUT_MESSAGE = (inputName: string) =>
  `Component input "${inputName}" must be specified.`;
