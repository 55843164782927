import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpsertDataCategoryActionData } from '@ca/gdpr';
import { upsertDataCategory } from '../../../../store/actions/settings.actions';

@Component({
  selector: 'ca-upsert-data-categories',
  template: ` <h2 mat-dialog-title>
      {{ title }}
    </h2>
    <div mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg">
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input
              matInput
              type="text"
              formControlName="name"
              placeholder="Contact Information"
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for description|@@description"
              >Description</mat-label
            >
            <textarea
              matInput
              type="text"
              formControlName="description"
              placeholder="The information that is necessary to contact an individual or organisation"
            ></textarea>
          </mat-form-field>
        </div>

        <div>
          <mat-slide-toggle
            formControlName="sensitive"
            i18n="
              Slide toggle label for Contains sensitive
              data|@@contains-sensitive-data-toggle"
            >Contains sensitive data</mat-slide-toggle
          >
        </div>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        type="button"
        color="primary"
        (click)="save()"
        [disabled]="!fg.valid"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </div>`,
})
export class UpsertDataCategoriesComponent {
  fg: FormGroup;

  get title() {
    return $localize`:@@create-or-edit-data-category-label:${
      this.data.id > 0 ? 'Edit' : 'Create'
    } Data Category`;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: UpsertDataCategoryActionData,
    private fb: FormBuilder,
    protected dialogRef: MatDialogRef<UpsertDataCategoriesComponent>,
    private store: Store
  ) {
    this.fg = fb.group({
      id: [data.id, Validators.required],
      name: [data.name, Validators.required],
      description: [data.description, Validators.required],
      sensitive: [data.sensitive, Validators.required],
    });
  }

  save() {
    this.store.dispatch(upsertDataCategory(this.fg.value));
    this.dialogRef.close();
  }
}
