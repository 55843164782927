import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadQuestions, LoadQuestionsSuccess } from '../actions/public.actions';
import { map, switchMap } from 'rxjs';
import { DsrDataSubjectQnaService } from '../../services/public/dsr-data-subject-qna.service';

@Injectable()
export class PublicEffects {
  constructor(
    private actions$: Actions,
    private svc: DsrDataSubjectQnaService
  ) {}

  onLoadQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadQuestions),
      switchMap((v: { uuid: string }) =>
        this.svc.getQnAViewModel(v.uuid).pipe(
          map((questions) => {
            return LoadQuestionsSuccess({ questions });
          })
        )
      )
    )
  );
}
