import { SettingsState } from '../../../store/reducers/settings.reducer';
import {
  AnswerVM,
  DynamicQuestionData,
  Question,
  QuestionVM,
  QuestionnaireConfig,
  StaticQuestionData,
} from './models';

// Factory functions

//#region dynamic data questions
export function DynamicSingleSelectQuestion(
  data: Omit<DynamicQuestionData, 'dynamic' | 'recordAnswer' | 'type'>,
  withOther = false
): DynamicQuestionData {
  return {
    ...data,
    dynamic: true,
    recordAnswer: true,
    type: withOther === true ? 'single/other' : 'single',
  };
}

export function DynamicMultiSelectQuestion(
  data: Omit<DynamicQuestionData, 'dynamic' | 'recordAnswer' | 'type'>,
  withOther = false
): DynamicQuestionData {
  return {
    ...data,
    dynamic: true,
    recordAnswer: true,
    type: withOther === true ? 'multi/other' : 'multi',
  };
}
//#endregion

//#region static data questions
export function StaticSingleSelectQuestion(
  data: Omit<StaticQuestionData, 'dynamic' | 'recordAnswer' | 'type'>
): StaticQuestionData {
  return {
    ...data,
    dynamic: false,
    recordAnswer: true,
    type: 'single',
  };
}

export function StaticMultiSelectQuestion(
  data: Omit<StaticQuestionData, 'dynamic' | 'recordAnswer' | 'type'>
): StaticQuestionData {
  return {
    ...data,
    dynamic: false,
    recordAnswer: true,
    type: 'multi',
  };
}

export function StaticABQuestion(
  data: Omit<StaticQuestionData, 'dynamic' | 'recordAnswer' | 'type'>
): StaticQuestionData {
  return {
    ...data,
    dynamic: false,
    recordAnswer: true,
    type: 'A/B',
  };
}

export function StaticABOtherQuestion(
  data: Omit<StaticQuestionData, 'dynamic' | 'recordAnswer' | 'type'>
): StaticQuestionData {
  return {
    ...data,
    dynamic: false,
    recordAnswer: true,
    type: 'A/B/other',
  };
}
//#endregion

// Mappers
const OTHER_OPTION: AnswerVM = {
  id: 'other',
  selected: false,
  text: 'Other',
  type: 'other',
  value: 'other',
  // onChosen: (question, answer) => {

  // }
};

export function extractQuestionData(
  config: QuestionnaireConfig,
  state: SettingsState
): QuestionVM[] {
  if (state === undefined) throw new Error('settingsState is not defined!');
  // TODO: check if list is loaded for each settings state key used.
  const mapped: QuestionVM[] = config.questions.map((q: Question) => {
    const isOtherType = q.type.includes('/other');
    const d: QuestionVM = q.dynamic
      ? {
          ...q,
          answers: isOtherType
            ? [
                ...(
                  state[q.settingsStateKey] as {
                    id: string | number;
                    name: string;
                  }[]
                ).map(
                  (o) =>
                    ({
                      id: o.id,
                      selected: false,
                      text: o.name,
                      type: 'predefined',
                    } as AnswerVM)
                ),
                OTHER_OPTION,
              ]
            : (
                state[q.settingsStateKey] as {
                  id: string | number;
                  name: string;
                }[]
              ).map(
                (o) =>
                  ({
                    id: o.id,
                    selected: false,
                    text: o.name,
                    type: 'predefined',
                  } as AnswerVM)
              ),
        }
      : {
          ...q,
          answers: isOtherType
            ? [
                ...q.answers.map((a) => ({
                  ...a,
                  selected: false,
                })),
                OTHER_OPTION,
              ]
            : q.answers.map((a) => ({
                ...a,
                selected: false,
              })),
        };
    return d;
  });

  return mapped;
}
