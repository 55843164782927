import { Component, Input, OnInit } from '@angular/core';
import {
  ControllerRoPARow,
  ProcessingActivityRole,
  ProcessorRoPARow,
} from '@ca/gdpr';
import { DeleteRoPARow } from '../../../store/actions/data-register.actions';
import { Store } from '@ngrx/store';
import { MISSING_INPUT_MESSAGE } from '../../../config/errors';
import {
  DISPLAYED_COLUMNS_CONTROLLER_ROPA_TABLE,
  DISPLAYED_COLUMNS_PROCESSOR_ROPA_TABLE,
} from '../../../config/data-register';
import { DispatchUpdateCategoryFromRegisterAction } from '../../../utils/data-register';
import { DataRegisterRoPAGroupVM } from '../../data-register/data-register.component';

@Component({
  selector: 'ca-data-register-table',
  template: ` <table
    mat-table
    [dataSource]="group.datasource"
    class="data-register-purpose-table"
  >
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-menu-item (click)="editRow(element)" color="primary">
          <mat-icon color="primary">edit</mat-icon>
          <!--<ng-container i18n="@@edit-btn">EDIT</ng-container>-->
        </button>
      </td>
      <td mat-cell *matCellDef="let element">
        <button mat-menu-item (click)="deleteRow(element)" color="primary">
          <mat-icon color="warn">delete</mat-icon>
          <!--<ng-container i18n="@@delete-btn">DELETE</ng-container>-->
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef i18n="@@category">Category</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dataCategory?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lifetimeMonths">
      <th mat-header-cell *matHeaderCellDef i18n="@@lifetime-months">
        Lifetime in months
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.lifetimeMonths }}
      </td>
    </ng-container>
    <ng-container matColumnDef="legalGrounds">
      <th mat-header-cell *matHeaderCellDef i18n="@@legal-grounds">
        Legal Grounds
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.legalGrounds | namedObjects }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dataLocations">
      <th mat-header-cell *matHeaderCellDef i18n="@@data-locations">
        Data Locations
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.dataLocations | namedObjects }}
      </td>
    </ng-container>
    <ng-container matColumnDef="disclosedTo">
      <th mat-header-cell *matHeaderCellDef i18n="@@disclosed-to">
        Disclosed To
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.disclosedTo | namedObjects }}
      </td>
    </ng-container>
    <ng-container matColumnDef="transferOutsideEEA">
      <th mat-header-cell *matHeaderCellDef i18n="@@transfers-outside-eea">
        Transfers Outside the EEA
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.transferOutsideEEA | namedObjects }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef i18n="@@created-at">Created At</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef i18n="@@updated-at">Updated At</th>
      <td mat-cell *matCellDef="let element">
        {{ element.updatedAt }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>`,
})
export class DataRegisterTableComponent implements OnInit {
  @Input() group!: DataRegisterRoPAGroupVM;
  @Input() role!: ProcessingActivityRole;
  constructor(private store: Store) {}
  displayedColumns: string[] = [];
  ngOnInit(): void {
    if (!this.group) throw new Error(MISSING_INPUT_MESSAGE('group'));
    if (!this.role) throw new Error(MISSING_INPUT_MESSAGE('role'));
    this.displayedColumns =
      this.role === 'controller'
        ? DISPLAYED_COLUMNS_CONTROLLER_ROPA_TABLE
        : DISPLAYED_COLUMNS_PROCESSOR_ROPA_TABLE;
  }
  editRow(ropaRow: ControllerRoPARow | ProcessorRoPARow) {
    // DispatchAddCategoryFromRegisterAction(this.store, )
    DispatchUpdateCategoryFromRegisterAction(
      this.store,
      this.group.base,
      ropaRow
    );
  }

  deleteRow(ropaRow: ControllerRoPARow | ProcessorRoPARow) {
    this.store.dispatch(DeleteRoPARow({ id: ropaRow.id }));
  }
}
