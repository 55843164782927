import { createAction, props } from '@ngrx/store';
import { GdprToolActionType } from '../action.types';
import {
  DeleteEmailTemplateResult,
  UpsertCompanySettingsResult,
  UpsertEmailTemplateResult,
} from '../models';
import { DeleteRequest } from '@ca/ca-data';
import {
  HumidorEmailTemplate,
  HumidorUpsertEmailTemplateData,
} from '../../models/e-mail-templates.models';
import { CompanySettings, UpsertCompanySettingsActionData } from '@ca/gdpr';

//#region COMPANY SETTINGS
export const LoadCompanySettings = createAction(
  GdprToolActionType.LoadCompanySettings
);
export const LoadCompanySettingsSuccess = createAction(
  GdprToolActionType.LoadCompanySettingsSuccess,
  props<{ companySettings: CompanySettings }>()
);
export const UpsertCompanySettings = createAction(
  GdprToolActionType.UpsertCompanySettings,
  props<UpsertCompanySettingsActionData>()
);
export const UpsertCompanySettingsSuccess = createAction(
  GdprToolActionType.UpsertCompanySettingsSuccess,
  props<UpsertCompanySettingsResult>()
);
//#endregion

//#region E-MAIL TEMPLATES
export const LoadEmailTemplates = createAction(
  GdprToolActionType.LoadEmailTemplates
);
export const LoadEmailTemplatesSuccess = createAction(
  GdprToolActionType.LoadEmailTemplatesSuccess,
  props<{ templates: HumidorEmailTemplate[] }>()
);
export const UpsertEmailTemplate = createAction(
  GdprToolActionType.UpsertEmailTemplates,
  props<{ upsert: HumidorUpsertEmailTemplateData }>()
);
export const UpsertEmailTemplateSuccess = createAction(
  GdprToolActionType.UpsertEmailTemplatesSuccess,
  props<UpsertEmailTemplateResult>()
);
export const DeleteEmailTemplate = createAction(
  GdprToolActionType.DeleteEmailTemplates,
  props<DeleteRequest>()
);
export const DeleteEmailTemplateSuccess = createAction(
  GdprToolActionType.DeleteEmailTemplatesSuccess,
  props<DeleteEmailTemplateResult>()
);
//#endregion
