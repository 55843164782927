import { Component } from '@angular/core';
// import { GuidedTourService } from 'ngx-guided-tour';
// import { dataRegisterAsControllerTour } from '../../tours';

@Component({
  selector: 'ca-controller-register',
  template: `<ca-dataregister role="controller"></ca-dataregister>
    <ngx-guided-tour></ngx-guided-tour>`,
})
export class ControllerRegisterComponent {
  // constructor(private tour: GuidedTourService) {
  //   setTimeout(() => {
  //     console.log('starting tour...');
  //     this.tour.startTour(dataRegisterAsControllerTour);
  //     this.tour.activateOrb();
  //   }, 1000);
  // }
}
