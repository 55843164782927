export enum GdprToolActionType {
  /**
   * Settings
   */

  //#region UpsertInline
  removeFormUpsertInlineQueue = '[UPSERT INLINE] Remove from Queue',
  //#endregion

  //#region ReplyToAddress
  LoadReplyToAddresses = '[REPLY TO ADDRESSES] Load Reply-to addresses',
  LoadReplyToAddressesSuccess = '[REPLY TO ADDRESSES] Load Reply-to addresses [Success]',
  UpsertReplyToAddress = '[REPLY TO ADDRESSES] Upsert Reply-to addresses',
  UpsertReplyToAddressSuccess = '[REPLY TO ADDRESSES] Upsert Reply-to addresses [Success]',
  DeleteReplyToAddress = '[REPLY TO ADDRESSES] Delete Reply-to addresses',
  DeleteReplyToAddressSuccess = '[REPLY TO ADDRESSES] Delete Reply-to addresses [Success]',
  //#endregion

  //#region Data Locations
  LoadDataLocations = '[DATA LOCATION] Load Data Locations',
  LoadDataLocationsSuccess = '[DATA LOCATION] Load Data Locations [Success]',
  LoadSingleDataLocation = '[SINGLE DATA LOCATION] Load Single Data Location',
  LoadSingleDataLocationSuccess = '[SINGLE DATA LOCATION] Load Single Data Location [Success]',
  UpsertDataLocation = '[DATA LOCATIONS] Upsert Data Location',
  UpsertDataLocationSuccess = '[DATA LOCATIONS] Upsert Data Location [Success]',
  UpsertDataLocationInline = '[DATA LOCATIONS] Upsert Data Location INLINE',
  UpsertDataLocationInlineSuccess = '[DATA LOCATIONS] Upsert Data Location INLINE [Success]',
  DeleteDataLocation = '[DATA LOCATIONS] Delete Data Location',
  DeleteDataLocationSuccess = '[DATA LOCATIONS] Delete Data Location [Success]',
  //#endregion

  //#region Disclosed To
  LoadDisclosedTo = '[DISCLOSED TO] Load Disclosed To',
  LoadDisclosedToSuccess = '[DISCLOSED TO] Load Disclosed To [Success]',
  UpsertDisclosedTo = '[DISCLOSED TO] Upsert Disclosed To',
  UpsertDisclosedToSuccess = '[DISCLOSED TO] Upsert Disclosed To [Success]',
  UpsertDisclosedToInline = '[DISCLOSED TO] Upsert Disclosed To INLINE',
  UpsertDisclosedToInlineSuccess = '[DISCLOSED TO] Upsert Disclosed To INLINE [Success]',
  DeleteDisclosedTo = '[DISCLOSED TO], Delete Disclosed To',
  DeleteDisclosedToSuccess = '[DISCLOSED TO] Delete Disclosed To [Success]',
  //#endregion
  //#region Company Settings
  LoadCompanySettings = '[COMPANY SETTINGS] Load Company Settings',
  LoadCompanySettingsSuccess = '[COMPANY SETTINGS] Load Company Settings [Success]',
  UpsertCompanySettings = '[COMPANY SETTINGS] Upsert Company Settings',
  UpsertCompanySettingsSuccess = '[COMPANY SETTINGS] Upsert Company Settings [Success]',
  //#endregion
  //#region External Processors
  LoadExternalProcessors = '[EXTERNAL PROCESSOR] Load External Processors',
  LoadExternalProcessorsSuccess = '[EXTERNAL PROCESSOR] Load External Processors [Success]',
  UpsertExternalProcessor = '[EXTERNAL PROCESSORS] Upsert External Processor',
  UpsertExternalProcessorSuccess = '[EXTERNAL PROCESSORS] Upsert External Processor [Success]',
  DeleteExternalProcessor = '[EXTERNAL PROCESSOR] Delete External Processor',
  DeleteExternalProcessorSuccess = '[EXTERNAL PROCESSOR] Delete External Processor [Success]',
  //#endregion
  //#region Data Subject Types
  LoadDataSubjectTypes = '[SUBJECT TYPE] Load Data Subject Types',
  LoadDataSubjectTypesSuccess = '[SUBJECT TYPE] Load Data Subject Types [Success]',
  UpsertDataSubjectType = '[SUBJECT TYPE] Upsert Data Subject Type',
  UpsertDataSubjectTypeSuccess = '[SUBJECT TYPE] Upsert Data Subject Type [Success]',
  DeleteDataSubjectType = '[SUBJECT TYPE] Delete Data Subject Type',
  DeleteDataSubjectTypeSuccess = '[SUBJECT TYPE] Delete Data Subject Type [Success]',
  //#endregion
  //#region External Controllers
  LoadExternalControllers = '[EXTERNAL CONTROLLERS] Load External Controllers',
  LoadExternalControllersSuccess = '[EXTERNAL CONTROLLERS] Load External Controllers [Success]',
  UpsertExternalController = '[EXTERNAL CONTROLLERS] Upsert External Controller',
  UpsertExternalControllerSuccess = '[EXTERNAL CONTROLLERS] Upsert External Controller [Success]',
  DeleteExternalController = '[EXTERNAL CONTROLLERS] Delete External Controllers',
  DeleteExternalControllerSuccess = '[EXTERNAL CONTROLLERS] Delete External Controller [Success]',
  //#endregion
  //#region DataCategories
  LoadDataCategories = '[DATA CATEGORIES] Load DataCategories',
  LoadDataCategoriesSuccess = '[DATA CATEGORIES] Load Data Categories [Success]',
  UpsertDataCategory = '[DATA CATEGORIES] Upsert Data Category',
  UpsertDataCategorySuccess = '[DATA CATEGORIES] Upsert Data Category [Success]',
  DeleteDataCategory = '[DATA CATEGORIES] Delete Data Categories',
  DeleteDataCategorySuccess = '[DATA CATEGORIES] Delete Data Category [Success]',
  //#endregion
  //#region Processing Purposes
  LoadProcessingPurposes = '[PROCESSING PURPOSES] Load Processing Purposes',
  LoadProcessingPurposesSuccess = '[PROCESSING PURPOSES] Load Processing Purposes [Success]',
  UpsertProcessingPurpose = '[PROCESSING PURPOSES] Upsert Processing Purpose',
  UpsertProcessingPurposeSuccess = '[PROCESSING PURPOSES] Upsert Processing Purpose [Success]',
  UpsertProcessingPurposeInline = '[PROCESSING PURPOSES] Upsert Processing Purpose INLINE',
  UpsertProcessingPurposeInlineSuccess = '[PROCESSING PURPOSES] Upsert Processing Purpose INLINE [Success]',
  DeleteProcessingPurpose = '[PROCESSING PURPOSES] Delete Processing Purposes',
  DeleteProcessingPurposeSuccess = '[PROCESSING PURPOSES] Delete Processing Purpose [Success]',
  //#endregion
  //#region Security Measures
  LoadSecurityMeasures = '[SECURITY MEASURE] Load Security Measures',
  LoadSecurityMeasuresSuccess = '[SECURITY MEASURE] Load Security Measures [Success]',
  UpsertSecurityMeasure = '[SECURITY MEASURE] Upsert Security Measure',
  UpsertSecurityMeasureInline = '[SECURITY MEASURE] Upsert Security Measure INLINE',
  UpsertSecurityMeasureSuccess = '[SECURITY MEASURE] Upsert Security Measure [Success]',
  UpsertSecurityMeasureInlineSuccess = '[SECURITY MEASURE] Upsert Security Measure INLINE [Success]',
  DeleteSecurityMeasure = '[SECURITY MEASURE] Delete Security Measure',
  DeleteSecurityMeasureSuccess = '[SECURITY MEASURE] Delete Security Measure [Success]',
  //#endregion
  //#region Legal Basis
  LoadLegalGrounds = '[LEGAL BASIS] Load Legal Grounds',
  LoadLegalGroundsSuccess = '[LEGAL BASIS] Load Legal Grounds [Success]',
  UpsertLegalGround = '[LEGAL BASIS] Upsert Legal Grounds',
  UpsertLegalGroundSuccess = '[LEGAL BASIS] Upsert Legal Grounds [Success]',
  DeleteLegalGround = '[LEGAL BASIS] Delete Legal Grounds',
  DeleteLegalGroundSuccess = '[LEGAL BASIS] Delete Legal Grounds [Success]',
  //#endregion
  //#region Transfers Outside EEA
  LoadTransfersOutsideEEA = '[Transfers Outside EEA] Load Transfers Outside EEA',
  LoadTransfersOutsideEEASuccess = '[Transfers Outside EEA] Load Transfers Outside EEA [Success]',
  UpsertTransfersOutsideEEA = '[Transfers Outside EEA] Upsert Transfers Outside EEA',
  UpsertTransfersOutsideEEASuccess = '[Transfers Outside EEA] Upsert Transfers Outside EEA [Success]',
  DeleteTransfersOutsideEEA = '[Transfers Outside EEA] Delete Transfers Outside EEA',
  DeleteTransfersOutsideEEASuccess = '[Transfers Outside EEA] Delete Transfers Outside EEA [Success]',

  /**
   * Data Register
   */
  //#endregion
  //#region Processing Activities
  LoadProcessingActivities = '[PROCESSING ACTIVITY] Load Processing Activities',
  LoadProcessingActivitiesSuccess = '[PROCESSING ACTIVITY] Load Processing Activities [Success]',
  LoadSingleProcessingActivity = '[PROCESSING ACTIVITY] Load Single Processing Activity',
  LoadSingleProcessingActivitySuccess = '[PROCESSING ACTIVITY] Load Single Processing Activity [Success]',
  UpsertProcessingActivity = '[PROCESSING ACTIVITY] Upsert Processing Activity',
  UpsertProcessingActivitySuccess = '[PROCESSING ACTIVITY] Upsert Processing Activity [Success]',
  DeleteProcessingActivity = '[PROCESSING ACTIVITY] Delete Processing Activity',
  DeleteProcessingActivitySuccess = '[PROCESSING ACTIVITY] Delete Processing Activity [Success]',
  expandProcessingActivityRow = '[EXPAND PROCESSING ACTIVITY] Expand Processing Activity',
  expandProcessingActivityRowSuccess = '[EXPAND PROCESSING ACTIVITY] Expand Processing Activity [Success]',
  InsertNewRoPA = '[PROCESSING ACTIVITIES] Insert New RoPA',
  //#endregion
  //#region Data Register
  //#region V2
  LoadDataRegister = '[DATA REGISTER] Load Data Register',
  LoadDataRegisterSuccess = '[DATA REGISTER] Load Data Register [Success]',

  LoadRoPADetail = '[DATA REGISTER] Load RoPA Detail',
  LoadRoPADetailSuccess = '[DATA REGISTER] Load RoPA Detail [Success]',

  UpsertRoPAGroup = '[DATA REGISTER] Upsert RoPA Group',
  UpsertRoPAGroupSuccess = '[DATA REGISTER] Upsert RoPA Group [Success]',
  DeleteRoPAGroup = '[DATA REGISTER] Delete RoPA Group',
  DeleteRoPAGroupSuccess = '[DATA REGISTER] Delete RoPA Group [Success]',

  UpsertRoPARow = '[DATA REGISTER] Upsert RoPA Row',
  UpsertRoPARowSuccess = '[DATA REGISTER] Upsert RoPA Row [Success]',
  DeleteRoPARow = '[DATA REGISTER] Delete RoPA Row',
  DeleteRoPARowSuccess = '[DATA REGISTER] Delete RoPA Row [Success]',
  CreateDataSubjectTypeFromRegister = '[DATA REGISTER] Create Data Subject Type in Data Register',
  AddDataSubjectPurposeFromRegister = '[DATA REGISTER] Add Processing Purpose for Data Subject Type (Data Register context)',
  UpsertDataSubjectPurposeFromRegister = '[DATA REGISTER] Upsert Processing Purpose Configuration for Data Subject Type (Data Register context)',

  AddCategoryFromRegister = '[DATA REGISTER] Add Data Category in Purpose from Data Register context',
  UpsertCategoryFromRegister = '[DATA REGISTER] Upsert Data Category in Purpose from Data Register context',
  ExportDataRegister = '[DATA REGISTER] Export Data Register',
  //#endregion
  //#endregion
  //#region DSR
  LoadDataSubjectRequests = '[DATA SUBJECT REQUEST] Load Data Subject Requests',
  LoadDataSubjectRequestsSuccess = '[DATA SUBJECT REQUEST] Load Data Subject Requests [Success]',

  UpsertDataSubjectRequest = '[DATA SUBJECT REQUEST] Upsert Data Subject Request',
  UpsertDataSubjectRequestSuccess = '[DATA SUBJECT REQUEST] Upsert Data Subject Request [Success]',

  LoadProcedures = '[PROCEDURE] Load Procedures',
  LoadProceduresSuccess = '[PROCEDURE] Load Procedures Success [Success]',
  LoadDSR = '[DSR] Load DSR',
  LoadDSRSuccess = '[DSR] Load DSR [Success]',

  ClearDSR = '[DSR] Clear DSR Detail',
  RegisterNewDSR = '[DSR] Register New DSR',

  ConfirmDataSubjectIdentity = '[DSR Identity] Confirm',
  ConfirmDataSubjectIdentitySuccess = '[DSR Identity] Confirm [Success]',
  OpenRejectOrCancelDSR = '[DSR] Open Reject or Cancel Dialog',
  ConfirmDataSubjectIdentityByMail = '[DSR Identity] Confirm by E-Mail',
  ConfirmDataSubjectIdentityByMailSuccess = '[DSR Identity] Confirm by E-Mail [Success]',
  RejectDSR = '[DSR] Reject DSR',
  RejectDSRSuccess = '[DSR] Reject DSR [Success]',
  CancelDSR = '[DSR] Cancel DSR',
  CancelDSRSuccess = '[DSR] Cancel DSR [Success]',
  DeleteDataSubjectRequest = '[DATA SUBJECT REQUEST] Delete Data Subject Request',
  DeleteDataSubjectRequestSuccess = '[DATA SUBJECT REQUEST] Delete Data Subject Request [Success]',
  //#endregion

  //#region File Uploads
  UploadFile = '[Files] Upload File',
  UploadFileSuccess = '[Files] Upload File [Success]',
  ClearUnlinkedMedia = '[Files] Clear Unlinked Media',
  //#endregion

  //#region Questions and Answers
  PoseQuestionToDataSubject = '[Questions and Answers] Pose Question to Data Subject',
  PoseQuestionToDataSubjectSuccess = '[Questions and Answers] Pose Question to Data Subject [Success]',
  //#endregion

  //#region E-Mail Templates
  LoadEmailTemplates = '[Mail Templates] Load E-Mail Templates',
  LoadEmailTemplatesSuccess = '[Mail Templates] Load E-Mail Templates [Success]',
  UpsertEmailTemplates = '[Mail Templates] Upsert E-Mail Templates',
  UpsertEmailTemplatesSuccess = '[Mail Templates] Upsert E-Mail Templates [Success]',
  DeleteEmailTemplates = '[Mail Templates] Delete E-Mail Templates',
  DeleteEmailTemplatesSuccess = '[Mail Templates] Delete E-Mail Templates [Success]',
  //#endregion

  //#region Mail
  LoadMailLogs = '[Mail Logs] Load E-Mail Logs',
  LoadMailLogsSuccess = '[Mail Logs] Load E-Mail Logs [Success]',
  SendMail = '[Mail] Send Mail',
  SendMailSuccess = '[Mail] Send Mail [Success]',
  ScheduleMail = '[Mail] Schedule Mail',
  ScheduleMailSuccess = '[Mail] Schedule Mail [Success]',
  CancelScheduledMail = '[Mail] Cancel Scheduled Mail',
  CancelScheduledMailSuccess = '[Mail] Cancel Scheduled Mail [Success]',
  //#endregion
}
