import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadDisclosedTo,
  loadDisclosedToSuccess,
  deleteDataLocation,
  deleteDataLocationSuccess,
  deleteExternalProcessor,
  deleteExternalProcessorSuccess,
  DeleteExternalController,
  DeleteExternalControllerSuccess,
  deleteSecurityMeasure,
  deleteSecurityMeasureSuccess,
  deleteSubjectType,
  deleteSubjectTypeSuccess,
  loadDataLocations,
  loadDataLocationsSuccess,
  loadExternalProcessors,
  loadExternalProcessorsSucces,
  LoadExternalControllers,
  LoadExternalControllersSuccess,
  loadSecurityMeasures,
  loadSecurityMeasuresSucces,
  loadDataSubjectTypes,
  loadDataSubjectTypesSucces,
  upsertDataLocation,
  upsertDataLocationSuccess,
  upsertExternalProcessor,
  upsertExternalProcessorSuccess,
  upsertSecurityMeasure,
  upsertSecurityMeasureSuccess,
  upsertSubjectType,
  upsertSubjectTypeSuccess,
  upsertDisclosedTo,
  upsertDisclosedToSuccess,
  loadLegalGrounds,
  loadLegalGroundsSuccess,
  loadTransfersOutsideEEA,
  loadTransfersOutsideEEASuccess,
  upsertTransferEEA,
  upsertTransferEEASuccess,
  upsertLegalGround,
  upsertLegalGroundSuccess,
  deleteDisclosedTo,
  deleteDisclosedToSuccess,
  deleteLegalGround,
  deleteLegalGroundSuccess,
  deleteTransfersOutsideEEA,
  deleteTransferOutsideEEASuccess,
  loadCompanySettings,
  upsertProcessingPurposeSuccess,
  loadProcessingPurposes,
  loadProcessingPurposesSuccess,
  upsertProcessingPurpose,
  deleteProcessingPurpose,
  deleteProcessingPurposeSuccess,
  deleteDataCategory,
  deleteDataCategorySuccess,
  upsertDataCategory,
  upsertDataCategorySuccess,
  loadDataCategories,
  loadDataCategoriesSuccess,
  UpsertExternalController,
  UpsertExternalControllerSuccess,
  upsertSecurityMeasureInline,
  upsertSecurityMeasureInlineSuccess,
  upsertDisclosedToInlineSuccess,
  upsertDisclosedToInline,
  upsertProcessingPurposeInline,
  upsertProcessingPurposeInlineSuccess,
  upsertDataLocationInline,
  upsertDataLocationInlineSuccess,
  deleteReplyToAddress,
  deleteReplyToAddressSuccess,
} from '../actions/settings.actions';
import { firstValueFrom, switchMap } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import {
  DataLocation,
  ExternalProcessor,
  DisclosedTo,
  SecurityMeasure,
  DataSubjectType,
  UpsertDataLocation,
  UpsertExternalProcessorActionData,
  UpsertSecurityMeasure,
  UpsertDataSubjectTypeActionData,
  UpsertDisclosedTo,
  LegalGround,
  TransferOutsideEEA,
  UpsertTransferOutsideEEAActionData,
  UpsertLegalGroundActionData,
  CompanySettings,
  ProcessingPurpose,
  UpsertProcessingPurposeActionData,
  UpsertDataCategoryActionData,
  DataCategory,
  UpsertCompanySettingsActionData,
} from '@ca/gdpr';
import { CaDataResponse, DeleteRequest, PutResult } from '@ca/ca-data';
import { DataLocationsService } from '../../services/settings/data-locations.service';
import { NotificationFactory, QueueNotification } from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';
import { ExternalProcessorsService } from '../../services/settings/external-processors.service';
import { SubjectTypeService } from '../../services/settings/subject-type.service';
import { SecurityMeasureService } from '../../services/settings/security-measure.service';
import { DisclosedToService } from '../../services/settings/disclosed-to.service';
import { LegalGroundsService } from '../../services/settings/legal-grounds.service';
// import {
//   DeleteEmailTemplate,
//   DeleteEmailTemplateSuccess,
//   LoadEmailTemplates,
//   LoadEmailTemplatesSuccess,
//   UpsertEmailTemplate,
//   UpsertEmailTemplateSuccess,
// } from '../actions/e-mail-templates.actions';
import { EMailTemplatesService } from '../../services/settings/e-mail-templates.service';
import { TransferOutsideEeaService } from '../../services/settings/transfer-outside-eea.service';
import { abortDelete } from '../actions/general.actions';
import {
  HumidorEmailTemplate,
  HumidorUpsertEmailTemplateData,
} from '../../models/e-mail-templates.models';
import { CompanySettingsService } from '../../services/settings/company-settings.service';
import {
  LoadCompanySettingsSuccess,
  UpsertCompanySettings,
  UpsertCompanySettingsSuccess,
} from '../actions/company-settings.actions';
import { ProcessingPurposeService } from '../../services/settings/processing-purpose.service';
import { DataCategoriesService } from '../../services/settings/data-categories.service';
import { ExternalControllersService } from '../../services/settings/external-controllers.service';
import {
  ExternalController,
  UpsertExternalControllerActionData,
} from '@ca/gdpr';
import { UpsertInline } from '../reducers/settings.reducer';
import { ReplyToAdressesService } from '../../services/settings/reply-to-adresses.service';

@Injectable()
export class SettingsEffects {
  private nf = new NotificationFactory('Settings');

  constructor(
    private actions$: Actions,
    private dataLocations: DataLocationsService,
    private externalProcessors: ExternalProcessorsService,
    private externalControllers: ExternalControllersService,
    private subjectTypes: SubjectTypeService,

    private securityMeasures: SecurityMeasureService,
    private disclosedTo: DisclosedToService,
    private legalGrounds: LegalGroundsService,
    private emailTemplates: EMailTemplatesService,
    private transferOutsideEEA: TransferOutsideEeaService,
    private companySettings: CompanySettingsService,
    private processingPurposes: ProcessingPurposeService,
    private replyToAddresses: ReplyToAdressesService,
    private dataCategories: DataCategoriesService,
    private store: Store
  ) {
    this.attachRefreshAfterUpsertObservers();
  }

  /**
   * Load
   */
  //#region LOAD
  onLoadDataLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDataLocations),
      switchMap(() => {
        return firstValueFrom(this.dataLocations.get())
          .then((res: HttpResponse<CaDataResponse<DataLocation[]>>) => {
            if (res.ok && res.body != null) {
              return loadDataLocationsSuccess({ dataLocations: res.body.data });
            }
            return QueueNotification(
              this.nf.createError('data locations', 'get')
            );
          })
          .catch(() => {
            return QueueNotification(
              this.nf.createError('data locations', 'get')
            );
          });
      })
    )
  );
  onLoadDisclosedTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDisclosedTo),
      switchMap(() =>
        firstValueFrom(this.disclosedTo.get())
          .then((res: HttpResponse<CaDataResponse<DisclosedTo[]>>) => {
            if (res.ok && res.body != null) {
              return loadDisclosedToSuccess({
                disclosedTo: res.body.data,
              });
            }
            return QueueNotification(
              this.nf.createError('disclosed to', 'get')
            );
          })
          .catch(() => {
            return QueueNotification(
              this.nf.createError('disclosed to', 'get')
            );
          })
      )
    )
  );
  onLoadExternalProcessors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadExternalProcessors),
      switchMap(() => {
        return firstValueFrom(this.externalProcessors.get())
          .then((res: HttpResponse<CaDataResponse<ExternalProcessor[]>>) => {
            if (res.ok && res.body != null) {
              return loadExternalProcessorsSucces({
                externalProcessors: res.body.data,
              });
            }
            return QueueNotification(
              this.nf.createError('external processors', 'get')
            );
          })
          .catch(() =>
            QueueNotification(this.nf.createError('external processors', 'get'))
          );
      })
    )
  );
  onLoadExternalControllers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadExternalControllers),
      switchMap(() => {
        return firstValueFrom(this.externalControllers.get())
          .then((res: HttpResponse<CaDataResponse<ExternalController[]>>) => {
            if (res.ok && res.body != null) {
              return LoadExternalControllersSuccess({
                externalControllers: res.body.data,
              });
            }
            return QueueNotification(
              this.nf.createError('external controller', 'get')
            );
          })
          .catch(() => {
            return QueueNotification(
              this.nf.createError('external controller', 'get')
            );
          });
      })
    )
  );
  onLoadSubjectTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDataSubjectTypes),
      switchMap(() =>
        firstValueFrom(this.subjectTypes.get())
          .then((res: HttpResponse<CaDataResponse<DataSubjectType[]>>) => {
            if (res.ok && res.body != null) {
              return loadDataSubjectTypesSucces({
                subjectTypes: res.body.data,
              });
            }
            return QueueNotification(
              this.nf.createError('subject types', 'get')
            );
          })
          .catch(() =>
            QueueNotification(this.nf.createError('subject types', 'get'))
          )
      )
    )
  );
  onLoadCompanySettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompanySettings),
      switchMap(() => {
        return firstValueFrom(this.companySettings.getCompanySettings())
          .then((res: HttpResponse<CaDataResponse<CompanySettings>>) =>
            res.ok && res.body != null
              ? LoadCompanySettingsSuccess({
                  companySettings: res.body.data,
                })
              : QueueNotification(
                  this.nf.createError('company settings', 'get')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('company settings', 'get'))
          );
      })
    )
  );
  onLoadSecurityMeasures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSecurityMeasures),
      switchMap(() => {
        return firstValueFrom(this.securityMeasures.get())
          .then((res: HttpResponse<CaDataResponse<SecurityMeasure[]>>) => {
            if (res.ok && res.body != null) {
              return loadSecurityMeasuresSucces({
                securityMeasures: res.body.data,
              });
            }
            return QueueNotification(
              this.nf.createError('security measures', 'get')
            );
          })
          .catch(() => {
            return QueueNotification(
              this.nf.createError('security measures', 'get')
            );
          });
      })
    )
  );
  // onLoadEmailTemplates$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoadEmailTemplates),
  //     switchMap(() =>
  //       firstValueFrom(this.emailTemplates.get())
  //         .then((res: HttpResponse<CaDataResponse<HumidorEmailTemplate[]>>) =>
  //           res.ok && res.body != null
  //             ? LoadEmailTemplatesSuccess({ templates: res.body.data })
  //             : QueueNotification(
  //                 this.nf.createError('e-mail templates', 'get')
  //               )
  //         )
  //         .catch(() =>
  //           QueueNotification(this.nf.createError('e-mail templates', 'get'))
  //         )
  //     )
  //   )
  // );
  onLoadLegalGrounds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLegalGrounds),
      switchMap(() =>
        firstValueFrom(this.legalGrounds.get())
          .then((res: HttpResponse<CaDataResponse<LegalGround[], string>>) =>
            res.ok && res.body != null
              ? loadLegalGroundsSuccess({ legalGrounds: res.body.data })
              : QueueNotification(this.nf.createError('legal grounds', 'get'))
          )
          .catch(() =>
            QueueNotification(this.nf.createError('legal grounds', 'get'))
          )
      )
    )
  );
  onLoadTransferEEA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransfersOutsideEEA),
      switchMap(() =>
        firstValueFrom(this.transferOutsideEEA.get())
          .then((res: HttpResponse<CaDataResponse<TransferOutsideEEA[]>>) =>
            res.ok && res.body != null
              ? loadTransfersOutsideEEASuccess({
                  transfersOutsideEEA: res.body.data,
                })
              : QueueNotification(
                  this.nf.createError('transfers outside EEA', 'get')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('transfers outside EEA', 'get')
            )
          )
      )
    )
  );
  onLoadProcessingPurposes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProcessingPurposes),
      switchMap(() =>
        firstValueFrom(this.processingPurposes.get())
          .then((res: HttpResponse<CaDataResponse<ProcessingPurpose[]>>) =>
            res.ok && res.body != null
              ? loadProcessingPurposesSuccess({
                  processingPurposes: res.body.data,
                })
              : QueueNotification(
                  this.nf.createError('processing purposes', 'get')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('processing purposes', 'get'))
          )
      )
    )
  );
  onLoadDataCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDataCategories),
      switchMap(() =>
        firstValueFrom(this.dataCategories.get())
          .then((res: HttpResponse<CaDataResponse<DataCategory[]>>) =>
            res.ok && res.body != null
              ? loadDataCategoriesSuccess({
                  dataCategories: res.body.data,
                })
              : QueueNotification(this.nf.createError('data categories', 'get'))
          )
          .catch(() =>
            QueueNotification(this.nf.createError('data categories', 'get'))
          )
      )
    )
  );
  //#endregion

  //#region UPSERT
  onUpsertDataLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertDataLocation),
      switchMap((value: UpsertDataLocation) =>
        firstValueFrom(this.dataLocations.upsert(value))
          .then((res: HttpResponse<PutResult<'put-data-locations'>>) =>
            res.ok && res.body && res.body.success
              ? upsertDataLocationSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('data locations', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('data locations', 'upsert'))
          )
      )
    )
  );
  onUpsertDataLocationInline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertDataLocationInline),
      switchMap((value: UpsertInline<UpsertDataLocation>) =>
        firstValueFrom(this.dataLocations.upsert(value.upsert))
          .then((res: HttpResponse<PutResult<'put-data-locations'>>) =>
            res.ok && res.body && res.body.success
              ? upsertDataLocationInlineSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('data locations', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('data locations', 'upsert'))
          )
      )
    )
  );
  onUpsertExternalProcessor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertExternalProcessor),
      switchMap((value: UpsertExternalProcessorActionData) =>
        firstValueFrom(this.externalProcessors.upsert(value))
          .then((res: HttpResponse<PutResult<'put-external-processor'>>) =>
            res.ok && res.body && res.body.success
              ? upsertExternalProcessorSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('external processors', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('external processors', 'upsert')
            )
          )
      )
    )
  );
  onUpsertDisclosedTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertDisclosedTo),
      switchMap((value: UpsertDisclosedTo) =>
        firstValueFrom(this.disclosedTo.upsert(value))
          .then((res: HttpResponse<PutResult<'put-disclosed-to'>>) =>
            res.ok && res.body && res.body.success
              ? upsertDisclosedToSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(this.nf.createError('disclosed to', 'upsert'))
          )
          .catch(() =>
            QueueNotification(this.nf.createError('disclosed to', 'upsert'))
          )
      )
    )
  );
  onUpsertDisclosedToInline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertDisclosedToInline),
      switchMap((value: UpsertInline<UpsertDisclosedTo>) =>
        firstValueFrom(this.disclosedTo.upsert(value.upsert))
          .then((res: HttpResponse<PutResult<'put-disclosed-to'>>) =>
            res.ok && res.body && res.body.success
              ? upsertDisclosedToInlineSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(this.nf.createError('disclosed to', 'upsert'))
          )
          .catch(() =>
            QueueNotification(this.nf.createError('disclosed to', 'upsert'))
          )
      )
    )
  );
  onUpsertSubjectType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertSubjectType),
      switchMap((value: UpsertDataSubjectTypeActionData) =>
        firstValueFrom(this.subjectTypes.upsert(value))
          .then((res: HttpResponse<PutResult<'put-data-subject-type'>>) =>
            res.ok && res.body && res.body.success
              ? upsertSubjectTypeSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('subject types', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('subject types', 'upsert'))
          )
      )
    )
  );
  onUpsertExternalController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertExternalController),
      switchMap((value: UpsertExternalControllerActionData) =>
        firstValueFrom(this.externalControllers.upsert(value))
          .then((res: HttpResponse<PutResult<'put-external-controllers'>>) =>
            res.ok && res.body && res.body.success
              ? UpsertExternalControllerSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('justification', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('justification', 'upsert'))
          )
      )
    )
  );
  onUpsertSecurityMeasure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertSecurityMeasure),
      switchMap((value: UpsertSecurityMeasure) =>
        firstValueFrom(this.securityMeasures.upsert(value))
          .then((res: HttpResponse<PutResult<'put-security-measures'>>) =>
            res.ok && res.body && res.body.success
              ? upsertSecurityMeasureSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('security measures', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('security measures', 'upsert')
            )
          )
      )
    )
  );
  onUpsertSecurityMeasureInline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertSecurityMeasureInline),
      switchMap((value: UpsertInline<UpsertSecurityMeasure>) =>
        firstValueFrom(this.securityMeasures.upsert(value.upsert))
          .then((res: HttpResponse<PutResult<'put-security-measures'>>) =>
            res.ok && res.body && res.body.success
              ? upsertSecurityMeasureInlineSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('security measures', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('security measures', 'upsert')
            )
          )
      )
    )
  );
  // onUpsertEmailTemplates$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UpsertEmailTemplate),
  //     switchMap((value: { upsert: HumidorUpsertEmailTemplateData }) =>
  //       firstValueFrom(this.emailTemplates.upsert(value.upsert))
  //         .then((res: HttpResponse<PutResult<'put-email-templates'>>) =>
  //           res.ok && res.body && res.body.success
  //             ? UpsertEmailTemplateSuccess({
  //                 request: value.upsert,
  //                 response: res.body,
  //               })
  //             : QueueNotification(
  //                 this.nf.createError('e-mail template', 'upsert')
  //               )
  //         )
  //         .catch(() =>
  //           QueueNotification(this.nf.createError('e-mail template', 'upsert'))
  //         )
  //     )
  //   )
  // );
  onUpsertLegalGround$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertLegalGround),
      switchMap((value: UpsertLegalGroundActionData) =>
        firstValueFrom(this.legalGrounds.upsert(value))
          .then((res: HttpResponse<PutResult<'put-legal-basis'>>) =>
            res.ok && res.body != null && res.body.success
              ? upsertLegalGroundSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(this.nf.createError('legal ground', 'upsert'))
          )
          .catch(() =>
            QueueNotification(this.nf.createError('legal ground', 'upsert'))
          )
      )
    )
  );
  onUpsertTransferOutsideEEA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertTransferEEA),
      switchMap((value: UpsertTransferOutsideEEAActionData) =>
        firstValueFrom(this.transferOutsideEEA.upsert(value))
          .then((res: HttpResponse<PutResult<'put-transfer-eea'>>) =>
            res.ok && res.body != null && res.body.success
              ? upsertTransferEEASuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('transfer outside EEA', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('transfer outside EEA', 'upsert')
            )
          )
      )
    )
  );
  onUpsertProcessingPurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertProcessingPurpose),
      switchMap((value: UpsertProcessingPurposeActionData) =>
        firstValueFrom(this.processingPurposes.upsert(value))
          .then((res: HttpResponse<PutResult<'put-processing-purpose'>>) =>
            res.ok && res.body != null && res.body.success
              ? upsertProcessingPurposeSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('processing purpose', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('processing purpose', 'upsert')
            )
          )
      )
    )
  );
  onUpsertProcessingPurposeInline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertProcessingPurposeInline),
      switchMap((value: UpsertInline<UpsertProcessingPurposeActionData>) =>
        firstValueFrom(this.processingPurposes.upsert(value.upsert))
          .then((res: HttpResponse<PutResult<'put-processing-purpose'>>) =>
            res.ok && res.body != null && res.body.success
              ? upsertProcessingPurposeInlineSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('processing purpose', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('processing purpose', 'upsert')
            )
          )
      )
    )
  );
  onUpsertDataCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertDataCategory),
      switchMap((value: UpsertDataCategoryActionData) =>
        firstValueFrom(this.dataCategories.upsert(value))
          .then((res: HttpResponse<PutResult<'put-data-categories'>>) =>
            res.ok && res.body != null && res.body.success
              ? upsertDataCategorySuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('data categories', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('data categories', 'upsert'))
          )
      )
    )
  );

  onUpsertCompanySettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertCompanySettings),
      switchMap((value: UpsertCompanySettingsActionData) =>
        firstValueFrom(this.companySettings.upsert(value))
          .then((res: HttpResponse<PutResult<'put-company-info'>>) =>
            res.ok && res.body != null && res.body.success
              ? UpsertCompanySettingsSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('data categories', 'upsert')
                )
          )
          .catch(() =>
            QueueNotification(this.nf.createError('data categories', 'upsert'))
          )
      )
    )
  );

  //#endregion

  //#region DELETE
  onDeleteDataLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDataLocation),
      switchMap((value: DeleteRequest) =>
        this.dataLocations
          .delete(value.id)
          .then((res) =>
            res
              ? deleteDataLocationSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(this.nf.createError('data locations', 'remove'))
          )
      )
    )
  );
  onDeleteExternalProcessor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteExternalProcessor),
      switchMap((value: DeleteRequest) =>
        this.externalProcessors
          .delete(value.id)
          .then((res) =>
            res
              ? deleteExternalProcessorSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('external processors', 'remove')
            )
          )
      )
    )
  );
  onDeleteSubjectType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSubjectType),
      switchMap((value: DeleteRequest) =>
        this.subjectTypes
          .delete(value.id)
          .then((res) =>
            res
              ? deleteSubjectTypeSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(this.nf.createError('subject type', 'remove'))
          )
      )
    )
  );
  onDeleteExternalController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteExternalController),
      switchMap((value: DeleteRequest) =>
        this.externalControllers
          .delete(value.id)
          .then((res) =>
            res
              ? DeleteExternalControllerSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('external-controllers', 'remove')
            )
          )
      )
    )
  );
  onDeleteSecurityMeasure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSecurityMeasure),
      switchMap((value: DeleteRequest) =>
        this.securityMeasures
          .delete(value.id)
          .then((res) =>
            res
              ? deleteSecurityMeasureSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('security measures', 'remove')
            )
          )
      )
    )
  );
  // onDeleteEmailTemplate$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DeleteEmailTemplate),
  //     switchMap((value: DeleteRequest) =>
  //       this.emailTemplates
  //         .delete(value.id)
  //         .then((res) =>
  //           res
  //             ? DeleteEmailTemplateSuccess({
  //                 request: value,
  //                 success: res,
  //               })
  //             : abortDelete()
  //         )
  //         .catch(() =>
  //           QueueNotification(this.nf.createError('e-mail templates', 'remove'))
  //         )
  //     )
  //   )
  // );
  onLegalGround$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLegalGround),
      switchMap((value: DeleteRequest) =>
        this.legalGrounds
          .delete(value.id)
          .then((res) =>
            res
              ? deleteLegalGroundSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(this.nf.createError('disclosed to', 'remove'))
          )
      )
    )
  );
  onDeleteDisclosedTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDisclosedTo),
      switchMap((value: DeleteRequest) =>
        this.disclosedTo
          .delete(value.id)
          .then((res) =>
            res
              ? deleteDisclosedToSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(this.nf.createError('disclosed to', 'remove'))
          )
      )
    )
  );
  onDeleteTransferOutsideEEA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTransfersOutsideEEA),
      switchMap((value) =>
        this.transferOutsideEEA
          .delete(value.id)
          .then((res) =>
            res
              ? deleteTransferOutsideEEASuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('transfer outside EEA', 'remove')
            )
          )
      )
    )
  );
  onDeleteReplyToAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteReplyToAddress),
      switchMap((value) =>
        this.replyToAddresses
          .delete(value.id)
          .then((res) =>
            res
              ? deleteReplyToAddressSuccess({ request: value, success: res })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('reply-to addresses', 'remove')
            )
          )
      )
    )
  );
  onDeleteProcessingPurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProcessingPurpose),
      switchMap((value) =>
        this.processingPurposes
          .delete(value.id)
          .then((res) =>
            res
              ? deleteProcessingPurposeSuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(
              this.nf.createError('processing purposes', 'remove')
            )
          )
      )
    )
  );
  onDeleteDataCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDataCategory),
      switchMap((value) =>
        this.dataCategories
          .delete(value.id)
          .then((res) =>
            res
              ? deleteDataCategorySuccess({
                  request: value,
                  success: res,
                })
              : abortDelete()
          )
          .catch(() =>
            QueueNotification(this.nf.createError('data category', 'remove'))
          )
      )
    )
  );
  //#endregion

  private attachRefreshAfterUpsertObservers() {
    this.actions$
      .pipe(ofType(upsertDataLocationSuccess, upsertDataLocationInlineSuccess))
      .subscribe(() => this.store.dispatch(loadDataLocations()));

    this.actions$
      .pipe(ofType(upsertExternalProcessorSuccess))
      .subscribe(() => this.store.dispatch(loadExternalProcessors()));

    this.actions$
      .pipe(ofType(upsertSubjectTypeSuccess))
      .subscribe(() => this.store.dispatch(loadDataSubjectTypes()));

    this.actions$
      .pipe(ofType(UpsertExternalControllerSuccess))
      .subscribe(() => this.store.dispatch(LoadExternalControllers()));

    this.actions$
      .pipe(
        ofType(upsertSecurityMeasureSuccess, upsertSecurityMeasureInlineSuccess)
      )
      .subscribe(() => this.store.dispatch(loadSecurityMeasures()));

    // this.actions$
    //   .pipe(ofType(UpsertEmailTemplateSuccess))
    //   .subscribe(() => this.store.dispatch(LoadEmailTemplates()));

    this.actions$
      .pipe(ofType(upsertDisclosedToSuccess, upsertDisclosedToInlineSuccess))
      .subscribe(() => this.store.dispatch(loadDisclosedTo()));

    this.actions$
      .pipe(ofType(upsertTransferEEASuccess))
      .subscribe(() => this.store.dispatch(loadTransfersOutsideEEA()));

    this.actions$
      .pipe(ofType(upsertLegalGroundSuccess))
      .subscribe(() => this.store.dispatch(loadLegalGrounds()));

    this.actions$
      .pipe(
        ofType(
          upsertProcessingPurposeSuccess,
          upsertProcessingPurposeInlineSuccess
        )
      )
      .subscribe(() => this.store.dispatch(loadProcessingPurposes()));

    this.actions$
      .pipe(ofType(upsertDataCategorySuccess))
      .subscribe(() => this.store.dispatch(loadDataCategories()));
    this.actions$
      .pipe(ofType(UpsertCompanySettingsSuccess))
      .subscribe(() => this.store.dispatch(loadCompanySettings()));
  }
}
