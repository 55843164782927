import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { loadScript } from './loadScript';

/**
 * Docs: https://docs.unlayer.com/docs
 */

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace unlayer {
  function init(object: any): void;
  function createEditor(object: {
    id: string;
    displayMode: string;
    source: { name: string; version: string };
    projectId?: number | undefined;
    tools?: object | undefined;
    appearance?: object | undefined;
    locale?: string | undefined;
  }): void;
  function loadDesign(object: any): void;
  function saveDesign(Function: any): void;
  function exportHtml(Function: any): void;
}

export interface UnlayerOptions {
  projectId?: number;
  tools?: object;
  appearance?: object;
  locale?: string;
}

let lastEditorId = 0;

@Component({
  selector: 'ca-unlayer-editor',
  template:
    '<div [id]="id" class="unlayer-editor" [style.min-height]="minHeight"></div>',
  styleUrls: ['./unlayer-editor.component.scss'],
})
export class UnlayerEditorComponent implements AfterViewInit {
  @Input()
  editorId!: string;
  @Input() options: UnlayerOptions = {};
  @Input()
  projectId!: number;
  @Input()
  tools!: object;
  @Input()
  appearance!: object;
  @Input()
  locale!: string;
  @Input() id: string;

  @Input() minHeight = '500px';

  @Output() loaded = new EventEmitter();
  @Output() ready = new EventEmitter();

  editor: any;

  constructor() {
    this.id = this.editorId || `editor-${++lastEditorId}`;
  }

  ngAfterViewInit() {
    loadScript(this.loadEditor.bind(this));
  }

  protected loadEditor() {
    const options: UnlayerOptions = this.options || {};

    if (this.projectId) {
      options.projectId = this.projectId;
    }

    if (this.tools) {
      options.tools = this.tools;
    }

    if (this.appearance) {
      options.appearance = this.appearance;
    }

    if (this.locale) {
      options.locale = this.locale;
    }

    this.editor = unlayer.createEditor({
      ...options,
      id: this.id,
      displayMode: 'email',
      source: {
        name: 'angular-email-editor',
        version: '0.9.0',
      },
    });

    this.loaded.emit({});

    this.editor.addEventListener('editor:ready', () => {
      this.ready.emit({
        options: this.options,
        id: this.id,
        editorId: this.editorId,
        editor: this.editor,
      });
    });

    // TODO: implement autosave
    // this.editor.addEventListener('design:updated', function(updates) {
    //   // Design is updated by the user

    //   this.editor.exportHtml(function(data) {
    //     var json = data.design; // design json
    //     var html = data.html; // design html

    //     // Save the json, or html here
    //   })
    // })
  }

  public loadDesign(data: object) {
    this.editor.loadDesign(data);
  }

  public saveDesign(cb: (data: object) => void) {
    this.editor.saveDesign(cb);
  }

  public exportHtml(cb: (data: object) => void) {
    this.editor.exportHtml(cb);
  }
}
