import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SecurityMeasure } from '@ca/gdpr';
import { Store } from '@ngrx/store';
import {
  GdprToolState,
  selectInlineInsertedByType,
  selectSecurityMeasures,
} from '../../../../store';
import { FormGroup } from '@angular/forms';
import { CaSubscriber } from '@ca/ca-utils';
import { MatDialog } from '@angular/material/dialog';
import { UpsertSecurityMeasureDialogComponent } from '../../dialogs/upsert-security-measure-dialog/upsert-security-measure-dialog.component';
import { newSecurityMeasure } from '../../../../config/defaults';
import { InlineInsertedResource } from '../../../../store/reducers/settings.reducer';
import { SecurityMeasuresSelectorInfoHover } from '../../../../config/info-hovers';

@Component({
  selector: 'ca-security-measures-selector',
  template: ` <div class="info-hover-form-field" [formGroup]="formGroup">
    <mat-form-field class="stretch">
      <mat-label>{{ label }}</mat-label>
      <mat-select *ngIf="options" [formControlName]="formControlName" multiple>
        <mat-option *ngFor="let sm of options" [value]="sm.id">
          {{ sm.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button (click)="addNew()">
      <mat-icon>add</mat-icon>
    </button>
    <!--<ca-info-hover [info]="info"></ca-info-hover>-->
  </div>`,
})
export class SecurityMeasuresSelectorComponent implements OnInit, OnDestroy {
  @Input() formGroup!: FormGroup;
  private sub = new CaSubscriber();
  info = SecurityMeasuresSelectorInfoHover;
  options?: SecurityMeasure[];
  protected label = $localize`:@@security-measures:Security Measures`;
  protected formControlName = 'securityMeasureIds';
  private restoreValue: number[] | null = null;
  originId: string;

  get fc() {
    return this.formGroup.get(this.formControlName);
  }

  constructor(private store: Store<GdprToolState>, private dialog: MatDialog) {
    this.originId = crypto.randomUUID();
  }

  ngOnInit(): void {
    this.sub.subscribe(this.store.select(selectSecurityMeasures), {
      next: (sm?: SecurityMeasure[]) => {
        this.restoreValue = this.fc?.value;
        this.options = sm ?? [];
        this.fc?.patchValue(this.restoreValue);
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  addNew() {
    this.sub.subscribe(
      this.store.select(selectInlineInsertedByType('security-measure')),
      {
        next: (inlineInserted?: InlineInsertedResource[]) => {
          if (inlineInserted) {
            const currentValue = this.fc?.value;

            const insertedValues = this.options
              ?.filter((e) => inlineInserted.map((e) => e.id).includes(e.id))
              .map((e) => e.id);

            if (insertedValues && insertedValues?.length > 0) {
              this.fc?.patchValue(
                currentValue
                  ? [...currentValue, ...insertedValues]
                  : [...insertedValues]
              );
              this.formGroup.updateValueAndValidity();
            }
          }
        },
      }
    );

    this.dialog.open(UpsertSecurityMeasureDialogComponent, {
      data: {
        upsert: newSecurityMeasure,
        inline: true,
        originId: this.originId,
      },
      disableClose: true,
    });
  }
}
