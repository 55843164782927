import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { GdprToolState } from '../../../../store';
import { UpsertRemark } from '../../../../store/actions/general.actions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ca-upsert-remark-dialog',
  templateUrl: './upsert-remark-dialog.component.html',
  styles: ['.upsert-remark-dialog { min-width: 50vw;}'],
})
export class UpsertRemarkDialogComponent implements OnInit {
  remark?: FormControl<string | null>;

  constructor(
    private store: Store<GdprToolState>,
    @Inject(MAT_DIALOG_DATA) protected data: UpsertRemarkDialogData,
    protected ref: MatDialogRef<UpsertRemarkDialogComponent>
  ) {}

  ngOnInit(): void {
    this.remark = new FormControl<string | null>(
      this.data.remark ?? null,
      Validators.required
    );
  }

  saveRemark() {
    if (this.remark != undefined && this.remark.value !== null) {
      {
        this.store.dispatch(
          UpsertRemark({
            ...this.data,
            remark: this.remark.value,
          })
        );
        this.ref.close();
      }
    }
  }
}
export interface UpsertRemarkDialogData {
  context: string;
  id: number;
  parentId: number;
  parentTable: string;
  remark?: string;
}
