import { createAction, props } from '@ngrx/store';
import {
  DismissSystemMessageActionData,
  DismissSystemMessageActionResult,
  UpsertRemarkData,
  UpsertRemarksResult,
} from '../models';
import {
  CreateSystemMessageData,
  SystemMessage,
  SystemMessageAction,
} from '../../models/messaging.models';

export const abortDelete = createAction('[Delete] Aborted');
export const UpsertRemark = createAction(
  '[Remarks] Upsert Remark',
  props<UpsertRemarkData>()
);
export const UpsertRemarkSuccess = createAction(
  '[Remarks] Upsert Remark [Success]',
  props<UpsertRemarksResult>()
);

export const SendSystemMessage = createAction(
  '[System Messages] Send Message',
  props<CreateSystemMessageData>()
);
export const SendSystemMessageSuccess = createAction(
  '[System Messages] Send Message [Success]'
);
export const loadSystemMessages = createAction(
  '[System Messages] Load System Messages'
);
export const loadSystemMessagesSuccess = createAction(
  '[System Messages] Load System Messages [Success]',
  props<{ messages: SystemMessage[] }>()
);

export const dismissSystemMessage = createAction(
  '[System Messages] Dismiss System Message',
  props<DismissSystemMessageActionData>()
);
export const dismissSystemMessageSuccess = createAction(
  '[System Messages] Dismiss System Message [Success]',
  props<DismissSystemMessageActionResult>()
);
