import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnboardingState, selectProfile, UserState } from '@ca/onboarding';
import { APP_ROUTES, NAVIGATION } from '../../config/navigation';
import { CaSubscriber } from '@ca/ca-utils';
import { Router } from '@angular/router';
import { GdprToolState } from '../../store';
import { DSR } from '@ca/gdpr';
import { GetInvokedRightLabel } from '../../utils/dsr';
import {
  DataRegisterInfoHover,
  DataSubjectRequestsInfoHover,
} from '../../config/info-hovers';
import { MatDialog } from '@angular/material/dialog';
import { NavLink } from '../../models/navigation.models';

import { GuidedTourService } from 'ngx-guided-tour';
import { dashboardWelcomeTour } from '../../tours';
@Component({
  selector: 'ca-home',
  templateUrl: './home.component.html',
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
      }
      .dashboard {
        margin: 1.66em;
      }
      .dashboard-card {
        width: 100%;
        height: 100%;
      }
      .messages {
        .message {
          margin: 0.66em;
        }
        display: flex;
        flex-direction: column;
        /* column-gap: 0.66em; */
        .actions {
          a,
          button {
            margin: 0 0.66em;
          }
        }
      }
    `,
  ],
})
export class HomeComponent implements OnDestroy {
  items: NavLink[];
  processorsRoPAHref = [
    APP_ROUTES['dashboard'],
    APP_ROUTES['dataregisterProcessor'],
  ];
  controllersRoPAHref = [
    APP_ROUTES['dashboard'],
    APP_ROUTES['dataregisterController'],
  ];
  dsrsHref = APP_ROUTES['dsrs'];
  user?: UserState['profile'];
  dataRegisterInfo = DataRegisterInfoHover;
  dsrInfo = DataSubjectRequestsInfoHover;
  private sub = new CaSubscriber();
  upcomingDSRs?: DSR[];

  tourDoneText = $localize`:@@dashboard-tour-done-text:Start`;
  constructor(
    private store: Store<GdprToolState & { onboarding: OnboardingState }>,
    private router: Router,
    public dialog: MatDialog,
    private tour: GuidedTourService
  ) {
    setTimeout(() => {
      console.log('starting tour...');
      this.tour.startTour({
        ...dashboardWelcomeTour,
        completeCallback: () => {
          console.log('completed');
          this.router.navigate(['/dashboard', 'questionnaire']);
        },
      });
      this.tour.activateOrb();
    }, 1000);

    this.items = NAVIGATION.filter(
      (e) => e.link === undefined || e.link.includes('dashboard') === false
    );

    this.sub.subscribe(this.store.select(selectProfile), {
      next: (user) => {
        this.user = user;
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  navigate(routerLink: any[]) {
    this.router.navigate(routerLink);
  }
  registerDSR() {
    throw new Error('Not Implemented!');
  }
  linkToDSR(dsrId: number) {
    return `${APP_ROUTES['procedures']}/${dsrId}`;
  }
  labelDSR(dsr: DSR) {
    return $localize`:@@dsr-from-name-for-right:DSR from ${dsr.firstName} ${
      dsr.lastName
    } for ${GetInvokedRightLabel(dsr.invokedRight)}`;
  }
}

export const DEFAULT_MESSAGE_ACTION = {
  key: 'dismiss',
  label: $localize`:@@dismiss:DISMISS`,
};
