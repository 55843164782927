<div *ngIf="(isUserManager$ | async) === true">
  <h1 i18n="@@admin-checked-label">You're the manager, awesome!</h1>

  <div class="users-table-container mat-elevation-z8">
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <div class="users-table-table-container">
      <table
        mat-table
        [dataSource]="data"
        class="users-table"
        matSort
        matSortActive="id"
        matSortDisableClear
        matSortDirection="asc"
        multiTemplateDataRows
      >
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              aria-label=""
              i18n-aria-label="actions button|@@action-btns"
              [matMenuTriggerFor]="userMenu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu">
              <button mat-menu-item (click)="editUser(row)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                color="warn"
                (click)="deleteUser(row)"
                i18n="@@delete-btn"
              >
                DELETE <mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.firstName }} {{ row.lastName }}
          </td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="active">
          <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@active">
            Active
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-slide-toggle
              [disabled]="row.id === activeUserId"
              [checked]="row.active"
              (change)="onActiveChange($event, row)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="email">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            disableClear
            i18n="@@email"
          >
            E-Mail
          </th>
          <td mat-cell *matCellDef="let row">{{ row.email }}</td>
        </ng-container>
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">
            &nbsp;
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="onToggleActiveRow($event, element)"
            >
              <mat-icon *ngIf="expandedElement !== element"
                >keyboard_arrow_down</mat-icon
              >
              <mat-icon *ngIf="expandedElement === element"
                >keyboard_arrow_up</mat-icon
              >
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="users-table-detail"
              [@detailExpand]="
                element === expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <mat-divider class="sub-divider"></mat-divider>

              <ca-user-summary-grid
                [profile]="expandedElement"
              ></ca-user-summary-grid>

              <div class="align-end">
                <button
                  mat-button
                  (click)="editUser(element)"
                  i18n="@@edit-btn"
                >
                  EDIT <mat-icon color="primary">edit</mat-icon>
                </button>
                <button
                  mat-button
                  color="warn"
                  (click)="deleteUser(element)"
                  i18n="@@delete-btn"
                >
                  DELETE <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="ca-table-row"
          [class.expanded-row]="expandedElement === row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="user-detail-row"
        ></tr>
      </table>
    </div>
  </div>

  <div class="actions">
    <button mat-stroked-button (click)="addUser()" i18n="@@add-user-btn">
      <mat-icon>add</mat-icon> ADD USER
    </button>
  </div>
</div>

<div
  *ngIf="(isUserManager$ | async) === false"
  i18n="@@unauthorized-to-view-page"
>
  You are unauthorized to view this page.
</div>
