import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { GdprToolState } from '../../store';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { DSR } from '@ca/gdpr';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../config/navigation';
import {
  OpenRejectOrCancelDSR,
  registerNewDSR,
} from '../../store/actions/dsr.actions';
import { GetDSRDeadline } from '../../utils/dsr';
import { MatChipListbox } from '@angular/material/chips';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-invoked-rights',
  templateUrl: './dsr.component.html',
  styles: [
    `
      .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 100vw;
        padding: 1.33em;
      }

      .container-sbtw {
        justify-content: space-between;
        align-items: center;
      }
      mat-card {
        margin: 25px;
      }
      .dsr-card {
        background-color: transparent;
      }
      .dsr-card-content {
        padding: 0.66em 0;
      }
      .dsr-card-title,
      .dsr-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3em;
      }
      .dsr-card-actions {
        button {
          margin: 0 0.66em;
        }
      }
      .dsr-card-title {
        width: 100%;
      }
    `,
  ],
})
export class DSRComponent implements OnDestroy {
  protected defaultFilterValue = ['In Progress', 'Waiting for Data Subject'];
  protected dsrStatusFilter!: MatChipListbox;
  protected showFilters = false;
  @ViewChild('dsrStatusFilter') set content(content: MatChipListbox) {
    if (content) {
      this.dsrStatusFilter = content;
      // this.sub.subscribe(
      //   merge(
      //     this.dsrStatusFilter.change.asObservable(),
      //     this.dataSubjectRequest$
      //   ),
      //   {
      //     next: (data: DSR[] | MatChipListboxChange | undefined) => {
      //       if (data !== undefined) {
      //         if (data && (data as any).value !== undefined) {
      //           // filter old data with new filter
      //           const filterValue = (data as MatChipListboxChange).value;
      //           this.filteredDataSubjectRequests =
      //             filterValue.length > 0
      //               ? this.dataSubjectRequests?.filter((e) =>
      //                   filterValue.includes(e.status)
      //                 )
      //               : this.dataSubjectRequests;
      //         } else {
      //           // filter new data with old filter
      //           this.dataSubjectRequests = data as DSR[];
      //           this.filteredDataSubjectRequests =
      //             this.dsrStatusFilter !== undefined &&
      //             this.dsrStatusFilter.value !== undefined
      //               ? this.dataSubjectRequests?.filter((e) =>
      //                   this.dsrStatusFilter.value.includes(e.status)
      //                 )
      //               : this.dataSubjectRequests ?? [];
      //         }
      //         this.cd.detectChanges();
      //       }
      //     },
      //   }
      // );
      this.dsrStatusFilter._setSelectionByValue(this.defaultFilterValue, false);
      this.cd.detectChanges();
    }
  }

  private sub = new CaSubscriber();

  protected filtersLabel = $localize`:@@dsr-filters:Data Subject Request Filters`;
  // protected dataSubjectRequest$: Observable<DSR[] | undefined>;
  protected dataSubjectRequests?: DSR[];
  protected filteredDataSubjectRequests?: DSR[];

  constructor(
    private router: Router,
    private store: Store<GdprToolState & OnboardingState>,
    private cd: ChangeDetectorRef
  ) {
    // this.dataSubjectRequest$ = store.select(selectDSRs);
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  openRequest(e: DSR) {
    this.router.navigate([`dashboard/${APP_ROUTES['procedures']}/${e.id}`]);
  }

  addDataSubjectRequest() {
    this.store.dispatch(registerNewDSR());
  }

  removeDsr(dsr: DSR) {
    this.store.dispatch(
      OpenRejectOrCancelDSR({
        dsr,
        mode: 'reject',
      })
    );
  }

  getDeadline(dsr: DSR) {
    return GetDSRDeadline(dsr);
  }

  // filtersChange(event: MatChipListboxChange) {}
}
