import { createReducer, on } from '@ngrx/store';
import {
  DeleteRoPAGroupSuccess,
  DeleteRoPARowSuccess,
  LoadDataRegisterSuccess,
} from '../actions/data-register.actions';
import {
  ControllerRoPAGroup,
  // ProcessingActivityAsController,
  // ProcessingActivityDetail,
  ProcessorRoPAGroup,
} from '@ca/gdpr';
import { onboardingActions } from '@ca/onboarding';

export interface DataRegisterState {
  controllerGroups?: ControllerRoPAGroup[];
  processorGroups?: ProcessorRoPAGroup[];

  // processingActivities?: ProcessingActivityAsController[];
  // expandedProcessingActivity: ProcessingActivityDetail | null;
  unlinkedUploadedFiles?: number[];
}

const initialState: DataRegisterState = {
  // expandedProcessingActivity: null,
};
export const reducer = createReducer(
  initialState,
  //#region Load
  on(LoadDataRegisterSuccess, (_, { ropa }) => ({
    ..._,
    controllerGroups: ropa.filter(
      (v) =>
        v.role === 'controller' &&
        v.dataSubjectType !== null &&
        v.purpose !== null
    ) as ControllerRoPAGroup[],
    processorGroups: ropa.filter(
      (v) =>
        v.role === 'processor' &&
        v.dataSubjectType !== null &&
        v.purpose !== null
    ) as ProcessorRoPAGroup[],
  })),
  //#endregion

  //#region Delete
  on(DeleteRoPAGroupSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          processorGroups: _.processorGroups?.filter((e) => e.id != request.id),
          controllerGroups: _.controllerGroups?.filter(
            (e) => e.id != request.id
          ),
        }
      : _
  ),
  on(DeleteRoPARowSuccess, (_, { request, success }) =>
    success
      ? {
          ..._,
          processorGroups: _.processorGroups
            ? _.processorGroups.map((e) => ({
                ...e,
                rows: e.rows.filter((e) => e.id != request.id),
              }))
            : _.processorGroups,

          controllerGroups: _.controllerGroups
            ? _.controllerGroups.map((e) => ({
                ...e,
                rows: e.rows.filter((e) => e.id != request.id),
              }))
            : _.controllerGroups,
        }
      : _
  ),
  //#endregion

  // LOGOUT
  on(onboardingActions.logout, () => initialState)
);
