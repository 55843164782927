import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DisclosedTo } from '@ca/gdpr';
import {
  GdprToolState,
  selectDisclosedTo,
  selectInlineInsertedByType,
} from '../../../../store';

import { FormGroup } from '@angular/forms';
import { UpsertDisclosedToDialogComponent } from '../../dialogs/upsert-disclosed-to-dialog/upsert-disclosed-to-dialog.component';
import { newDisclosedTo } from '../../../../config/defaults';
import { MatDialog } from '@angular/material/dialog';
import { InlineInsertedResource } from '../../../../store/reducers/settings.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { DisclosedToSelectorInfoHover } from '../../../../config/info-hovers';

@Component({
  selector: 'ca-disclosed-to-selector',
  template: `<div class="info-hover-form-field" [formGroup]="formGroup">
    <mat-form-field class="stretch">
      <mat-label>{{ label }}</mat-label>
      <mat-select *ngIf="options" [formControlName]="formControlName" multiple>
        <mat-option *ngFor="let sm of options" [value]="sm.id">
          {{ sm.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button (click)="addNew()">
      <mat-icon>add</mat-icon>
    </button>
  </div>`,
})
export class DisclosedToSelectorComponent implements OnInit, OnDestroy {
  @Input() formGroup!: FormGroup;
  private sub = new CaSubscriber();
  info = DisclosedToSelectorInfoHover;
  options?: DisclosedTo[];
  protected label = $localize`:@@disclosed-to:Disclosed To`;
  protected formControlName = 'disclosedToIds';
  private restoreValue: number[] | null = null;
  originId: string;
  constructor(private store: Store<GdprToolState>, private dialog: MatDialog) {
    this.originId = crypto.randomUUID();
  }
  ngOnInit(): void {
    this.sub.subscribe(this.store.select(selectDisclosedTo), {
      next: (sm?: DisclosedTo[]) => {
        this.restoreValue = this.formGroup.get(this.formControlName)?.value;
        this.options = sm ?? [];
        this.formGroup.get(this.formControlName)?.patchValue(this.restoreValue);
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  addNew() {
    this.sub.subscribe(
      this.store.select(selectInlineInsertedByType('disclosed-to')),
      {
        next: (inlineInserted?: InlineInsertedResource[]) => {
          if (inlineInserted) {
            const currentValue =
              this.formGroup.controls[this.formControlName].value;

            const insertedValues = this.options
              ?.filter((e) => inlineInserted.map((e) => e.id).includes(e.id))
              .map((e) => e.id);

            if (insertedValues && insertedValues?.length > 0)
              this.formGroup.controls[this.formControlName].patchValue(
                currentValue
                  ? [...currentValue, ...insertedValues]
                  : [...insertedValues]
              );
          }
        },
      }
    );

    this.dialog.open(UpsertDisclosedToDialogComponent, {
      data: { upsert: newDisclosedTo, inline: true, originId: this.originId },
      disableClose: true,
    });
  }
}
