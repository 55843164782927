export const TableNamesForLinking = {
  TransfersOutsideEEA: 'transfers-outside-EEA',
  ProcessingActivities: 'ropas',
  DSRs: 'dsars', // TODO: change?
  LegalGrounds: 'legal-grounds',
  Mail: 'mail',
  MailTemplates: 'mail-templates',
};
export const TransferOutsideEEAContext = 'transferOutsideEEA';
export const LegalGroundsContext = 'legalBasis';
export type DSRContextKey =
  | 'dsr.global'
  | 'dsr.confirmIdentity'
  | 'dsr.qualifyDataSubjectTypes'
  | 'dsr.markRoPA'
  | 'dsr.processStep'
  | 'dsr.completionStep';

export const DSRContext: { [key: string]: DSRContextKey } = {
  general: 'dsr.global',
  confirmIdentity: 'dsr.confirmIdentity',
  qualifyDataSubject: 'dsr.qualifyDataSubjectTypes',
  markRoPA: 'dsr.markRoPA',
  processStep: 'dsr.processStep',
  completionStep: 'dsr.completionStep',
};
