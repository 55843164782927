import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { newDataCategory } from '../../../config/defaults';
import { DataCategory } from '@ca/gdpr';
import { GdprToolState, selectDataCategories } from '../../../store';
import { deleteDataCategory } from '../../../store/actions/settings.actions';
import { UpsertDataCategoriesComponent } from '../dialogs/upsert-data-categories/upsert-data-categories.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataCategoriesInfoHover } from '../../../config/info-hovers';

@Component({
  selector: 'ca-data-categories',
  template: `<h1 class="info-title">
      <span i18n="@@data-categories-title">Data Categories</span>
      <!-- <ca-info-hover [info]="info"></ca-info-hover>-->
    </h1>
    <ng-container *ngIf="dataSource">
      <mat-form-field>
        <mat-label i18n="@@search-input-label">Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. Biometric data"
          #input
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef i18n="@@description">
            Description
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sensitive">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            i18n="@@has-sensitive-data"
          >
            Sensitive Data?
          </th>
          <td mat-cell *matCellDef="let element" class="table-text">
            {{ GetSensitiveLabel(element.sensitive) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="remove(element)"
                i18n="@@delete-btn"
              >
                DELETE<mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page of data categories"
    ></mat-paginator>
    <button mat-raised-button (click)="add()" i18n="@@add-btn">
      <mat-icon>add</mat-icon> ADD
    </button>`,
})
export class DataCategoriesComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  protected info = DataCategoriesInfoHover;
  private SENSITIVE_LABEL = $localize`:@@yes:YES`;
  private INSENSITIVE_LABEL = $localize`:@@no:NO`;

  GetSensitiveLabel(isSensitive: boolean) {
    return isSensitive ? this.SENSITIVE_LABEL : this.INSENSITIVE_LABEL;
  }

  dataSource?: MatTableDataSource<DataCategory>;
  displayedColumns: string[] = ['actions', 'name', 'sensitive', 'description'];

  constructor(
    private store: Store<GdprToolState & { onboarding: OnboardingState }>,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.store
      .select(selectDataCategories)
      .subscribe((purposes?: DataCategory[]) => {
        if (!this.dataSource)
          this.dataSource = new MatTableDataSource(purposes);
        else this.dataSource.data = purposes ?? [];
      });
  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  add() {
    this.dialog.open(UpsertDataCategoriesComponent, {
      data: newDataCategory,
      disableClose: true,
    });
  }

  edit(category: DataCategory) {
    this.dialog.open(UpsertDataCategoriesComponent, {
      data: category,
      disableClose: true,
    });
  }

  remove(category: DataCategory) {
    this.store.dispatch(deleteDataCategory({ id: category.id }));
  }

  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
