import { createReducer, on } from '@ngrx/store';
import {
  ClearUnlinkedMedia,
  UploadFilesSuccess,
} from '../actions/file-upload.actions';
import { onboardingActions } from '@ca/onboarding';
export interface UnlinkedMediaTracker {
  trackingId: string;
  unlinkedIds: number[];
}
export interface WIPState {
  /**
   * Keeps track of unlinked media ids. Look-up via trackingId.
   */
  unlinkedMedia: UnlinkedMediaTracker[];
}

export const initialState: WIPState = { unlinkedMedia: [] };
export const reducer = createReducer(
  initialState,
  on(UploadFilesSuccess, (_, { request, result }) => {
    if (
      request.parentId === undefined ||
      (request.parentId === 0 && result.success)
    ) {
      return {
        ..._,
        unlinkedMedia: [
          ..._.unlinkedMedia,
          {
            trackingId: request.trackingId,
            unlinkedIds: result.results.map((e) => e.id),
          },
        ],
      };
    } else return _;
  }),
  on(ClearUnlinkedMedia, (_, { keyToClear }) => ({
    ..._,
    unlinkedMedia: keyToClear
      ? _.unlinkedMedia.filter((e) => e.trackingId !== keyToClear)
      : [],
  })),
  on(onboardingActions.logout, () => initialState)
);
