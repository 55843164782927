import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, catchError } from 'rxjs';
import { UnauthorizedHttpRequest } from '../store/actions';
import { FEATURE_KEY } from '@ca/ca-ng-core';
import { OnboardingState } from '../types';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(async (error) => {
        if (error.status === 401) {
          console.error('Unauthorized API request', moment().toISOString());
          this.store.dispatch(
            UnauthorizedHttpRequest({
              url: request.urlWithParams,
              error: error,
            })
          );
        }
        throw error;
      })
    );
  }
}
