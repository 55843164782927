import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { APP_ROUTES } from './config/navigation';
import { getDashboardRoute } from './app-routing.module';
import { Store } from '@ngrx/store';
import { Observable, PartialObserver, filter } from 'rxjs';
import { NAVIGATION } from './config/navigation';
import { MediaMatcher } from '@angular/cdk/layout';
import { CaSubscriber } from '@ca/ca-utils';
import { HumidorAppSettings } from './config/app';
import { FEATURE_KEY, OnboardingState, selectUser } from '@ca/onboarding';

@Component({
  selector: 'ca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private sub: CaSubscriber;
  protected links = NAVIGATION;
  protected sidenavOpened = false;

  protected mobileQuery: MediaQueryList;
  private isMobile = false;
  private _mobileQueryListener: () => void = () => {
    this.changeDetectorRef.detectChanges();
    this.isMobile = this.mobileQuery.matches;
    console.log('mobile changes', this.isMobile);
  };

  protected isLoggedIn = false;

  private navigationObserver: PartialObserver<NavigationEnd> = {
    next: (re) => {
      this.resetLinksActiveStatus();
      const event = re as NavigationEnd;
      const links = [...this.links];
      this.links.forEach((l) => {
        if (l.children && l.children.length > 0)
          l.children.forEach((cl) => links.push(cl));
      });
      const activeLink = links.find(
        (n) => event.url === `/${getDashboardRoute(n)}`
      );
      if (activeLink) activeLink.isActive = true;
      this.isMobile = this.mobileQuery.matches;
    },
  };

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected media: MediaMatcher,
    private router: Router,
    private store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    this.sub = new CaSubscriber();
    this.sub.subscribe(this.store.select(selectUser), {
      next: (user) => {
        console.log('user state change', user.profile?.active);
        this.isLoggedIn = user?.profile !== undefined ?? false;
        if (!this.isLoggedIn) this.sidenavOpened = false;
      },
    });

    // media query for mobile version
    this.mobileQuery = this.media.matchMedia(
      HumidorAppSettings.mobileMediaQuery
    );
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    // navigation observer
    this.sub.subscribe(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ) as Observable<NavigationEnd>,
      this.navigationObserver
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  private resetLinksActiveStatus() {
    this.links.forEach((element) => {
      element.isActive = false;
      if (element.children)
        element.children.forEach((el) => (el.isActive = false));
    });
  }

  goHome() {
    this.router.navigate([APP_ROUTES['dashboard']]);
  }
}
