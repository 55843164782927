import { Component, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TransferOutsideEEA } from '@ca/gdpr';
import { upsertTransferEEA } from '../../../../store/actions/settings.actions';
import { CaSubscriber } from '@ca/ca-utils';
import { OnboardingState, selectActiveDivisionId } from '@ca/onboarding';
import { GdprToolState } from '../../../../store';

import { FileUploadHandler } from '../../../../utils/FileUploadHandler';
import {
  TableNamesForLinking,
  TransferOutsideEEAContext,
} from '../../../../config/table-names-for-linking';

@Component({
  selector: 'ca-upsert-transfer-outside-eeadialog',
  template: `
    <h2 mat-dialog-title>
      {{ title }}
    </h2>
    <div mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg">
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
        </div>
        <div *ngIf="data && data.media">
          <ca-media-list [media]="data.media"></ca-media-list>
        </div>
        <div>
          <ca-file-uploader
            [companyId]="companyId"
            [parentTable]="uploaderParentTable"
            [parentId]="data && data.id > 0 ? data.id : undefined"
            [context]="uploaderContext"
            (filesDropped)="onUploadMedia($event)"
          ></ca-file-uploader>
        </div>
      </form>
    </div>

    <mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        color="primary"
        type="button"
        [disabled]="!fg.valid"
        (click)="save()"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-dialog-actions>
  `,
})
export class UpsertTransferOutsideEEADialogComponent
  extends FileUploadHandler<TransferOutsideEEA>
  implements OnDestroy
{
  get title() {
    return $localize`:@@create-or-edit-transfers-outside-eea:${
      this._data.id > 0 ? 'Edit' : 'Create'
    } Transfer Outside EEA`;
  }
  private sub = new CaSubscriber();
  fg: FormGroup;
  companyId = 0;
  uploaderContext = TransferOutsideEEAContext;
  uploaderParentTable = TableNamesForLinking.TransfersOutsideEEA;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected override _data: TransferOutsideEEA,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpsertTransferOutsideEEADialogComponent>,
    protected override store: Store<
      GdprToolState & { onboarding: OnboardingState }
    >
  ) {
    super(store, _data);
    this.sub.subscribe(this.store.select(selectActiveDivisionId), {
      next: (id?: number) => (this.companyId = id ?? 0),
    });
    this.fg = fb.group({
      id: [_data.id, Validators.required],
      name: [_data.name, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  save() {
    this.store.dispatch(
      upsertTransferEEA({
        ...this.fg.value,
        insertMediaIds: this.insertMediaIds,
      })
    );
    this.dialogRef.close();
  }
}
