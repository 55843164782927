import { Inject, Injectable } from '@angular/core';
import { CaDataService, PostResult } from '@ca/ca-data';
import { CaEnvironment } from '@ca/ca-utils';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { SystemMessage } from '../../models/messaging.models';
import { CrudService } from '../CrudService';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class MessagesService extends CrudService<
  SystemMessage,
  'put-messages'
> {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,

    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, '/api/general/messages', store);
  }

  setMessageRead(id: number) {
    return this.data.post<{ id: number }, PostResult>(
      `${this.env.baseUrl}/api/general/message-set-status-read?companyId=${this.companyId}`,
      {
        id,
      }
    );
  }
}
