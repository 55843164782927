<div class="upsert-data-category upsert-form">
  <h1 mat-dialog-title>{{ title }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <form class="upsert-form" [formGroup]="fg">
      <input formControlName="id" hidden />
      <input hidden formControlName="parentId" />

      <ng-container formGroupName="dataCategory">
        <div class="info-hover-form-field">
          <mat-form-field class="stretch">
            <mat-label>{{ dataCategoryLabel }}</mat-label>
            <input
              matInput
              type="text"
              aria-label="Data Category Name Input"
              formControlName="name"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <ng-container *ngIf="filteredDataCategories">
                <mat-option
                  *ngFor="let option of filteredDataCategories | async"
                  [value]="option"
                >
                  {{ option.name }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <!-- <ca-info-hover [info]="dataCategoryInfo"></ca-info-hover> -->
        </div>

        <ng-container *ngIf="categoryId === 0">
          <div>
            <mat-form-field>
              <mat-label i18n="Form field label for description|@@description"
                >Description</mat-label
              >
              <textarea
                matInput
                type="text"
                formControlName="description"
                i18n-placeholder="
                  Placeholder for description textarea|@@description-ph"
                placeholder="The information that is necessary to contact an individual or organisation"
              ></textarea>
            </mat-form-field>
          </div>
          <div>
            <mat-slide-toggle
              formControlName="sensitive"
              i18n="@@contains-sensitive-data"
              >Contains sensitive data</mat-slide-toggle
            >
          </div>
        </ng-container>
      </ng-container>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@lifetime-months">Lifetime in months</mat-label>
          <input matInput type="number" formControlName="lifetimeMonths" />
        </mat-form-field>
        <!-- <ca-info-hover
          info="How long will you generally process/store personal information for this purpose?"
        ></ca-info-hover> -->
      </div>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@clarify-lifetime">Clarify lifetime</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="lifetimeClarification"
          ></textarea>
        </mat-form-field>
        <!-- <ca-info-hover
          info="Explain why you process/store personal information for this purpose."
        ></ca-info-hover> -->
      </div>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@data-locations">Data locations</mat-label>
          <mat-select formControlName="dataLocationIds" multiple>
            <mat-option *ngFor="let dl of dataLocations" [value]="dl.id">{{
              dl.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <ca-info-hover
          info="Where will you process/store this personal information?"
        ></ca-info-hover> -->
      </div>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@disclosed-to">Disclosed to</mat-label>
          <mat-select formControlName="disclosedToIds" multiple>
            <mat-option *ngFor="let dl of disclosedTos" [value]="dl.id">{{
              dl.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <ca-info-hover
          info="Who will have access to this personal information?"
        ></ca-info-hover> -->
      </div>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@legal-grounds">Legal grounds</mat-label>
          <mat-select formControlName="legalGroundIds" multiple>
            <mat-option *ngFor="let dl of legalGrounds" [value]="dl.id">{{
              dl.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <ca-info-hover
          info="Who will have access to this personal information?"
        ></ca-info-hover> -->
      </div>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@clarify-legal-grounds"
            >Clarify legal grounds</mat-label
          >
          <textarea
            matInput
            formControlName="legalGroundClarification"
          ></textarea>
        </mat-form-field>
        <!-- <ca-info-hover
          info="Argue why you base your right to process this personal information on this/these legal ground(s)?"
        ></ca-info-hover> -->
      </div>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@transfers-outside-eea"
            >Transfers outside the EEA</mat-label
          >
          <mat-select formControlName="transferEEAIds" multiple>
            <mat-option
              *ngFor="let dl of transfersOutsideEEA"
              [value]="dl.id"
              >{{ dl.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <!-- <ca-info-hover
          info="Do you transfer this data outside the EEA?"
        ></ca-info-hover> -->
      </div>

      <div class="info-hover-form-field">
        <mat-form-field class="stretch">
          <mat-label i18n="@@clarify-transfers-outside-eea"
            >Clarify transfer outside the EEA</mat-label
          >
          <textarea
            matInput
            formControlName="transferEEAClarification"
          ></textarea>
        </mat-form-field>
        <!-- <ca-info-hover
          info="Argue your right to transfer this personal information to this/these countries outside the EEA?"
        ></ca-info-hover> -->
      </div>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <a
      class="cancel-link cancel"
      style="margin-right: 0.66em"
      mat-dialog-close
      i18n="@@cancel-btn"
    >
      Cancel
    </a>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      i18n="@@save-btn"
    >
      SAVE
    </button>
  </div>
</div>
