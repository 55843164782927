import { createAction, props } from '@ngrx/store';
import { GdprToolActionType } from '../action.types';
import { UploadFilesActionData, UploadFilesActionResult } from '../models';

export const UploadFiles = createAction(
  GdprToolActionType.UploadFile,
  props<UploadFilesActionData>()
);
export const UploadFilesSuccess = createAction(
  GdprToolActionType.UploadFileSuccess,
  props<UploadFilesActionResult>()
);
export const ClearUnlinkedMedia = createAction(
  GdprToolActionType.ClearUnlinkedMedia,
  props<{
    keyToClear?: string;
  }>()
);
