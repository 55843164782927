import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressData } from '@ca/core';
import {
  ControllerRoPAGroup,
  ProcessingActivityRole,
  ProcessorRoPAGroup,
  UpsertExternalControllerActionData,
  DataCategory,
  ProcessingPurpose,
  UpsertDataCategoryActionData,
  UpsertDataSubjectTypeActionData,
  UpsertExternalProcessorActionData,
  UpsertProcessingPurposeActionData,
} from '@ca/gdpr';
//#region Types
export type CreateFormWithData<T> = (
  formBuilder: FormBuilder,
  data: T
) => FormGroup;
export type CreateUpsertFormWithData = (
  formBuilder: FormBuilder,
  data?: unknown
) => FormGroup;
export type CreateRoPAGroupData = {
  id?: number;
  upsert?: Partial<ProcessorRoPAGroup | ControllerRoPAGroup>;
  role: ProcessingActivityRole;
  purpose?: ProcessingPurpose;
  dataSubjectType: UpsertDataSubjectTypeActionData;
};
export type CreateRoPARowData = {
  id?: number;
  parentId: number;
  dataCategory: DataCategory | UpsertDataCategoryActionData;
  parent?: Partial<ProcessorRoPAGroup & ControllerRoPAGroup>;
};
//#endregion

//#region General
// Creates a form group to upsert an Inline Address
export const CreateAddressFormGroupInitializer: CreateFormWithData<
  AddressData
> = (formBuilder: FormBuilder, address?: AddressData) => {
  return formBuilder.group({
    street: [address?.street ?? null, Validators.required],
    postalCode: [address?.postalCode ?? null, Validators.required],
    houseNumber: [
      address?.houseNumber ?? null,
      [Validators.required, Validators.min(0)],
    ],
    houseNumberSuffix: address?.houseNumberSuffix ?? null,
    city: [address?.city ?? null, Validators.required],
    country: [address?.country ?? null, Validators.required],
  });
};

//#endregion
// {
//   "id": 1,
//   "companyId": 1,
//   "parentId": 1,
//   "lifetimeMonths": 0,
//   "lifetimeClarification": "",
//   "dataLocationIds": [],
//   "disclosedToIds": [],
//   "transferEEAIds": [],
//   "transferEEAClarification": "text",
//   "legalGroundIds": [],
//   "legalGroundClarification": "text",
//   "dataCategoryId": 0
// }
//#region Data Register
export const CreateSubjectAndPurposeFG: CreateUpsertFormWithData = (
  formBuilder: FormBuilder
) => {
  return formBuilder.group({
    name: [null, Validators.required],
    purposes: [[], Validators.minLength(1)],
  });
};

export const CreatePurposeConfigurationFG: CreateFormWithData<
  CreateRoPAGroupData
> = (formBuilder: FormBuilder, data: CreateRoPAGroupData) => {
  return formBuilder.group({
    id: data.upsert?.id ?? data.id ?? 0,
    insertMediaIds: [undefined], // TODO
    role: [data.role, Validators.required],
    purposeId: [
      data.upsert?.purpose?.id ?? data.purpose?.id ?? 0,
      Validators.required,
    ],
    purpose: [
      data.upsert?.purpose ?? data.purpose ?? null,
      Validators.required,
    ],
    dataSubjectType: [data.dataSubjectType, Validators.required],
    lifetimeMonths: [
      data.upsert?.lifetimeMonths ?? null,
      [Validators.required, Validators.min(0)],
    ],
    lifetimeClarification: [data.upsert?.lifetimeClarification ?? null],
    dataLocationIds: [
      data.upsert?.dataLocations?.map((e) => e.id) ?? null,
      [Validators.required, Validators.minLength(1)],
    ],
    // only for controller RoPA
    legalGroundIds: [
      data.upsert !== undefined &&
      (data.upsert as ControllerRoPAGroup)?.legalGrounds !== undefined
        ? (data.upsert as ControllerRoPAGroup).legalGrounds.map((e) => e.id)
        : null,
      data.role === 'controller'
        ? [Validators.required, Validators.minLength(1)]
        : [Validators.nullValidator],
    ],
    legalGroundClarification: [
      data.upsert !== undefined &&
      (data.upsert as ControllerRoPAGroup)?.legalGroundClarification !==
        undefined
        ? (data.upsert as ControllerRoPAGroup).legalGroundClarification
        : null,
      [Validators.required, Validators.minLength(10)],
    ],
    transferEEAIds: [data.upsert?.transferEEA ?? null],
    transferEEAClarification: [data.upsert?.transferEEAClarification ?? null],
    disclosedToIds: [data.upsert?.disclosedTo?.map((e) => e.id) ?? null],
    // only for processor RoPA
    controllers: [
      data.role === 'processor' &&
      data.upsert &&
      (data.upsert as ProcessorRoPAGroup).externalControllers != undefined
        ? (data.upsert as ProcessorRoPAGroup).externalControllers.map(
            (e) => e.id
          )
        : null,
    ],
  });
};

export const CreateDataCategoryConfigurationFG: CreateFormWithData<
  CreateRoPARowData
> = (formBuilder: FormBuilder, data: CreateRoPARowData) => {
  return formBuilder.group({
    id: [data.id ?? 0, Validators.required],
    parentId: [data.parentId, Validators.required],
    dataCategory: formBuilder.group({
      id: [data.dataCategory.id, Validators.required],
      name: [data.dataCategory.name, Validators.required],
      sensitive: [data.dataCategory.sensitive],
      description: data.dataCategory.description,
    }),
    lifetimeMonths: [
      data.parent?.lifetimeMonths ?? null,
      [Validators.required, Validators.min(0)],
    ],
    lifetimeClarification: [data.parent?.lifetimeClarification ?? null],
    dataLocationIds: [
      data.parent?.dataLocations?.map((e) => e.id) ?? [],
      [Validators.required, Validators.minLength(1)],
    ],
    legalGroundIds: [
      data.parent?.legalGrounds?.map((e) => e.id) ?? [],
      [Validators.required, Validators.minLength(1)],
    ],
    legalGroundClarification: [
      data.parent?.legalGroundClarification ?? null,
      [Validators.required, Validators.minLength(10)],
    ],
    transferEEAIds: [data.parent?.transferEEA?.map((e) => e.id) ?? null],
    transferEEAClarification: [data.parent?.transferEEAClarification ?? null],
    disclosedToIds: [data.parent?.disclosedTo?.map((e) => e.id) ?? null],
  });
};

//#endregion

//#region Settings
// Creates a form group to upsert an ExternalController
export const CreateExternalControllerForm: CreateFormWithData<
  UpsertExternalControllerActionData
> = (formBuilder: FormBuilder, data: UpsertExternalControllerActionData) =>
  formBuilder.group({
    id: [data.id, Validators.required],
    name: [data.name, Validators.required],
    email: [data.email, Validators.required],
    phone: [data.phone, Validators.required],
    vatNumber: [data.vatNumber, Validators.required],
    address: CreateAddressFormGroupInitializer(formBuilder, data.address),
  });

// Creates a form group to upsert an ExternalProcessor
export const CreateExternalProcessorForm: CreateFormWithData<
  UpsertExternalProcessorActionData
> = (formBuilder: FormBuilder, data: UpsertExternalProcessorActionData) =>
  formBuilder.group({
    id: [data.id, Validators.required],
    name: [data.name, Validators.required],
    email: [data.email, Validators.required],
    phone: [data.phone, Validators.required],
    address: CreateAddressFormGroupInitializer(formBuilder, data.address),
  });

export const CreateProcessingPurposeForm: CreateFormWithData<
  UpsertProcessingPurposeActionData
> = (formBuilder, data) => {
  return formBuilder.group({
    id: [data.id, Validators.required],
    name: [data.name, Validators.required],
    description: [data.description, Validators.required],
  });
};
//#endregion
