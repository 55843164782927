import { createAction, props } from '@ngrx/store';
import { UpsertEmailTemplateData } from '../models';
export enum MailActions {
  UpsertTemplate = '[Templates] Upsert',
  UpsertTemplateSuccess = '[Templates] Upsert [Success]',
}

export const UpsertEmailTemplate = createAction(
  MailActions.UpsertTemplate,
  props<{ upsert: UpsertEmailTemplateData }>()
);
export const UpsertEmailTemplateSuccess = createAction(
  MailActions.UpsertTemplateSuccess
);
