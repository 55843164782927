import { Component, Inject } from '@angular/core';
import { ProcessingActivityRole } from '@ca/gdpr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ca-add-subject-register-dialog',
  template: `
    <!-- TODO: else select role here and proceed -->
    <ca-add-subject-register
      *ngIf="data.role"
      [role]="data.role"
    ></ca-add-subject-register>
  `,
})
export class AddSubjectRegisterDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: { role: ProcessingActivityRole },
    protected ref: MatDialogRef<AddSubjectRegisterDialogComponent>
  ) {}
}
