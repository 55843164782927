import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { GdprToolState, selectTransfersOutsideEEA } from '../../../store';
import { TransferOutsideEEA } from '@ca/gdpr';
import { UpsertTransferOutsideEEADialogComponent } from '../dialogs/upsert-transfer-outside-eeadialog/upsert-transfer-outside-eeadialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { deleteTransfersOutsideEEA } from '../../../store/actions/settings.actions';
import { newTransferOutsideEEA } from '../../../config/defaults';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TransferOutsideEEAInfoHover } from '../../../config/info-hovers';

@Component({
  selector: 'ca-transfers-outside-eea',
  template: `
    <h1 class="info-title" i18n="@@transfers-outside-eea-title">
      Transfers Outside EEA
      <!--<ca-info-hover [info]="info"></ca-info-hover>-->
    </h1>
    <ng-container *ngIf="dataSource">
      <mat-form-field>
        <mat-label i18n="Form field search label|@@search">Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. Biometric data"
          #input
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="files">
          <th mat-header-cell *matHeaderCellDef i18n="@@media">Media</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.media !== undefined">article</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)" i18n="@@edit-btn">
                EDIT <mat-icon color="primary">edit</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="remove(element)"
                i18n="@@delete-btn"
              >
                DELETE<mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page of transfers outside EEA"
      aria-label-i18n="@pagination-size-selector"
    ></mat-paginator>
    <button mat-raised-button (click)="add()" i18n="@add-btn">
      <mat-icon>add</mat-icon> ADD
    </button>
  `,
})
export class TransfersOutsideEeaComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource?: MatTableDataSource<TransferOutsideEEA>;
  //TODO: Add address
  displayedColumns: string[] = ['actions', 'name', 'files'];
  info = TransferOutsideEEAInfoHover;

  constructor(
    private store: Store<GdprToolState & { onboarding: OnboardingState }>,
    public dialog: MatDialog
  ) {
    this.store
      .select(selectTransfersOutsideEEA)
      .subscribe((transfersOutsideEEA?: TransferOutsideEEA[]) => {
        if (!this.dataSource)
          this.dataSource = new MatTableDataSource(transfersOutsideEEA ?? []);
        else this.dataSource.data = transfersOutsideEEA ?? [];
      });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      throw new Error(
        'datasource was undefined, can not initialize paginator and sort headers...'
      );
    }
  }
  add() {
    this.dialog.open(UpsertTransferOutsideEEADialogComponent, {
      data: newTransferOutsideEEA,
      disableClose: true,
    });
  }
  edit(item: TransferOutsideEEA) {
    this.dialog.open(UpsertTransferOutsideEEADialogComponent, {
      data: item,
      disableClose: true,
    });
  }
  remove(item: TransferOutsideEEA) {
    this.store.dispatch(deleteTransfersOutsideEEA({ id: item.id }));
  }

  applyFilter(event: Event) {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}
