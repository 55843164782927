import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnlayerEditorComponent } from '@ca/unlayer-editor';
import { Store } from '@ngrx/store';
import {
  UnlayerMergeTags,
  UpsertEmailTemplateData,
} from '../models/editor.models';
import { UpsertEmailTemplate } from '../store/actions';

@Component({
  selector: 'ca-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent {
  @ViewChild(UnlayerEditorComponent) unlayerEditor!: UnlayerEditorComponent;
  fg: FormGroup;
  id: string = crypto.randomUUID();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private viewModel: {
      templateData: UpsertEmailTemplateData;
      mergeTags: UnlayerMergeTags;
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TemplateEditorComponent>,
    private store: Store
  ) {
    this.fg = fb.group({
      id: [viewModel.templateData.id, Validators.required],
      name: [viewModel.templateData.name, Validators.required],
      type: [viewModel.templateData.type, Validators.required],
      subject: [viewModel.templateData.subject],
    });
  }

  onEditorReady(e: any) {
    // load design if mode is upsert
    if (
      this.viewModel.templateData.config &&
      Object.keys(this.viewModel.templateData.config).length > 0
    )
      this.unlayerEditor.editor.loadDesign(this.viewModel.templateData.config);

    this.unlayerEditor.editor.setMergeTags(this.viewModel.mergeTags);
  }

  save() {
    this.unlayerEditor.editor.exportHtml((data: any) => {
      const { design, html } = data;
      const saveData: UpsertEmailTemplateData = {
        ...this.fg.value,
        config: design,
        html,
      };

      this.store.dispatch(UpsertEmailTemplate({ upsert: saveData }));
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
