<div class="data-register-header">
  <!-- <button
    id="data-register-more-btn"
    mat-icon-button
    [mat-menu-trigger-for]="mainMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #mainMenu="matMenu">
    <button mat-menu-item color="primary" (click)="addSubject()">
      <ng-container i18n="@@add-subject">ADD SUBJECT</ng-container>
      <mat-icon color="primary">add</mat-icon>
    </button>
    <button mat-menu-item color="primary" (click)="exportRegister(role)">
      <ng-container i18n="@@export-data"
        >EXPORT {{ role === 'controller' ? 'CONTROLLER' : 'PROCESSOR' }} DATA
        REGISTER</ng-container
      >
      <mat-icon color="primary">file_download</mat-icon>
    </button>
    <button mat-menu-item color="primary" (click)="exportRegister('all')">
      <ng-container i18n="@@export-data-registers"
        >EXPORT DATA REGISTER</ng-container
      >
      <mat-icon color="primary">file_download</mat-icon>
    </button>
  </mat-menu> -->
  <h1 i18n="@@data-register-title">
    Data Register -
    {{ role === 'controller' ? 'Controller' : 'Processor' }}
  </h1>
  <!-- <button mat-button color="primary" (click)="exportRegister(role)">
    <ng-container i18n="@@export-data"
      >EXPORT {{ role === 'controller' ? 'CONTROLLER' : 'PROCESSOR' }} DATA
      REGISTER</ng-container
    >
    <mat-icon color="primary">file_download</mat-icon>
  </button> -->
  <button mat-button color="primary" (click)="exportRegister('all')">
    <ng-container i18n="@@export-data-registers"
      >EXPORT DATA REGISTER</ng-container
    >
    <mat-icon color="primary">file_download</mat-icon>
  </button>
</div>

<mat-tab-group
  *ngIf="groups && vm"
  class="data-register-tabs"
  (selectedTabChange)="checkAdd($event)"
>
  <mat-tab *ngFor="let subject of dataSubjectTypes" [label]="subject.name">
    <div class="data-register-tab">
      <div class="data-register-header data-register-tab-header">
        <div class="data-register-actions">
          <!-- <button mat-icon-button [mat-menu-trigger-for]="mainMenu">
            <mat-icon>more_vert</mat-icon>
          </button>-->
          <!--<mat-menu #mainMenu="matMenu">

          </mat-menu>-->
        </div>
        <h1>{{ subject.name }}</h1>
        <button
          mat-menu-item
          color="primary"
          (click)="upsertPurposeGroup(subject)"
          i18n-matTooltip="@@add-purpose-for-subjectType-tooltip"
          matTooltipPosition="left"
          [matTooltip]="'Add processing purpose for ' + subject.name"
        >
          <ng-container i18n="@@add-purpose-btn"
            >ADD {{ subject.name }} PURPOSE</ng-container
          >
          <mat-icon color="primary">add</mat-icon>
        </button>
      </div>

      <div *ngFor="let group of vm[subject.name]" class="data-register-purpose">
        <div
          class="data-register-purpose-header data-register-header data-register-tab-header"
        >
          <div class="data-register-actions">
            <h2>{{ group.title }}</h2>
            <button mat-icon-button [mat-menu-trigger-for]="purposeMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #purposeMenu="matMenu">
              <button
                mat-menu-item
                (click)="addCategory(group.base)"
                i18n-matTooltip="@@add-data-category-tooltip"
                matTooltipPosition="left"
                [matTooltip]="
                  'Add a data Category to ' + group.base.purpose.name
                "
              >
                <ng-container i18n="@@add-category-btn"
                  >ADD CATEGORY</ng-container
                >
                <mat-icon color="primary">add</mat-icon>
              </button>
              <button
                mat-menu-item
                (click)="upsertPurposeGroup(subject, group.base)"
              >
                <ng-container i18n="@@edit-group-btn"
                  >EDIT "{{ group.title }}"</ng-container
                >
                <mat-icon color="primary">edit</mat-icon>
              </button>
              <button mat-menu-item (click)="deletePurposeGroup(group.base.id)">
                <ng-container i18n="@@delete-purpose-btn"
                  >DELETE PURPOSE</ng-container
                >
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </div>
        </div>

        <ca-data-register-table
          [group]="group"
          role="controller"
        ></ca-data-register-table>
      </div>
    </div>
  </mat-tab>
  <mat-tab disabled label="+" labelClass="add">
    <ng-template mat-tab-label>
      <button mat-icon-button (click)="addSubject()">
        <mat-icon>add_circle</mat-icon>
      </button>
    </ng-template>
  </mat-tab>
</mat-tab-group>
