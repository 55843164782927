import { GuidedTour, Orientation } from 'ngx-guided-tour';
// https://github.com/lsqlabs/ngx-guided-tour
export const dataRegisterAsControllerTour: GuidedTour = {
  steps: [
    {
      title: $localize`:@@data-register-as-controller-tour-step-1-title:Data Register as Controller`,
      content: $localize`:@@data-register-as-countroller-tour-step-1-content:This is where you can register all your processing activities as a controller. A "controller" under the GDPR is an entity that determines the purposes and means of the processing of personal data. In simpler terms, it is the organization or person that decides why and how personal data is processed.`,
    },
    {
      title: $localize`:@@data-register-as-controller-tour-step-2-title:What are data subject types and processing purposes?`,
      content: $localize`:@@data-register-as-countroller-tour-step-2-content:A data subject type describes a group of people from whom personal data is stored for specific processing purposes. Examples of data subject types are: clients, employees, suppliers, etc.\nA processing purpose describes the reason for processing personal data from people that fall into this category.`,
    },
    {
      title: $localize`:@@data-register-as-controller-tour-step-3-title:Identifying a data subject type`,
      content: $localize`:@@data-register-as-countroller-tour-step-3-content:Let's walk through this one together. We'll register our first data subject type: Prospects. A group of people that might be interested in our services that could be converted to clients. We collect their e-mail addresses, names, employers, job titles and use this information to send personalised promotional e-mails to convince them why we are so great. Click on the three dots next to the page title (Data Register - Controller), choose "Prospects" as the name for this new data subject type.`,
      selector: '#data-register-more-btn',
      orientation: Orientation.Right,
    },
  ],
  tourId: 'data-register-as-controller',
  useOrb: true,
};

export const dashboardWelcomeTour: GuidedTour = {
  steps: [
    {
      title: $localize`:@@dasboard-tour-welcome-title:Welcome to humidor!`,
      content: $localize`:@@dasboard-tour-welcome-content:We're thrilled to have you on board! Humidor is here to make GDPR compliance easier for you by helping you manage your data register efficiently and effectively. Let's get started with a quick overview.`,
    },
    {
      title: $localize`:@@dasboard-tour-what-title:What Does Humidor Do?`,
      content: $localize`:@@dasboard-tour-what-content:Humidor is designed to help you create, manage, and maintain your GDPR data register. This register keeps track of all the personal data your organization processes, ensuring you stay compliant with GDPR requirements. With Humidor, you can easily document what data you collect, how it's used, where it's stored, and much more.`,
    },
    {
      title: $localize`:@@dasboard-tour-why-title:Why is a Data Register Important?`,
      content: $localize`:@@dasboard-tour-why-content:A well-maintained data register is crucial for GDPR compliance. It helps you demonstrate accountability, manage risks, and maintain transparency with your customers. By keeping a detailed record of your data processing activities, you can respond to data subject requests more efficiently and ensure that your data management practices are both lawful and responsible.`,
    },
    {
      title: $localize`:@@dasboard-tour-ready-title:Ready to Get Started?`,
      content: $localize`:@@dasboard-tour-ready-content:You're just a few steps away from completing your data register! Click the button below to start answering a few simple questions that will guide you through the process. Let's make sure your organization is on the right track with GDPR compliance!`,
      selector: '#getting-started-card',
      orientation: Orientation.Bottom,
    },
  ],
  tourId: 'welcome',
  useOrb: true,
};
// $localize`:@@dasboard-tour-:`
