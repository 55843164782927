import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpsertSecurityMeasure } from '@ca/gdpr';
import {
  upsertSecurityMeasure,
  upsertSecurityMeasureInline,
} from '../../../../../app/store/actions/settings.actions';
import { GdprToolState } from '../../../../store';
import { UpsertInlineDialogData } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-upsert-security-measure-dialog',
  template: ` <h2 mat-dialog-title>{{ title }}</h2>
    <form class="upsert-form" [formGroup]="fg">
      <mat-dialog-content>
        <input formControlName="id" hidden />
        <mat-form-field>
          <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
          <input matInput type="text" formControlName="name" /> </mat-form-field
        ><br />
        <mat-form-field>
          <mat-label i18n="Form field label for description|@@description"
            >Description</mat-label
          >
          <textarea
            matInput
            cdkTextareaAutosize
            formControlName="description"
          ></textarea>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <a
          class="cancel-link cancel"
          style="margin-right: 0.66em"
          mat-dialog-close
          i18n="@@cancel-btn"
        >
          Cancel
        </a>
        <button
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="!fg.valid"
          (click)="save()"
          i18n="@@save-btn"
        >
          SAVE
        </button>
      </mat-dialog-actions>
    </form>`,
})
export class UpsertSecurityMeasureDialogComponent {
  get title() {
    return $localize`:@@create-or-edit-security-measure:${
      this.data.upsert.id > 0 ? 'Edit' : 'Create'
    } Security Measure`;
  }

  fg: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: UpsertInlineDialogData<UpsertSecurityMeasure>,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpsertSecurityMeasureDialogComponent>,
    private store: Store<GdprToolState>
  ) {
    this.fg = fb.group({
      id: [data.upsert.id, Validators.required],
      name: [data.upsert.name, Validators.required],
      description: data.upsert.description,
    });
  }

  save() {
    if (this.data.inline && this.data.inline === true && this.data.originId)
      this.store.dispatch(
        upsertSecurityMeasureInline({
          upsert: this.fg.value,
          originId: this.data.originId,
        })
      );
    else if (this.data.inline && this.data.originId === undefined)
      throw new Error('OriginId must be specified.');
    else this.store.dispatch(upsertSecurityMeasure(this.fg.value));
    this.dialogRef.close();
  }
}
