<div class="dashboard">
  <h1>
    <mat-icon inline>waving_hand</mat-icon>&nbsp;
    <span i18n="@@welcome">Hi</span> {{ user?.firstName }}
  </h1>
  <div class="main-overview">
    <mat-card class="dashboard-card" (click)="navigate(controllersRoPAHref)">
      <mat-card-header>
        <h1
          class="info-title"
          mat-card-title
          i18n="@@data-register-dashboard-title"
        >
          Controller

          <!-- <ca-info-hover [info]="dataRegisterInfo"></ca-info-hover> -->
        </h1>
        <div mat-card-subtitle i18n="@@data-register-controller-subtitle">
          Records of processing activity
        </div>
      </mat-card-header>

      <mat-card-actions align="end">
        <button mat-icon-button (click)="navigate(controllersRoPAHref)">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="dashboard-card" (click)="navigate(processorsRoPAHref)">
      <mat-card-header>
        <h1
          class="info-title"
          mat-card-title
          i18n="@@data-register-dashboard-title"
        >
          Processor
        </h1>
        <div mat-card-subtitle i18n="@@data-register-processor-subtitle">
          Records of processing activity
        </div>
      </mat-card-header>
      <mat-card-actions align="end">
        <button mat-icon-button (click)="navigate(processorsRoPAHref)">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card
      class="dashboard-card"
      [routerLink]="['/dashboard', 'questionnaire']"
      id="getting-started-card"
    >
      <mat-card-header>
        <h1
          class="info-title"
          mat-card-title
          i18n="@@shortcuts-dashboard-title"
        >
          Getting started
        </h1>
      </mat-card-header>
      <mat-card-content>
        <div mat-card-subtitle i18n="@@shortcuts-subtitle">
          Build your data register
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-icon-button [routerLink]="['/dashboard', 'questionnaire']">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<ngx-guided-tour [doneText]="tourDoneText"></ngx-guided-tour>
