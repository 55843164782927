import { Component, Input } from '@angular/core';
import { DSR } from '@ca/gdpr';
import { Store } from '@ngrx/store';
import { GdprToolState } from '../../../store';
import { Observable } from 'rxjs';
import { ProcessingActivityGroups } from '@ca/gdpr';

@Component({
  selector: 'ca-process-dsr',
  template: `
    <div class="process-dsr">
      <section *ngFor="let d of data$ | async; let i = index">
        <mat-checkbox class="purpose-checkbox">{{
          d.purpose.name
        }}</mat-checkbox>
        <span>
          <ul>
            <li *ngFor="let r of d.rows">
              <mat-checkbox>
                {{ r.dataCategory.name }}
              </mat-checkbox>
            </li>
          </ul>
        </span>
      </section>
    </div>
  `,
  styleUrls: ['./process-dsr.component.scss'],
})
export class ProcessDsrComponent {
  @Input() dsr!: DSR;

  protected data$?: Observable<ProcessingActivityGroups>;

  constructor(private store: Store<GdprToolState>) {}

  // ngOnInit(): void {
  // this.data$ = this.store
  //   .select((state) => selectDSRMarkedRoPA(state, this.dsr.id))
  //   .pipe(map((d) => d.markedRoPA));
  // }
}
