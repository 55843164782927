import { DeleteRequest } from '@ca/ca-data';
import { createAction, props } from '@ngrx/store';
import {
  ExternalController,
  UpsertExternalControllerActionData,
  DataLocation,
  DisclosedTo,
  CompanySettings,
  ExternalProcessor,
  LegalGround,
  DataSubjectType,
  SecurityMeasure,
  TransferOutsideEEA,
  ProcessingPurpose,
  DataCategory,
  UpsertDataLocation,
  UpsertDisclosedTo,
  UpsertExternalProcessorActionData,
  UpsertDataSubjectTypeActionData,
  UpsertSecurityMeasure,
  UpsertLegalGroundActionData,
  UpsertTransferOutsideEEAActionData,
  UpsertProcessingPurposeActionData,
  UpsertDataCategoryActionData,
} from '@ca/gdpr';
import { GdprToolActionType } from '../action.types';
import {
  GetSingle,
  UpsertDataLocationResult,
  UpsertDisclosedToResult,
  DeleteDataLocationResult,
  UpsertExternalProcessorResult,
  UpsertSubjectTypeResult,
  UpsertSecurityMeasureResult,
  DeleteExternalProcessorResult,
  DeleteSubjectTypeResult,
  DeleteExternalControllerResult,
  DeleteSecurityMeasureResult,
  UpsertLegalGroundResult,
  DeleteLegalGroundResult,
  UpsertTransferOutsideEEAResult,
  DeleteTransfersOutsideEEAResult,
  DeleteDisclosedToResult,
  DeleteProcessingPurposeTypeResult,
  UpsertProcessingPurposeResult,
  DeleteDataCategoryResult,
  UpsertDataCategoryResult,
  UpsertExternalControllerResult,
  UpsertSecurityMeasureInlineResult,
  UpsertDisclosedToInlineResult,
  UpsertProcessingPurposeInlineResult,
  UpsertDataLocationInlineResult,
  DeleteReplyToAddressResult,
} from '../models';
import { UpsertInline } from '../reducers/settings.reducer';
/**
 * Load
 */
//#region LOAD
export const loadReplyToAddresses = createAction(
  GdprToolActionType.LoadReplyToAddresses
);
export const loadReplyToAddressesSuccess = createAction(
  GdprToolActionType.LoadReplyToAddressesSuccess
);
export const loadDataLocations = createAction(
  GdprToolActionType.LoadDataLocations
);
export const loadDataLocationsSuccess = createAction(
  GdprToolActionType.LoadDataLocationsSuccess,
  props<{ dataLocations: DataLocation[] }>()
);
export const loadSingleDataLocation = createAction(
  GdprToolActionType.LoadSingleDataLocation,
  props<GetSingle>()
);
export const LoadSingleDataLocationSuccess = createAction(
  GdprToolActionType.LoadSingleDataLocationSuccess,
  props<{ dataLocation: DataLocation[] }>()
);
export const loadDisclosedTo = createAction(GdprToolActionType.LoadDisclosedTo);
export const loadDisclosedToSuccess = createAction(
  GdprToolActionType.LoadDisclosedToSuccess,
  props<{ disclosedTo: DisclosedTo[] }>()
);
export const loadCompanySettings = createAction(
  GdprToolActionType.LoadCompanySettings
);
export const loadCompanySettingsSuccess = createAction(
  GdprToolActionType.LoadCompanySettingsSuccess,
  props<{ companySettings: CompanySettings }>()
);
export const loadExternalProcessors = createAction(
  GdprToolActionType.LoadExternalProcessors
);
export const loadExternalProcessorsSucces = createAction(
  GdprToolActionType.LoadExternalProcessorsSuccess,
  props<{ externalProcessors: ExternalProcessor[] }>()
);
export const loadLegalGrounds = createAction(
  GdprToolActionType.LoadLegalGrounds
);
export const loadLegalGroundsSuccess = createAction(
  GdprToolActionType.LoadLegalGroundsSuccess,
  props<{ legalGrounds: LegalGround[] }>()
);
export const loadDataSubjectTypes = createAction(
  GdprToolActionType.LoadDataSubjectTypes
);
export const loadDataSubjectTypesSucces = createAction(
  GdprToolActionType.LoadDataSubjectTypesSuccess,
  props<{ subjectTypes: DataSubjectType[] }>()
);
export const LoadExternalControllers = createAction(
  GdprToolActionType.LoadExternalControllers
);
export const LoadExternalControllersSuccess = createAction(
  GdprToolActionType.LoadExternalControllersSuccess,
  props<{ externalControllers: ExternalController[] }>()
);
export const loadSecurityMeasures = createAction(
  GdprToolActionType.LoadSecurityMeasures
);
export const loadSecurityMeasuresSucces = createAction(
  GdprToolActionType.LoadSecurityMeasuresSuccess,
  props<{ securityMeasures: SecurityMeasure[] }>()
);
export const loadTransfersOutsideEEA = createAction(
  GdprToolActionType.LoadTransfersOutsideEEA
);
export const loadTransfersOutsideEEASuccess = createAction(
  GdprToolActionType.LoadTransfersOutsideEEASuccess,
  props<{ transfersOutsideEEA: TransferOutsideEEA[] }>()
);
export const loadProcessingPurposes = createAction(
  GdprToolActionType.LoadProcessingPurposes
);
export const loadProcessingPurposesSuccess = createAction(
  GdprToolActionType.LoadProcessingPurposesSuccess,
  props<{ processingPurposes: ProcessingPurpose[] }>()
);
export const loadDataCategories = createAction(
  GdprToolActionType.LoadDataCategories
);
export const loadDataCategoriesSuccess = createAction(
  GdprToolActionType.LoadDataCategoriesSuccess,
  props<{ dataCategories: DataCategory[] }>()
);
//#endregion

//#region UPSERT
export const removeFormUpsertInlineQueue = createAction(
  GdprToolActionType.removeFormUpsertInlineQueue,
  props<{ originId: string }>()
);
export const upsertDataLocation = createAction(
  GdprToolActionType.UpsertDataLocation,
  props<UpsertDataLocation>()
);
export const upsertDataLocationSuccess = createAction(
  GdprToolActionType.UpsertDataLocationSuccess,
  props<UpsertDataLocationResult>()
);
export const upsertDataLocationInline = createAction(
  GdprToolActionType.UpsertDataLocationInline,
  props<UpsertInline<UpsertDataLocation>>()
);
export const upsertDataLocationInlineSuccess = createAction(
  GdprToolActionType.UpsertDataLocationInlineSuccess,
  props<UpsertDataLocationInlineResult>()
);
export const upsertDisclosedTo = createAction(
  GdprToolActionType.UpsertDisclosedTo,
  props<UpsertDisclosedTo>()
);
export const upsertDisclosedToSuccess = createAction(
  GdprToolActionType.UpsertDisclosedToSuccess,
  props<UpsertDisclosedToResult>()
);
export const upsertDisclosedToInline = createAction(
  GdprToolActionType.UpsertDisclosedToInline,
  props<UpsertInline<UpsertDisclosedTo>>()
);
export const upsertDisclosedToInlineSuccess = createAction(
  GdprToolActionType.UpsertDisclosedToInlineSuccess,
  props<UpsertDisclosedToInlineResult>()
);
export const upsertExternalProcessor = createAction(
  GdprToolActionType.UpsertExternalProcessor,
  props<UpsertExternalProcessorActionData>()
);
export const upsertExternalProcessorSuccess = createAction(
  GdprToolActionType.UpsertExternalProcessorSuccess,
  props<UpsertExternalProcessorResult>()
);
export const upsertSubjectType = createAction(
  GdprToolActionType.UpsertDataSubjectType,
  props<UpsertDataSubjectTypeActionData>()
);
export const upsertSubjectTypeSuccess = createAction(
  GdprToolActionType.UpsertDataSubjectTypeSuccess,
  props<UpsertSubjectTypeResult>()
);
export const upsertSecurityMeasure = createAction(
  GdprToolActionType.UpsertSecurityMeasure,
  props<UpsertSecurityMeasure>()
);
export const upsertSecurityMeasureInline = createAction(
  GdprToolActionType.UpsertSecurityMeasureInline,
  props<UpsertInline<SecurityMeasure>>()
);

export const upsertSecurityMeasureSuccess = createAction(
  GdprToolActionType.UpsertSecurityMeasureSuccess,
  props<UpsertSecurityMeasureResult>()
);
export const upsertSecurityMeasureInlineSuccess = createAction(
  GdprToolActionType.UpsertSecurityMeasureInlineSuccess,
  props<UpsertSecurityMeasureInlineResult>()
);

export const UpsertExternalController = createAction(
  GdprToolActionType.UpsertExternalController,
  props<UpsertExternalControllerActionData>()
);
export const UpsertExternalControllerSuccess = createAction(
  GdprToolActionType.UpsertExternalControllerSuccess,
  props<UpsertExternalControllerResult>()
);
export const upsertLegalGround = createAction(
  GdprToolActionType.UpsertLegalGround,
  props<UpsertLegalGroundActionData>()
);
export const upsertLegalGroundSuccess = createAction(
  GdprToolActionType.UpsertLegalGroundSuccess,
  props<UpsertLegalGroundResult>()
);
export const upsertTransferEEA = createAction(
  GdprToolActionType.UpsertTransfersOutsideEEA,
  props<UpsertTransferOutsideEEAActionData>()
);
export const upsertTransferEEASuccess = createAction(
  GdprToolActionType.UpsertTransfersOutsideEEASuccess,
  props<UpsertTransferOutsideEEAResult>()
);
export const upsertProcessingPurpose = createAction(
  GdprToolActionType.UpsertProcessingPurpose,
  props<UpsertProcessingPurposeActionData>()
);
export const upsertProcessingPurposeSuccess = createAction(
  GdprToolActionType.UpsertProcessingPurposeSuccess,
  props<UpsertProcessingPurposeResult>()
);
export const upsertProcessingPurposeInline = createAction(
  GdprToolActionType.UpsertProcessingPurposeInline,
  props<UpsertInline<UpsertProcessingPurposeActionData>>()
);
export const upsertProcessingPurposeInlineSuccess = createAction(
  GdprToolActionType.UpsertProcessingPurposeInlineSuccess,
  props<UpsertProcessingPurposeInlineResult>()
);
export const upsertDataCategory = createAction(
  GdprToolActionType.UpsertDataCategory,
  props<UpsertDataCategoryActionData>()
);
export const upsertDataCategorySuccess = createAction(
  GdprToolActionType.UpsertDataCategorySuccess,
  props<UpsertDataCategoryResult>()
);

//#endregion

//#region DELETE
export const deleteExternalProcessor = createAction(
  GdprToolActionType.DeleteExternalProcessor,
  props<DeleteRequest>()
);
export const deleteExternalProcessorSuccess = createAction(
  GdprToolActionType.DeleteExternalProcessorSuccess,
  props<DeleteExternalProcessorResult>()
);
export const deleteSubjectType = createAction(
  GdprToolActionType.DeleteDataSubjectType,
  props<DeleteRequest>()
);
export const deleteSubjectTypeSuccess = createAction(
  GdprToolActionType.DeleteDataSubjectTypeSuccess,
  props<DeleteSubjectTypeResult>()
);
export const deleteReplyToAddress = createAction(
  GdprToolActionType.DeleteReplyToAddress,
  props<DeleteRequest>()
);

export const deleteReplyToAddressSuccess = createAction(
  GdprToolActionType.DeleteReplyToAddressSuccess,
  props<DeleteReplyToAddressResult>()
);
export const deleteProcessingPurpose = createAction(
  GdprToolActionType.DeleteProcessingPurpose,
  props<DeleteRequest>()
);
export const deleteProcessingPurposeSuccess = createAction(
  GdprToolActionType.DeleteProcessingPurposeSuccess,
  props<DeleteProcessingPurposeTypeResult>()
);
export const DeleteExternalController = createAction(
  GdprToolActionType.DeleteExternalController,
  props<DeleteRequest>()
);
export const DeleteExternalControllerSuccess = createAction(
  GdprToolActionType.DeleteExternalControllerSuccess,
  props<DeleteExternalControllerResult>()
);
export const deleteSecurityMeasure = createAction(
  GdprToolActionType.DeleteSecurityMeasure,
  props<DeleteRequest>()
);
export const deleteSecurityMeasureSuccess = createAction(
  GdprToolActionType.DeleteSecurityMeasureSuccess,
  props<DeleteSecurityMeasureResult>()
);
export const deleteDataLocation = createAction(
  GdprToolActionType.DeleteDataLocation,
  props<DeleteRequest>()
);
export const deleteDataLocationSuccess = createAction(
  GdprToolActionType.DeleteDataLocationSuccess,
  props<DeleteDataLocationResult>()
);
export const deleteLegalGround = createAction(
  GdprToolActionType.DeleteLegalGround,
  props<DeleteRequest>()
);
export const deleteLegalGroundSuccess = createAction(
  GdprToolActionType.DeleteLegalGroundSuccess,
  props<DeleteLegalGroundResult>()
);
export const deleteTransfersOutsideEEA = createAction(
  GdprToolActionType.DeleteTransfersOutsideEEA,
  props<DeleteRequest>()
);
export const deleteTransferOutsideEEASuccess = createAction(
  GdprToolActionType.DeleteTransfersOutsideEEASuccess,
  props<DeleteTransfersOutsideEEAResult>()
);
export const deleteDisclosedTo = createAction(
  GdprToolActionType.DeleteDisclosedTo,
  props<DeleteRequest>()
);
export const deleteDisclosedToSuccess = createAction(
  GdprToolActionType.DeleteDisclosedToSuccess,
  props<DeleteDisclosedToResult>()
);
export const deleteDataCategory = createAction(
  GdprToolActionType.DeleteDataCategory,
  props<DeleteRequest>()
);
export const deleteDataCategorySuccess = createAction(
  GdprToolActionType.DeleteDataCategorySuccess,
  props<DeleteDataCategoryResult>()
);
//#endregion
