import { ChangeDetectorRef, Component } from '@angular/core';
import { Remark } from '@ca/core';

@Component({
  selector: 'ca-remarks-list',
  template: `
    <!-- TODO: ADD REMOVE AND EDIT OPTIONS ON CLICK -->
    <div class="remarks">
      <div class="remark-group" *ngFor="let remarksGroup of remarks">
        <div class="remark-group-header">
          <p class="remark-group-title bold">{{ remarksGroup.header }}</p>
        </div>
        <div
          class="remark-group-remarks"
          *ngFor="let remark of remarksGroup.remarks"
        >
          <p class="remark">
            {{ remark.remark }}
          </p>
          <span class="remark-info" *ngIf="remark.createdAt !== null">
            {{ remark.createdByUserName }} - {{ remark.createdAt }}</span
          >
        </div>
      </div>
      <div *ngIf="remarks === undefined || remarks.length === 0">
        <p class="info">
          Remarks will be shown here. Add a remark by clicking the
          <mat-icon inline color="primary">feedback</mat-icon> button, located
          in the top-right corner of each step.
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      .remarks {
        .remark-group-remarks {
          display: flex;
          flex-direction: column;
          margin: 1em;

          .remark-group-header,
          .remark-group-remarks {
            border-radius: 1.33em;
            padding: 0.66em;
            margin-bottom: 0.66em;
            width: fit-content;
          }

          .remark {
            text-align: left;
            align-self: flex-start;
            overflow-wrap: normal;
            max-width: 20vw;
          }
          .remark-info {
            margin: 1em;
            align-self: flex-end;
            text-align: right;
            color: gray;
          }
        }
      }
    `,
  ],
})
export class RemarksListComponent {
  remarks?: { key: string; header: string; remarks: Remark[]; order: number }[];

  visible = false;
  constructor(private cdRef: ChangeDetectorRef) {}

  public update(args: {
    remarks: Remark[];
    key: string;
    header: string;
    order: number;
  }) {
    if (this.remarks) {
      const existing = this.remarks.find((e) => e.key === args.key);
      if (existing)
        this.remarks = this.remarks.filter((e) => e.key !== args.key);
    } else this.remarks = [];

    this.remarks.push(args);
    this.remarks = this.remarks.sort((a, b) => a.order - b.order);
    this.cdRef.detectChanges();
  }
}
