import { Component, Input } from '@angular/core';
import { DSR } from '@ca/gdpr';
import { GetInvokedRightLabel } from '../../../utils/dsr';

@Component({
  selector: 'ca-dsr-status-badge',
  template: ` <mat-chip-listbox>
    <ng-container *ngIf="dsr.invokedRight">
      <mat-chip color="light" [highlighted]="true">{{
        GetInvokedRightLabel(dsr.invokedRight)
      }}</mat-chip>
    </ng-container>
    <mat-chip
      [color]="
        ['Waiting for Data Subject'].includes(dsr.status) ? 'accent' : 'light'
      "
      [highlighted]="true"
      >{{ dsr.status }}</mat-chip
    >

    <mat-chip
      [color]="dsr.identityConfirmed ? 'success' : 'failure'"
      [highlighted]="true"
    >
      <mat-icon>how_to_reg</mat-icon>
    </mat-chip>
  </mat-chip-listbox>`,
})
export class DsrStatusBadgeComponent {
  @Input() dsr!: DSR;
  GetInvokedRightLabel = GetInvokedRightLabel;
}
