import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ca-info-modal',
  template: `<h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <div [innerHTML]="data.htmlContent"></div>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close i18n="@@close-btn">CLOSE</button>
    </div> `,
})
export class InfoModalComponent {
  constructor(
    protected ref: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected data: { title: string; htmlContent: string }
  ) {}
}
