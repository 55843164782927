<div
  id="active-question-container"
  *ngIf="activeQuestion && questionnaire"
  [class.a-b-question]="activeQuestion.type === 'A/B'"
  [class.a-b-other-question]="activeQuestion.type === 'A/B/other'"
  [class.single-select-question]="activeQuestion.type === 'single'"
  [class.multi-select-question]="activeQuestion.type === 'multi'"
>
  <mat-progress-bar
    mode="determinate"
    [value]="questionnaire.progress | async"
  ></mat-progress-bar>

  <ng-container *ngIf="!completed">
    <h1 id="active-question">{{ activeQuestion.text }}</h1>
    <h3
      *ngIf="activeQuestion.type === 'single' || activeQuestion.type === 'A/B'"
    >
      Please select one
    </h3>

    <h3 *ngIf="activeQuestion.type === 'multi'">Select one or more</h3>
    <h3 *ngIf="activeQuestion.type === 'multi/other'">
      Select one or more, or specify a new one
    </h3>
    <h3
      *ngIf="
        (activeQuestion.type === 'A/B/other' ||
          activeQuestion.type === 'single/other') &&
        !questionnaire.showOther
      "
    >
      Select one or specify a new one
    </h3>
    <h3 *ngIf="activeQuestion.type === 'A/B/other' && questionnaire.showOther">
      Specify a new one
    </h3>

    <div id="active-question-answers" *ngIf="!questionnaire.showOther">
      <div
        class="active-question-answer"
        *ngFor="let answer of activeQuestion.answers"
        (click)="questionnaire.activateAnswer(activeQuestion, answer)"
        [class.selected]="answer.selected"
        [class.other]="answer.type === 'user defined'"
      >
        <img *ngIf="answer.img" [src]="answer.img.src" [alt]="answer.img.alt" />
        <span class="answer-text">{{ answer.text }}</span>
      </div>
    </div>

    <div id="other-answer" *ngIf="questionnaire.showOther">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input type="text" matInput [(ngModel)]="questionnaire.otherAnswer" />
      </mat-form-field>
    </div>

    <div id="actions">
      <button mat-button *ngIf="showBack" (click)="questionnaire.goBack()">
        Back
      </button>
      <button
        *ngIf="
          questionnaire.showOther ||
          (activeQuestion.type !== 'A/B/other' &&
            activeQuestion.type !== 'A/B' &&
            activeQuestion.type !== 'single/other' &&
            activeQuestion.type !== 'single')
        "
        mat-raised-button
        color="primary"
        [disabled]="
          (questionnaire.showOther && questionnaire.otherAnswer === null) ||
          (!questionnaire.showOther && !questionnaire.validAnswer)
        "
        (click)="questionnaire.proceed(activeQuestion)"
      >
        <!-- TODO: also disable when no answers are selected! -->
        Proceed
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="completed">
    <pre>{{ summary | json }}</pre>
  </ng-container>
</div>

<div id="questionnaire-summary">
  <h3>{{ summary?.title }}</h3>
  <div *ngFor="let s of summary?.answeredQuestions" class="answer-box">
    <span class="bold">{{ s.question.text }} ({{ s.question.type }})</span>
    <span class="answer">
      <ng-container *ngFor="let a of s.answers">
        {{ a.text }} ({{ a.type }})
      </ng-container>
    </span>
  </div>
</div>
