import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ExternalProcessor } from '@ca/gdpr';
import { GdprToolState, selectExternalProcessors } from '../../../../store';

@Component({
  selector: 'ca-external-processors-table',
  templateUrl: './external-processors-table.component.html',
  styleUrls: ['./external-processors-table.component.scss'],
})
export class ExternalProcessorsTableComponent {
  protected displayedColumns: string[] = ['select', 'name', 'email'];
  protected dataSource?: MatTableDataSource<ExternalProcessor>;
  protected selection = new SelectionModel<ExternalProcessor>(true, []);

  get selected() {
    return this.selection.selected.map((e) => `${e.name}`).join(', ');
  }

  constructor(protected store: Store<GdprToolState>) {
    this.store
      .select(selectExternalProcessors)
      .subscribe((externalProcessors) => {
        if (externalProcessors !== null && externalProcessors !== undefined) {
          const exP = externalProcessors.map((e) => ({
            ...e,
            name: e.name,
            email: e.email,
          }));
          this.dataSource = new MatTableDataSource<ExternalProcessor>(exP);
        }
      });
  }

  protected isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.dataSource) {
      if (this.isAllSelected()) {
        this.selection.clear();

        return;
      }

      this.selection.select(...this.dataSource.data);
    }
  }
}
