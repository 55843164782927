import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CaSubscriber } from '@ca/ca-utils';
import { ProcessingPurpose } from '@ca/gdpr';
import { Store } from '@ngrx/store';
import {
  GdprToolState,
  selectProcessingPurposesAndInlineUpserts,
} from '../../../../store';
import { MatDialog } from '@angular/material/dialog';
import { InlineInsertedResource } from '../../../../store/reducers/settings.reducer';
import { UpsertProcessingPurposeComponent } from '../../dialogs/upsert-processing-purpose/upsert-processing-purpose.component';
import { newProcessingPurpose } from '../../../../config/defaults';
import { removeFormUpsertInlineQueue } from '../../../../store/actions/settings.actions';
import { ProcessingPurposesSelectorInfoHover } from '../../../../config/info-hovers';

@Component({
  selector: 'ca-processing-purpose-selector',
  template: `<div class="info-hover-form-field" [formGroup]="formGroup">
    <mat-form-field class="stretch">
      <mat-label>{{ label }}</mat-label>
      <mat-select
        *ngIf="options"
        [formControlName]="formControlName"
        multiple
        [compareWith]="compareFn"
      >
        <mat-option *ngFor="let v of options" [value]="v">
          {{ v.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button (click)="addNew()">
      <mat-icon>add</mat-icon>
    </button>
    <!-- <ca-info-hover [info]="info"></ca-info-hover>-->
  </div>`,
})
export class ProcessingPurposeSelectorComponent implements OnInit, OnDestroy {
  @Input() formGroup!: FormGroup;
  private sub = new CaSubscriber();
  info = ProcessingPurposesSelectorInfoHover;
  options?: ProcessingPurpose[];
  protected label = $localize`:@@processing-purpose:Processing Purpose`;
  protected formControlName = 'purposes';
  // private restoreValue: number[] | null = null;
  originId: string;
  get fc() {
    return this.formGroup.controls[this.formControlName];
  }
  constructor(private store: Store<GdprToolState>, private dialog: MatDialog) {
    this.originId = crypto.randomUUID();
  }

  ngOnInit(): void {
    this.sub.subscribe(
      this.store.select(selectProcessingPurposesAndInlineUpserts),
      {
        next: (v: {
          purposes?: ProcessingPurpose[];
          inlineInserted?: InlineInsertedResource[];
        }) => {
          const restoreValue = this.fc.value;
          this.options = v.purposes ?? [];
          if (v.inlineInserted != undefined && v.inlineInserted.length > 0) {
            v.inlineInserted = v.inlineInserted.filter(
              (x) =>
                restoreValue.map((e: { id: number }) => e.id).includes(x.id) ===
                false
            );
            const insertValues = this.options?.filter((e) =>
              v.inlineInserted?.map((e) => e.id).includes(e.id)
            );
            if (insertValues.length === 0) this.fc.setValue(restoreValue);
            else if (insertValues.length > 0) {
              this.fc.setValue(
                restoreValue
                  ? [...restoreValue, ...insertValues]
                  : [...insertValues]
              );
              this.store.dispatch(
                removeFormUpsertInlineQueue({
                  originId: this.originId,
                })
              );
            }
          }
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  protected compareFn = compareProcessingPurposes;

  addNew() {
    this.dialog.open(UpsertProcessingPurposeComponent, {
      data: {
        upsert: newProcessingPurpose,
        inline: true,
        originId: this.originId,
      },
      disableClose: true,
    });
  }
}
export const compareProcessingPurposes = (
  a: ProcessingPurpose,
  b: ProcessingPurpose
) => a.id === b.id;
