<h1 mat-dialog-title i18n="@@create-or-update-user-title">
  {{ data.id > 0 ? 'Update' : 'Create' }} User
</h1>
<div mat-dialog-content>
  <form [formGroup]="fg">
    <input hidden formControlName="id" />

    <mat-form-field>
      <mat-label i18n="@@first-name">First name</mat-label>
      <input matInput formControlName="firstName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@last-name">Last name</mat-label>
      <input matInput formControlName="lastName" />
    </mat-form-field>
    <mat-divider></mat-divider>

    <p>
      <mat-form-field>
        <mat-label i18n="@@email">E-Mail</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@preferred-language">Preferred Language</mat-label>
        <mat-select formControlName="preferredLanguage">
          <mat-option *ngFor="let l of languagesForSelect" [value]="l">
            {{ l }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field>
        <mat-label i18n="@@mobile">Mobile</mat-label>
        <input matInput formControlName="gsm" />
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@telephone">Telephone</mat-label>
        <input matInput formControlName="tel" />
      </mat-form-field>
    </p>

    <p>
      <mat-slide-toggle formControlName="active" i18n="@@active"
        >Active</mat-slide-toggle
      >
    </p>
    <p>
      <mat-slide-toggle formControlName="twoFaEnabled">{{
        toggle2FALabel
      }}</mat-slide-toggle>
    </p>

    <mat-divider></mat-divider>

    <h4 i18n="@@roles-title">Roles</h4>
    <div formArrayName="linkedDivisions">
      <div *ngFor="let division of divisions.controls; let i = index">
        <div [formGroupName]="i">
          <mat-form-field>
            <mat-label i18n="@@division">Division</mat-label>
            <mat-select formControlName="divisionsId">
              <mat-option *ngFor="let d of divisionsForSelect" [value]="d.id">{{
                d.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n="@@role">Role</mat-label>
            <mat-select formControlName="rolesId">
              <mat-option
                *ngFor="let d of rolesForSelect"
                [disabled]="d.accessLevel < accessLevel"
                [value]="d.id"
                >{{ d.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n="@@job-function">Job Title</mat-label>
            <input matInput formControlName="jobTitle" />
          </mat-form-field>
          <button
            mat-icon-button
            [disabled]="divisions.controls.length === 1"
            type="button"
            (click)="removeDivision(i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </div>
      <button
        mat-button
        type="button"
        (click)="addDivision($event)"
        i18n="@@add-role-btn"
      >
        <mat-icon>add</mat-icon> ADD ROLE
      </button>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n="@@cancel-btn">CANCEL</button>
  <button mat-raised-button color="primary" (click)="save()">
    <mat-icon>save</mat-icon> <span i18n="@@save-btn">SUBMIT</span>
  </button>
</div>
