import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import {
  CaSubscriber,
  displayNamedObjectOrString,
  filterByNamedObjectOrString,
  isString,
} from '@ca/ca-utils';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { Observable, map, startWith } from 'rxjs';
import {
  ExternalController,
  ProcessingActivityRole,
  DataLocation,
  DataSubjectType,
  DisclosedTo,
  LegalGround,
  ProcessingPurpose,
  TransferOutsideEEA,
  UpsertDataSubjectTypeActionData,
} from '@ca/gdpr';
import {
  GdprToolState,
  selectAddSubjectRegisterSettingsData,
  selectProcessingPurposes,
} from '../../../store';
import {
  CreatePurposeConfigurationFG,
  CreateSubjectAndPurposeFG,
} from '../../../utils/forms';
import { UpsertRoPAGroup } from '../../../store/actions/data-register.actions';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ca-add-subject-register',
  templateUrl: './add-subject-register.component.html',
  styleUrls: ['./add-subject-register.component.scss'],
})
export class AddSubjectRegisterComponent implements OnInit, OnDestroy {
  @Input() role!: ProcessingActivityRole;
  filteredDataSubjectTypes?: Observable<DataSubjectType[]>;
  dataSubjectTypeForSelect?: DataSubjectType[];
  private sub = new CaSubscriber();

  subjectAndPurposeFG: FormGroup;
  purposesConfigurationFG: FormGroup;

  title = $localize`:@@create-data-subject-register-title:Create a Register for a new Data Subject Type`;
  nameLabel = $localize`:@@data-subject-name-question:Who is the subject of the information?`;
  nameInfo = $localize`:@@data-subject-category-question:This is used to categorize the subjects of the personal data that we process. "Employees", "Clients", "Suppliers" are common data subject types`;
  purposesLabel = $localize`:@@processing-purpose-question:Why do you process this information?`;
  purposeInfo = $localize`:@@processing-purpose-info:Purposes describe our intent and reason for processing personal data`;

  selectedPurposes?: ProcessingPurpose[];
  selectedPurposeNames: string[] = [];
  purposes: ProcessingPurpose[] = [];
  dataLocations?: DataLocation[];
  disclosedTos?: DisclosedTo[];
  externalControllers?: ExternalController[];
  legalGrounds?: LegalGround[];
  transfersOutsideEEA?: TransferOutsideEEA[];

  get RoPAGroupFormArray() {
    return this.purposesConfigurationFG.get(
      'purposeConfigurations'
    ) as FormArray<FormGroup>;
  }

  constructor(
    protected store: Store<GdprToolState & { onboarding: OnboardingState }>,
    private fb: FormBuilder,
    private ref: MatDialogRef<AddSubjectRegisterComponent>
  ) {
    this.subjectAndPurposeFG = CreateSubjectAndPurposeFG(this.fb);

    this.purposesConfigurationFG = this.fb.group({
      purposeConfigurations: this.fb.array([], Validators.minLength(1)),
    });

    this.sub.subscribe(this.store.select(selectProcessingPurposes), {
      next: (purposes?: ProcessingPurpose[]) => {
        if (purposes) this.purposes = purposes;
      },
    });

    this.sub.subscribe(
      this.store.select(selectAddSubjectRegisterSettingsData),
      {
        next: (data) => {
          if (data.dataSubjectTypes)
            this.dataSubjectTypeForSelect = data.dataSubjectTypes;
          if (data.processingPurposes) this.purposes = data.processingPurposes;
          if (data.dataLocations) this.dataLocations = data.dataLocations;
          if (data.disclosedTo) this.disclosedTos = data.disclosedTo;
          if (data.externalControllers)
            this.externalControllers = data.externalControllers;
          if (data.legalGrounds) this.legalGrounds = data.legalGrounds;
          if (data.transfersOutsideEEA)
            this.transfersOutsideEEA = data.transfersOutsideEEA;
        },
      }
    );
  }

  ngOnInit() {
    this.filteredDataSubjectTypes = this.subjectAndPurposeFG
      .get('name')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterDataSubjectTypesForSelect(value || ''))
      );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  confirmSubjectAndPurposes(stepper: MatStepper) {
    const v: {
      name: string | DataSubjectType;
      purposes: ProcessingPurpose[];
    } = this.subjectAndPurposeFG.value;

    const insertSubjectType: UpsertDataSubjectTypeActionData = isString(v.name)
      ? {
          id: 0,
          name: v.name,
        }
      : v.name;

    this.RoPAGroupFormArray.controls = [];
    this.selectedPurposeNames = [];
    // Create UpsertRoPAGroup foreach purpose
    v.purposes.forEach((p) => {
      this.selectedPurposeNames.push(p.name);
      this.RoPAGroupFormArray.controls.push(
        CreatePurposeConfigurationFG(this.fb, {
          dataSubjectType: insertSubjectType,
          purpose: p,
          role: this.role,
          id: 0,
        })
      );
    });
    this.purposesConfigurationFG.updateValueAndValidity();
    stepper.next();
  }

  get UpsertData() {
    return this.RoPAGroupFormArray.getRawValue();
  }

  addDataSubjectTypeRegister() {
    // dispatch upsert action to create group
    const v = this.RoPAGroupFormArray.getRawValue();
    v.forEach((group: any) => {
      if (group.dataSubjectType !== undefined && group.dataSubjectType.id > 0) {
        group.dataSubjectTypeId = group.dataSubjectType.id;
      }
      this.store.dispatch(UpsertRoPAGroup(group));
    });
    this.ref.close();
  }

  private _filterDataSubjectTypesForSelect(
    value: DataSubjectType | string
  ): DataSubjectType[] {
    return filterByNamedObjectOrString(value, this.dataSubjectTypeForSelect);
  }

  protected displayFn(option?: DataSubjectType | string) {
    return displayNamedObjectOrString(option);
  }
}
