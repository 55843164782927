import { Store } from '@ngrx/store';
import { newDataCategory } from '../config/defaults';
import {
  ControllerRoPAGroup,
  ControllerRoPARow,
  ProcessorRoPAGroup,
  ProcessorRoPARow,
} from '@ca/gdpr';
import {
  AddCategoryFromRegister,
  UpsertCategoryFromRegister,
} from '../store/actions/data-register.actions';

export function DispatchAddCategoryFromRegisterAction(
  store: Store,
  group: ControllerRoPAGroup | ProcessorRoPAGroup
) {
  store.dispatch(
    AddCategoryFromRegister({
      mode: 'controller',
      parent: group,
      upsertDataCategory: newDataCategory,
      dataCategoryIdsToExclude: group.rows.map((c) => c.dataCategory.id),
    })
  );
}

export function DispatchUpdateCategoryFromRegisterAction(
  store: Store,
  group: ControllerRoPAGroup | ProcessorRoPAGroup,
  row: ControllerRoPARow | ProcessorRoPARow
) {
  store.dispatch(
    UpsertCategoryFromRegister({
      mode: 'controller',
      parent: group,
      upsertDataCategory: newDataCategory,
      dataCategoryIdsToExclude: group.rows.map((c) => c.dataCategory.id),
      row,
    })
  );
}
