import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GdprToolState } from '../../../../store';
import { FormControl, Validators } from '@angular/forms';
import { UpsertRemark } from '../../../../store/actions/general.actions';

@Component({
  selector: 'ca-upsert-remark',
  template: `
    <form>
      <h3 *ngIf="showTitle" i18n="@@remarks-title">Remarks</h3>
      <div>
        <mat-form-field style="width:100%">
          <mat-label i18n="@@remark">Remark</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            [formControl]="remark"
          ></textarea>
        </mat-form-field>
      </div>
      <button
        [disabled]="remark.valid === false"
        mat-raised-button
        color="primary"
        (click)="saveRemark()"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </form>
  `,
  styles: [``],
})
export class UpsertRemarkComponent {
  @Input() showTitle = true;
  @Input() context!: string;
  @Input() parentTable!: string;
  @Input() parentId!: number;
  @Input() id = 0;

  constructor(private store: Store<GdprToolState>) {}

  remark = new FormControl<string | null>(null, Validators.required);

  saveRemark() {
    if (this.remark.value !== null) {
      this.store.dispatch(
        UpsertRemark({
          context: this.context,
          id: this.id,
          parentId: this.parentId,
          parentTable: this.parentTable,
          remark: this.remark.value,
        })
      );
    }
  }
}
