import { Component } from '@angular/core';
import { DSR } from '@ca/gdpr';
import { Store } from '@ngrx/store';
import { GdprToolState } from '../../../store';
import { poseQuestionToDataSubject } from '../../../store/actions/dsr.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-dsr-communication-area',
  template: `
    <div class="messages">
      <div class="qna" *ngFor="let qna of dsr?.questionsAndAnswers">
        <mat-divider></mat-divider>
        <div class="question">
          <p class="question bold">
            <ng-container i18n="@@qna-question-title">Question: </ng-container
            >{{ qna.question }}
          </p>
          <span class="timing">{{ qna.createdAt }}</span>
        </div>
        <div class="answer">
          <p class="answer">
            <!-- TODO: translate!! -->
            {{ qna.answer !== null ? qna.answer : '-- AWAITING RESPONSE --' }}
          </p>
          <span *ngIf="qna.answer !== null" class="timing">
            {{ qna.respondedAt }}</span
          >
        </div>
      </div>
    </div>
    <form *ngIf="fg" [formGroup]="fg.additionalInfo">
      <div>
        <mat-form-field style="width: 100%">
          <mat-label i18n="@@new-question"
            >New Question to Data Subject</mat-label
          >
          <textarea
            type="text"
            matInput
            cdkTextareaAutosize
            formControlName="question"
          ></textarea>
        </mat-form-field>
      </div>
      <div>
        <button
          mat-button
          [disabled]="fg.additionalInfo.valid === false"
          (click)="sendQuestion()"
          i18n="@@send-btn"
        >
          SEND <mat-icon>mail_send</mat-icon>
        </button>
      </div>
    </form>
  `,
})
export class DsrCommunicationAreaComponent {
  private sub = new CaSubscriber();
  protected dsr?: DSR;
  fg?: QnAForm;
  constructor(protected store: Store<GdprToolState>) {
    // this.sub.subscribe(this.store.select(selectDSR), {
    //   next: (dsr?: DSR) => {
    //     this.dsr = dsr;
    //     if (dsr !== undefined) {
    //       this.fg = new QnAForm();
    //     }
    //   },
    // });
  }
  sendQuestion() {
    if (this.dsr?.id) {
      const question: string | undefined | null =
        this.fg?.additionalInfo.get('question')?.value;
      if (question !== undefined && question !== null)
        this.store.dispatch(
          poseQuestionToDataSubject({
            id: this.dsr?.id,
            question,
          })
        );
    }
  }
}
export class QnAForm {
  additionalInfo: FormGroup;
  constructor() {
    this.additionalInfo = new FormGroup({
      question: this.newQuestionFC(),
    });
  }

  private newQuestionFC() {
    return new FormControl<string | null>(null, Validators.required);
  }
}
