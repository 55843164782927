//#region Selector Controls
export const DataLocationsSelectorControlInfoHover = $localize`:@@selectors-where-is-data-stored-info-hover:Where will this personal information be stored?`;
export const SecurityMeasuresSelectorInfoHover = $localize`:@@selectors-security-measures-info-hover:What security measures have you taken to protect this data?`;
export const ProcessingPurposesSelectorInfoHover = $localize`:@@selectors-describe-purposes-info-hover:Purposes describe our intent and reason for processing personal data`;
export const DisclosedToSelectorInfoHover = $localize`:@@selectors-who-has-access-info-hover:Who will have access to this personal information?`;
// export const InfoHover = ``;
//#endregion

//#region Settings Resources
export const DataRegisterInfoHover = $localize`:@@data-register-info-hover:Your data register as a collection of processing activities.`;
export const DataSubjectRequestsInfoHover = $localize`:@@dsr-info-hover:Requests to invoke rights by data subjects.`;
export const TransferOutsideEEAInfoHover = $localize`:@@transfers-outside-eea-info-hover:Manage the list of non-EEA countries where personal data travels. Some might have an Adequacy Decision of the European Commision in their favour and are considered to be complient, for others safeguards and transparent communication about these transfers are to be provided.`;
export const SecurityMeasuresInfoHover = $localize`:@@security-measures-info-hover:Manage the security measures taken by your organisation to secure personal data that you store or process.`;
export const ProcessingPurposesInfoHover = $localize`:@@processing-purposes-info-hover:Manage the purposes for processing personal data, these are the main categories in a data register for a particulair subject type.`;
export const ExternalProcessorInfoHover = $localize`:@@external-processors-info-hover:Manage external processors, any external natural person or legal entity which processes personal data on behalf of the controller, as described in GDPR legislation.`; //#endregion
export const ExternalControllersInfoHover = $localize`:@@external-controllers-info-hover:Manage the external controllers, these are external parties that perform the role of controller, as described in GDPR legislation.`;
export const DisclosedToInfoHover = $localize`:@@disclosed-to-info-hover:Manage the departments and external entities to whom personal data is disclosed.`;
export const DataSubjectTypesInfoHover = $localize`:@@data-subject-types-info-hover:Manage the data subject/person types for whom processing of personal data can occur.`;

export const DataLocationsInfoHover = $localize`:@@data-locations-info-hover:Manage locations where personal data is stored, which can be used throughout the application.`;
export const DataCategoriesInfoHover = $localize`:@@data-categories-info-hover:Manage categories of personal data that can be used throughout the application.`;
// export const InfoHover = ``;
//#endregion
