import { BehaviorSubject, Observable, map } from 'rxjs';
import { SettingsState } from '../../../store/reducers/settings.reducer';
import {
  QuestionnaireVM,
  QuestionVM,
  QuestionnaireSummary,
  QuestionnaireConfig,
  QuestionnaireSummaryItem,
  AnswerVM,
} from './models';
import { extractQuestionData } from './utils';

export class Questionnaire {
  private config: QuestionnaireVM;
  private questionBS: BehaviorSubject<QuestionVM>;
  private summaryBS: BehaviorSubject<QuestionnaireSummary>;
  private progressPercentage: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalNumberOfQuestions: number;

  get validAnswer() {
    return (
      this.questionBS.value.optional ||
      this.questionBS.value.answers.findIndex((e) => e.selected) !== -1
    );
  }

  activeQuestion$: Observable<QuestionVM>;
  progress: Observable<number> = this.progressPercentage.asObservable();
  summary: Observable<QuestionnaireSummary>;
  showOther = false;
  otherAnswer: string | null = null;

  constructor(config: QuestionnaireConfig, settingsState: SettingsState) {
    this.config = {
      summaryTitle: config.summaryTitle,
      questions: extractQuestionData(config, settingsState),
      pageTitle: config.pageTitle,
    };

    this.totalNumberOfQuestions = this.config.questions.length;

    this.questionBS = new BehaviorSubject(this.config.questions[0]);

    this.summaryBS = new BehaviorSubject({
      answeredQuestions: new Array<QuestionnaireSummaryItem>(),
      skippedQuestions: new Array<QuestionVM>(),
      title: config.summaryTitle,
    });

    this.progress = this.summaryBS.asObservable().pipe(
      map((a) => {
        return (
          ((a.answeredQuestions.length + a.skippedQuestions.length) /
            this.totalNumberOfQuestions) *
          100
        );
      })
    );

    this.activeQuestion$ = this.questionBS.asObservable();

    this.summary = this.summaryBS.asObservable();
  }

  activateAnswer(question: QuestionVM, answer: AnswerVM) {
    if (answer.onChosen) answer.onChosen(question, answer);

    if (answer.type === 'other') {
      this.showOther = true;
      return;
    }

    // generic logic
    if (
      question.type === 'A/B' ||
      question.type === 'A/B/other' ||
      question.type === 'single' ||
      question.type === 'single/other'
    ) {
      question.answers.forEach((a: AnswerVM) => {
        if (a.id !== answer.id) a.selected = false;
      });
      answer.selected = !answer.selected;
      this.proceed(question);
    } else {
      answer.selected = !answer.selected;
    }
  }

  /**
   * Function is called when user clicks proceed OR an answer to a question with type: single | single/other | A/B | A/B/other.
   * @param question the question to proced from
   */
  proceed(question: QuestionVM) {
    // Answer is recorded and form is invalid, move to the next question

    // ON OTHER
    // Proceed from other screen (user clicked other and entered a valid value in other field)
    if (this.showOther && this.otherAnswer) {
      if (question.type === 'A/B/other' || question.type === 'single/other')
        question.answers = question.answers
          .filter((e) => !(typeof e.id === 'string' && e.id.includes('other-')))
          .map((q) => ({ ...q, selected: false }));

      // add new option to answers list and select it
      const text = (this.otherAnswer as string).toString();
      const id = 'other-' + (this.otherAnswer as string).toLowerCase();
      question = {
        ...question,
        answers: [
          ...question.answers,
          {
            id,
            selected: true,
            text,
            type: 'user defined',
            value: id,
          },
        ],
      };

      // clear and hide other screen
      this.otherAnswer = null;
      this.showOther = false;
      if (question.type.includes('multi')) {
        this.questionBS.next(question);
        return;
      }
    }
    // RECORD QUESTION
    const selectedAnswers = question?.answers.filter((a) => a.selected);
    // const alreadyAnswered =
    //   this.summaryBS.value.answeredQuestions.findIndex(
    //     (a) => a.question.id === currentQuestion.id
    //   ) !== -1;

    console.log(selectedAnswers);

    if (question && selectedAnswers.length > 0) {
      const answer: QuestionnaireSummaryItem = {
        question: {
          id: question.id,
          text: question.summary_label ?? question.text,
          type: question.type,
        },
        answers: selectedAnswers.map((a) => ({
          id: a.id,
          text: a.type === 'other' ? `${this.otherAnswer} (insert)` : a.text,
          type: a.type,
        })),
      };

      this.addToSummary(question, answer);

      // NAVIGATE TO NEXT QUESTION
      const currentIndex = this.config.questions.findIndex(
        (quest) => quest.id === this.questionBS.value.id
      );

      // only check navigateToQuestionID for single, A/B (+other) questions
      if (selectedAnswers[0].navigateToQuestionId) {
        if (
          question.type === 'A/B' ||
          question.type === 'A/B/other' ||
          question.type === 'single' ||
          question.type === 'single/other'
        ) {
          if (currentIndex < 0)
            throw new Error('Current question index could not be determined');
          this.nextQuestionByAnswerOption(selectedAnswers[0]);
        }
      } else if (question.navigateToQuestionId)
        this.nextQuestionByQuestionOption(question);
      else this.nextQuestionDefault(currentIndex + 1);
    } else throw new Error('Question was not defined');
  }

  // TODO:FIX: other answers that were selected are lost when going back
  goBack() {
    if (this.showOther) {
      this.otherAnswer = null;
      this.showOther = false;
      return;
    }

    const findAnswerForActiveQuestion =
      this.summaryBS.value.answeredQuestions.findIndex(
        (e) => e.question.id === this.questionBS.value.id
      );
    const previousQuestioned =
      this.summaryBS.value.answeredQuestions[
        this.summaryBS.value.answeredQuestions.length - 1
      ];

    if (previousQuestioned) {
      const previousQuestion = this.config.questions.find(
        (e) => e.id === previousQuestioned.question.id
      );

      // remove at index findAnswerForActiveQuestion if exists
      if (findAnswerForActiveQuestion >= 0) {
        const answered = this.summaryBS.value.answeredQuestions.pop();
        this.summaryBS.next({
          ...this.summaryBS.value,
        });
        console.log(
          answered?.answers
            .filter((e) => e.type === 'user defined')
            .map((e) => ({
              id: e.id,
              type: e.type,
              text: e.text,
              value: e.id,
              selected: true,
            }))
        );
        if (previousQuestion)
          this.questionBS.next({
            ...previousQuestion,
            answers: [
              ...previousQuestion.answers,
              ...(answered?.answers
                .filter((e) => e.type === 'user defined')
                .map((e) => ({
                  id: e.id,
                  type: e.type,
                  text: e.text,
                  value: e.id,
                  selected: true,
                })) ?? []),
            ],
          });
      } else if (previousQuestion) this.questionBS.next(previousQuestion);
    } else throw new Error('Could not find previous question');
    // set active question to previous question
  }

  private addToSummary(question: QuestionVM, answer: QuestionnaireSummaryItem) {
    const alreadyAnswered =
      this.summaryBS.value.answeredQuestions.findIndex(
        (a) => a.question.id === question.id
      ) !== -1;

    if (alreadyAnswered)
      // replace
      this.summaryBS.next({
        ...this.summaryBS.value,
        answeredQuestions: [
          ...this.summaryBS.value.answeredQuestions.filter(
            (q) => q.question.id !== question.id
          ),
          answer,
        ],
      });
    else
      this.summaryBS.next({
        ...this.summaryBS.value,
        answeredQuestions: [...this.summaryBS.value.answeredQuestions, answer],
      });
  }

  private nextQuestionByAnswerOption(answer: AnswerVM) {
    const nextQuestion = this.config.questions.find(
      (q) => q.id === answer.navigateToQuestionId
    );
    if (!nextQuestion)
      throw new Error(
        `Navigation by answer config failed: Question with ID ${answer.navigateToQuestionId} could not be found.`
      );
    this.questionBS.next(nextQuestion);
  }

  private nextQuestionByQuestionOption(question: QuestionVM) {
    const nextQuestion = this.config.questions.find(
      (q) => q.id === question.navigateToQuestionId
    );
    if (!nextQuestion)
      throw new Error(
        `Navigation by question config failed: Question with ID ${question.navigateToQuestionId} could not be found.`
      );
    this.questionBS.next(nextQuestion);
  }

  private nextQuestionDefault(currentIndex: number) {
    let nextQuestion =
      this.config.questions.length >= currentIndex
        ? this.config.questions[currentIndex]
        : null;

    while (nextQuestion?.skipByDefault === true) {
      this.summaryBS.next({
        ...this.summaryBS.value,
        skippedQuestions: [
          ...this.summaryBS.value.skippedQuestions,
          nextQuestion,
        ],
      });
      currentIndex += 1;
      nextQuestion =
        this.config.questions.length >= currentIndex
          ? this.config.questions[currentIndex]
          : null;
      // TODO: update progress pointer
    }

    // skip next question if optional
    if (!nextQuestion) {
      this.questionBS.complete();
    } else {
      this.questionBS.next(nextQuestion);
    }
  }
}
