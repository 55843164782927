import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CaDataService } from '@ca/ca-data';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';
import { IQueryParameter, CaEnvironment } from '@ca/ca-utils';
import {
  OnboardingState,
  selectActiveDivisionId,
  FEATURE_KEY,
} from '@ca/onboarding';
import { Store } from '@ngrx/store';
import { EMPTY_QUERY_PARAM } from '@ca/ca-ng-core';
import { Subscription } from 'rxjs';
@Injectable()
export class BaseMailService implements OnDestroy {
  baseUrl: string;
  protected companyId?: number;
  protected companyIdSubscription?: Subscription;
  protected sandboxMode: boolean;
  get companyQueryParameter(): IQueryParameter {
    return this.companyId
      ? {
          name: 'companyId',
          value: this.companyId,
        }
      : EMPTY_QUERY_PARAM;
  }

  constructor(
    protected data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected env: CaEnvironment,
    protected store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    this.sandboxMode = this.env.production === false;
    this.baseUrl = `${this.env.apiBaseUrl}/api/mail`;
    this.companyIdSubscription = this.store
      .select(selectActiveDivisionId)
      .subscribe((id) => (this.companyId = id));
  }
  ngOnDestroy(): void {
    this.companyIdSubscription?.unsubscribe();
  }

  protected constructParams(params?: IQueryParameter[]): IQueryParameter[] {
    return params
      ? [...params, this.companyQueryParameter]
      : [this.companyQueryParameter];
  }
}
