import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CreateExternalControllerForm } from '../../../../utils/forms';
import { UpsertExternalControllerActionData } from '@ca/gdpr';
import { UpsertExternalController } from '../../../../store/actions/settings.actions';

@Component({
  selector: 'ca-upsert-external-controller',
  template: `
    <h2 mat-dialog-title>{{ title }}</h2>
    <div mat-dialog-content>
      <form class="upsert-form" [formGroup]="fg">
        <input formControlName="id" hidden />
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for Name|@@name">Name</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n="Form field label for e-mail|@@email"
              >E-Mail</mat-label
            >
            <input matInput type="text" formControlName="email" />
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label i18n="Form field label for phone|@@phone">Phone</mat-label>
          <input matInput type="text" formControlName="phone" />
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n="Form field label for VAT number|@@vat"
            >VAT Number</mat-label
          >
          <input matInput type="text" formControlName="vatNumber" />
        </mat-form-field>

        <mat-divider></mat-divider>
        <ng-container formGroupName="address">
          <div>
            <mat-form-field>
              <mat-label i18n="Form field label for street|@@street"
                >Street</mat-label
              >
              <input matInput type="text" formControlName="street" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label i18n="Form field label for House Number|@@housenr-full"
                >House Number</mat-label
              >
              <input matInput type="number" formControlName="houseNumber" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label
                i18n="
                  Form field label for House Number Suffix|@@housenr-suffix-full"
                >House Number Suffix</mat-label
              >
              <input matInput type="text" formControlName="houseNumberSuffix" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label i18n="Form field label for Postal code|@@housenr-full"
                >House Number</mat-label
              >
              <input matInput type="text" formControlName="postalCode" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label i18n="Form field label for City|@@city"
                >City</mat-label
              >
              <input matInput type="text" formControlName="city" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label i18n="Form field label for Country|@@country"
                >Country</mat-label
              >
              <input matInput type="text" formControlName="country" />
            </mat-form-field>
          </div>
        </ng-container>
      </form>
    </div>
    <mat-dialog-actions align="end">
      <a
        class="cancel-link cancel"
        style="margin-right: 0.66em"
        mat-dialog-close
        i18n="@@cancel-btn"
      >
        Cancel
      </a>
      <button
        mat-raised-button
        color="primary"
        type="button"
        [disabled]="!fg.valid"
        (click)="save()"
        i18n="@@save-btn"
      >
        SAVE
      </button>
    </mat-dialog-actions>
  `,
})
export class UpsertExternalControllerComponent {
  get title() {
    return $localize`:@@create-or-edit-external-controller:${
      this.data.id > 0 ? 'Update' : 'Create'
    } External Controller`;
  }
  fg: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: UpsertExternalControllerActionData,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpsertExternalControllerComponent>,
    private store: Store
  ) {
    this.fg = CreateExternalControllerForm(this.fb, data);
  }

  save() {
    this.store.dispatch(UpsertExternalController(this.fg.value));
    this.dialogRef.close();
  }
}
