import { Inject, Injectable } from '@angular/core';
import { CaDataService } from '@ca/ca-data';
import { CaEnvironment } from '@ca/ca-utils';
import { CrudService } from '../CrudService';
import { Store } from '@ngrx/store';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { ExternalController } from '@ca/gdpr';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class ExternalControllersService extends CrudService<
  ExternalController,
  'put-external-controllers'
> {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, '/api/settings/external-controllers', store);
  }
}
