<mat-grid-list
  cols="2"
  rowHeight="1.66em"
  style="width: 100%"
  class="user-detail-card"
  *ngIf="profile"
>
  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content bold" i18n="@@mobile">Mobile</div>
  </mat-grid-tile>

  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content">
      {{ profile.gsm }}
    </div>
  </mat-grid-tile>

  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content bold" i18n="@@telephone">
      Telephone
    </div>
  </mat-grid-tile>

  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content">
      {{ profile.tel }}
    </div>
  </mat-grid-tile>

  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content bold" i18n="@@preferred-language">
      Preferred Language
    </div>
  </mat-grid-tile>

  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content">
      {{ profile.preferredLanguage }}
    </div>
  </mat-grid-tile>
</mat-grid-list>

<mat-divider style="margin: 0.66em 0" class="sub-divider"></mat-divider>

<mat-grid-list
  cols="3"
  rowHeight="1.66em"
  style="width: 100%"
  class="user-detail-card roles-table"
  *ngIf="profile"
>
  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content bold" i18n="@@division">
      Division
    </div>
  </mat-grid-tile>

  <mat-grid-tile class="user-detail-grid-tile">
    <div class="user-detail-grid-tile-content bold" i18n="@@role">Role</div>
  </mat-grid-tile>

  <mat-grid-tile class="user-detail-grid-tile bold">
    <div class="user-detail-grid-tile-content" i18n="@@job-function">
      Job Title
    </div>
  </mat-grid-tile>

  <ng-container *ngFor="let role of profile.linkedDivisions">
    <mat-grid-tile class="user-detail-grid-tile">
      <div class="user-detail-grid-tile-content">
        {{ role.division.name }}
      </div>
    </mat-grid-tile>

    <mat-grid-tile class="user-detail-grid-tile">
      <div class="user-detail-grid-tile-content">
        {{ role.role.name }}
      </div>
    </mat-grid-tile>

    <mat-grid-tile class="user-detail-grid-tile">
      <div class="user-detail-grid-tile-content">
        {{ role.jobTitle }}
      </div>
    </mat-grid-tile>
  </ng-container>
</mat-grid-list>
