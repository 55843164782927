import { Inject, Injectable } from '@angular/core';
import { CrudService } from '../CrudService';
import { ReplyToAddress } from '@ca/ca-mailing';
import { CaDataService } from '@ca/ca-data';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';
import { CaEnvironment } from '@ca/ca-utils';
import { OnboardingState, FEATURE_KEY } from '@ca/onboarding';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ReplyToAdressesService extends CrudService<ReplyToAddress> {
  constructor(
    protected override data: CaDataService,
    @Inject(CA_ENVIRONMENT) protected override env: CaEnvironment,
    protected override store: Store<{ [FEATURE_KEY]: OnboardingState }>
  ) {
    super(data, env, '/api/settings/reply-to-addresses', store);
  }
}
