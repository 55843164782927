import { NavLink, NavLinkGroup } from '../models/navigation.models';

export const APP_ROUTES: { [key: string]: string } = {
  // authenticated users
  dashboard: 'dashboard',
  appSettings: 'app-settings',
  dataregister: 'dataregister',
  dataregisterProcessor: 'processor-register',
  dataregisterController: 'controller-register',
  upsertRoPA: 'upsert-ropa',
  dsrs: 'data-subject-access-requests',
  procedures: 'procedures',
  procedureCategories: 'procedure-categories',
  companySettings: 'company-settings',
  dataRegisterQuestionnaire: 'questionnaire',

  // settings
  dataLocations: 'data-locations',
  processors: 'external-processors',
  controllers: 'external-controllers',
  dataSubjectTypes: 'data-subject-types',
  securityMeasures: 'security-measures',
  // eMailTemplates: 'mail-templates',
  legalGrounds: 'legal-grounds',
  departments: 'disclosed-to',
  transfersOutsideEEA: 'transfers-outside-eea',
  processingPurposes: 'processing-purposes',
  // replyToAddresses: 'reply-to-addresses',
  dataCategories: 'data-cateogries',

  // admin/developer only
  team: 'team',
};

export const NAVIGATION: NavLink[] = [
  new NavLink(
    $localize`:@@nav-link-home:Home`,
    APP_ROUTES['dashboard'],
    $localize`:@@nav-link-info-home:GDPR Tool Dashboard`,
    'home',
    true
  ),
  new NavLink(
    $localize`:@@nav-link-data-register-controller:Register as Controller`,
    APP_ROUTES['dataregisterController'],
    $localize`:@@nav-link-info-data-register-controller:Manage your dataregister, an extensive list of your processing activities as controller.`,
    'menu_book'
  ),
  new NavLink(
    $localize`:@@nav-link-data-register-processor:Register as Processor`,
    APP_ROUTES['dataregisterProcessor'],
    $localize`:@@nav-link-info-data-register-processor:Manage your dataregister, an extensive list of your processing activities as processor.`,
    'menu_book'
  ),
  // new NavLink(
  //   $localize`:@@nav-link-dsr:Requests`,
  //   APP_ROUTES['dsrs'],
  //   $localize`:@@nav-link-info-dsr:Register and follow up on DSR's.`,
  //   'emoji_people'
  // ),
  // new NavLink(
  //   $localize`:@@nav-link-team:Team`,
  //   APP_ROUTES['team'],
  //   $localize`:@@nav-link-info-team:Manage your Team (Administrator only access)`,
  //   'groups'
  // ),
  new NavLink(
    $localize`:@@nav-link-company-settings:Company Settings`,
    APP_ROUTES['companySettings'],
    $localize`:@@nav-link-info-company-settings:Manage your company settings to assign roles and information about your company.`,
    'business'
  ),
  new NavLinkGroup(
    $localize`:@@nav-link-app-settings:App settings`,
    [
      new NavLink(
        $localize`:@@nav-link-data-categories:Data Categories`,
        APP_ROUTES['dataCategories'],
        $localize`:@@nav-link-info-data-categories:Manage Data Categories`
        // 'category'
      ),
      new NavLink(
        $localize`:@@nav-link-data-locations:Data Locations`,
        APP_ROUTES['dataLocations'],
        $localize`:@@nav-link-info-data-locations:Manage Data Locations`
        // 'pin_drop'
      ),
      new NavLink(
        $localize`:@@nav-link-data-subject-types:Data Subject Types`,
        APP_ROUTES['dataSubjectTypes'],
        $localize`:@@nav-link-info-data-subject-types:Manage Data Subject Types`
        // 'groups_3'
      ),
      // new NavLink(
      //   $localize`:@@nav-link-e-mail-templates:E-Mail Templates`,
      //   APP_ROUTES['eMailTemplates'],
      //   $localize`:@@nav-link-info-e-mail-templates:Manage your E-Mail Templates`
      // ),
      new NavLink(
        $localize`:@@nav-link-external-processors:External Processors`,
        APP_ROUTES['processors'],
        $localize`:@@nav-link-info-external-processors:Manage your external processors`
      ),
      new NavLink(
        $localize`:@@nav-link-external-controllers:External Controllers`,
        APP_ROUTES['controllers'],
        $localize`:@@nav-link-info-external-controllers:Manage your external controllers`
      ),
      // new NavLink(
      //   $localize`Legal Grounds`,
      //   APP_ROUTES['legalGrounds'],
      //   $localize`Manage Legal Grounds`
      // ),
      new NavLink(
        $localize`:@@nav-link-transfers-outside-eea:Transfers Outside EEA`,
        APP_ROUTES['transfersOutsideEEA'],
        $localize`:@@nav-link-info-transfers-outside-eea:Manage Transfers Outside EEA`
      ),
      new NavLink(
        $localize`:@@nav-link-processing-purposes:Processing Purposes`,
        APP_ROUTES['processingPurposes'],
        $localize`:@@nav-link-info-processing-purposes:Manage Processing Purposes`
      ),
      // new NavLink(
      //   $localize`:@@nav-link-reply-to-addresses:Reply-to Addresses`,
      //   APP_ROUTES['replyToAddresses'],
      //   $localize`:@@nav-link-info-reply-to-addresses:Manage Reply-to Addresses`
      // ),
      new NavLink(
        $localize`:@@nav-link-security-measures:Security Measures`,
        APP_ROUTES['securityMeasures'],
        $localize`:@@nav-link-info-security-measures:Manage Security Measures`
      ),
      new NavLink(
        $localize`:@@nav-link-departments:Departments & Entities`,
        APP_ROUTES['departments'],
        $localize`:@@nav-link-info-departments:Manage departments and Entities inside or outside your organisation with whom you share personal data.`
      ),
      // new NavLink('', APP_ROUTES[''], '', false)
    ],
    'settings'
  ),
];
