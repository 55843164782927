import { Inject, Injectable } from '@angular/core';
import { CaDataResponse, CaDataService } from '@ca/ca-data';
import { UploadFilesActionData } from '../../store/models';
import { CaEnvironment } from '@ca/ca-utils';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private url: string;
  constructor(
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment,
    private data: CaDataService
  ) {
    this.url = `${env.apiBaseUrl}/api/general/media`;
  }
  upload(value: UploadFilesActionData) {
    const fd = new FormData();
    value.files.forEach((e, i) => {
      fd.set('file' + i, e);
    });
    fd.set('length', value.files.length.toString());
    fd.set('parentTable', value.parentTable);
    if (value.parentId) fd.set('parentId', value.parentId.toString());
    fd.set('context', value.context);
    fd.set('companyId', value.companyId.toString());
    return this.data.post<FormData, CaDataResponse<MediaUploadResult[]>>(
      this.url,
      fd
    );
  }
}

export interface MediaUploadResult {
  id: number;
  name: string;
  url: string;
}
