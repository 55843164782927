import { createAction, props } from '@ngrx/store';
import { PublicQnaData } from '../../services/public/dsr-data-subject-qna.service';

export enum GdprToolPublicActionType {
  LoadQuestions = '[QnAs] Load Questions',
  LoadQuestionsSuccess = '[QnAs] Load Questions [Success]',
  SetHistoryVisibility = '[QnAs] Set History Visibility',
}
export const LoadQuestions = createAction(
  GdprToolPublicActionType.LoadQuestions,
  props<{ uuid: string }>()
);
export const LoadQuestionsSuccess = createAction(
  GdprToolPublicActionType.LoadQuestionsSuccess,
  props<{ questions: PublicQnaData[] | undefined }>()
);
export const SetHistoryVisibility = createAction(
  GdprToolPublicActionType.SetHistoryVisibility,
  props<{ showHistory: boolean }>()
);
