import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OnboardingState } from '@ca/onboarding';
import { Store } from '@ngrx/store';
import {
  GdprToolState,
  selectControllerGroups,
  selectProcessingPurposes,
  selectProcessorGroups,
} from '../../store';
import {
  ProcessingActivityGroup,
  ProcessingActivityGroups,
  ProcessingActivityRole,
  ProcessingActivityRow,
  ProcessingPurpose,
} from '@ca/gdpr';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import {
  IndexedName,
  CaSubscriber,
  onlyUniqueIndexedNames,
  GroupBy,
} from '@ca/ca-utils';
import {
  UpsertDataSubjectPurposeFromRegister,
  DeleteRoPAGroup,
  ExportDataRegister,
} from '../../store/actions/data-register.actions';
import { DispatchAddCategoryFromRegisterAction } from '../../utils/data-register';
import { MISSING_INPUT_MESSAGE } from '../../config/errors';
import { MatDialog } from '@angular/material/dialog';
import { AddSubjectRegisterDialogComponent } from '../data-register/add-subject-register-dialog/add-subject-register-dialog.component';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  templateUrl: './data-register.component.html',
  selector: 'ca-dataregister',
  styles: [
    `
      .data-register-actions {
        display: flex;
        justify-content: flex-end;
        column-gap: 0.66em;
      }
    `,
  ],
})
export class DataregisterComponent implements OnInit, OnDestroy {
  @Input() role!: ProcessingActivityRole;
  @ViewChild(MatTabGroup) set content(g: MatTabGroup) {
    this.tabGroup = g;
  }
  tabGroup?: MatTabGroup;
  groups?: Map<string, ProcessingActivityGroups>;
  vm: DataRegisterVM = {};
  dataSubjectTypes: IndexedName[] = [];
  purposes: ProcessingPurpose[] = [];

  private sub = new CaSubscriber();
  private roPAGroupsObserver = {
    next: (groups: ProcessingActivityGroups | undefined) => {
      if (groups) {
        this.dataSubjectTypes = groups
          .map((e) => ({
            id: e.dataSubjectType.id,
            name: e.dataSubjectType.name,
          }))
          .filter(onlyUniqueIndexedNames);

        this.groups = GroupBy(groups, (g) => g.dataSubjectType.name);
        this.groups.forEach((v, k) => {
          const purposes = v
            .map((x) => x.purpose)
            .filter(onlyUniqueIndexedNames);
          this.vm[k] = [];
          purposes.forEach((p) => {
            const data = v.find(
              (y) => y.dataSubjectType.name === k && y.purpose.id === p.id
            );
            const rows = data?.rows ?? [];

            if (data) {
              const PurposeVM = {
                purposeId: p.id,
                dataSubjectTypeId:
                  this.dataSubjectTypes.find((e) => e.name === k)?.id ?? -1,
                title: p.name,
                base: data,
                datasource: new MatTableDataSource(rows),
              };
              if (PurposeVM.dataSubjectTypeId > 0) this.vm[k].push(PurposeVM);
              else
                throw new Error(
                  'Data Subject Type Id could not be mapped.\n' +
                    JSON.stringify(PurposeVM.base, null, 4)
                );
            }
          });
        });
      }
    },
  };

  constructor(
    protected store: Store<GdprToolState & { onboarding: OnboardingState }>,
    private fb: FormBuilder,
    protected dialog: MatDialog
  ) {
    this.sub.subscribe(this.store.select(selectProcessingPurposes), {
      next: (purposes?: ProcessingPurpose[]) => {
        if (purposes) this.purposes = purposes;
      },
    });
  }
  ngOnInit(): void {
    if (!this.role) throw new Error(MISSING_INPUT_MESSAGE('role'));
    this.sub.subscribe(
      this.store.select((state) =>
        this.role === 'controller'
          ? selectControllerGroups(state)
          : selectProcessorGroups(state)
      ),
      this.roPAGroupsObserver
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  addCategory(group: ProcessingActivityGroup) {
    DispatchAddCategoryFromRegisterAction(this.store, group);
  }

  upsertPurposeGroup(
    dataSubjectType: IndexedName,
    recordToUpdate?: ProcessingActivityGroup
  ) {
    this.store.dispatch(
      UpsertDataSubjectPurposeFromRegister({
        role: this.role,
        dataSubjectType,
        recordToUpdate: recordToUpdate,
        excludePurposeOptions: this.getPurposesToExclude(dataSubjectType),
      })
    );
  }

  private getPurposesToExclude(dataSubjectType: IndexedName) {
    return this.vm[dataSubjectType.name].map((g) => g.purposeId);
  }

  deletePurposeGroup(id: number) {
    this.store.dispatch(DeleteRoPAGroup({ id }));
  }
  addSubject() {
    const role = this.role;
    this.dialog.open(AddSubjectRegisterDialogComponent, {
      data: {
        role,
      },
      disableClose: true,
    });
  }
  exportRegister(role: ProcessingActivityRole | 'all') {
    this.store.dispatch(
      ExportDataRegister({
        role,
      })
    );
  }
  activeTabIndex = 0;
  checkAdd(event: MatTabChangeEvent) {
    console.log('tab changed', event);
    if (event.tab.textLabel === '+' && this.tabGroup) {
      // reset tab to previous
      this.tabGroup.selectedIndex = this.activeTabIndex;
      // this.addSubject();
    } else this.activeTabIndex = event.index;
  }
}

export interface DataRegisterVM {
  [key: string]: DataRegisterRoPAGroupVM[];
}

export interface DataRegisterRoPAGroupVM {
  purposeId: number;
  dataSubjectTypeId: number;
  title: string;
  base: ProcessingActivityGroup;
  datasource: MatTableDataSource<ProcessingActivityRow>;
}
