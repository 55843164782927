import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  SendSystemMessage,
  SendSystemMessageSuccess,
  dismissSystemMessage,
  dismissSystemMessageSuccess,
  loadSystemMessages,
  loadSystemMessagesSuccess,
} from '../actions/general.actions';
import { map, switchMap } from 'rxjs';
import { MessagesService } from '../../services/core/messages.service';
import {
  LoggingService,
  NotificationFactory,
  QueueNotification,
} from '@ca/ca-ng-core';
import { CreateSystemMessageData } from '../../models/messaging.models';
import { DismissSystemMessageActionData } from '../models';
import { PostResult } from '@ca/ca-data';
import { HttpResponse } from '@angular/common/http';

import { onboardingActions } from '@ca/onboarding';
import { CaSubscriber } from '@ca/ca-utils';
import { Store } from '@ngrx/store';
import { LoadCompanySettings } from '../actions/company-settings.actions';
import { LoadDataRegister } from '../actions/data-register.actions';
import { loadDataSubjectRequests } from '../actions/dsr.actions';
import {
  loadDataLocations,
  loadDataSubjectTypes,
  loadSecurityMeasures,
  loadExternalProcessors,
  LoadExternalControllers,
  loadDisclosedTo,
  loadLegalGrounds,
  loadTransfersOutsideEEA,
  loadProcessingPurposes,
  loadDataCategories,
} from '../actions/settings.actions';

@Injectable()
export class GeneralEffects implements OnDestroy {
  private nf = new NotificationFactory('GeneralEffects');
  private sub = new CaSubscriber();

  login$ = this.actions$.pipe(ofType(onboardingActions.loginSuccess));
  constructor(
    private actions$: Actions,
    private messages: MessagesService,
    private store: Store,
    private logger: LoggingService
  ) {
    this.sub.subscribe(this.login$, {
      next: (res) => {
        this.loadSettingsData();
      },
    });
  }

  private loadSettingsData() {
    this.logger.log('loading settings data');
    this.store.dispatch(loadSystemMessages());
    this.store.dispatch(loadDataLocations());
    this.store.dispatch(loadDataSubjectTypes());
    this.store.dispatch(loadSecurityMeasures());
    this.store.dispatch(loadExternalProcessors());
    this.store.dispatch(LoadExternalControllers());
    this.store.dispatch(loadDisclosedTo());
    this.store.dispatch(loadLegalGrounds());
    this.store.dispatch(LoadCompanySettings());
    this.store.dispatch(loadTransfersOutsideEEA());
    this.store.dispatch(loadProcessingPurposes());
    this.store.dispatch(loadDataCategories());
    this.store.dispatch(loadDataSubjectRequests());
    this.store.dispatch(LoadDataRegister());
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  //#region System Messages
  onLoadSystemMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSystemMessages),
      switchMap(() =>
        this.messages
          .get()
          .pipe(
            map((msgs) =>
              msgs.ok && msgs.body !== null
                ? loadSystemMessagesSuccess({ messages: msgs.body.data })
                : QueueNotification(
                    this.nf.createError('system messages', 'get')
                  )
            )
          )
      )
    )
  );

  onAddSystemMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendSystemMessage),
      switchMap((value: CreateSystemMessageData) =>
        this.messages
          .upsert(value)
          .pipe(
            map((res) =>
              res.ok && res.body !== null && res.body.id > 0
                ? SendSystemMessageSuccess()
                : QueueNotification(
                    this.nf.createError('system messages', 'upsert')
                  )
            )
          )
      )
    )
  );

  onDismissSystemMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dismissSystemMessage),
      switchMap((value: DismissSystemMessageActionData) =>
        this.messages.setMessageRead(value.id).pipe(
          map((res: HttpResponse<PostResult>) =>
            res.ok && res.body != null && res.body.success
              ? dismissSystemMessageSuccess({
                  request: value,
                  response: res.body,
                })
              : QueueNotification(
                  this.nf.createError('Could not dismiss system message')
                )
          )
        )
      )
    )
  );
  //#endregion
}
